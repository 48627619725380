import {IAction} from '../../core.types'
import {IGiftFilesState} from '../gift-file.types'
import {giftFilesDownloadAction} from './gift-file.actions'
import {blob2url} from '../../utils/shared.utils'

export const giftFileReducer = (state: IGiftFilesState | null = null, action: IAction): IGiftFilesState | null => {
    switch (action.type) {
        case giftFilesDownloadAction.successActionType: {
            const id = action.meta.meta.queryParameter.campaignId
            return {
                ...state,
                [id]: blob2url(action.payload),
            }
        }
        default:
            return state
    }
}
import {FC, useEffect, useMemo} from 'react'
import {useAccessedCampaignItemStyles} from './campaign-item.styles'
import {AccessedCampaignItem} from './AccessedCampaignItem'
import {useTranslation} from 'react-i18next'
import {ECampaignStatus, EGiftType} from 'dst-react-core/campaign/campaign.types'
import Typography from '@material-ui/core/Typography'
import LiveTvIcon from '@material-ui/icons/LiveTv'
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary'
import QueueMusic from '@material-ui/icons/QueueMusic'
import FileCopy from '@material-ui/icons/FileCopy'
import VideocamIcon from '@material-ui/icons/Videocam'
import LibraryBooks from '@material-ui/icons/LibraryBooks'
import MusicVideo from '@material-ui/icons/MusicVideo'
import {useDownloadImage} from 'dst-react-core/image/download-image.hook'
import {useSelector} from 'react-redux'
import {myCampaignsImageSelector} from '../../../redux/homepage.selectors'

export interface IContainerAccessedCampaignItemProps {
    campaignName: string,
    campaignDescription: string
    campaignReview: number,
    campaignPreviewImageId: string,
    campaignLogoImageId: string,
    campaignGiftType: EGiftType
    campaignStatus: ECampaignStatus,
    goToCampaignPage: () => void
}

export const ContainerAccessedCampaignItem: FC<IContainerAccessedCampaignItemProps> = props => {
    const {campaignGiftType, campaignStatus, campaignPreviewImageId, campaignLogoImageId} = props

    const loadImageCallback = useDownloadImage()

    const logoSrc = useSelector(myCampaignsImageSelector(+campaignLogoImageId))
    const previewSrc = useSelector(myCampaignsImageSelector(+campaignPreviewImageId))

    const accessedCampaignItemClasses = useAccessedCampaignItemStyles()
    const {t} = useTranslation()

    const viewWishLabel = t('general_dashboard.accessed_campaigns.view_wish')

    useEffect(
        () => {
            loadImageCallback(campaignPreviewImageId)
            loadImageCallback(campaignLogoImageId)
        },
        [loadImageCallback, campaignLogoImageId, campaignPreviewImageId],
    )

    const giftTypeTitle: JSX.Element = useMemo(
        () => {
            switch (campaignGiftType) {
                case EGiftType.FILE:
                    return (
                        <>
                            <FileCopy className={'icon'}/>
                            <span>{t('gift_type.file')}</span>
                        </>
                    )
                case EGiftType.VOUCHER:
                    return (
                        <>
                            <LibraryBooks className={'icon'}/>
                            <span>{t('gift_type.voucher')}</span>
                        </>
                    )
                case EGiftType.LIVESTREAM:
                    return (
                        <>
                            <LiveTvIcon className={'icon'}/>
                            <span>{t('gift_type.livestream')}</span>
                        </>
                    )
                case EGiftType.SINGLE_VIDEO:
                    return (
                        <>
                            <VideocamIcon className={'icon'}/>
                            <span>{t('gift_type.single_video')}</span>
                        </>
                    )
                case EGiftType.MULTI_VIDEO:
                    return (
                        <>
                            <VideoLibraryIcon className={'icon'}/>
                            <span>{t('gift_type.multi_video')}</span>
                        </>
                    )
                case EGiftType.SINGLE_PODCAST:
                    return (
                        <>
                            <MusicVideo className={'icon'}/>
                            <span>{t('gift_type.single_podcast')}</span>
                        </>
                    )
                case EGiftType.MULTI_PODCAST:
                    return (
                        <>
                            <QueueMusic className={'icon'}/>
                            <span>{t('gift_type.multi_podcast')}</span>
                        </>
                    )
            }
        },
        [t, campaignGiftType],
    )

    const chipLabel = useMemo(
        () => {
            switch (campaignStatus) {
                case ECampaignStatus.PUBLISHED:
                    return t('general_dashboard.filter.statuses.on_going')
                case ECampaignStatus.CLOSED:
                    return t('general_dashboard.filter.statuses.terminate')
                default:
                    return t('general_dashboard.filter.statuses.not_ready')
            }
        },
        [t, campaignStatus],
    )

    return (
        <AccessedCampaignItem
            {...props}
            accessedCampaignItemClasses={accessedCampaignItemClasses}
            viewWishLabel={viewWishLabel}
            giftTypeTitle={giftTypeTitle}
            chipLabel={chipLabel}
            campaignLogoImageSrc={logoSrc}
            campaignPreviewImageSrc={previewSrc}
        />
    )
}
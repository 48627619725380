import {useTranslation} from 'react-i18next'
import ContactsIcon from '@material-ui/icons/Contacts'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'
import {useMemo} from 'react'
import {
    GENERAL_PROFILE_SUBROUTING,
} from '../../profile-routes'

export interface IProfileNavigationConfig {
    title: string,
    icon: JSX.Element,
    link: string
}

export const useProfileNavigationConfig = (): IProfileNavigationConfig[] => {
    const {t} = useTranslation()

    return useMemo(() => [
        {
            title: t('general_dashboard.account_overview'),
            icon: <ContactsIcon/>,
            link: GENERAL_PROFILE_SUBROUTING.overview.path,
        },
        {
            title: t('general_dashboard.personal_profile'),
            icon: <AccountCircleIcon/>,
            link: GENERAL_PROFILE_SUBROUTING.profile.path,
        },
        {
            title: t('general_dashboard.modify_password'),
            icon: <VerifiedUserIcon/>,
            link: GENERAL_PROFILE_SUBROUTING.password.path,
        },
    ], [t])
}
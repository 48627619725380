import * as React from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import {SelectProps} from '@material-ui/core/Select/Select'
import {IOption} from 'dst-react-core/core.types'
import { useTranslation } from 'react-i18next'

export interface IGenericSelectProps extends SelectProps {
    helperText?: React.ReactNode
    options: IOption[]
    withEmpty?: boolean
}

const GenericSelect: React.FunctionComponent<IGenericSelectProps> = props => {
    const {
        label,
        helperText,
        withEmpty,
        options,
        className,
        ...rest
    } = props

    const {t} = useTranslation()

    return (
        <FormControl variant="outlined" className={className}>
            <InputLabel>{label}</InputLabel>
            <Select label={label} {...rest}>
                {withEmpty && <MenuItem value="">None</MenuItem>}
                {options.map(opt => <MenuItem value={opt.value} key={opt.value}>{t(`date.months.${opt.label.toLowerCase() || opt.value.toLowerCase()}`)}</MenuItem>)}
            </Select>
            <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
    )
}

export default GenericSelect

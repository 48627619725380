import * as React from 'react'
import {useTranslation} from 'react-i18next'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import List from '@material-ui/core/List'
import {memo} from 'react'

interface IWishPageHeaderRateActionsProps {
    valuateCampaign: (value: number) => any
}

export const WishPageHeaderRateActions: React.FunctionComponent<IWishPageHeaderRateActionsProps> = memo(({valuateCampaign}) => {

    const {t} = useTranslation()

    const handleValuateCampaign = (value: number) => () => valuateCampaign(value)

    return (
        <List>
            <ListItem button onClick={handleValuateCampaign(1)}>
                <ListItemText primary={t('header.rate.terrible')} />
            </ListItem>
            <ListItem button onClick={handleValuateCampaign(2)}>
                <ListItemText primary={t('header.rate.disappointing')} />
            </ListItem>
            <ListItem button onClick={handleValuateCampaign(3)}>
                <ListItemText primary={t('header.rate.enough')} />
            </ListItem>
            <ListItem button onClick={handleValuateCampaign(4)}>
                <ListItemText primary={t('header.rate.good')} />
            </ListItem>
            <ListItem button onClick={handleValuateCampaign(5)}>
                <ListItemText primary={t('header.rate.great')} />
            </ListItem>
        </List>
    )
})

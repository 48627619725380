import React, {FC, memo, MutableRefObject} from 'react'
import {IContainerGeneralHeaderSearchBarProps} from './ContainerGeneralHeaderSearchBar'
import {CircularProgress, TextField} from '@material-ui/core'
import Chip from '@material-ui/core/Chip'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import clsx from 'clsx'
import SearchIcon from '@material-ui/icons/Search'

export interface IGeneralHeaderSearchBarProps extends IContainerGeneralHeaderSearchBarProps {
    searchBarClasses: any,
    isChipShown: boolean,
    setChipShownCallback: (isChipShown: boolean) => void,
    chipLabel: string,
    setChipLabelCallback: (label: string) => void,
    searchbarPlaceholderLabel: string,
    searchbarInputRef: MutableRefObject<HTMLInputElement | null>,
    isMyCampaignsListInPending: boolean,
}

export const GeneralHeaderSearchBar: FC<IGeneralHeaderSearchBarProps> = memo(props => {
    const {
        searchBarClasses,
        searchValue,
        setSearchValueCallback,
        isChipShown,
        setChipShownCallback,
        chipLabel,
        setChipLabelCallback,
        searchbarPlaceholderLabel,
        searchbarInputRef,
        isMyCampaignsListInPending,
        textFieldClassName,
        isSideMenu,
        placeholder
    } = props

    return (
        <form
            className={searchBarClasses.searchbarWrap}
            onSubmit={
                (e) => {
                    e.preventDefault()

                    setChipLabelCallback(searchValue)

                    if (!isSideMenu) {
                        setChipShownCallback(true)
                        setSearchValueCallback('')
                    }
                }
            }
        >
            <TextField
                className={clsx('searchInputWrap', {searchInputWrapMobile: isSideMenu}, textFieldClassName)}
                value={searchValue}
                onChange={e => setSearchValueCallback(e.target.value)}
                size={'medium'}
                fullWidth
                disabled={!isSideMenu && isChipShown}
                InputProps={
                    {
                        className: 'searchInput',
                        placeholder: isChipShown ? ('') : (placeholder || searchbarPlaceholderLabel),
                        ref: searchbarInputRef,
                        startAdornment: (
                            isChipShown && (
                                <Chip
                                    className={'chip'}
                                    label={chipLabel}
                                    color={'primary'}
                                    deleteIcon={<HighlightOffIcon/>}
                                    onDelete={() => {
                                        setChipShownCallback(false)
                                    }}
                                />
                            )
                        ),
                        endAdornment: (
                            <div className={'endAdornment'}>
                                {
                                    isMyCampaignsListInPending && (
                                        <CircularProgress
                                            className={'icon'}
                                        />
                                    )
                                }

                                {
                                    (isSideMenu && !isMyCampaignsListInPending) && (
                                        <SearchIcon
                                            className={'icon'}
                                            onClick={
                                                () => setChipLabelCallback(searchValue)
                                            }
                                        />
                                    )
                                }
                            </div>
                        ),
                        disableUnderline: isSideMenu,
                    }
                }
            />
        </form>
    )
})
import {IAction} from '../../core.types'
import {IImagesState} from '../image.types'
import {downloadAction} from './image.actions'
import {blob2url} from '../../utils/shared.utils'

export const imageReducer = (state: IImagesState | null = null, action: IAction): IImagesState | null => {
    switch (action.type) {
        case downloadAction.successActionType: {
            const id = action.meta.meta.pathVariable.id
            return {
                ...state,
                ...!state?.[id] && {
                    [id]: blob2url(action.payload),
                },
            }
        }
        default:
            return state
    }
}
import makeStyles from '@material-ui/core/styles/makeStyles'
import {EXTRA_COLORS_PALETTE} from '../../../../../../../../material.theme'
import {flex, resetUl} from '../../../../../../../../dst-react-core/utils/styles.utils'

export const useGeneralHeaderFilterCategoryTabStyles = makeStyles(() => ({
    categoryTabWrap: {
        ...flex(false, false, 20),
        width: '100%',
        flexDirection: 'column',
        '& .timeFilter, & .giftTypeFilter': {
            ...resetUl(),
            ...flex(true, false, 20, 'initial'),
            width: '100%',
        },
        '& .timeFilter': {
            '& .label': {
                fontWight: 500,
                color: EXTRA_COLORS_PALETTE.GREY.GENERAL_TEXT,
                fontSize: 14,
                '& .icon': {
                    fontSize: 16,
                    marginLeft: 5
                }
            },
            '& .expirationDateLabel': {
                borderBottom: `1px solid ${EXTRA_COLORS_PALETTE.GENERAL_BORDER.DEFAULT_WITH_OPACITY}`,
            },
        },
        '& .giftTypeFilter': {},
    },
}))
import {FC, memo} from 'react'
import {IContainerProfileFormProps} from './ContainerProfileForm'
import {FormikContextType} from 'formik'
import {IGenderConfigItem, IProfileFormValues} from './profile-form.types'
import {ProfileFormBirthDateField} from './pure-components/ProfileFormBirthDateField'
import {ProfileFormGenderField} from './pure-components/ProfileFormGenderField'
import Button from '@material-ui/core/Button'
import {PROFILE_FORM_FIELDS} from './profile-form.constants'
import {ContainerProfileTextField} from '../../shared/form-controls/ContainerProfileTextField'

export interface IProfileFormProps extends IContainerProfileFormProps {
    profileSubPagesFormClasses: any,
    formik: FormikContextType<IProfileFormValues>,
    nameLabel: string
    surnameLabel: string
    emailLabel: string
    birthDateLabel: string
    genderLabel: string
    genderConfigItems: IGenderConfigItem[],
    saveDataLabel: string
}

export const ProfileForm: FC<IProfileFormProps> = memo(props => {
    const {
        profileSubPagesFormClasses,
        formik,
        nameLabel,
        surnameLabel,
        emailLabel,
        birthDateLabel,
        genderLabel,
        genderConfigItems,
        saveDataLabel,
    } = props

    return (
        <form className={profileSubPagesFormClasses.formWrap}>
            <ContainerProfileTextField
                formik={formik}
                fieldName={PROFILE_FORM_FIELDS.name}
                title={nameLabel}
            />

            <ContainerProfileTextField
                formik={formik}
                fieldName={PROFILE_FORM_FIELDS.surname}
                title={surnameLabel}
            />

            <ProfileFormBirthDateField
                formik={formik}
                title={birthDateLabel}
                profileFormClasses={profileSubPagesFormClasses}
            />

            <ContainerProfileTextField
                formik={formik}
                fieldName={PROFILE_FORM_FIELDS.email}
                title={emailLabel}
            />

            <ProfileFormGenderField
                title={genderLabel}
                formik={formik}
                profileFormClasses={profileSubPagesFormClasses}
                genderConfigItems={genderConfigItems}
            />

            <Button
                className={'saveDataButton'}
                variant={'contained'}
                onClick={() => formik.handleSubmit()}
            >
                {saveDataLabel}
            </Button>
        </form>
    )
})
import {useTranslation} from 'react-i18next'
import {useGeneralLoginStyles} from './general-login.styles'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import GeneralLoginForm from './components/GeneralLoginForm'
import Typography from '@material-ui/core/Typography'
import Logo from 'assets/images/logo.png'


const GeneralLogin: React.FunctionComponent = () => {
    const classes = useGeneralLoginStyles()

    const {t} = useTranslation()

    return (
        <Box className={classes.root}>
            <Paper elevation={0} className={classes.paper}>
                <Box className={classes.logo}>
                    <img src={Logo} alt='logo' />
                    <Typography variant={'caption'}>{t('auth.login.title')}</Typography>
                </Box>
                <GeneralLoginForm />
            </Paper>
        </Box>
    )
}

export default GeneralLogin

import {FC, memo} from 'react'
import {Checkbox, FormControlLabel} from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

interface IAccessOverviewMarketingConsentControlProps {
    isMarketingConsentAllowed: boolean,
    setMarketingConsentCallback: (e: React.ChangeEvent<HTMLInputElement>) => void,
    marketingConsentTextLabel: string
}

export const AccessOverviewMarketingConsentControl: FC<IAccessOverviewMarketingConsentControlProps> = memo(props => {
    const {
        setMarketingConsentCallback,
        marketingConsentTextLabel,
        isMarketingConsentAllowed,
    } = props

    return (
        <Typography className={'applyMarketingConsent'}>
            <FormControlLabel
                control={(
                    <Checkbox
                        className={'checkbox'}
                        checked={isMarketingConsentAllowed}
                        onChange={setMarketingConsentCallback}
                    />
                )}
                label={(
                    <span className={'text'}>
                        {
                            marketingConsentTextLabel
                        }
                    </span>
                )}
            />
        </Typography>
    )
})
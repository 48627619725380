import {IAction} from 'dst-react-core/core.types'
import {ECampaignPageName, IWebAppCampaignState} from 'dst-react-core/campaign/campaign.types'
import {
    campaignFetchAction,
    campaignHasAccessAction,
    campaignPageDataAction, CLEAR_CAMPAIGN_DATA_TYPE,
    CLEAR_CAMPAIGN_PAGE_DATA_TYPE, CLEAR_CAMPAIGN_WISH_PAGE,
} from './campaign.actions'
import {
    myReviewByCampaignGetAction,
    reviewSummaryGetAction,
    reviewWritePostAction,
} from 'dst-react-core/review/redux/review.actions'
import {giftsFetchAction} from 'dst-react-core/gift/redux/gift.actions'
import {eclexiaGetLiveProgramAction} from 'dst-react-core/eclexia/redux/eclexia.actions'
import {CAMPAIGN_INITIAL_STATE} from '../campaign.constants'

export const campaignReducer = (state: IWebAppCampaignState | null = CAMPAIGN_INITIAL_STATE, action: IAction): IWebAppCampaignState | null => {
    const _state = state as IWebAppCampaignState
    switch (action.type) {
        case campaignHasAccessAction.successActionType: {
            return {
                ..._state,
                currentCampaign: {
                    ..._state.currentCampaign,
                    hasAccess: action.payload,
                },
            }
        }
        case campaignHasAccessAction.failureActionType: {
            return {
                ..._state,
                currentCampaign: {
                    ..._state.currentCampaign,
                    hasAccess: false,
                },
            }
        }
        case campaignPageDataAction.successActionType: {
            const page = action.meta.meta.queryParameter.name === ECampaignPageName.MAIN ? 'mainPage' : 'accessPage'
            return {
                ..._state,
                currentCampaign: {
                    ..._state.currentCampaign,
                    [page]: action.payload,
                },
            }
        }
        case campaignFetchAction.successActionType: {
            return {
                ..._state,
                currentCampaign: {
                    ..._state.currentCampaign,
                    campaign: action.payload,
                },
            }
        }
        case giftsFetchAction.successActionType: {
            return {
                ..._state,
                currentCampaign: {
                    ..._state.currentCampaign,
                    gifts: action.payload._embedded.gifts,
                },
            }
        }
        case reviewSummaryGetAction.successActionType: {
            return {
                ..._state,
                currentCampaign: {
                    ..._state.currentCampaign,
                    reviewSummary: action.payload,
                },
            }
        }
        case myReviewByCampaignGetAction.successActionType: {
            return {
                ..._state,
                currentCampaign: {
                    ..._state.currentCampaign,
                    review: action.payload,
                },
            }
        }
        case myReviewByCampaignGetAction.failureActionType: {
            return {
                ..._state,
                currentCampaign: {
                    ..._state.currentCampaign,
                    review: null,
                },
            }
        }
        case reviewWritePostAction.successActionType: {
            return {
                ..._state,
                currentCampaign: {
                    ..._state.currentCampaign,
                    review: action.payload,
                },
            }
        }
        case CLEAR_CAMPAIGN_WISH_PAGE: {
            return {
                ..._state,
                currentCampaign: {
                    ..._state.currentCampaign,
                    mainPage: null,
                    gifts: null,
                    reviewSummary: null,
                    review: undefined, // because null is indicator that review is not exists we should use undefined to show that it is empty and should be filled
                },
            }
        }
        case CLEAR_CAMPAIGN_PAGE_DATA_TYPE: {
            const page = action.payload === ECampaignPageName.MAIN ? 'mainPage' : 'accessPage'
            return {
                ..._state,
                currentCampaign: {
                    ..._state.currentCampaign,
                    [page]: null,
                },
            }
        }
        case CLEAR_CAMPAIGN_DATA_TYPE: {
            return {
                ..._state,
                currentCampaign: {
                    ..._state.currentCampaign,
                    campaign: null,
                },
            }
        }
        case eclexiaGetLiveProgramAction.successActionType: {
            return {
                ..._state,
                currentCampaign: {
                    ..._state.currentCampaign,
                    liveProgramGuid: action.payload.Result.guid
                }
            }
        }
        default:
            return {..._state}
    }
}
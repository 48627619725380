import {FC, memo} from 'react'
import {IContainerProfilePersonalProfileProps} from './ContainerProfilePersonalProfile'
import Typography from '@material-ui/core/Typography'
import {ContainerProfileForm} from './profile-form/ContainerProfileForm'

export interface IProfilePersonalProfileProps extends IContainerProfilePersonalProfileProps {
    personalProfileClasses: any,
    profileClasses: any,
    personalProfileLabel: string
    editProfileTipLabel: string
}

export const ProfilePersonalProfile: FC<IProfilePersonalProfileProps> = memo(props => {
    const {
        personalProfileClasses,
        profileClasses,
        personalProfileLabel,
        editProfileTipLabel,
    } = props

    return (
        <div className={personalProfileClasses.personalProfileWrap}>
            <div className="titleWrap">
                <div className={profileClasses.titleWrap}>
                    <Typography className={'title'}>
                        {personalProfileLabel}
                    </Typography>

                    <Typography className={'subtitle'}>
                        {editProfileTipLabel}
                    </Typography>
                </div>
            </div>

            <div className="formWrap">
                <ContainerProfileForm/>
            </div>
        </div>
    )
})
import * as React from 'react'
import {CSSProperties, memo, useState} from 'react'
import {IEclexiaFullVideo} from 'dst-react-core/eclexia/eclexia.types'
import {MultiplyGiftItem} from './MultiplyGiftItem'
import Box from '@material-ui/core/Box'

interface IMultiGiftListProps {
    items: (IEclexiaFullVideo | null)[]
    current: IEclexiaFullVideo | null
    onSelect: (item: IEclexiaFullVideo | null) => void
    icon?: React.ReactNode
    className?: string
    style?: CSSProperties
}

export const MultiGiftList: React.FunctionComponent<IMultiGiftListProps> = memo(props => {

    const {
        items,
        current,
        onSelect,
        icon,
        className,
        style,
    } = props

    const [expanded, setExpanded] = useState<null | number>(null)

    const handlerSelectFactory = (item: IEclexiaFullVideo | null) => () => {
        // skip is item already selected
        if (current?.id === item?.id) return

        onSelect(item)
        setExpanded(item?.id ?? null)
    }

    const handlerExpandFactory = (item: IEclexiaFullVideo | null) => () => {
        // this on is already expanded an shouldn`t hide another one
        if (item?.id === current?.id) return

        // if selected item is already expanded we should hide it,
        // otherwise - show if it`s possible
        setExpanded(state => state === item?.id ? null : item?.id ?? null)
    }

    return (
        <Box className={className} style={style}>
            {items.map((item, i) => <MultiplyGiftItem
                item={item}
                expanded={(current?.id === item?.id) || (item?.id === expanded)}
                onSelect={handlerSelectFactory(item)}
                onExpand={handlerExpandFactory(item)}
                icon={icon}
                key={item?.id ?? i}
            />)}
        </Box>
    )
})

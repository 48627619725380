import {useTheme} from '@material-ui/core'
import createTheme from '@material-ui/core/styles/createTheme'
import {darken, lighten} from '@material-ui/core/styles'
import {useSelector} from 'react-redux'
import {
    campaignAccessPageSectionSelector,
} from '../redux/current-campaign.selectors'
import {useMemo} from 'react'
import {useBackgroundType} from '../campaign.hooks'
import {font, important} from 'dst-react-core/utils/styles.utils'
import {campaignAccessPageSelector} from 'deziro-web-app-components/redux/selectors'
import {EXTRA_COLORS_PALETTE} from '../../../material.theme'

export const useCampaignTheme = () => {

    const theme = useTheme()

    const globalValues = useSelector(campaignAccessPageSelector)?.global

    const values = useSelector(campaignAccessPageSectionSelector)

    const styleValues = values?.style

    const contentValues = values?.content

    const bg = useBackgroundType(styleValues)

    return useMemo(() => {

        const accentHex = globalValues?.inputTextColorHex ?? theme.palette.primary.main

        const accentDarkHex = darken(accentHex, 0.1)

        const accentLightHex = lighten(accentHex, 0.1)

        const buttonHex = styleValues?.buttonBackgroundColorHex || theme.palette.primary.main
        const buttonColorHex = styleValues?.buttonLinkColorHex || theme.palette.primary.contrastText

        return createTheme({
            ...theme,
            palette: {
                ...theme.palette,
                primary: {
                    ...theme.palette.primary,
                    main: accentHex,
                    light: accentLightHex,
                    dark: accentDarkHex,
                },
                background: {
                    ...theme.palette.background,
                    default: bg,
                },
            },
            typography: {
                ...theme.typography,
                h2: {
                    ...theme.typography.h2,
                    '&.title': {
                        fontFamily: font(globalValues?.titleFont),
                        color: important(contentValues?.titleColorHex),
                    },
                },
                h4: {
                    ...theme.typography.h4,
                    '&.title': {
                        fontFamily: font(globalValues?.titleFont),
                        color: important(contentValues?.titleColorHex),
                    },
                },
                caption: {
                    ...theme.typography.caption,
                    '&.subtitle': {
                        fontFamily: font(globalValues?.subtitleFont),
                        color: important(contentValues?.subtitleColorHex),
                    },
                },
                body1: {
                    fontFamily: font(globalValues?.globalFont),
                },
            },
            overrides: {
                ...theme.overrides,
                MuiChip: {
                    root: {
                        backgroundColor: 'transparent',
                        color: important(contentValues?.subtitleColorHex),
                    },
                    colorPrimary: {
                        backgroundColor: EXTRA_COLORS_PALETTE.PRIMARY.MAIN,
                        color: important('#fff'),
                        '$clickable&:hover, $clickable&:focus, $clickable&:active': {
                            backgroundColor: EXTRA_COLORS_PALETTE.PRIMARY.MAIN,
                        },
                    },
                },
                MuiButton: {
                    ...theme.overrides?.MuiButton,
                    contained: {
                        ...theme.overrides?.MuiButton?.contained,
                        backgroundColor: buttonHex,
                        color: buttonColorHex,
                        '&:hover': {
                            backgroundColor: darken(buttonHex, 0.1),
                        },
                        '&:disabled': {
                            ...(theme.overrides?.MuiButton?.contained as any)?.['&:disabled'],
                            backgroundColor: lighten(buttonHex, 0.1),
                        },
                    },
                },
                MuiOutlinedInput: {
                    ...theme.overrides?.MuiOutlinedInput,
                    input: {
                        color: accentHex,
                    },
                    notchedOutline: {
                        ...theme.overrides?.MuiOutlinedInput?.notchedOutline,
                        borderColor: accentHex,
                        ':hover:not(.Mui-focused) > &': {
                            borderColor: important(accentHex),
                        },
                        '.Mui-focused > &': {
                            borderColor: important(accentHex),
                        },
                    },
                },
                MuiTypography: {
                    ...theme.overrides?.MuiTypography,
                    root: {
                        color: important(contentValues?.subtitleColorHex) || theme.palette.primary.main,
                    },
                },
                MuiInputLabel: {
                    root: {
                        color: accentHex,
                    },
                },
                MuiLink: {
                    root: {
                        color: important(styleValues?.linkColorHex) || theme.palette.primary.main,
                    },
                },
            },
        })
    }, [bg, globalValues, styleValues, contentValues, theme])
}
import * as React from 'react'
import {useTranslation} from 'react-i18next'
import CodeAssignmentForm from './components/CodeAssignmentForm'
import {useHistory} from 'react-router'
import {useSelector} from 'react-redux'
import {authIsLoggedInSelector} from '../../../components/auth/redux/auth.selectors'
import {useEffect} from 'react'
import {CAMPAIGN_SUBROUTING} from '../../campaign.routes'
import {useParams} from 'react-router-dom'
import {IRouteId} from 'dst-react-core/core.types'
import {insertId} from 'dst-react-core/utils/shared.utils'
import GenericAnnotation from 'deziro-web-app-components/shared/utils/GenericAnnotation'
import { AuthSideContainer } from 'deziro-web-app-components/components/login/components/AuthSideContainer'
import { CAMPAIGN_AUTH_LOGIN_PATH } from 'web-app/campaign/auth/campaign-auth.routes'

const CodeAssignment: React.FunctionComponent = () => {

    const {id} = useParams<IRouteId>()

    const {t} = useTranslation()

    const history = useHistory()

    const isLoggedIn = useSelector(authIsLoggedInSelector)

    useEffect(() => {
        !isLoggedIn && history.push(insertId(CAMPAIGN_SUBROUTING.auth.path, id))
    }, [isLoggedIn, history, id])

    return (
        <AuthSideContainer path={CAMPAIGN_AUTH_LOGIN_PATH} classes=''>
            <GenericAnnotation
                heading={t('auth.code.title')}
                hint={t('auth.code.hint')}
                sm
            />
            <CodeAssignmentForm />
        </AuthSideContainer>
    )
}

export default CodeAssignment

import {IWebAppRootState} from 'redux/app.reducers'
import {IAuthState} from '../auth.types'
import {createSelector} from 'reselect'
import {IPerson} from 'dst-react-core/person/person.types'

export const authSelector = (state: IWebAppRootState): IAuthState | null => state?.auth

export const authIsLoggedInSelector = createSelector(
    authSelector,
    (auth: IAuthState | null) => auth?.isLoggedIn ?? null,
)

export const personSelector = createSelector(
    authSelector,
    (auth: IAuthState | null): IPerson | null => auth?.person ?? null
)

export const isLoginAttemptFailedSelector = createSelector(
    authSelector,
    (auth: IAuthState | null): boolean => auth?.isLoginAttemptFailed || false
)

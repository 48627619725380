import {FC} from 'react'
import {useCampaignImageWrapper} from '../../../../campaign.hooks'
import {EBannerType, IBanner} from 'dst-react-core/campaign/campaign.types'
import {BannerCell} from 'deziro-web-app-components/components/sections/components/BannerCell'
import * as React from 'react'
import { prepareExternalLink } from 'dst-react-core/utils/shared.utils'
import { analyticClickDecorator } from 'dst-react-core/google-analytics/gmt.init'

interface IContainerBannerCellProps {
    banner: IBanner,
}

export const ContainerBannerCell: FC<IContainerBannerCellProps> = props => {
    const {
        banner
    } = props

    const image = useCampaignImageWrapper(banner.previewImage?.id ?? null)

    const clickHandler: React.MouseEventHandler<HTMLElement> = () => {
        let preparedUrl = ''

        switch (banner.type) {
            case EBannerType.LINK: {
                preparedUrl = prepareExternalLink(banner.url as string)
                break
            }

            case EBannerType.CAMPAIGN: {
                preparedUrl = banner.giftedCampaign?.url as string
                break
            }
        }

        window.open(preparedUrl)
    }

    return (
        <BannerCell
            banner={banner}
            key={banner.id}
            image={image}
            clickHandler={analyticClickDecorator('banner-click', clickHandler)}
        />
    )
}
import * as React from 'react'
import {memo, useEffect, useRef, useState} from 'react'
import {IEclexiaFullVideo} from 'dst-react-core/eclexia/eclexia.types'
import clsx from 'clsx'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import PlayCircleFilledRoundedIcon from '@material-ui/icons/PlayCircleFilledRounded'
import {MultiGiftList} from '../components/MultiGiftList'
import {EclexiaPlayer} from '../../../shared/components/eclexia-player/EclexiaPlayer'

interface IVideoCourseGiftSectionProps {
    videos: (IEclexiaFullVideo | null)[],
    isMockedVideoPlayer?: boolean
}

export const VideoCourseGiftSection: React.FunctionComponent<IVideoCourseGiftSectionProps> = memo((props) => {
    const {
        videos,
        isMockedVideoPlayer
    } = props

    const [currentVideo, setCurrentVideo] = useState<null | IEclexiaFullVideo>(null)

    const contentRef = useRef<null | HTMLDivElement>(null)

    useEffect(() => {
        if (videos.length)
            setCurrentVideo(videos[0])
    }, [videos])

    return (
        <Box className={clsx('gift', 'multiplyGift')}>
            <div className="content" ref={contentRef}>
                <EclexiaPlayer
                    guid={currentVideo?.guid ?? ''}
                    isMocked={isMockedVideoPlayer}
                />
                <Box className="info">
                    <Typography className="title" variant={'body1'}>
                        {currentVideo?.title}
                    </Typography>
                    <Typography className="subtitle" variant={'body2'}>
                        {currentVideo?.description}
                    </Typography>
                </Box>
            </div>
            <MultiGiftList
                items={videos}
                current={currentVideo}
                onSelect={setCurrentVideo}
                icon={<PlayCircleFilledRoundedIcon />}
                className="list"
                style={{
                    maxHeight: contentRef.current?.clientHeight,
                }}
            />
        </Box>
    )
})

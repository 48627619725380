import * as React from 'react'
import clsx from 'clsx'
import {scrollToElement} from 'dst-react-core/utils/shared.utils'
import Box from '@material-ui/core/Box'
import {useHeaderStyles} from '../../../shared/styles/header.styles'
import {memo} from 'react'

interface IWishPageHeaderLogoProps {
    element?: Element,
    campaignHeaderClasses?: any
}

export const WishPageHeaderLogo: React.FunctionComponent<IWishPageHeaderLogoProps> = memo(({element, campaignHeaderClasses}) => {
    const classes = useHeaderStyles()

    return (
        <Box className={clsx(classes.logo, campaignHeaderClasses?.logo)} onClick={() => scrollToElement(element as Element)}/>
    )
})
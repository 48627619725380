import * as React from 'react'
import {FC, memo, MouseEventHandler, useState} from 'react'
import clsx from 'clsx'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'
import Drawer from '@material-ui/core/Drawer'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Divider from '@material-ui/core/Divider'
import Avatar from '@material-ui/core/Avatar'
import {WishPageHeaderRateActions} from './components/WishPageHeaderRateActions'
import {WishPageHeaderUserActions} from './components/WishPageHeaderUserActions'
import {WishPageHeaderNavigation} from './components/WIshPageHeaderNavigation'
import {WishPageHeaderWishButton} from './components/WIshPageHeaderWishButton'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import StarRoundedIcon from '@material-ui/icons/StarRounded'
import {WishPageHeaderLogo} from './components/WishPageHeaderLogo'
import {useTranslation} from 'react-i18next'
import {IPerson} from 'dst-react-core/person/person.types'
import {IReview} from 'dst-react-core/review/review.types'
import {ISectionAnchors} from '../../models/wish-page-core.types'
import {useHeaderStyles} from '../../shared/styles/header.styles'
import {IWishPageHeaderMenuItem} from '../hooks/wish-page-header-dropdown-menu.hook'

interface ICampaignHeaderDrawerProps {
    open: boolean
    onToggle: (open: boolean) => MouseEventHandler
    anchors: ISectionAnchors,
    campaignHeaderClasses: any,
    person: IPerson | null,
    review: IReview | null | undefined,
    valuateCampaign: (value: number) => void,
    menuItems:  IWishPageHeaderMenuItem[]
}

export const WishPageHeaderDrawer: FC<ICampaignHeaderDrawerProps> = memo(props => {

    const classes = useHeaderStyles()

    const {t} = useTranslation()

    const {
        open,
        onToggle,
        anchors,
        campaignHeaderClasses,
        person,
        review,
        valuateCampaign,
        menuItems
    } = props

    const [expandedId, setExpandedId] = useState(0)

    const expandDrawer = (id: number) => () => {
        setExpandedId(expandedId === id ? 0 : id)
    }

    return <Drawer anchor={'left'} open={open} onClose={onToggle(false)}>
        <Box className={clsx(classes.drawer, campaignHeaderClasses.drawer)}>
            <Box>
                <Box className={classes.drawerLogo}>
                    <WishPageHeaderLogo
                        campaignHeaderClasses={campaignHeaderClasses} element={anchors.home.current as Element}
                    />

                    <IconButton className={classes.burger} onClick={onToggle(false)}>
                        <CloseIcon/>
                    </IconButton>
                </Box>
                <WishPageHeaderNavigation campaignClasses={campaignHeaderClasses} anchors={anchors}/>
                <Box>
                    <Divider/>
                    <ListItem>
                        <ListItemIcon> <Avatar>{person?.name.charAt(0)}</Avatar></ListItemIcon>
                        <ListItemText primary={`${person?.name} ${person?.surname}`}/>
                    </ListItem>
                    <Collapse in={true}>
                        <WishPageHeaderUserActions
                            menuItems={menuItems}
                        />
                    </Collapse>
                    <Divider/>
                </Box>
            </Box>
            <Box>
                {/** Wait 2 phase */}
                {/*<>*/}
                {/*    <ListItem button onClick={expandDrawer(2)}>*/}
                {/*        <ListItemIcon>*/}
                {/*            <ShareIcon/>*/}
                {/*        </ListItemIcon>*/}
                {/*        <ListItemText*/}
                {/*            primary={<>*/}
                {/*                {t('header.share.share')}*/}
                {/*                {expandedId === 2 ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/>}*/}
                {/*            </>}*/}
                {/*        />*/}

                {/*    </ListItem>*/}
                {/*    <Collapse in={expandedId === 2} timeout="auto" unmountOnExit className="padding">*/}
                {/*        <WishPageHeaderShareActions/>*/}
                {/*    </Collapse>*/}
                {/*</>*/}
                <>
                    <ListItem
                        button
                        onClick={expandDrawer(3)}
                        className={clsx({disabled: !!review})}
                    >
                        <ListItemIcon>
                            <StarRoundedIcon/>
                        </ListItemIcon>
                        <ListItemText
                            primary={<>
                                {t('header.rate.rate')}
                                {expandedId === 3 ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/>}
                            </>}
                        />
                    </ListItem>
                    <Collapse
                        in={expandedId === 3}
                        timeout="auto"
                        unmountOnExit
                        className="padding"
                    >
                        <WishPageHeaderRateActions valuateCampaign={valuateCampaign}/>
                    </Collapse>
                </>
                <WishPageHeaderWishButton
                    campaignHeaderClasses={campaignHeaderClasses} element={anchors.wish.current as Element}
                />
            </Box>
        </Box>
    </Drawer>
})
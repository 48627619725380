import * as React from 'react'
import {getEclexiaIFrameUrl} from 'dst-react-core/eclexia/eclexia.utils'
import Box from '@material-ui/core/Box'
import {LinearProgress} from '@material-ui/core'
import {FC, memo, MutableRefObject, useState} from 'react'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import {useEclexiaPlayerStyles} from './eclexia-player.styles'

interface IEclexiaVideoProps {
    guid?: string
    audioOnly?: boolean,
    live?: boolean,
    iframeRef?: MutableRefObject<any>,
    isMocked?: boolean
}

export const EclexiaPlayer: FC<IEclexiaVideoProps> = memo(props => {

    const {
        guid,
        audioOnly,
        live,
        iframeRef,
        isMocked,
    } = props

    const classes = useEclexiaPlayerStyles()

    const [isOverlay, setOverlay] = useState(true)

    const url: string = guid ? getEclexiaIFrameUrl(guid, audioOnly, live) : ''

    return (
        <Box className={classes.wrapper}>
            {
                isMocked ?
                    (
                        <div className={classes.mockedPlayer}>
                            <PlayArrowIcon className={'playIcon'}/>
                        </div>
                    ):

                    (
                        url ? (
                            <div className={'overlayWrap'} onClick={() => setOverlay(false)}>
                                {
                                    (live && isOverlay) && (
                                        <div className="liveOverlay">
                                            <div className="playButton">
                                                <PlayArrowIcon/>
                                            </div>
                                        </div>
                                    )
                                }

                                <iframe
                                    ref={iframeRef}
                                    className={classes.iframe}
                                    title={guid}
                                    src={url}
                                    allow="autoplay; fullscreen"
                                />
                            </div>
                        ) : (
                            <LinearProgress/>
                        )
                    )
            }
        </Box>
    )
})
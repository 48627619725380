import {IRouter} from '../../dst-react-core/routing/routes.types'
import {AUTH_PATH, CAMPAIGN_PATH} from '../web-app.routes'

export const CAMPAIGN_AUTH_PATH = `${CAMPAIGN_PATH}${AUTH_PATH}`
export const WISH_PAGE_PATH = '/wish-page'
export const CAMPAIGN_WISH_PAGE_PATH = `${CAMPAIGN_PATH}${WISH_PAGE_PATH}`

export const CAMPAIGN_ROUTE = Object.freeze<IRouter>({
    path: CAMPAIGN_PATH,
    description: 'Web app campaign route',
    subrouting: {
        auth: {
            path: CAMPAIGN_AUTH_PATH,
            description: 'Web app campaign auth route',
        },
        wish_page: {
            path: CAMPAIGN_WISH_PAGE_PATH,
            description: 'Web app campaign wish page route',
        },
    },
})

export const CAMPAIGN_SUBROUTING = CAMPAIGN_ROUTE.subrouting
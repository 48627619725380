import * as React from 'react'
import Box from '@material-ui/core/Box'
import clsx from 'clsx'
import AuthFooter from '../auth-footer/AuthFooter'
import { CampaignAuthHeaderContainer } from '../CampaignAuthHeaderContainer'

interface IAuthSideContainerProps {
    children: React.ReactNode
    classes: any
    path: string
    loginLogo?: string
}

const AuthSide: React.FunctionComponent<IAuthSideContainerProps> = ({children, classes, path, loginLogo}) => {
    return (
        <Box className={classes.root}>
            <Box className={clsx(classes.side, 'left')}>
                <Box className={classes.contentWrapper}>
                    <CampaignAuthHeaderContainer loginLogo={loginLogo} path={path} />
                    <Box className={classes.content}>
                        <Box className={classes.paper}>{children}</Box>
                    </Box>
                    <AuthFooter />
                </Box>
            </Box>
            <Box className={clsx(classes.side, 'right')} />
        </Box>
    )
}

export default AuthSide

import {IAction} from '../core.types'

export enum HttpMethods {GET = 'GET', POST = 'POST', DELETE = 'DELETE', PATCH = 'PATCH', PUT = 'PUT'}

export const WITH_SPINNER = 'WITH_SPIN'
export const WITH_NO_SPINNER = 'NO_SPIN'
export const SUCCESS = 'SUCCESS'
export const FAILURE = 'FAILURE'
export const SPINNER = 'SPINNER'
export const IMAGES = 'IMAGES'
export const CAMPAIGN_HAS_ACCESS = 'CAMPAIGN_HAS_ACCESS'

export interface IRequestModel {
    url: string
    method: HttpMethods
    delay?: number // magari da gestire meglio quando materialmente viene fatta una chiamata
    headers?: IRequestHeaders
    responseType?: string
}

export interface IQueryParameter {
    [key: string]: string | number | boolean | undefined | null
}

export interface IPathVariable {
    [key: string]: string | number | undefined | null
}

export interface IRequestHeaders {
    [key: string]: string
}

export interface IResponseHeaders {
    xAuthToken?: string
}

export interface IFetchActionMeta {
    requestModel: IRequestModel
    queryParameter?: IQueryParameter
    runTimeHeaders?: IRequestHeaders
    pathVariable?: IPathVariable
    successAction: (payload: unknown, meta: IFetchAction) => any
    failureAction: (payload: unknown, meta: IFetchAction) => IFailureFetchAction
    requestId: string | number
    serverBaseUrl?: string
    responseHeaders?: IResponseHeaders
    responseStatusCode?: number
    actions?: IAction[]
}

export interface IFetchAction {
    type: string
    payload: any
    meta: IFetchActionMeta
}


export interface ISuccessFetchAction extends IAction {
    meta: IFetchAction // last fetch
}

export interface IFailureFetchAction extends ISuccessFetchAction {
}

export interface IActionFactoryReturn {
    build: (payload: any,
            requestId: string | number,
            runTimeHeaders?: IRequestHeaders,
            pathVariable?: IPathVariable,
            queryParameter?: IQueryParameter,
            meta?: object,
    ) => IFetchAction
    failureActionType: string
    pendingActionType: string
    pendingActionTypeWithSpinner: string
    successActionType: string
}

export interface IPaginatedResponse {
    content: any[]
    size: number
    sort: string
}

export interface ILink {
    href: string
    templated?: boolean
}

export interface ILinkList {
    self: ILink

    [key: string]: ILink
}

export interface IResponseDataModelMeta {
    created: Date | string | null
    createdBy: string | null
    id: number
    lastModified: Date | string | null
    lastModifiedBy: string | null
    _links: ILinkList
}

export interface IResponseDataModelMetaNoStrict {
    created?: Date | null
    createdBy?: string | null
    id?: number
    lastModified?: Date | null
    lastModifiedBy?: string | null
    _links?: ILinkList
}

export type IResponseNoLinkMeta = Omit<IResponseDataModelMeta, '_links'>

export interface IGenericRequestResponse<T = any> {
    status: number,
    response: Response,
    body: T | ReadableStream<Uint8Array> | null
    message?: string
}

export interface IDownloadMeta {
    url: string,
    token: string,
    contentType?: string
    fileType?: string
    fileName?: string
}

export interface IDownloadAction {
    type: string
    meta: IDownloadMeta
}
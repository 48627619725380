import {HttpMethods, IRequestModel} from '../../fetch/fetch.types'

/**
 * @description Find reference for how to implement an API into ./fetch/fetch.api.ts
 */

export const PRINCIPAL_IMPLS_ENDPOINT = '/principalImpls'
export const SEARCH_ENDPOINT = '/search'
export const MY_PRINCIPAL_ENDPOINT = '/myPrincipal'

export const PERSONS_ENDPOINT = '/persons'
export const MY_PERSONS_ENDPOINT = '/myPerson'
export const PROFILE_ENDPOINT = '/profile'

/**
 * @description My Principal => /principalImpls/search/myPrincipal
 * @return Principal
 *
 */
export const MY_PRINCIPAL_FETCH_API: IRequestModel = {
    url: `${PRINCIPAL_IMPLS_ENDPOINT}${SEARCH_ENDPOINT}${MY_PRINCIPAL_ENDPOINT}`,
    method: HttpMethods.GET
}

export const PRINCIPAL_UPDATE_API: IRequestModel = {
    url: `${PRINCIPAL_IMPLS_ENDPOINT}/{id}`,
    method: HttpMethods.PATCH,
    headers: {
        'Content-Type': 'application/json',
    },
}
import {changePasswordActionBuilder} from './redux/profile.actions'

export const changePasswordBuilder = (
    currentPassword: string,
    newPassword: string
) => {
    return changePasswordActionBuilder(
        {
            currentPassword,
            newPassword
        },
        {},
    )
}
import * as React from 'react'
import Box from '@material-ui/core/Box'
import clsx from 'clsx'
import {IEclexiaFullVideo} from 'dst-react-core/eclexia/eclexia.types'
import {EclexiaPlayer} from '../../../shared/components/eclexia-player/EclexiaPlayer'
import {memo} from 'react'

interface ISinglePodcastGiftSectionProps {
    podcast: IEclexiaFullVideo | null,
    isMockedVideoPlayer?: boolean
}

export const SinglePodcastGiftSection: React.FunctionComponent<ISinglePodcastGiftSectionProps> = memo((props) => {
    const {
        podcast,
        isMockedVideoPlayer
    } = props

    return (
        <Box className={clsx('gift')}>
            <EclexiaPlayer
                guid={podcast?.guid ?? ''}
                isMocked={isMockedVideoPlayer}
            />
        </Box>
    )
})

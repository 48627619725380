import * as React from 'react'

const FormProvider = React.forwardRef<HTMLFormElement, React.HTMLProps<HTMLFormElement>>((props, ref) => {

    const {
        children,
        ...rest
    } = props

    return (
        <form {...rest} ref={ref}>
            {children}
            <input type="submit" style={{display: 'none'}} />
        </form>
    )
})

export default FormProvider

import {FC, memo, useMemo} from 'react'
import {CustomAvatar} from './CustomAvatar'
import {useSelector} from 'react-redux'
import {personSelector} from '../../../../web-app/components/auth/redux/auth.selectors'

export interface IContainerCustomAvatarProps {
    className?: string,
    name?: string,
    size?: number
}

export const ContainerCustomAvatar: FC<IContainerCustomAvatarProps> = memo(props => {
    const userData = useSelector(personSelector)

    const {
        size
    } = props

    const fontSize = useMemo(
        () => typeof size === 'number' ? size / 2 : `${size}`,
        [size]
    )

    return (
        <CustomAvatar
            {...props}
            userData={userData}
            fontSize={fontSize}
        />
    )
})


import {FC, memo} from 'react'
import Avatar from '@material-ui/core/Avatar'
import {IContainerCustomAvatarProps} from './ContainerCustomAvatar'
import {IPerson} from '../../../../dst-react-core/person/person.types'

export interface ICustomAvatarProps extends IContainerCustomAvatarProps {
    userData: IPerson | null,
    fontSize: string | number
}

export const CustomAvatar: FC<ICustomAvatarProps> = memo(props => {
    const {
        className,
        name,
        userData,
        size,
        fontSize,
    } = props

    return (
        <Avatar
            className={className}
            style={
                {
                    width: size,
                    height: size,
                    fontSize,
                }
            }
        >
            {(name || userData?.name || '-').slice(0, 1)}
        </Avatar>
    )
})


import React, {FC, memo} from 'react'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import Typography from '@material-ui/core/Typography'
import FilterListIcon from '@material-ui/icons/FilterList'
import SearchIcon from '@material-ui/icons/Search'
import {IContainerGeneralHeaderMenuProps} from './ContainerGeneralHeaderMenu'
import CloseIcon from '@material-ui/icons/Close'
import clsx from 'clsx'
import MenuItem from '@material-ui/core/MenuItem'
import {ContainerCustomAvatar} from '../../../../../../shared/components/common/avatar/ContainerCustomAvatar'
import {IPerson} from '../../../../../../dst-react-core/person/person.types'
import {IHomepageDropdownMenuConfig} from '../hooks/homepage-menu-config.hook'

interface IGeneralHeaderMenuProps extends IContainerGeneralHeaderMenuProps {
    anchorDropDownMenuEl: HTMLElement | null,
    handleDropDownMenuClick: (event: React.MouseEvent<any>) => void,
    handleDropDownMenuClose: () => void,
    menuClasses: any,
    person: IPerson | null,
    logoutCallback: () => void,
    serviceCenterLabel: string,
    dropdownMenuConfig: IHomepageDropdownMenuConfig[]
}

export const GeneralHeaderMenu: FC<IGeneralHeaderMenuProps> = memo(props => {
    const {
        anchorDropDownMenuEl,
        handleDropDownMenuClick,
        handleDropDownMenuClose,
        menuClasses,
        person,
        serviceCenterLabel,
        isSearchMode,
        setSearchModeCallback,
        areCampaignsInSearch,
        isFilterMode,
        setFilterModeCallback,
        areFiltersAbleToBeOpened,
        dropdownMenuConfig,
    } = props

    return (
        <ul className={clsx(menuClasses.mainMenu)}>
            {
                !areCampaignsInSearch && (
                    <li>
                        <div className={'userInfoSubMenuWrap'}>
                            <ContainerCustomAvatar/>

                            <Button
                                aria-controls="generalHeaderMenu" aria-haspopup="true"
                                onClick={handleDropDownMenuClick}
                            >
                                <Typography className={'dropDownTitle'}>
                                    {person?.name || '-'}
                                </Typography>

                                <ArrowDropDownIcon className={'arrowDownIcon'}/>
                            </Button>

                            <Menu
                                id="generalHeaderMenu"
                                anchorEl={anchorDropDownMenuEl}
                                keepMounted
                                open={Boolean(anchorDropDownMenuEl)}
                                onClose={handleDropDownMenuClose}
                                className={menuClasses.dropDownMenu}
                            >
                                {
                                    dropdownMenuConfig.map(item => (
                                        <MenuItem
                                            key={item.label}
                                            onClick={
                                                (e) => {
                                                    handleDropDownMenuClick(e)
                                                    item.action()
                                                    handleDropDownMenuClose()
                                                }
                                            }
                                        >
                                            <Typography className={clsx('menuItemText', item.className)}>
                                                {item.icon}
                                                {item.label}
                                            </Typography>
                                        </MenuItem>
                                    ))
                                }
                            </Menu>
                        </div>
                    </li>
                )
            }

            {
                !areCampaignsInSearch && (
                    <li
                        className="menuButton"
                        onClick={() => areFiltersAbleToBeOpened && setFilterModeCallback(!isFilterMode)}
                    >
                        <FilterListIcon className={'icon'}/>
                    </li>
                )
            }

            {
                !isFilterMode && (
                    <li
                        className={clsx('menuButton', {menuButtonActive: isSearchMode})}
                        onClick={() => areFiltersAbleToBeOpened && setSearchModeCallback(!isSearchMode)}
                    >
                        {
                            isSearchMode ?
                                (
                                    <CloseIcon className={'icon'}/>
                                ) :
                                (
                                    <SearchIcon className={'icon'}/>
                                )
                        }
                    </li>
                )
            }

            <li className="menuButton sideMenuButton">
                <Typography>
                    {serviceCenterLabel}
                </Typography>
            </li>
        </ul>
    )
})
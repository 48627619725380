import {FC, memo} from 'react'
import {IContainerAccessedCampaignsProps} from './ContainerAccessedCampaigns'
import Typography from '@material-ui/core/Typography'
import {ContainerAccessedCampaignList} from './campaign-list/ContainerAccessedCampaignList'

export interface IAccessedCampaignsProps extends IContainerAccessedCampaignsProps {
    accessedCampaignClasses: any,
    titleLabel: string
    subTitleLabel: string,
    isMobile: boolean,
    welcomeLabel: string
}

export const AccessedCampaigns: FC<IAccessedCampaignsProps> = memo(props => {
    const {
        accessedCampaignClasses,
        titleLabel,
        subTitleLabel,
        isMobile,
        welcomeLabel
    } = props

    return (
        <div className={accessedCampaignClasses.campaignWrap}>
            <div className="titleWrap">
                <Typography className={'title'}>
                    {isMobile ? welcomeLabel : titleLabel}
                </Typography>
                {
                    !isMobile && (
                        <Typography className={'subTitle'}>
                            {subTitleLabel}
                        </Typography>
                    )
                }
            </div>

            <div className="listWrap">
                <ContainerAccessedCampaignList/>
            </div>
        </div>
    )
})
import Typography from '@material-ui/core/Typography'
import React, {FC} from 'react'
import {Slide, toast} from 'react-toastify'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import ErrorIcon from '@material-ui/icons/Error'
import WarningIcon from '@material-ui/icons/Warning'
import {EToastColor} from './toast.types'

export enum SnackBarType {Success, Error, Warning, Info}

interface IGenericSnackbarProps {
    closeToast?: any
    toastProps?: any
    text: string
    Icon: () => JSX.Element
    textColor?: string
}

const GenericSnackbar: FC<IGenericSnackbarProps> = ({closeToast, toastProps, text, Icon, textColor = 'white'}) => (
    <div className="row px-3">
        <Icon />
        <Typography className="mx-2" style={{color: textColor}}>
            {text}
        </Typography>
    </div>
)

const getColor = (type?: SnackBarType) => {
    switch (type) {
        case SnackBarType.Success:
            return EToastColor.Success
        case SnackBarType.Error:
            return EToastColor.Error
        case SnackBarType.Warning:
            return EToastColor.Warning
        case SnackBarType.Info:
        default:
            return EToastColor.Info
    }
}

// todo @paolo allineare l'icon action al centro dello snack
const getIcon = (type?: SnackBarType) => {
    switch (type) {
        case SnackBarType.Error:
            return () => <ErrorIcon style={{color: 'white'}} />
        case SnackBarType.Warning:
            return () => <WarningIcon style={{color: EToastColor.Error}} />
        default:
            return () => <InfoOutlinedIcon style={{color: 'white'}} />
    }
}

const triggerToast = (text: string, type?: SnackBarType, customId?: string, color?: string) => {
    toast(<GenericSnackbar
            text={text}
            Icon={getIcon(type)}
            textColor={color}
        />,
        {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
            pauseOnFocusLoss: true,
            toastId: customId ?? text.replace(' ', '_').toUpperCase(),
            transition: Slide,
            hideProgressBar: true,
            draggable: false,
            style: {
                backgroundColor: getColor(type),
            },
        })
}

export const triggerWarning = (text: string, customId?: string) => triggerToast(text, SnackBarType.Warning, customId, EToastColor.Error)
export const triggerInfo = (text: string, customId?: string) => triggerToast(text, SnackBarType.Info, customId)
export const triggerError = (text: string, customId?: string) => triggerToast(text, SnackBarType.Error, customId)
export const triggerSuccess = (text: string, customId?: string) => triggerToast(text, SnackBarType.Success, customId)

import * as React from 'react'
import Typography from '@material-ui/core/Typography'
import {useTranslation} from 'react-i18next'
import makeStyles from '@material-ui/core/styles/makeStyles'

interface IValuationTextProps {
    value?: number
}

const useValidationTextStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.grey[800],
        margin: theme.spacing(6.25, 0),
    },
}))

const ValuationText: React.FunctionComponent<IValuationTextProps> = props => {

    const {
        value,
    } = props

    const {t} = useTranslation()

    const classes = useValidationTextStyles()

    return (
        <Typography
            variant={'subtitle2'}
            classes={classes}
        >
            {/* EXTRA SPACE NEED TO SAVE LINE HEIGHT ON EMPTY LOCALIZED STRING */}
            {t(`dialog.valuation.star_comments.${value ? value : 0}`)}&nbsp;
        </Typography>
    )
}

export default ValuationText

import {FC, memo} from 'react'
import {
    IContainerGeneralHeaderFilterCategoryTabProps,
} from './ContainerGeneralHeaderFilterCategoryTab'
import Typography from '@material-ui/core/Typography'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import {EGiftType} from '../../../../../../../../dst-react-core/campaign/campaign.types'
import {IOption} from '../../../../../../../../dst-react-core/core.types'

interface IGeneralHeaderFilterCategoryTabProps extends IContainerGeneralHeaderFilterCategoryTabProps {
    categoryTabClasses: any,
    getCategoryChip: <T = any, Q = any>(value: T, label: string, setStateCallback: (value: T | Q) => void, state: T, defaultValue: Q) => JSX.Element,
    sortByLabel: string
    creationDateLabel: string,
    giftTypeChipConfig: IOption[]
}

export const GeneralHeaderFilterCategoryTab: FC<IGeneralHeaderFilterCategoryTabProps> = memo(props => {
    const {
        categoryTabClasses,
        getCategoryChip,
        isFilterByNewest,
        setFilterByNewestCallback,
        sortByLabel,
        creationDateLabel,
        giftTypeChipConfig,
        setFilterByGiftTypeCallback,
        filterByGiftType,
    } = props

    const getCategoryTimeFilterChip =
        (val: boolean | undefined, label: string) => getCategoryChip<(boolean | undefined), undefined>(val, label, setFilterByNewestCallback, isFilterByNewest, undefined)

    const getCategoryGiftTypeFilterChip =
        (val: EGiftType | '', label: string) => getCategoryChip<(EGiftType | ''), ''>(val, label, setFilterByGiftTypeCallback, filterByGiftType, '')

    return (
        <div className={categoryTabClasses.categoryTabWrap}>
            <ul className={'timeFilter'}>
                <li>
                    <Typography className={'label'}>
                        {sortByLabel}
                        <ArrowForwardIcon className={'icon'}/>
                    </Typography>
                </li>

                <li>
                    <Typography className={'label expirationDateLabel'}>
                        {creationDateLabel}
                    </Typography>
                </li>

                <li>
                    {
                        getCategoryTimeFilterChip(true, 'general_dashboard.filter.categories.most_recent')
                    }
                </li>

                <li>
                    {
                        getCategoryTimeFilterChip(false, 'general_dashboard.filter.categories.less_recent')
                    }
                </li>
            </ul>

            <ul className={'giftTypeFilter'}>
                {
                    giftTypeChipConfig.map(chip => {
                        return (
                            <li key={chip.value}>
                                {getCategoryGiftTypeFilterChip(chip.value as ('' | EGiftType), chip.label)}
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
})
import {Store} from 'redux'
import {appReducer, IWebAppRootState} from './app.reducers'
import {configureStore} from '../dst-react-core/core.store'
import {webAppRootEpics} from './app.epics'


/**
 * STORE DELL'APP CONFIGURATO
 */
export const store: Store = configureStore<IWebAppRootState>(appReducer, webAppRootEpics)

import Box from '@material-ui/core/Box'
import AppBar from '@material-ui/core/AppBar'
import clsx from 'clsx'
import {insertId} from 'dst-react-core/utils/shared.utils'
import { FC } from 'react'

interface ICampaignAuthHeaderProps {
    id: string,
    history: any,
    classes: any,
    campaignHeaderClasses: any
    path: string
}

const CampaignAuthHeader: FC<ICampaignAuthHeaderProps> = (props) => {
    const {id, history, classes, campaignHeaderClasses, path} = props

    return (
        <AppBar
            color="default"
            position="relative"
            className={campaignHeaderClasses.root}
        >
            <Box className={classes.side}>
                <Box
                    className={clsx(classes.logo, campaignHeaderClasses.logo)}
                    onClick={() => history.push(insertId(path, id))}
                />
            </Box>
        </AppBar>
    )
}

export default CampaignAuthHeader

import * as React from 'react'
import Typography from '@material-ui/core/Typography'
import StarRoundedIcon from '@material-ui/icons/StarRounded'
import clsx from 'clsx'
import Box from '@material-ui/core/Box'
import {memo} from 'react'
import {fixNumber} from 'dst-react-core/utils/numbers-utils/fix-number.util'

interface IRatingStarsProps {
    rating: number,
}

export const RatingStars: React.FunctionComponent<IRatingStarsProps> = memo(({rating}) => {

    return (
        <Box className="stars">
            <Typography className="counter" variant={'h5'}>{fixNumber(rating)}</Typography>
            {[...new Array(5)].map((_, i: number) => {
                return <StarRoundedIcon className={clsx({filled: i + 1 <= rating})} key={i}/>
            })}
        </Box>
    )
})

import {combineEpics} from 'redux-observable'
import {fetchActionGenerator} from '../../fetch/fetch.utils'
import {
    GIFT_FILES_ALL_GET_API,
    GIFT_FILES_DELETE_API,
    GIFT_FILES_DOWNLOAD_API,
    GIFT_FILES_UPLOAD_API,
} from './gift-file.api.constants'
import {mergeMapFetchEpics} from '../../fetch/fetch.call'

export const [
    giftFilesFetchAllAction,
    giftFilesFetchAllActionBuilder,
    giftFilesFetchAllActionEpic,
] = fetchActionGenerator('GIFT_FILES_FETCH_ALL', GIFT_FILES_ALL_GET_API)

export const [
    giftFilesUploadAction,
    giftFilesUploadActionBuilder,
    giftFilesUploadActionEpic,
] = fetchActionGenerator('GIFT_FILES_UPLOAD', GIFT_FILES_UPLOAD_API, mergeMapFetchEpics)

export const [
    giftFilesDownloadAction,
    giftFilesDownloadActionBuilder,
    giftFilesDownloadActionEpic,
    isGiftFileDownloadActionInPending,
] = fetchActionGenerator('GIFT_FILES_DOWNLOAD', GIFT_FILES_DOWNLOAD_API, mergeMapFetchEpics)

export const [
    giftFilesDeleteAction,
    giftFilesDeleteActionBuilder,
    giftFilesDeleteActionEpic,
] = fetchActionGenerator('GIFT_FILES_DELETE', GIFT_FILES_DELETE_API, mergeMapFetchEpics)


const giftFileActions = combineEpics(
    giftFilesFetchAllActionEpic,
    giftFilesUploadActionEpic,
    giftFilesDownloadActionEpic,
    giftFilesDeleteActionEpic,
)

export default giftFileActions
import { useAuthSideContainerStyles } from 'deziro-web-app-components/components/login/components/side-container/auth-side-container.styles'
import AuthSide from 'deziro-web-app-components/components/login/components/side-container/AuthSide'
import { FC } from 'react'
export interface IAuthSideContainerProps {
    path: string
    classes: any
    loginLogo?: string
}

export const AuthSideContainer: FC<IAuthSideContainerProps> = ({children, path, loginLogo, classes: styles}) => {
    const classes = useAuthSideContainerStyles()

    return (
        <AuthSide children={children} classes={styles || classes} path={path} loginLogo={loginLogo} />
    )
}

import React from 'react'
import {
    giftFilesDeleteActionBuilder,
    giftFilesDownloadActionBuilder,
    giftFilesFetchAllActionBuilder,
    giftFilesUploadActionBuilder,
} from './redux/gift-file.actions'

export const giftFilesFetchBuilder = () => {
    return giftFilesFetchAllActionBuilder(null)
}

export const giftFileUploadBuilder = (file: FormData) => {
    return giftFilesUploadActionBuilder(file)
}

export const giftFileDownloadBuilder = (campaignId: React.ReactText) => {
    return giftFilesDownloadActionBuilder(null, {}, {}, {campaignId})
}

export const giftFileDeleteBuilder = (id: React.ReactText) => {
    return giftFilesDeleteActionBuilder(null, {}, {id})
}
import {useDispatch} from 'react-redux'
import {DialogType, EDialogSizes} from './dialogs.types'
import {setCurrentDialog} from './redux/dialogs.actions'
import {IValuationDialogProps} from './dialogs/valuation-dialog/ValuationDialog'
import {VALUATION_DIALOG} from './dialogs.constants'

export const useDialog = <T = {}>(type: DialogType, size: EDialogSizes = EDialogSizes.SM) => {
    const dispatch = useDispatch()
    return (props?: T) => {
        dispatch(setCurrentDialog({type, size}, props))
    }
}

export const useValuationDialog = (size: EDialogSizes = EDialogSizes.SM) => {
    return useDialog<IValuationDialogProps>(VALUATION_DIALOG, size)
}
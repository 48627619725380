import * as React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Box from '@material-ui/core/Box'
import {BoxProps} from '@material-ui/core/Box/Box'
import DoneIcon from '@material-ui/icons/Done'
import Chip from '@material-ui/core/Chip'
import clsx from 'clsx'

export interface IChipSwitchOption<T> {
    value: T
    render?: React.ReactNode
}

export interface IChipSwitchProps<T = any> extends Omit<BoxProps, 'onChange'> {
    options: IChipSwitchOption<T>[]
    onChange?: (value: T) => any
    value?: T
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        gap: theme.spacing(2),
    },
}))

const ChipSwitch: React.FunctionComponent<IChipSwitchProps> = props => {

    const {
        options,
        onChange,
        value,
        className,
        ...rest
    } = props

    const classes = useStyles()

    const onChangeFactory = (value: any) => () => onChange && onChange(value)

    return (
        <Box className={clsx(classes.root, className)} {...rest}>
            {options.map(({value: v, render}) => <Chip
                color={value === v ? 'primary' : 'default'}
                deleteIcon={<DoneIcon />}
                label={render ?? v}
                onDelete={value === v ? onChangeFactory(v) : undefined}
                onClick={onChangeFactory(v)}
                key={v}
            />)}
        </Box>
    )
}

export default ChipSwitch

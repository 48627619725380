import {combineEpics, Epic} from 'redux-observable'
import authActions from '../web-app/components/auth/redux/auth.actions'
import campaignsActions from '../web-app/campaign/redux/campaign.actions'
import imagesActions from '../dst-react-core/image/redux/image.actions'
import eclexiaActions from '../dst-react-core/eclexia/redux/eclexia.actions'
import reviewActions from '../dst-react-core/review/redux/review.actions'
import giftActions from '../dst-react-core/gift/redux/gift.actions'
import giftFileActions from '../dst-react-core/gift-file/redux/gift-file.actions'
import voucherActions from '../dst-react-core/voucher/redux/voucher.actions'
import homepageActions from '../web-app/general/dashboard/homepage/redux/homepage.actions'
import {profileActions} from '../web-app/general/dashboard/profile/redux/profile.actions'
import generalAuthEpics from 'web-app/general/auth/redux/general-auth.actions'

export const webAppRootEpics: Epic = combineEpics(
    authActions,
    generalAuthEpics,
    imagesActions,
    eclexiaActions,
    campaignsActions,
    reviewActions,
    giftActions,
    giftFileActions,
    voucherActions,
    homepageActions,
    profileActions
)

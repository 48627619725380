import {HttpMethods, IRequestModel} from '../../fetch/fetch.types'
import {SEARCH_ENDPOINT} from '../../core.api.constants'

export const GIFTS_ENDPOINT = '/gifts'
export const FIND_BY_ACCESSED_CAMPAIGN_ENDPOINT = '/findByAccessedCampaign'


/**
 * @description Get campaign gifts => /gifts/search/findByAccessedCampaign?campaignId=25
 * @return Gift[]
 */
export const GIFTS_GET_API: IRequestModel = {
    url: `${GIFTS_ENDPOINT}${SEARCH_ENDPOINT}${FIND_BY_ACCESSED_CAMPAIGN_ENDPOINT}`,
    method: HttpMethods.GET,
}
import {FC, useState} from 'react'
import {FormikContextType} from 'formik'
import {ProfileTextField} from './ProfileTextField'
import {useProfileSubPageFormStyles} from '../../../profile-sub-page-form.styles'

export interface IContainerProfileTextFieldProps {
    title: string,
    formik: FormikContextType<any>,
    fieldName: string,
    isPassword?: boolean
    withPlaceholder?: boolean
}

export const ContainerProfileTextField: FC<IContainerProfileTextFieldProps> = props => {
    const profileFormClasses = useProfileSubPageFormStyles()

    const [isPasswordShown, setPasswordShown] = useState(false)

    const setPasswordShownCallback = (isPasswordShown: boolean) => {
        setPasswordShown(isPasswordShown)
    }

    return (
        <ProfileTextField
            {...props}
            profileFormClasses={profileFormClasses}
            setPasswordShownCallback={setPasswordShownCallback}
            isPasswordShown={isPasswordShown}
        />
    )
}
import {useTranslation} from 'react-i18next'
import GenericAnnotation from 'deziro-web-app-components/shared/utils/GenericAnnotation'
import { useScreen } from 'dst-react-core/core.hooks'

interface IResetPasswordAnnotationProps {
    hint: string
}

const ResetPasswordAnnotation: React.FunctionComponent<IResetPasswordAnnotationProps> = ({hint}) => {
    const {t} = useTranslation()
    const {isMobile} = useScreen()

    return (
        <GenericAnnotation heading={t('auth.reset_password.title')} hint={hint} sm={isMobile} />
    )
}

export default ResetPasswordAnnotation

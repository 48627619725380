import * as React from 'react'
import clsx from 'clsx'
import Box from '@material-ui/core/Box'
import {memo} from 'react'
import {IVoucherGIftSectionProps} from '../../../models/container-voucher-accordion.types'

export const VoucherGIftSection: React.FunctionComponent<IVoucherGIftSectionProps> = memo((props) => {
    const {
        vouchers,
        ContainerVoucherAccordion,
    } = props

    return (
        <Box className={clsx('gift')}>
            {vouchers.map(v => (
                <ContainerVoucherAccordion
                    design={v.design}
                    voucher={v} key={v?.voucher?.id ?? ''}
                />
            ))}
        </Box>
    )
})

import {FC, memo} from 'react'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import {IContainerProfileTextFieldProps} from './ContainerProfileTextField'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import clsx from 'clsx'

interface IProfileFormFieldProps extends IContainerProfileTextFieldProps {
    profileFormClasses: any,
    setPasswordShownCallback: (value: boolean) => void,
    isPasswordShown: boolean
}

export const ProfileTextField: FC<IProfileFormFieldProps> = memo(props => {
    const {
        title,
        formik,
        fieldName,
        profileFormClasses,
        setPasswordShownCallback,
        isPasswordShown,
        isPassword,
        withPlaceholder,
    } = props

    return (
        <div className={profileFormClasses.formField}>
            <Typography
                className={'title'}
            >
                {title}
            </Typography>

            <TextField
                className={clsx('textField', {[profileFormClasses.passwordField]: !isPasswordShown && !formik.values[fieldName].length})}
                fullWidth
                variant={'outlined'}
                value={formik.values[fieldName]}
                name={fieldName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched[fieldName] && !!formik.errors[fieldName]}
                helperText={formik.touched[fieldName] && formik.errors[fieldName]}
                type={isPassword ? (isPasswordShown ? 'text' : 'password') : 'text'}
                InputProps={{
                    endAdornment: (
                        isPassword && (
                            isPasswordShown ?
                                (
                                    <VisibilityIcon
                                        onClick={() => setPasswordShownCallback(false)}
                                    />
                                ) :
                                (
                                    <VisibilityOffIcon
                                        onClick={() => setPasswordShownCallback(true)}
                                    />
                                )
                        )
                    ),
                    placeholder: withPlaceholder ? title : '',
                }}
            />
        </div>
    )
})
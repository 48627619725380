import {FC, memo} from 'react'
import Typography from '@material-ui/core/Typography'
import {SelectableChip} from '../../../../../../../../shared/components/common/selectable-chip/SelectableChip'
import {FormikContextType} from 'formik'
import {IGenderConfigItem, IProfileFormValues} from '../profile-form.types'
import {PROFILE_FORM_FIELDS} from '../profile-form.constants'

interface IProfileFormGenderFieldProps {
    profileFormClasses: any,
    title: string,
    formik: FormikContextType<IProfileFormValues>,
    genderConfigItems: IGenderConfigItem[]
}

export const ProfileFormGenderField: FC<IProfileFormGenderFieldProps> = memo(props => {
    const {
        profileFormClasses,
        title,
        formik,
        genderConfigItems,
    } = props

    return (
        <div className={profileFormClasses.formField}>
            <Typography
                className={'title'}
            >
                {title}
            </Typography>

            <div className={profileFormClasses.genderChipList}>
                {
                    genderConfigItems.map(c => (
                        <SelectableChip
                            key={c.value}
                            label={c.label}
                            selected={formik.values.gender === c.value}
                            handleClick={() => {
                                formik.setFieldValue(PROFILE_FORM_FIELDS.gender, c.value)
                            }}
                        />
                    ))
                }
            </div>
        </div>
    )
})

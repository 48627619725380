import { ICampaignPage, IWebAppCurrentCampaign } from 'dst-react-core/campaign/campaign.types'
import { IImagesState } from 'dst-react-core/image/image.types'
import { createSelector } from 'reselect'

export const imageSelector = (state: any): IImagesState | null => state.images ?? null
export const currentCampaignStateSelector = (state: any): IWebAppCurrentCampaign | null =>
    state.campaign?.currentCampaign ?? null

export const campaignAccessPageSelector = createSelector(
    currentCampaignStateSelector,
    (currentCampaign: IWebAppCurrentCampaign | null): ICampaignPage | null => currentCampaign?.accessPage ?? null
)

export const campaignImageSelector = (id: number | null | undefined) =>
    createSelector(imageSelector, (images: IImagesState | null): string | null => (id ? images?.[id] ?? null : null))

import { X_AUTH_TOKEN_LABEL_SAVED } from 'dst-react-core/core.constants'
import { IRouteId } from 'dst-react-core/core.types'
import { insertId } from 'dst-react-core/utils/shared.utils'
import { FC, useEffect } from 'react'
import { Route, Switch, useParams, } from 'react-router-dom'
import { CAMPAIGN_AUTH_SUBROUTING } from '../auth/campaign-auth.routes'
import { IAccessessLoadedProps } from '../CampaignRoute'
import { ContainerWishPage } from './ContainerWishPage'
import {useHistory} from 'react-router'
import {isBoolean} from 'dst-react-core/utils/js/boolean.util'

const WishPageRoute: FC<IAccessessLoadedProps> = (props) => {
    const {hasAccess, isLoggedIn} = props
    const {id} = useParams<IRouteId>()

    const history = useHistory()

    useEffect(() => {
        const token = localStorage.getItem(X_AUTH_TOKEN_LABEL_SAVED)

        if (isLoggedIn || token) {
            if (isBoolean(hasAccess) && !hasAccess) {
                history.push(insertId(CAMPAIGN_AUTH_SUBROUTING.code_assignment.path, id))
            }
        } else {
            history.push(insertId(CAMPAIGN_AUTH_SUBROUTING.login.path, id))
        }
    }, [isLoggedIn, hasAccess, id, history])


    return (
        <Switch>
            <Route exact path=''>
                <ContainerWishPage />
            </Route>
        </Switch>
    )
}

export default WishPageRoute

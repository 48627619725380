import {FC, memo} from 'react'
import Chip from '@material-ui/core/Chip'
import DoneIcon from '@material-ui/icons/Done'

interface ISelectableChipProps {
    label?: string,
    className?: string,
    selected?: boolean
    handleClick?: () => void
}

export const SelectableChip: FC<ISelectableChipProps> = memo(props => {
    const {
        label,
        className,
        selected,
        handleClick,
    } = props

    return (
        <Chip
            icon={selected ? <DoneIcon/> : <></>}
            label={label || 'Chip'}
            className={className}
            color={selected ? 'primary' : 'secondary'}
            onClick={handleClick}
        />
    )
})
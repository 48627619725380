import {FC} from 'react'
import {ProfilePersonalProfile} from './ProfilePersonalProfile'
import {useProfilePersonalProfileStyles} from './personal-profile.styles'
import {useProfileStyles} from '../../profile.styles'
import {useTranslation} from 'react-i18next'

export interface IContainerProfilePersonalProfileProps {

}

export const ContainerProfilePersonalProfile: FC<IContainerProfilePersonalProfileProps> = props => {
    const personalProfileClasses = useProfilePersonalProfileStyles()
    const profileClasses = useProfileStyles()

    const {t} = useTranslation()

    const personalProfileLabel = t('general_dashboard.personal_profile')
    const editProfileTipLabel = t('general_dashboard.edit_profile_tip')

    return (
        <ProfilePersonalProfile
            {...props}
            personalProfileClasses={personalProfileClasses}
            profileClasses={profileClasses}
            personalProfileLabel={personalProfileLabel}
            editProfileTipLabel={editProfileTipLabel}
        />
    )
}
import makeStyles from '@material-ui/core/styles/makeStyles'
import { campaignAccessPageSelector } from 'deziro-web-app-components/redux/selectors'
import { cssBackgroundUrl } from 'dst-react-core/utils/shared.utils'
import {useMemo} from 'react'
import {useSelector} from 'react-redux'
import { useBackgroundType, useCampaignImageWrapper } from './auth-side-hooks'
import { campaignAccessPageSectionSelector } from './auth-side.selectors'

export const useAuthSideContainerStyles = () => {

    const globalValues = useSelector(campaignAccessPageSelector)?.global

    const sectionValues = useSelector(campaignAccessPageSectionSelector)

    const imgUrl = useCampaignImageWrapper(sectionValues?.style.sideImage?.id)

    const bg = useBackgroundType(sectionValues?.style)

    return useMemo(() => makeStyles(theme => ({
        root: {
            width: '100%',
            height: '100%',
            display: 'flex',
            position: 'relative',
        },
        side: {
            width: '50%',
            height: '100%',
            overflow: 'auto',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            display: 'flex',
            flexDirection: 'column',
            '&.right': {
                backgroundImage: cssBackgroundUrl(imgUrl!),
            },
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                '&.right': {
                    display: 'none',
                },
            },
        },
        contentWrapper: {
            height: '100%',
            overflow: 'auto',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            background: bg,
            '&::-webkit-scrollbar': {
                width: 8,
                background: 'transparent',
            },
            '&::-webkit-scrollbar-track': {
                background: 'transparent',

            },
            '&::-webkit-scrollbar-thumb': {
                background: globalValues?.inputTextColorHex,
                borderWidth: 0,
            },
        },
        content: {
            height: 'fit-content',
            padding: theme.spacing(3, 4, 0),
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(3, 2, 0),
            },
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            // if block is too small min height should be 100% of wrapper - footer height - header height
            minHeight: 'calc(100% - 105px - 88px)',
        },
        paper: {
            width: '100%',
            maxWidth: 590,
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(3),
        },
    })), [imgUrl, bg, globalValues])()
}
import * as React from 'react'
import {memo} from 'react'
import Box from '@material-ui/core/Box'
import {PLACEHOLDER_PIC_URL} from 'dst-react-core/core.constants'
import { IBanner } from 'dst-react-core/campaign/campaign.types'

export interface IBannerCellProps {
    image: string | null,
    clickHandler: React.MouseEventHandler<HTMLElement>,
    banner: IBanner,
    preview?: boolean
}

export const BannerCell: React.FunctionComponent<IBannerCellProps> = memo(({banner, image, clickHandler}) => {
    return <Box
        className={'cell'}
        onClick={clickHandler}
    >
        <img src={image ?? PLACEHOLDER_PIC_URL} alt={banner.url ?? banner.present ?? banner.id + ''} />
    </Box>
})
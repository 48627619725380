import {GENERIC_DIALOG, TEST_DIALOG, VALUATION_DIALOG} from './dialogs.constants'

export interface ICurrentDialogPayload {
    type: DialogType
    size: EDialogSizes
}

export interface ICurrentDialog {
    type: string
    size: string
    meta?: any // ADD HERE EXTRA INFOS
}

export enum EDialogSizes {
    XS = 'xs',
    SM = 'sm',
    MD = 'md',
    LG = 'lg',
    XL = 'xl'
}

export type DialogType =
    typeof TEST_DIALOG |
    typeof GENERIC_DIALOG |
    typeof VALUATION_DIALOG
import {HttpMethods, IRequestModel} from '../../fetch/fetch.types'
import {MY_PERSONS_ENDPOINT, PERSONS_ENDPOINT} from '../../principal/redux/principal.api.constants'
import {SEARCH_ENDPOINT} from '../../core.api.constants'

/**
 * @description My Person => /persons/search/myPerson
 * @return Principal
 *
 */
export const MY_PERSON_FETCH_API: IRequestModel = {
    url: `${PERSONS_ENDPOINT}${SEARCH_ENDPOINT}${MY_PERSONS_ENDPOINT}`,
    method: HttpMethods.GET,
}

/**
 * @description Delete person => /persons/personId
 * 
 */
export const PERSON_DELETE_API: IRequestModel = {
    url: `${PERSONS_ENDPOINT}/{id}`,
    method: HttpMethods.DELETE
}

/**
 * @description Update person => /persons/personId
 * 
 */
export const PERSON_UPDATE_API: IRequestModel = {
    url: `${PERSONS_ENDPOINT}/{id}`,
    method: HttpMethods.PATCH,
    headers: {
        'Content-Type': 'application/json',
    }
}

import React, {FC, useState} from 'react'
import {GeneralHeaderMenu} from './GeneralHeaderMenu'
import {useGeneralHeaderMenuStyles} from './menu.styles'
import {useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {personSelector} from '../../../../../components/auth/redux/auth.selectors'
import {useLogout} from '../../../homepage/hooks/logout.hook'
import {useHomepageMenuConfig} from '../hooks/homepage-menu-config.hook'

export interface IContainerGeneralHeaderMenuProps {
    isSearchMode: boolean,
    setSearchModeCallback: (isSearchMode: boolean) => void,
    isFilterMode: boolean,
    setFilterModeCallback: (isFilterMode: boolean) => void,
    areCampaignsInSearch: boolean,
    areFiltersAbleToBeOpened: boolean,
}

export const ContainerGeneralHeaderMenu: FC<IContainerGeneralHeaderMenuProps> = (props) => {
    const menuClasses = useGeneralHeaderMenuStyles()
    const person = useSelector(personSelector)
    const {t} = useTranslation()

    const logoutCallback = useLogout()

    const [anchorDropDownMenuEl, setDropDownMenuEl] = useState<null | HTMLElement>(null)

    const handleDropDownMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setDropDownMenuEl(event.currentTarget)
    }

    const handleDropDownMenuClose = () => {
        setDropDownMenuEl(null)
    }

    const serviceCenterLabel = t('general_dashboard.service_center')

    const dropdownMenuConfig = useHomepageMenuConfig()

    return (
        <GeneralHeaderMenu
            {...props}
            anchorDropDownMenuEl={anchorDropDownMenuEl}
            handleDropDownMenuClick={handleDropDownMenuClick}
            handleDropDownMenuClose={handleDropDownMenuClose}
            menuClasses={menuClasses}
            person={person}
            logoutCallback={logoutCallback}
            dropdownMenuConfig={dropdownMenuConfig}
            serviceCenterLabel={serviceCenterLabel}
        />
    )
}
export const WEBAPP_VALIDATION_CONSTANTS = Object.freeze({
    minStringLength: 3,
    maxStringLength: 50,
    minPasswordStringLength: 5,
    maxPasswordStringLength: 15,
    unlimitedLength: 10000,
    minDayNumber: 1,
    maxDayNumber: 31,
    minMonth: 0,
    maxMonth: 11,
    minYear: 1900,
})

import {fetchActionGenerator} from '../../fetch/fetch.utils'
import {MY_REVIEW_BY_CAMPAIGN_GET_API, REVIEW_SUMMARY_GET_API, WRITE_REVIEW_POST_API} from './review.api.constants'
import {combineEpics} from 'redux-observable'
import {reviewWriteSuccessEpic} from './review.epics'
import {mergeMapFetchEpics} from '../../fetch/fetch.call'

export const [
    reviewSummaryGetAction,
    reviewSummaryGetActionBuilder,
    reviewSummaryGetActionEpic,
] = fetchActionGenerator('REVIEW_SUMMARY', REVIEW_SUMMARY_GET_API, mergeMapFetchEpics)

export const [
    myReviewByCampaignGetAction,
    myReviewByCampaignGetActionBuilder,
    myReviewByCampaignGetActionEpic,
] = fetchActionGenerator('MY_REVIEW_BY_CAMPAIGN', MY_REVIEW_BY_CAMPAIGN_GET_API)

export const [
    reviewWritePostAction,
    reviewWritePostActionBuilder,
    reviewWritePostActionEpic,
] = fetchActionGenerator('REVIEW_WRITE', WRITE_REVIEW_POST_API)

const reviewActions = combineEpics(
    reviewSummaryGetActionEpic,
    myReviewByCampaignGetActionEpic,
    reviewWritePostActionEpic,
    reviewWriteSuccessEpic,
)

export default reviewActions
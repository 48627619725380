import PasswordInput from 'deziro-web-app-components/components/login/form/components/PasswordInput'
import FormProvider from 'deziro-web-app-components/components/login/form/components/formik/FormProvider'
import GenericCheckbox from 'deziro-web-app-components/shared/utils/GenericCheckbox'
import FormikTextField from 'deziro-web-app-components/components/login/form/components/formik/FormikTextField'
import { TFormClasses } from 'deziro-web-app-components/components/login/styles/form.styles'
import {FC, memo} from 'react'

export interface ILoginFormValues {
    email: string
    password: string
}

export interface ILoginFormProps {
    formClasses: TFormClasses,
    formik: any,
    emailLabel: string,
    passwordLabel: string,
    checkboxLabel: string,
    handleKeepLoggedIn: (evt: React.ChangeEvent<HTMLInputElement>) => void,
    keepLoggedIn: boolean
}

const LoginForm: FC<ILoginFormProps> = memo((props) => {
    const {
        formClasses,
        formik,
        emailLabel,
        checkboxLabel,
        passwordLabel,
        handleKeepLoggedIn,
        keepLoggedIn
    } = props

    return (
        <>
            <FormProvider className={formClasses.form} onSubmit={e => e.preventDefault()}>
                <FormikTextField
                    formik={formik}
                    fieldName="email"
                    label={emailLabel}
                    className={formClasses.input}
                />
                <PasswordInput
                    name="password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={formik.touched.password && formik.errors.password}
                    variant="outlined"
                    className={formClasses.input}
                    label={passwordLabel}
                    value={formik.values.password}
                />
            </FormProvider>
            <GenericCheckbox
                label={checkboxLabel}
                checked={keepLoggedIn}
                onChange={handleKeepLoggedIn}
            />
        </>
    )
})

export default LoginForm

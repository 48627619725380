import { fetchActionFactory } from 'dst-react-core/fetch/fetch.action.factory'
import { switchMapFetchEpics } from 'dst-react-core/fetch/fetch.call'
import { IActionFactoryReturn } from 'dst-react-core/fetch/fetch.types'
import { isActionInPending } from 'dst-react-core/spinner/redux/spinner.reducers'
import { combineEpics } from 'redux-observable'
import { CHANGE_EMAIL_CONFIRM_API } from './general-auth.api.constants'

export const CHANGE_EMAIL_CONFIRM_ACTION_TYPE = 'CHANGE_EMAIL_CONFIRM_ACTION_TYPE'
export const CHANGE_EMAIL_CONFIRM_ACTION_TYPE_REQUEST_ID = 'CHANGE_EMAIL_CONFIRM_ACTION_TYPE_REQUEST_ID'

export const changeEmailConfirmAction: IActionFactoryReturn = fetchActionFactory(
    CHANGE_EMAIL_CONFIRM_API,
    CHANGE_EMAIL_CONFIRM_ACTION_TYPE
)
export const isChangeEmailConfirmActionInPending = isActionInPending(
    changeEmailConfirmAction.pendingActionTypeWithSpinner,
    CHANGE_EMAIL_CONFIRM_ACTION_TYPE_REQUEST_ID
)

const generalAuthEpics = combineEpics(
    switchMapFetchEpics(changeEmailConfirmAction.pendingActionTypeWithSpinner),
)

export default generalAuthEpics
/** Provide here all the const related to each Dialog */

/** GENERIC DIALOG */
export const GENERIC_DIALOG = 'GENERIC_DIALOG'
export const TEST_DIALOG = 'TEST_DIALOG'
export const VALUATION_DIALOG = 'VALUATION_DIALOG'
/** Auth */
/** .... */



import {Theme} from '@material-ui/core'
import createTheme from '@material-ui/core/styles/createTheme'

export const GENERAL_HEADER_FILTER_GRADIENT = 'linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #030208;'

export const EXTRA_COLORS_PALETTE = Object.freeze({
    BACKGROUND: {
        DEFAULT: '#0F0E14',
    },
    PRIMARY: {
        MAIN: '#4447E2',
        DARK: '#2224A2',
        LIGHT: '#7173ED',
    },
    SECONDARY: {
        MAIN: '#36C7E0',
    },
    TEXT: {
        PRIMARY: '#fff',
        SECONDARY: 'rgba(255, 255, 255, 0.5)',
        DISABLED: '#E4E4E4',
        HINT: '#848594D9',
    },
    ERROR: {
        MAIN: '#E24444',
    },
    GREY: {
        800: '#212026',
        500: '#292929',
        300: '#848594D8',
        100: '#ECECEF',
        GENERAL_TEXT: 'rgba(255, 255, 255, 0.5)',
    },
    GENERAL_BORDER: {
        DEFAULT_WITH_OPACITY: 'rgba(255, 255, 255, 0.12)',
        LIGHT: 'rgba(177, 178, 191, 0.05)',
    },
})

const defaultTheme = createTheme({
    typography: {
        fontFamily: [
            '"DM Sans"',
            'Arial',
        ].join(','),
    },
    palette: {
        error: {
            main: EXTRA_COLORS_PALETTE.ERROR.MAIN,
        },
        background: {
            default: EXTRA_COLORS_PALETTE.BACKGROUND.DEFAULT,
        },
        primary: {
            main: EXTRA_COLORS_PALETTE.PRIMARY.MAIN,
            dark: EXTRA_COLORS_PALETTE.PRIMARY.DARK,
            light: EXTRA_COLORS_PALETTE.PRIMARY.LIGHT,
        },
        secondary: {
            main: EXTRA_COLORS_PALETTE.SECONDARY.MAIN,
        },
        text: {
            primary: EXTRA_COLORS_PALETTE.TEXT.PRIMARY,
            secondary: EXTRA_COLORS_PALETTE.TEXT.SECONDARY,
            disabled: EXTRA_COLORS_PALETTE.TEXT.DISABLED,
            hint: EXTRA_COLORS_PALETTE.TEXT.HINT,
        },
        grey: {
            800: EXTRA_COLORS_PALETTE.GREY[800],
            500: EXTRA_COLORS_PALETTE.GREY[500],
            300: EXTRA_COLORS_PALETTE.GREY[300],
            100: EXTRA_COLORS_PALETTE.GREY[100],
        },
    },
})
export const themeOptions: Theme = createTheme({
    ...defaultTheme,
    overrides: {
        MuiTypography: {
            h2: {
                marginBottom: defaultTheme.spacing(4.25),
                [defaultTheme.breakpoints.down('sm')]: {
                    fontSize: 24,
                    marginBottom: defaultTheme.spacing(2),
                },
            },
            caption: {
                fontSize: 16,
                color: defaultTheme.palette.text.hint,
            },
        },
        MuiButton: {
            root: {
                borderRadius: 8,
                outline: 'none !important',
                minHeight: 40,
                fontSize: 12,
                color: defaultTheme.palette.text.secondary,
                fontFamily: [
                    '"DM Sans"',
                    'Arial',
                ].join(','),
                height: 50,
                [defaultTheme.breakpoints.down('sm')]: {
                    height: 40,
                },
                '&.big': {
                    height: 64,
                    [defaultTheme.breakpoints.down('sm')]: {
                        height: 56,
                    },
                },
            },
            text: {
                textTransform: 'none',
                fontSize: 16,
                lineHeight: '24px',
                width: 'fit-content',
                color: defaultTheme.palette.grey[300],
            },
            contained: {
                backgroundColor: defaultTheme.palette.primary.main,
                color: defaultTheme.palette.text.secondary,
                fontWeight: 700,
                fontSize: 14,
                lineHeight: '16px',
                letterSpacing: 0.5,
                '&:hover': {
                    backgroundColor: defaultTheme.palette.primary.dark,
                },
                '&:disabled': {
                    backgroundColor: defaultTheme.palette.primary.light,
                    color: defaultTheme.palette.text.secondary,
                },
            },
            outlined: {
                borderColor: defaultTheme.palette.primary.main,
            },
        },
        MuiPaper: {
            root: {
                padding: defaultTheme.spacing(4),
                [defaultTheme.breakpoints.down('sm')]: {
                    padding: defaultTheme.spacing(3),
                },
            },
            rounded: {
                borderRadius: 16,
            },
            elevation0: {
                background: defaultTheme.palette.background.default,
                boxShadow: '0px 8px 32px rgba(0, 0, 0, 0.2)',
                maxWidth: 632,
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
            },
            elevation8: {
                padding: 0,
                color: 'currentColor',
                borderRadius: 4,
            },
            // Elevation should be used for accordion
            elevation24: {
                padding: 0,
            },
        },
        MuiAppBar: {
            root: {
                height: 88,
                padding: 0,
                flexDirection: 'row',
                justifyContent: 'space-between',
                '& > *': {
                    height: '100%',
                },
                [defaultTheme.breakpoints.down('sm')]: {
                    height: 64,
                },
            },
            colorDefault: {
                backgroundColor: defaultTheme.palette.grey[800],
            },
        },
        MuiToolbar: {
            root: {
                alignItems: 'initial',
                backgroundColor: defaultTheme.palette.grey[500],
                gap: 1,
                '& > *': {
                    height: '100%',
                },
                '& > .MuiIconButton-root': {
                    width: 88,
                    borderRadius: 0,
                    backgroundColor: defaultTheme.palette.grey[800],
                    color: defaultTheme.palette.text.hint,
                },
                '& > .MuiButton-root': {
                    borderRadius: 0,
                },
            },
            gutters: {
                padding: '0!important',
            },
        },
        MuiTextField: {
            root: {
                '& *': {
                    outline: 'none !important',
                },
                '& .MuiSvgIcon-root': {
                    transition: '200ms',
                },
                color: 'currentColor',
            },
        },
        MuiFormHelperText: {
            root: {
                color: defaultTheme.palette.error.main,
                position: 'absolute',
                bottom: defaultTheme.spacing(-3),
                left: defaultTheme.spacing(-1),
                '&::first-letter': {
                    textTransform: 'uppercase',
                },
            },
        },
        MuiInputBase: {
            root: {
                color: 'currentColor',
            },
        },
        MuiOutlinedInput: {
            notchedOutline: {
                transition: defaultTheme.transitions.create(['border-color']),
                borderColor: defaultTheme.palette.text.hint,
                borderWidth: 1,
                ':hover:not(.Mui-focused) > &': {
                    borderColor: defaultTheme.palette.primary.dark,
                },
                '.Mui-focused > &': {
                    borderWidth: 2,
                },
            },
            input: {
                fontSize: 16,
            },
        },
        MuiCheckbox: {
            root: {
                color: defaultTheme.palette.text.hint,
            },
            colorSecondary: {
                '&$checked': {
                    color: defaultTheme.palette.primary.main,
                },
            },
        },
        MuiInputLabel: {
            root: {
                color: defaultTheme.palette.text.hint,
            },
        },
        MuiFormControlLabel: {
            root: {
                marginLeft: 0,
            },
        },
        MuiInputAdornment: {
            positionEnd: {
                color: defaultTheme.palette.text.hint,
            },
        },
        MuiIconButton: {
            root: {
                color: 'currentColor',
            },
        },
        MuiChip: {
            root: {
                backgroundColor: 'transparent',
                color: '#000',
            },
            colorPrimary: {
                backgroundColor: EXTRA_COLORS_PALETTE.PRIMARY.MAIN,
                color: '#fff',
                '$clickable&:hover, $clickable&:focus': {
                    backgroundColor: EXTRA_COLORS_PALETTE.PRIMARY.DARK,
                },
            },
            colorSecondary: {
                backgroundColor: '#38343c',
                color: '#fff',
                '$clickable&:hover, $clickable&:focus': {
                    backgroundColor: EXTRA_COLORS_PALETTE.GREY.GENERAL_TEXT,
                },
            },
        },
        MuiAvatar: {
            colorDefault: {
                backgroundColor: defaultTheme.palette.primary.main,
                color: defaultTheme.palette.text.primary,
            },
        },
        MuiDrawer: {
            paperAnchorLeft: {
                width: '80%',
                display: 'flex',
                flexDirection: 'column',
                padding: 0,
            },
        },
        MuiAccordion: {
            root: {
                // temporary
                backgroundColor: defaultTheme.palette.background.default,
            },
        },
        MuiAccordionSummary: {
            root: {
                padding: defaultTheme.spacing(0, 4),
            },
            content: {
                padding: 0,
            },
        },
        MuiDialog: {
            paperWidthSm: {
                maxWidth: 728,
            },
        },
        MuiDialogContent: {
            root: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            },
        },
    },
})

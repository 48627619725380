import {FC, memo, MutableRefObject, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useFormStyles} from 'deziro-web-app-components/components/login/styles/form.styles'
import LoginForm, {ILoginFormValues} from './LoginForm'
import {useFormik} from 'formik'
import {analyticClickDecorator} from 'dst-react-core/google-analytics/gmt.init'
import {useListenerToRef} from 'dst-react-core/core.hooks'

export interface ILoginFormContainerProps {
    handleKeepLoggedIn?: (evt: React.ChangeEvent<HTMLInputElement>) => void
    keepLoggedIn?: boolean
    onSubmit: (values: ILoginFormValues, staySignedIn: boolean) => void
    vSchema: any
    vString: any
    vEmail: any,
    submitButtonRef: MutableRefObject<HTMLButtonElement | null>,
    isGeneralLogin: boolean
}

export const LoginFormContainer: FC<ILoginFormContainerProps> = memo((props) => {
    const {
        onSubmit,
        handleKeepLoggedIn,
        keepLoggedIn,
        vSchema,
        vEmail,
        vString,
        submitButtonRef,
        isGeneralLogin,
    } = props

    const formClasses = useFormStyles()

    const {t} = useTranslation()

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: vSchema ? vSchema({
            email: vEmail?.required(),
            password: vString?.required(),
        }) : undefined,
        onSubmit: (values) => {
            onSubmit(values, keepLoggedIn!)
        },
        validateOnMount: true,
    })

    const submit: (e?: any) => void = useMemo(
        () => {
            const formikSubmit = formik.handleSubmit

            return isGeneralLogin ? formikSubmit : analyticClickDecorator('login-button', () => formikSubmit())
        },
        [formik, isGeneralLogin],
    )

    useListenerToRef(
        () => {
            submit()
        },
        submitButtonRef
    )

    return (
        <LoginForm
            formClasses={formClasses}
            emailLabel={t('string.email')}
            passwordLabel={t('string.password')}
            checkboxLabel={t('auth.login.stay_signed_in')}
            formik={formik}
            handleKeepLoggedIn={handleKeepLoggedIn!}
            keepLoggedIn={keepLoggedIn!}
        />
    )
})

import * as React from 'react'
import {FC, memo} from 'react'
import {ProfileRoutes} from './ProfileRoutes'
import {ContainerProfileNavigationMenu} from './navigation-menu/ContainerProfileNavigationMenu'
import {ContainerProfileSubHeader} from './components/shared/profile-sub-header/ContainerProfileSubHeader'

interface IProfileProps {
    profileClasses: any,
    isMobile: boolean
}

export const Profile: FC<IProfileProps> = memo(props => {
    const {
        profileClasses,
        isMobile,
    } = props

    return (
        <div className={profileClasses.profileWrap}>
            <div className={'profileHolder'}>
                {
                    !isMobile && (
                        <div className={'pagePart'}>
                            <div className="navigationMenu">
                                <ContainerProfileNavigationMenu/>
                            </div>
                        </div>
                    )
                }

                <div className={'pagePart'}>
                    <ContainerProfileSubHeader/>

                    {
                        isMobile && (
                            <div className="navigationMenu">
                                <ContainerProfileNavigationMenu/>
                            </div>
                        )
                    }

                    <ProfileRoutes/>
                </div>
            </div>
        </div>
    )
})

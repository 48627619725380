import {IProfile} from '../principal/principal.types'
import {IPrincipal} from '../principal/principal.types'
import {IResponseDataModelMeta} from '../fetch/fetch.types'

export interface IPerson extends IResponseDataModelMeta {
    name: string
    surname: string
    phone: string
    birthDate: Date | string | null
    marketingConsent: boolean
    gender: EPersonGender | null
    principal?: IPrincipal
    profile?: IProfile,

    /** Not from API */
    isManager?: boolean
}

export enum EPersonGender {
    MALE = 'MALE',
    FEMALE = 'FEMALE',
    OTHER = 'OTHER'
}

export enum EProfileRole {
    SUPERADMIN = 1,
    ADMIN = 2,
    USER = 3,
    MANAGER = 4,
    STREAMER = 5,
    CONTENT_CREATOR = 6
}
import {getMonthFormDate} from './month-date-string.util'
import {numberDigitFormat} from '../shared.utils'
import {formatDate} from './format.utils'
import {API_DATE_FORMAT} from '../../core.constants'

export interface IParsedDate {
    year: number
    month: {
        string?: string,
        index: number,
    },
    dayNumber: string | number,
}

export const parseDate = (date: string | Date, isDayNumberAsString = false): IParsedDate => {
    const dateObject = new Date(date)

    const dayNumber = dateObject.getDate()

    return {
        year: dateObject.getFullYear(),
        month: {
            string: getMonthFormDate(dateObject),
            index: dateObject.getMonth(),
        },
        dayNumber: isDayNumberAsString ? numberDigitFormat(dayNumber, 2) : dayNumber,
    }
}

const setDate = (d: number, m: number, y: number) => new Date(y, m, d)

export const joinDate = (parsedDate: IParsedDate, isFormatted = false): string | Date => {
    const dayNumber = parseInt(`${parsedDate.dayNumber}`)
    const month = parsedDate.month.index
    const year = parsedDate.year

    const date = setDate(dayNumber, month, year)

    return isFormatted ? formatDate(date, API_DATE_FORMAT) : date
}
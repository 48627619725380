import {myCampaignsActionBuilder} from './redux/homepage.actions'
import {ECampaignStatus, EGiftType} from 'dst-react-core/campaign/campaign.types'
import {joinMultipleQueryParams} from 'dst-react-core/utils/shared.utils'
import {DEZIRO_PROJECT_NAME, SUB_DOMAIN} from 'dst-react-core/core.constants'

export interface IMyCampaignFilter {
    name?: string,
    giftTypes?: (EGiftType | '')[],
    statuses?: (ECampaignStatus | '')[],
    sortDirection?: 'ASC' | 'DESC'
}

export const getMyCampaigns = (
    filter?: IMyCampaignFilter,
) => {
    return myCampaignsActionBuilder(
        null,
        {},
        {},
        {
            name: filter?.name || '',
            giftType: joinMultipleQueryParams(filter?.giftTypes || []),
            status: joinMultipleQueryParams(filter?.statuses || []),
            sortDirection: filter?.sortDirection || '',
        },
    )
}

export const getCampaignInfoByParsingCampaignUrl = (campaignUrl: string) => {
    const hasSubDomain = campaignUrl.includes(SUB_DOMAIN)

    const deziroDomainStartIndex = campaignUrl.indexOf(hasSubDomain ? `${SUB_DOMAIN}${DEZIRO_PROJECT_NAME}` : `.${DEZIRO_PROJECT_NAME}`)
    const startCampaignNameIndex = campaignUrl?.indexOf('/')

    return {
        customerName: campaignUrl.slice(0, deziroDomainStartIndex) + (hasSubDomain ? '' : '.'),
        campaignName: campaignUrl.slice(startCampaignNameIndex + 1, campaignUrl.length),
    }
}
import makeStyles from '@material-ui/core/styles/makeStyles'
import {useMemo} from 'react'
import {lighten} from '@material-ui/core/styles'
import {flex, important} from 'dst-react-core/utils/styles.utils'
import {ECampaignPageSection, ICampaignPage} from 'dst-react-core/campaign/campaign.types'
import {
    CSSDependsOnBrowserFlexGapSupporting,
} from 'dst-react-core/utils/css/supports-flex-gap.util'

export type TCampaignBaseStylesIncomingSettings = {
    [Key in keyof ICampaignPage]?: ICampaignPage[Key]
}

export interface ICampaignHeaderStylesIncomingSettings extends TCampaignBaseStylesIncomingSettings {
    bg?: string | null,
    text?: string | null,
    separator?: string | null,
    accent?: string,
    logo?: string | null,
    values?: ICampaignPage | null
}

export const useCampaignHeaderStyles = (settings: ICampaignHeaderStylesIncomingSettings) => {
    const section = settings?.sections?.find((section) => section.name === ECampaignPageSection.HEADER) ||
        settings?.values?.sections?.find((section) => section.name === ECampaignPageSection.HEADER)
    const accent = section?.style?.buttonBackgroundColorHex || '#000'

    return useMemo(() => makeStyles(theme => ({
        menu: {
            '& .MuiSvgIcon-root': {
                marginRight: theme.spacing(1),
            },
            '& .MuiListItem-root:not(.logout) .MuiSvgIcon-root': {
                color: important('accent'),
            },
        },
        campaignHeader: {
            backgroundColor: settings?.global?.inputTextColorHex ?? '#FEFEFE',
            '& .disabled': {
                pointerEvents: 'none',
                color: theme.palette.text.hint,
                '& svg': {
                    color: lighten(accent, 0.3),
                },
            },
        },
        logo: {
            backgroundImage: `url(${settings?.logo})` ?? '',
        },
        navigationButton: {
            ...settings?.text && {color: important(settings?.text)},
            transition: '500ms',
            '&.active': {
                boxShadow: `0px ${theme.spacing(0.5)}px 0px 0px ${accent} inset`,
                color: important(accent),
            },
            '.MuiDrawer-paperAnchorLeft &': {
                '&.active': {
                    boxShadow: `${theme.spacing(0.5)}px 0px 0px 0px ${accent} inset`,
                },
            },
            marginRight: theme.spacing(2),
        },
        actionButton: {
            ...settings?.text && {color: important(settings?.text)},
            '& .MuiButton-startIcon': {
                color: important(accent),
                '& .MuiAvatar-root': {
                    backgroundColor: settings?.accent,
                },
            },
            '& .MuiButton-endIcon': {
                color: important('#00000061'),
            },
            ...settings?.separator && {
                borderColor: important(settings?.separator),
            },
        },
        primaryButton: {
            backgroundColor: important(accent),
            color: important(settings?.bg ?? '#FEFEFE'),
            '.MuiDrawer-paperAnchorLeft &': {
                height: important('75px'),
                '& .MuiButton-label': {
                    justifyContent: important('center'),
                },
            },
        },
        drawer: {
            '& .MuiAvatar-root': {
                backgroundColor: important(accent),
            },
            '& .MuiListItem-root': {
                '& .MuiListItemIcon-root': {
                    color: important(accent),
                },
            },
        },
    })), [settings, accent])()
}

export const useGiftOverlayStyle = makeStyles({
    overlayWrapper: {
        position: 'relative',
        width: '100%',
        height: '100%',
    },
    overlay: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: 11111,
    },
})

export type TGiftOverlayClasses = ReturnType<typeof useGiftOverlayStyle>

export interface IWishPageStylesIncomingSettings extends ICampaignHeaderStylesIncomingSettings {
}

export const useWishPageStyle = (setting: IWishPageStylesIncomingSettings) => {

    const accent = setting?.global?.inputTextColorHex ?? '#fff'

    return useMemo(() => makeStyles(() => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            overflow: 'hidden',
        },
        page: {
            height: '100%',
            overflow: 'auto',
            '&::-webkit-scrollbar': {
                width: 7,
            },
            '&::-webkit-scrollbar-track': {
                background: lighten(accent, 0.7),
            },
            '&::-webkit-scrollbar-thumb': {
                background: accent,
                borderWidth: 0,
                borderRadius: 0,
            },
        },
    })), [accent])()
}

export const useCampaignFooterStyles = (setting: IWishPageStylesIncomingSettings) => {
    const giftSection = setting?.values?.sections?.find((section) => section.name === ECampaignPageSection.GIFT)

    return useMemo(() => makeStyles(theme => ({
        root: {
            display: 'flex',
            justifyContent: 'center',
            padding: theme.spacing(11, 2.25, 7),
            backgroundColor: 'white',
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(3, 2.25),
            },
            '& .MuiSvgIcon-root': {
                marginRight: theme.spacing(2),
                color: 'rgba(0, 0, 0, 0.38)',
            },
            '& .item': {
                width: 'fit-content',
                height: 25,
                display: 'block',
                transition: 'all ease 0.3s',
                cursor: 'pointer',
                position: 'relative',
                '&:after': {
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    width: 0,
                    content: '""',
                    height: 2,
                    background: theme.palette.primary.main,
                    transition: 'all ease 0.3s',
                },
                '&:hover': {
                    '&:after': {
                        width: '80%',
                    },
                },
            },
        },
        content: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            width: 'clamp(200px, 100%, 1300px)',
            gap: theme.spacing(5),
            '& > div:first-child': {
                paddingBottom: 50,
                borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
            },
        },
        info: {
            display: 'flex',
            justifyContent: 'space-between',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
            },
        },
        linksWrap: {
            ...flex(),
            ...CSSDependsOnBrowserFlexGapSupporting({gap: 180}),
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                ...CSSDependsOnBrowserFlexGapSupporting({gap: 90}),
            },
        },
        links: {
            display: 'flex',
            justifyContent: 'space-between',
            [theme.breakpoints.down('sm')]: {
                gap: theme.spacing(7),
                flexWrap: 'wrap',
            },
            '& a': {
                whiteSpace: 'nowrap',
            },
            '& .contacts': {
                display: 'flex',
                flexDirection: 'column',
                gap: 24,
                '& .contactUsButton': {
                    backgroundColor: important(giftSection?.style?.buttonBackgroundColorHex),
                    color: important(giftSection?.style?.buttonLinkColorHex),
                },
                '& .text': {
                    fontSize: 20,
                    lineHeight: '24px',
                    letterSpacing: 0.15,
                    color: 'black',
                },
                '& .MuiButton-root': {
                    marginTop: 8,
                    color: '#fff',
                    width: '100%',
                    maxWidth: 175,
                    padding: '20px 0',
                    height: '100%',
                    maxHeight: 50,
                    backgroundColor: setting?.values?.global?.inputTextColorHex,
                },
            },
            '& .links': {
                display: 'flex',
                flexWrap: 'wrap',
                gap: theme.spacing(15.5),
            },
        },
        list: {
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(3),
            [theme.breakpoints.down('sm')]: {
                display: 'grid',
                gridTemplate: '1fr 1fr / 1fr 1fr',
                gridAutoFlow: 'column',
                width: '100%',
            },
            '&.deziroLinks': {
                display: 'grid',
                gridTemplate: '25px 25px / 1fr 1fr',
                gridAutoFlow: 'column',
                width: '100%',
                ...CSSDependsOnBrowserFlexGapSupporting(
                    {},
                    {
                        marginRight: 180,
                        [theme.breakpoints.down('sm')]: {
                            marginRight: 0,
                        },
                    },
                ),
                [theme.breakpoints.down('sm')]: {
                    marginTop: 45,
                },
            },
            '&.social': {
                '& .networkIcon': {
                    margin: '4px 10px 4px 0',
                },
                '& div': {
                    display: 'flex',
                },
                [theme.breakpoints.down('sm')]: {
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    ...CSSDependsOnBrowserFlexGapSupporting(
                        {},
                        {
                            marginTop: 90,
                        },
                    ),
                },
            },
        },
        copyright: {
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            gap: theme.spacing(3.5),
            '& div': {
                display: 'flex',
                flexWrap: 'wrap',
            },
            '& .terms': {
                display: 'flex',
                justifyContent: 'space-between',
                gap: theme.spacing(3),
                marginLeft: 'auto',
            },
            [theme.breakpoints.down('sm')]: {
                '& .terms': {
                    marginLeft: 0,
                },
            },
        },
    })), [setting?.values?.global?.inputTextColorHex, giftSection?.style])()
}
import * as React from 'react'
import clsx from 'clsx'
import Box from '@material-ui/core/Box'
import {FaqCard} from './components/FaqCard'
import Typography from '@material-ui/core/Typography'
import {ICampaignPageSectionContent} from 'dst-react-core/campaign/campaign.types'
import {ISectionProps} from '../../models/wish-page-core.types'
import {useFAQContent} from './hooks/faq-content.hook'

interface ICampaignFaqSectionProps extends ISectionProps {
    content: ICampaignPageSectionContent | null,
    campaignSectionClasses: any,
    faqSectionClasses: any
}

export const CampaignFaqSection = React.forwardRef<HTMLDivElement | null, ICampaignFaqSectionProps>((props, ref) => {
    const {
        content,
        campaignSectionClasses,
        faqSectionClasses,
    } = props

    const faqRecords = useFAQContent()

    return (
        <div className={clsx(campaignSectionClasses.root, faqSectionClasses.root)} ref={ref}>
            <Box className={clsx(campaignSectionClasses.content, campaignSectionClasses.faqSection)}>
                <Box className="heading">
                    <Typography className="title" variant={'h4'}>
                        {content?.titleText}
                    </Typography>
                    <Typography className="subtitle" variant={'subtitle1'}>
                        {content?.subtitleText}
                    </Typography>
                </Box>
                <Box className="questions">
                    {faqRecords.map(({previewText, expandedText, topic, link}) => <FaqCard
                        key={previewText}
                        previewText={previewText}
                        expandedText={expandedText}
                        topic={topic}
                        link={link}
                    />)}
                </Box>
            </Box>
        </div>
    )
})

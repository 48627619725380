import {useLocation} from 'react-router-dom'
import {useEffect, useMemo, useState, useRef, useCallback, MutableRefObject} from 'react'
import {Theme} from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {DESKTOP_DEFAULT_SCREEN, LAPTOP_DEFAULT_SCREEN, LAPTOP_SMALL_SCREEN} from './core.constants'
import {checkIsObjectEqual} from './utils/shared.utils'
import {useHistory} from 'react-router'

export const useQuery = () => {
    return new URLSearchParams(useLocation().search)
}

export const useEqualObjectMemo = <T extends object>(data: T): T => {

    const memoRef = useRef(data)

    useEffect(() => {
        if (!checkIsObjectEqual(memoRef.current, data))
            memoRef.current = data
    }, [data])

    return memoRef.current
}

interface IDeviceInfo {
    currentScreenWidth: number,
    currentScreenHeight: number,
}

interface IUseScreenReturnedType {
    screenInfo: IDeviceInfo,
    isDefaultLaptop: boolean,
    isSmallLaptop: boolean,
    isMobile: boolean,
    getIsCurrentScreenLessOrEqual: (width: number) => boolean
}

export function useScreen(): IUseScreenReturnedType {
    const [screenInfo, setScreenInfo] = useState<IDeviceInfo>({} as IDeviceInfo)

    useEffect(() => {
        setScreenInfo({
            currentScreenWidth: window.innerWidth,
            currentScreenHeight: window.innerHeight,
        })
    }, [])

    const isDefaultLaptop = useMemo(
        () => {
            return !!screenInfo.currentScreenWidth && (
                screenInfo.currentScreenWidth < DESKTOP_DEFAULT_SCREEN &&
                screenInfo.currentScreenWidth <= LAPTOP_DEFAULT_SCREEN &&
                screenInfo.currentScreenWidth > LAPTOP_SMALL_SCREEN
            )
        },
        [screenInfo.currentScreenWidth],
    )

    const isSmallLaptop = useMemo(
        () => {
            return !!screenInfo.currentScreenWidth && screenInfo.currentScreenWidth <= LAPTOP_SMALL_SCREEN
        },
        [screenInfo.currentScreenWidth],
    )

    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

    const getIsCurrentScreenLessOrEqual = useCallback(
        (width: number) => {
            return screenInfo.currentScreenWidth <= width
        },
        [screenInfo.currentScreenWidth],
    )

    return {
        screenInfo,
        isDefaultLaptop,
        isSmallLaptop,
        isMobile,
        getIsCurrentScreenLessOrEqual,
    }
}

/** Only one mounted component on the screen can use this hook. It is used for adding event listener to the ref element */
export function useListenerToRef(callback: () => void, ref: MutableRefObject<any> | null, eventName: keyof HTMLElementEventMap = 'click') {
    const refNode = ref?.current

    useEffect(
        () => {
            refNode?.addEventListener(eventName, callback)

            return () => {
                refNode?.removeEventListener(eventName, callback)
            }
        },
        [refNode, eventName, callback],
    )
}

export function usePageLeave(onPageLeave: (location: Location) => void) {
    const history = useHistory()

    useEffect(() => {
        return history.listen((location) => {
            onPageLeave(location as unknown as Location)
        })
    }, [history, onPageLeave])
}

export const useUnload = (fn: (event: BeforeUnloadEvent) => any) => {
    const cb = useRef(fn)

    useEffect(() => {
        cb.current = fn
    }, [fn])

    useEffect(() => {
        const onUnload = (event: BeforeUnloadEvent) => {
            cb.current?.(event)
        }

        window.addEventListener('beforeunload', onUnload)

        return () => window.removeEventListener('beforeunload', onUnload)
    }, [])
}

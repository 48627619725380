import {FC, useCallback} from 'react'
import {AccessedCampaignList} from './AccessedCampaignList'
import {useAccessedCampaignListStyles} from './campaign-list.styles'
import {useSelector} from 'react-redux'
import {myCampaignsSelector} from '../../redux/homepage.selectors'
import {useTranslation} from 'react-i18next'
import {isInDevMode} from 'dst-react-core/utils/shared.utils'
import {getCampaignInfoByParsingCampaignUrl} from '../../homepage.utils'
import {IMyCampaign} from '../../homepage.types'
import {WISH_PAGE_PATH} from '../../../../../campaign/campaign.routes'
import {useHistory} from 'react-router'

export interface IContainerAccessedCampaignListProps {

}

export const ContainerAccessedCampaignList: FC<IContainerAccessedCampaignListProps> = props => {
    const campaignListClasses = useAccessedCampaignListStyles()

    const myCampaigns = useSelector(myCampaignsSelector)

    const {t} = useTranslation()
    const history = useHistory()

    const noCampaignsFoundLabel = t('general_dashboard.no_accessed_campaign')

    const getCampaignPagePath: (campaign: IMyCampaign) => string = useCallback(
        (campaign: IMyCampaign) => {
            const {
                customerName,
                campaignName,
            } = getCampaignInfoByParsingCampaignUrl(campaign?.url || '')

            return `${isInDevMode() ? `/${customerName}${campaignName}` : `https://${campaign?.url}`}${WISH_PAGE_PATH}`
        },
        [],
    )

    const goToCampaignPage = useCallback((campaign: IMyCampaign) => {
        if (isInDevMode()) {
            history.push(getCampaignPagePath(campaign))
        } else {
            window.location.href = getCampaignPagePath(campaign)
        }
    }, [getCampaignPagePath, history])

    return (
        <AccessedCampaignList
            {...props}
            campaignListClasses={campaignListClasses}
            myCampaigns={myCampaigns}
            noCampaignsFoundLabel={noCampaignsFoundLabel}
            goToCampaignPage={goToCampaignPage}
        />
    )
}
import {ChangeEvent, FC, memo} from 'react'
import MainLogo from 'assets/images/logo.png'
import {ContainerGeneralHeaderMenu} from './menu/ContainerGeneralHeaderMenu'
import clsx from 'clsx'
import {ContainerGeneralHeaderSearchBar} from './searchbar/ContainerGeneralHeaderSearchBar'
import {ContainerGeneralHeaderFilter} from './filter/ContainerGeneralHeaderFilter'
import Box from '@material-ui/core/Box'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import {ContainerGeneralHeaderFilterCategoryTab} from './filter/tabs/category/ContainerGeneralHeaderFilterCategoryTab'
import {ContainerGeneralHeaderFilterStatusTab} from './filter/tabs/status/ContainerGeneralHeaderFilterStatusTab'
import {ECampaignStatus, EGiftType} from 'dst-react-core/campaign/campaign.types'
import {GeneralHeaderSideMenuButton} from './side-menu/GeneralHeaderSideMenuButton'
import { Link } from 'react-router-dom'
import { HOMEPAGE_PATH } from '../../general-dashboard-routes'

interface IHeaderProps {
    headerClasses: any,
    isSearchMode: boolean,
    setSearchModeCallback: (isSearchMode: boolean) => void,
    isFilterMode: boolean,
    setFilterModeCallback: (isFilterMode: boolean) => void,
    searchValue: string,
    setSearchValueCallback: (value: string) => void,
    areCampaignsInSearch: boolean,
    currentFilterTabIndex: number,
    handleFilterTabChange: (event: ChangeEvent<{}>, newTabIndex: number) => void,
    filterStatus: ECampaignStatus | '',
    isFilterByNewest: boolean | undefined,
    filterByGiftType: EGiftType | '',
    setFilterStatusCallback: (status: ECampaignStatus | '') => void,
    setFilterByNewestCallback: (isByNewest: boolean | undefined) => void,
    setFilterByGiftTypeCallback: (giftType: EGiftType | '') => void,
    applyFilter: (keyword?: string) => void,
    isSideMenu: boolean,
    toggleSideMenu: (isOpen: boolean) => void,
    isSideMenuOpen: boolean,
    areFiltersAbleToBeOpened: boolean
}

export const Header: FC<IHeaderProps> = memo(props => {
    const {
        headerClasses,
        isSearchMode,
        setSearchModeCallback,
        isFilterMode,
        setFilterModeCallback,
        searchValue,
        setSearchValueCallback,
        areCampaignsInSearch,
        currentFilterTabIndex,
        handleFilterTabChange,
        filterStatus,
        setFilterStatusCallback,
        applyFilter,
        isSideMenu,
        toggleSideMenu,
        isSideMenuOpen,
        areFiltersAbleToBeOpened,
    } = props

    const filterTabs: JSX.Element[] = [
        /** Starts from empty element cause the first tab is disabled (index starts from 1, from the second element) */
        <></>,
        <ContainerGeneralHeaderFilterCategoryTab
            isFilterByNewest={props.isFilterByNewest}
            filterByGiftType={props.filterByGiftType}
            setFilterByNewestCallback={props.setFilterByNewestCallback}
            setFilterByGiftTypeCallback={props.setFilterByGiftTypeCallback}
        />,
        <ContainerGeneralHeaderFilterStatusTab
            filterStatus={filterStatus}
            setFilterStatusCallback={setFilterStatusCallback}
        />,
    ]

    return (
        <div className={clsx(headerClasses.headerWrap)}>
            <div className={clsx(headerClasses.header)}>
                <div className="headerPart">
                    <Link to={HOMEPAGE_PATH} className="logoWrap">
                        <img src={MainLogo} alt='logo' />
                    </Link>
                </div>

                {
                    !isSideMenu && (
                        <>
                            {
                                isSearchMode && (
                                    <div className="headerPart filterPart">
                                        <ContainerGeneralHeaderSearchBar
                                            searchValue={searchValue}
                                            setSearchValueCallback={setSearchValueCallback}
                                            applyFilter={applyFilter}
                                            isSideMenu={isSideMenu}
                                        />
                                    </div>
                                )
                            }

                            {
                                isFilterMode && (
                                    <div className="headerPart filterPart">
                                        <ContainerGeneralHeaderFilter
                                            currentFilterTabIndex={currentFilterTabIndex}
                                            handleFilterTabChange={handleFilterTabChange}
                                            setFilterModeCallback={setFilterModeCallback}
                                            applyFilter={applyFilter}
                                        />
                                    </div>
                                )
                            }

                            <div className="headerPart">
                                <ContainerGeneralHeaderMenu
                                    isSearchMode={isSearchMode}
                                    setSearchModeCallback={setSearchModeCallback}
                                    isFilterMode={isFilterMode}
                                    setFilterModeCallback={setFilterModeCallback}
                                    areCampaignsInSearch={areCampaignsInSearch}
                                    areFiltersAbleToBeOpened={areFiltersAbleToBeOpened}
                                />
                            </div>
                        </>
                    )
                }

                {
                    isSideMenu && (
                        <GeneralHeaderSideMenuButton
                            isSideMenuOpen={isSideMenuOpen}
                            toggleSideMenu={toggleSideMenu}
                            searchValue={searchValue}
                            setSearchValueCallback={setSearchValueCallback}
                            applyFilter={applyFilter}
                            isSideMenu={isSideMenu}
                            isFilterByNewest={props.isFilterByNewest}
                            filterByGiftType={props.filterByGiftType}
                            setFilterByNewestCallback={props.setFilterByNewestCallback}
                            setFilterByGiftTypeCallback={props.setFilterByGiftTypeCallback}
                            filterStatus={filterStatus}
                            setFilterStatusCallback={setFilterStatusCallback}
                            areFiltersAbleToBeOpened={areFiltersAbleToBeOpened}
                        />
                    )
                }
            </div>

            {
                !isSideMenu && (
                    <div className="filterTabs">
                        <Accordion
                            expanded={isFilterMode}
                            className={'accordion'}
                        >
                            <AccordionSummary className={'summary'}>
                                <></>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box
                                    className={'tabsWrap'}
                                >
                                    {
                                        filterTabs[currentFilterTabIndex]
                                    }
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                )
            }
        </div>
    )
})
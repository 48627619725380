import { useHeaderStyles } from 'deziro-web-app-components/shared/styles/header.styles'
import { IRouteId } from 'dst-react-core/core.types'
import { useHistory, useParams } from 'react-router-dom'
import { useCampaignAuthHeaderStyles } from 'deziro-web-app-components/components/login/components/auth-header/campaign-auth-header.styles'
import CampaignAuthHeader from 'deziro-web-app-components/components/login/components/auth-header/CampaignAuthHeader'
import { FC } from 'react'

export interface ICampaignAuthHeaderContainerProps {
    path: string
    loginLogo?: string
}

export const CampaignAuthHeaderContainer: FC<ICampaignAuthHeaderContainerProps> = (props) => {
    const {path, loginLogo} = props
    const { id } = useParams<IRouteId>()

    const history = useHistory()

    const classes = useHeaderStyles()

    const campaignHeaderClasses = useCampaignAuthHeaderStyles(loginLogo)

    return (
        <CampaignAuthHeader
            id={id}
            history={history}
            classes={classes}
            campaignHeaderClasses={campaignHeaderClasses}
            path={path}
        />
    )
}

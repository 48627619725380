import {fetchActionGenerator} from '../../fetch/fetch.utils'
import {VOUCHER_GET_API} from './voucher.api.constants'
import {combineEpics} from 'redux-observable'
import {mergeMapFetchEpics} from '../../fetch/fetch.call'

export const [
    getVoucherByGiftIdAction,
    getVoucherByGiftIdActionBuilder,
    getVoucherByGiftIdActionEpic,
] = fetchActionGenerator('VOUCHER_GET', VOUCHER_GET_API, mergeMapFetchEpics)

const voucherEpics = combineEpics(
    getVoucherByGiftIdActionEpic,
)

export default voucherEpics
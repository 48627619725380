import {ChangeEvent, FC} from 'react'
import {GeneralHeaderFilter} from './GeneralHeaderFilter'
import {useGeneralHeaderFilterStyles} from './filter.styles'
import {useGeneralHeaderFilterAppBarStyles} from './filter-app-bar.styles'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {isMyCampaignsActionInPending} from '../../../homepage/redux/homepage.actions'

export interface IContainerGeneralHeaderFilterProps {
    currentFilterTabIndex: number,
    handleFilterTabChange: (event: ChangeEvent<{}>, newTabIndex: number) => void,
    setFilterModeCallback: (isFilterMode: boolean) => void,
    applyFilter: () => void
}

export const ContainerGeneralHeaderFilter: FC<IContainerGeneralHeaderFilterProps> = props => {
    const filterClasses = useGeneralHeaderFilterStyles()
    const appBarClasses = useGeneralHeaderFilterAppBarStyles()
    const {t} = useTranslation()

    const isMyCampaignsListInPending = useSelector(isMyCampaignsActionInPending)

    const filterPerLabel = t('general_dashboard.filter.per')
    const categoryLabel = t('general_dashboard.filter.category')
    const statusLabel = t('general_dashboard.filter.status')

    return (
        <GeneralHeaderFilter
            {...props}
            filterClasses={filterClasses}
            appBarClasses={appBarClasses}
            filterPerLabel={filterPerLabel}
            categoryLabel={categoryLabel}
            statusLabel={statusLabel}
            applyFilter={props.applyFilter}
            isMyCampaignsListInPending={isMyCampaignsListInPending}
        />
    )
}

import {FC, memo, MutableRefObject} from 'react'

import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import GenericLink from 'deziro-web-app-components/shared/utils/GenericLink'
import { ILoginFormValues } from 'deziro-web-app-components/components/login/form/LoginForm'
import { LoginFormContainer } from 'deziro-web-app-components/components/login/form/LoginFormContainer'

import { insertId } from 'dst-react-core/utils/shared.utils'
import { EToastColor } from 'dst-react-core/components/toast/toast.types'

export interface ICampaignLoginFormProps {
    isLoginFailed: boolean
    isLoginInPending: boolean
    forgotPassword: string
    clickHere: string
    invalidCreds: string
    logIn: string
    path: string
    id: string
    formik?: any
    handleKeepLoggedIn?: (evt: React.ChangeEvent<HTMLInputElement>) => void
    keepLoggedIn: boolean
    onSubmit: (values: ILoginFormValues, staySignedIn: boolean) => void
    vSchema: any
    vString: any
    vEmail: any,
    submitButtonRef: MutableRefObject<HTMLButtonElement | null>
}

const CampaignLoginForm: FC<ICampaignLoginFormProps> = memo((props) => {
    const {
        submitButtonRef,
        isLoginFailed,
        isLoginInPending,
        clickHere,
        forgotPassword,
        invalidCreds,
        logIn,
        path,
        id,
        handleKeepLoggedIn,
        keepLoggedIn,
        onSubmit,
        vSchema,
        vEmail,
        vString,
    } = props
    return (
        <>
            <LoginFormContainer
                handleKeepLoggedIn={handleKeepLoggedIn}
                keepLoggedIn={keepLoggedIn}
                onSubmit={onSubmit}
                vSchema={vSchema}
                vString={vString}
                vEmail={vEmail}
                submitButtonRef={submitButtonRef}
                isGeneralLogin={false}
            />
            <Typography variant={'caption'}>
                {forgotPassword}&nbsp;
                <GenericLink href={path ? insertId(path, id) : ''}>{clickHere}</GenericLink>
            </Typography>
            {isLoginFailed && <Typography style={{ color: EToastColor.Error }}>{invalidCreds}</Typography>}
            <Button ref={submitButtonRef} className={'big'} variant='contained' disabled={isLoginInPending}>
                {logIn}
            </Button>
        </>
    )
})

export default CampaignLoginForm

import {Redirect, Route, Switch} from 'react-router-dom'
import {useResetPasswordStyles} from 'web-app/components/auth/reset-password/reset-password.styles'
import {GENERAL_AUTH_RESET_PASSWORD_SUBROUTING} from './general-auth-reset-rassword.routes'
import Box from '@material-ui/core/Box'
import GenericHeader from 'shared/components/generic/GenericHeader'
import ResetPasswordEmail from 'web-app/components/auth/reset-password/email/ResetPasswordEmail'
import ResetPasswordPassword from 'web-app/components/auth/reset-password/password/ResetPasswordPassword'

const GeneralAuthResetPasswordRoute: React.FunctionComponent = () => {
    const classes = useResetPasswordStyles()

    return (
        <Box className={classes.root}>
            <GenericHeader />
            <Box className={classes.container}>
                <Box className={classes.content}>
                    <Switch>

                        {/* ROUTE TO GENERAL AUTH LOGIN*/}
                        <Route path={GENERAL_AUTH_RESET_PASSWORD_SUBROUTING.email.path}>
                            <ResetPasswordEmail />
                        </Route>

                        {/* ROUTE TO GENERAL AUTH PASSWORD*/}
                        <Route path={GENERAL_AUTH_RESET_PASSWORD_SUBROUTING.password.path}>
                            <ResetPasswordPassword />
                        </Route>

                        {/* DEFAULT */}
                        <Route>
                            <Redirect to={GENERAL_AUTH_RESET_PASSWORD_SUBROUTING.email.path} />
                        </Route>

                    </Switch>
                </Box>
            </Box>
        </Box>
    )
}

export default GeneralAuthResetPasswordRoute

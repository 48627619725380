import {IRouter} from '../../../../dst-react-core/routing/routes.types'
import {EMAIL_PATH, PASSWORD_PATH} from '../../../web-app.routes'
import {CAMPAIGN_AUTH_PASSWORD_RESET_PATH} from '../campaign-auth.routes'

export const CAMPAIGN_AUTH_PASSWORD_EMAIL_PATH = `${CAMPAIGN_AUTH_PASSWORD_RESET_PATH}${EMAIL_PATH}`
export const CAMPAIGN_AUTH_PASSWORD_PASSWORD_PATH = `${CAMPAIGN_AUTH_PASSWORD_RESET_PATH}${PASSWORD_PATH}`

export const CAMPAIGN_AUTH_REST_PASSWORD_ROUTE = Object.freeze<IRouter>({
    path: CAMPAIGN_AUTH_PASSWORD_RESET_PATH,
    description: 'Web app campaign auth reset password route',
    subrouting: {
        email: {
            path: CAMPAIGN_AUTH_PASSWORD_EMAIL_PATH,
            description: 'Web app campaign auth reset reset-password email route',
        },
        password: {
            path: CAMPAIGN_AUTH_PASSWORD_PASSWORD_PATH,
            description: 'Web app campaign auth reset-password password route',
        },
    },
})

export const CAMPAIGN_AUTH_RESET_PASSWORD_SUBROUTING = CAMPAIGN_AUTH_REST_PASSWORD_ROUTE.subrouting
import React from 'react'
import {
    myReviewByCampaignGetActionBuilder,
    reviewSummaryGetActionBuilder,
    reviewWritePostActionBuilder,
} from './redux/review.actions'

export const getCampaignReviewSummaryBuilder = (campaignId: React.ReactText) => {
    return reviewSummaryGetActionBuilder(null, {}, {}, {campaignId})
}

export const getCampaignReviewBuilder = (campaignId: React.ReactText) => {
    return myReviewByCampaignGetActionBuilder(null, {}, {}, {campaignId})
}

export const writeReview = (campaignId: React.ReactText, rating: number) => {
    return reviewWritePostActionBuilder(null, {}, {}, {campaignId, rating})
}
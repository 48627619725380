import {useCallback} from 'react'
import {getMyCampaigns, IMyCampaignFilter} from '../homepage.utils'
import {useDispatch} from 'react-redux'

export const useGetMyCampaigns = () => {
    const dispatch = useDispatch()

    return useCallback(
        (filter?: IMyCampaignFilter) => {
            dispatch(getMyCampaigns(filter))
        },
        [dispatch],
    )
}
import makeStyles from '@material-ui/core/styles/makeStyles'
import {flex} from '../../../../dst-react-core/utils/styles.utils'

export const useCustomRatingStyles = makeStyles(() => ({
    reviewWrap: {
        ...flex(true, false, 5, 'initial'),
        marginTop: 12,
    },
    rating: {
        '& .MuiRating-decimal': {
            color: '#EFDE85',
        },
        '& .MuiRating-iconEmpty': {
            color: 'rgba(218, 219, 253, 0.2)'
        }
    },
    title: {
        size: 12,
        fontWeight: 500,
        lineHeight: '16px',
        color: '#EFDE85',
    },
}))
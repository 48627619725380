import {EBannerLine, IBanner} from 'dst-react-core/campaign/campaign.types'

export const prepareBanners = (banners: IBanner[] = []) => {
    const res = {} as { [key in EBannerLine]: any }
    for (const _key of Object.keys(EBannerLine)) {
        const key = _key as unknown as EBannerLine
        if (banners.find(b => b.line === key)?.visible)
            res[key] = banners.filter(b => b.line === key)
        else res[key] = null
    }
    return res
}
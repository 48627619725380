import TextField from '@material-ui/core/TextField'
import {StandardTextFieldProps} from '@material-ui/core/TextField/TextField'
import {FormikErrors, FormikTouched} from 'formik/dist/types'
import {useEffect, useRef} from 'react'

interface IFormikTextFieldProps extends StandardTextFieldProps {
    formik: {
        handleChange: {
            (e: React.ChangeEvent<any>): void
            <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void
        };
        handleBlur: {
            (e: React.FocusEvent<any>): void
            <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void
        },
        touched: FormikTouched<any>
        values: {
            [field: string]: any;
        }
        errors: FormikErrors<any>
    }
    fieldName: string
}

const FormikTextField: React.FunctionComponent<IFormikTextFieldProps> = props => {

    const {
        formik,
        fieldName,
        type,
        ...rest
    } = props

    const inputRef = useRef<HTMLDivElement>(null)

    // Avoid input number scrolling
    useEffect(() => {
        const handleWheel = (e: any) => e.target.blur()

        const elem = inputRef.current

        if (elem)
            elem.addEventListener('wheel', handleWheel)

        return () => {
            elem?.removeEventListener('wheel', handleWheel)
        }
    }, [])

    return (
        <TextField
            name={fieldName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            helperText={formik.touched[fieldName] && formik.errors[fieldName]}
            variant="outlined"
            value={formik.values[fieldName]}
            ref={inputRef}
            {...type === 'number' ? {
                // Avoid e while input is type number
                onKeyDown: (evt) => evt.key === 'e' && evt.preventDefault(),
            } : {}}
            type={type}
            {...rest}
        />
    )
}

export default FormikTextField

import * as React from 'react'
import {useEffect} from 'react'
import {useSelector} from 'react-redux'
import {Redirect, Route, Switch, useHistory, useLocation} from 'react-router-dom'
import {GENERAL_SUBROUTING} from './general.routes'
import {X_AUTH_TOKEN_LABEL_SAVED} from '../../dst-react-core/core.constants'
import {authIsLoggedInSelector} from '../components/auth/redux/auth.selectors'
import GeneralAuthRoute from './auth/GeneralAuthRoute'
import {ContainerGeneralDashboard} from './dashboard/ContainerGeneralDashboard'

const GeneralRoute: React.FunctionComponent = () => {

    const isLoggedIn = useSelector(authIsLoggedInSelector)

    const authToken = localStorage.getItem(X_AUTH_TOKEN_LABEL_SAVED)

    const pathName = useLocation().pathname

    const history = useHistory()

    // Redirection depends on signed user or not
    // If user signed but rout is /auth - redirect to dashboard
    // If user is not signed in - redirect to login page
    useEffect(() => {
        if (authToken && pathName.includes('/auth') && !pathName.includes('/change-email-confirm'))
            history.push(GENERAL_SUBROUTING.dashboard.path)
        else if (!authToken && !pathName.includes('/auth')) {
            history.push(GENERAL_SUBROUTING.auth.path)
        }
    }, [isLoggedIn, pathName, history, authToken])

    return (
        <Switch>

            {/* ROUTE TO GENERAL AUTH */}
            <Route path={GENERAL_SUBROUTING.auth.path}>
                <GeneralAuthRoute/>
            </Route>

            {/* ROUTE TO GENERAL DASHBOARD*/}
            <Route path={GENERAL_SUBROUTING.dashboard.path}>
                <ContainerGeneralDashboard/>
            </Route>

            {/* DEFAULT */}
            <Route>
                <Redirect to={GENERAL_SUBROUTING.auth.path}/>
            </Route>

        </Switch>
    )
}

export default GeneralRoute

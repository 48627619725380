import {
    campaignFetchBuilder,
    campaignHasAccessActionBuilder,
    campaignPageDataActionBuilder,
    campaignRequestAccessActionBuilder,
} from './redux/campaign.actions'
import {ECampaignPageName} from '../../dst-react-core/campaign/campaign.types'
import React from 'react'
import {IEclexiaDirectUrls} from '../../dst-react-core/eclexia/eclexia.types'
import {isInDevMode} from '../../dst-react-core/utils/shared.utils'

export const campaignCheckAccessActionBuilder = (campaignId: React.ReactText) => {
    return campaignHasAccessActionBuilder(
        null,
        {}, {},
        {campaignId},
    )
}

export const campaignAccessActionBuilder = (campaignId: React.ReactText, accessKey: string) => {
    return campaignRequestAccessActionBuilder(
        null,
        {}, {},
        {campaignId, accessKey},
    )
}

export const getCampaignByUrlBuilder = (url: string) => {
    return campaignFetchBuilder(null, {}, {}, {url})
}

export const getCampaignPageDataBuilder = (campaignId: React.ReactText, name: ECampaignPageName) => {
    return campaignPageDataActionBuilder(
        null,
        {}, {},
        {campaignId, name},
    )
}


export const campaignLinkGenerator = (): string => {
    const {host, pathname, protocol} = window.location

    // no matter which rout is after campaign name,
    // but because string starts with "/", first value of split array will be empty string
    // so we getting second one (arr[1])
    const url = pathname.split('/')[1]

    // If we in dev mode we should use simplified url like "localhost:3000/companyName.campaignName"
    // but in production we will have url like "companyName.deziro.webapp/campaignName
    const [company, campaign, _host] = isInDevMode() ? [...url.split('.'), host] : [host.replace(/^www./, '').split('.')[0], url, host.split('.').slice(0).join('.')]

    // Preparing of the url of campaign
    return `www.${company}.${protocol}//${_host}/${campaign}`
}

export const getVideoUrlWithPriority = (video: IEclexiaDirectUrls | null | undefined): string => {
    return (
        video?.['mp4-720p'] ??
        video?.['mp4-480p'] ??
        video?.['mp4-360p'] ??
        video?.['mp4-240p'] ??
        ''
    )
}

export const getPodcastUrlWithPriority = (podcast: IEclexiaDirectUrls | null | undefined): string => {
    return (
        podcast?.['mp3'] ??
        ''
    )
}
import React, {FC} from 'react'
import {GeneralHeaderSideMenu} from './GeneralHeaderSideMenu'
import {useGeneralHeaderSideMenuStyles} from './side-menu.styles'
import {IContainerGeneralHeaderSearchBarProps} from '../searchbar/ContainerGeneralHeaderSearchBar'
import {useSelector} from 'react-redux'
import {personSelector} from '../../../../../components/auth/redux/auth.selectors'
import {useTranslation} from 'react-i18next'
import {ECampaignStatus, EGiftType} from '../../../../../../dst-react-core/campaign/campaign.types'
import {useHomepageMenuConfig} from '../hooks/homepage-menu-config.hook'

export interface IContainerGeneralHeaderSideMenuProps extends IContainerGeneralHeaderSearchBarProps {
    handleClose: () => void,
    setFilterByNewestCallback: (isByNewest: boolean | undefined) => void,
    setFilterByGiftTypeCallback: (giftType: EGiftType | '') => void,
    isFilterByNewest: boolean | undefined,
    filterByGiftType: EGiftType | '',
    filterStatus: ECampaignStatus | '',
    setFilterStatusCallback: (status: ECampaignStatus | '') => void,
    areFiltersAbleToBeOpened: boolean,
}

export const ContainerGeneralHeaderSideMenu: FC<IContainerGeneralHeaderSideMenuProps> = props => {
    const {
        isSideMenu,
        setFilterByNewestCallback,
        setFilterByGiftTypeCallback,
        isFilterByNewest,
        filterByGiftType,
        filterStatus,
        setFilterStatusCallback,
    } = props

    const {t} = useTranslation()

    const person = useSelector(personSelector)

    const sideMenuClasses = useGeneralHeaderSideMenuStyles()

    const serviceCenterLabel = t('general_dashboard.service_center')
    const searchBarPlaceholder = t('general_dashboard.searchbar_short_placeholder')

    const menuConfig = useHomepageMenuConfig()

    return (
        <GeneralHeaderSideMenu
            {...props}
            isSideMenu={isSideMenu}
            sideMenuClasses={sideMenuClasses}
            userName={person?.name || '-'}
            menuConfig={menuConfig}
            serviceCenterLabel={serviceCenterLabel}
            setFilterByNewestCallback={setFilterByNewestCallback}
            setFilterByGiftTypeCallback={setFilterByGiftTypeCallback}
            isFilterByNewest={isFilterByNewest}
            filterByGiftType={filterByGiftType}
            filterStatus={filterStatus}
            setFilterStatusCallback={setFilterStatusCallback}
            searchBarPlaceholder={searchBarPlaceholder}
        />
    )
}
import {NEVER, Observable} from 'rxjs'
import {IAction} from '../../core.types'
import {ofType} from 'redux-observable'
import {mergeMap} from 'rxjs/operators'
import {ECLEXIA_ACTION_WITH_TOKEN_UPDATE_ACTION_TYPE, eclexiaLoginAction} from './eclexia.actions'
import {ECLEXIA_ACCESS_TOKEN, ECLEXIA_ACCESS_TOKEN_EXPIRATION_DATE} from '../eclexia.constans'
import {authEclexiaUser} from '../eclexia.utils'

export const eclexiaAuthorizedSuccessEpic = (action$: Observable<IAction>) => {
    return action$.pipe(
        ofType(eclexiaLoginAction.successActionType),
        mergeMap((action: IAction) => {
            const {
                access_token,
                expires_in,
                token_type,
            } = action.payload
            localStorage.setItem(ECLEXIA_ACCESS_TOKEN, `${token_type} ${access_token}`)
            localStorage.setItem(ECLEXIA_ACCESS_TOKEN_EXPIRATION_DATE, `${Date.now() + expires_in * 1000}`)
            return action.meta.meta.actions ?? NEVER
        }),
    )
}

export const eclexiaActionWithTokenUpdateEpic = (action$: Observable<IAction>) => {
    return action$.pipe(
        ofType(ECLEXIA_ACTION_WITH_TOKEN_UPDATE_ACTION_TYPE),
        mergeMap((action: IAction) => {
            const eclexiaExpiresAt = +(localStorage.getItem(ECLEXIA_ACCESS_TOKEN_EXPIRATION_DATE) ?? 0)
            return Date.now() > eclexiaExpiresAt ? [authEclexiaUser(action.meta.actions)] : action.meta.actions
        }),
    )
}
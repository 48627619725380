import {FC, memo} from 'react'
import {IContainerGeneralHeaderFilterStatusTabProps} from './ContainerGeneralHeaderFilterStatusTab'
import {ECampaignStatus} from '../../../../../../../../dst-react-core/campaign/campaign.types'

interface IGeneralHeaderFilterStatusTabProps extends IContainerGeneralHeaderFilterStatusTabProps {
    statusTabClasses: any,
    onGoingLabel: string,
    terminateLabel: string,
    getStatusChip: (value: ECampaignStatus, label: string) => JSX.Element
}

export const GeneralHeaderFilterStatusTab: FC<IGeneralHeaderFilterStatusTabProps> = memo(props => {
    const {
        statusTabClasses,
        onGoingLabel,
        terminateLabel,
        getStatusChip,
    } = props


    return (
        <div className={statusTabClasses.chipWrap}>
            {
                getStatusChip(ECampaignStatus.PUBLISHED, onGoingLabel)
            }
            {
                getStatusChip(ECampaignStatus.CLOSED, terminateLabel)
            }
        </div>
    )
})
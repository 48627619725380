import {
    ECLEXIA_GET_SPECIFIED_VIDEO_API,
    ECLEXIA_GET_VIDEOS_API, ECLEXIA_LOGIN_API, GET_SPECIFIED_LIVE_PROGRAM_API,
} from './eclexia.constants.api'
import {ECLEXIA_API_URL, ECLEXIA_LOGIN_URL} from '../eclexia.constans'
import {fetchActionGenerator} from '../../fetch/fetch.utils'
import {mergeMapFetchEpics, switchMapFetchEpics} from '../../fetch/fetch.call'
import {combineEpics} from 'redux-observable'
import {eclexiaActionWithTokenUpdateEpic, eclexiaAuthorizedSuccessEpic} from './eclexia.epics'
import {IFetchAction} from '../../fetch/fetch.types'

export const [
    eclexiaGetVideosAction,
    eclexiaGetVideosActionBuilder,
    eclexiaGetVideosActionEpic,
    isEclexiaGetVideosActionInPending,
    ECLEXIA_GET_VIDEOS_ACTION_TYPE,
    ECLEXIA_GET_VIDEOS_ACTION_REQUEST_ID,
] = fetchActionGenerator('ECLEXIA_GET_VIDEOS', ECLEXIA_GET_VIDEOS_API, switchMapFetchEpics, ECLEXIA_API_URL)

export const [
    eclexiaGetSpecifiedVideoAction,
    eclexiaGetSpecifiedVideoActionBuilder,
    eclexiaGetSpecifiedVideoActionEpic,
    isEclexiaGetSpecifiedVideoActionInPending,
    ECLEXIA_GET_SPECIFIED_VIDEO_ACTION_TYPE,
    ECLEXIA_GET_SPECIFIED_VIDEO_ACTION_REQUEST_ID,
] = fetchActionGenerator('ECLEXIA_GET_SPECIFIED_VIDEO', ECLEXIA_GET_SPECIFIED_VIDEO_API, mergeMapFetchEpics, ECLEXIA_API_URL)

export const [
    eclexiaLoginAction,
    eclexiaLoginActionBuilder,
    eclexiaLoginActionEpic,
] = fetchActionGenerator('ECLEXIA_LOGIN', ECLEXIA_LOGIN_API, switchMapFetchEpics, ECLEXIA_LOGIN_URL)

export const ECLEXIA_ACTION_WITH_TOKEN_UPDATE_ACTION_TYPE = 'ECLEXIA_ACTION_WITH_TOKEN_UPDATE_ACTION_TYPE'
export const eclexiaActionWithTokenUpdate = (action: IFetchAction) => ({
    type: ECLEXIA_ACTION_WITH_TOKEN_UPDATE_ACTION_TYPE,
    meta: {
        actions: [action],
    },
})

export const [
    eclexiaGetLiveProgramAction,
    eclexiaGetLiveProgramActionBuilder,
    eclexiaGetLiveProgramActionEpic,
] = fetchActionGenerator('ECLEXIA_GET_LIVE_PROGRAM', GET_SPECIFIED_LIVE_PROGRAM_API, switchMapFetchEpics, ECLEXIA_API_URL)

const eclexiaActions = combineEpics(
    eclexiaGetVideosActionEpic,
    eclexiaGetSpecifiedVideoActionEpic,
    eclexiaLoginActionEpic,
    eclexiaGetLiveProgramActionEpic,
    eclexiaAuthorizedSuccessEpic,
    eclexiaActionWithTokenUpdateEpic,
)

export default eclexiaActions
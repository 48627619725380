import * as React from 'react'
import {GenericDialog} from './GenericDialog'

interface ITestDialogProps {
}

export const TestDialog: React.FC<ITestDialogProps> = () => {

    return (
        <GenericDialog
            dialogTitle={'Test Dialog'}
            dialogTextContent={'lorem_1'}
            actionCloseLabel={'Chiudi'}
            actionAgreeLabel={'Ok'}/>
    )
}


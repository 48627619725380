import {imageSelector, IWebAppRootState} from '../../../redux/app.reducers'
import {IImagesState} from '../../../dst-react-core/image/image.types'
import {IEclexiaFullVideo, IEclexiaVideosState} from '../../../dst-react-core/eclexia/eclexia.types'
import {IGiftFilesState} from '../../../dst-react-core/gift-file/gift-file.types'
import {createSelector} from 'reselect'
import React from 'react'
import {IVoucherGift, IVouchersState} from '../../../dst-react-core/voucher/voucher.types'

export const voucherSelector = (state: IWebAppRootState) : IVouchersState | null => state.vouchers ?? null

export const eclexiaVideoSelector = (state: IWebAppRootState): IEclexiaVideosState | null => state.eclexiaVideos ?? null

export const giftFileSelector = (state: IWebAppRootState): IGiftFilesState | null => state.giftFiles ?? null

export const campaignImageSelector = (id: number | null | undefined) => createSelector(
    imageSelector,
    (images: IImagesState | null): string | null => id ? images?.[id] ?? null : null,
)

export const campaignVoucherSelector = (id: number | null | undefined) => createSelector(
    voucherSelector,
    (vouchers: IVouchersState | null): IVoucherGift | null => id ? vouchers?.[id] ?? null : null,
)

export const campaignEclexiaVideoSelector = (id: React.ReactText | null | undefined) => createSelector(
    eclexiaVideoSelector,
    (videos: IEclexiaVideosState | null): IEclexiaFullVideo | null => id ? videos?.[`${id}`] ?? null : null,
)

export const campaignEclexiaVideosSelector = (ids: (React.ReactText | null | undefined)[]) => createSelector(
    eclexiaVideoSelector,
    (videos: IEclexiaVideosState | null): (IEclexiaFullVideo | null)[] => ids.reduce((acc, id) => [...acc, videos?.[`${id}`] ?? null], [] as (IEclexiaFullVideo | null)[]),
)

export const campaignEclexiaVouchersSelector = (ids: (number | null | undefined)[]) => createSelector(
    voucherSelector,
    (vouchers: IVouchersState | null): (IVoucherGift | null)[] => ids.reduce((acc, id) => [...acc, vouchers?.[+`${id}`] ?? null], [] as (IVoucherGift | null)[]),
)

export const campaignGiftFileSelector = (id: number | null | undefined) => createSelector(
    giftFileSelector,
    (files: IGiftFilesState | null): string | null => id ? files?.[id] ?? null : null,
)
import makeStyles from '@material-ui/core/styles/makeStyles'

export const useGeneralHeaderSideMenuFilter = makeStyles(theme => ({
    filterWrap: {
        '& > .title': {
            fontSize: 16,
            color: theme.palette.text.secondary,
            lineHeight: '24px',
            margin: '30px 0 20px',
        },
    },
}))
import {FC} from 'react'
import {ProfileSubHeader} from './ProfileSubHeader'
import {useProfileSubHeaderStyles} from './sub-header.styles'
import {useSelector} from 'react-redux'
import {personSelector} from '../../../../../../components/auth/redux/auth.selectors'
import {useTranslation} from 'react-i18next'
import {useLogout} from '../../../../homepage/hooks/logout.hook'
import {useScreen} from '../../../../../../../dst-react-core/core.hooks'

export interface IContainerProfileSubHeaderProps {

}

export const ContainerProfileSubHeader: FC<IContainerProfileSubHeaderProps> = props => {
    const subHeaderClasses = useProfileSubHeaderStyles()
    const person = useSelector(personSelector)
    const {t} = useTranslation()

    const {isMobile} = useScreen()

    const logoutCallback = useLogout()

    const personName = `${person?.name} ${person?.surname}` || '-'

    const logoutLabel = t('header.user.logout')

    return (
        <ProfileSubHeader
            {...props}
            subHeaderClasses={subHeaderClasses}
            logout={logoutCallback}
            personName={personName}
            logoutLabel={logoutLabel}
            isMobile={isMobile}
        />
    )
}
import {useCallback} from 'react'
import {authUserLogOutAction} from '../../../../components/auth/redux/auth.actions'
import {useDispatch} from 'react-redux'

export const useLogout = () => {
    const dispatch = useDispatch()

    return useCallback(
        () => {
            dispatch(authUserLogOutAction)
        },
        [dispatch],
    )
}
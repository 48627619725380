import {FC} from 'react'
import {SideMenuFilterSelect} from './SideMenuFilterSelect'
import {useSideMenuFilterSelectStyles} from './side-menu-filter-select.styles'
import {IOption} from '../../../../../../../../dst-react-core/core.types'

export interface IContainerSideMenuFilterSelectProps {
    title: string,
    options: IOption[],
    selectedValue: string | number | boolean | undefined,
    onChange: (value: string | undefined) => void,
    resetValue: '' | undefined
}

export const ContainerSideMenuFilterSelect: FC<IContainerSideMenuFilterSelectProps> = props => {
    const sideMenuFilterSelectClasses = useSideMenuFilterSelectStyles()

    return (
        <SideMenuFilterSelect
            {...props}
            sideMenuFilterSelectClasses={sideMenuFilterSelectClasses}
        />
    )
}
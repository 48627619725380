import * as React from 'react'
import FormControlLabel, {FormControlLabelProps} from '@material-ui/core/FormControlLabel'
import Checkbox, {CheckboxProps} from '@material-ui/core/Checkbox'
import makeStyles from '@material-ui/core/styles/makeStyles'
import clsx from 'clsx'

interface IFormControlLabelProps extends Omit<FormControlLabelProps, 'label' | 'control'> {
    control?: React.ReactElement<any, any>
}

interface IGenericCheckboxProps extends CheckboxProps {
    label?: React.ReactNode
    formControlLabelProps?: IFormControlLabelProps
    error?: boolean
}

const useGenericCheckboxStyles = makeStyles(() => ({
    form: {
        width: 'fit-content',
        display: 'flex',
    },
}))

const GenericCheckbox: React.FunctionComponent<IGenericCheckboxProps> = props => {

    const {
        formControlLabelProps,
        label,
        ...rest
    } = props

    const classes = useGenericCheckboxStyles()

    return (
        <FormControlLabel
            control={<Checkbox
                color="primary"
                {...rest}
            />}
            label={label ?? ''}
            {...formControlLabelProps}
            className={clsx(classes.form, formControlLabelProps?.className)}
        />
    )
}

export default GenericCheckbox

import {FC, memo} from 'react'
import clsx from 'clsx'

import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import {ECampaignStatus} from 'dst-react-core/campaign/campaign.types'
import {ContainerCustomAvatar} from 'shared/components/common/avatar/ContainerCustomAvatar'
import MainLogo from 'assets/images/logo.png'

import {IContainerGeneralHeaderSideMenuProps} from './ContainerGeneralHeaderSideMenu'
import {ContainerGeneralHeaderSideMenuFilter} from './filter/ContainerGeneralHeaderSideMenuFilter'
import {ContainerGeneralHeaderSearchBar} from '../searchbar/ContainerGeneralHeaderSearchBar'
import {IHomepageDropdownMenuConfig} from '../hooks/homepage-menu-config.hook'

interface IGeneralHeaderSideMenuProps extends IContainerGeneralHeaderSideMenuProps {
    sideMenuClasses: any,
    userName: string,
    serviceCenterLabel: string,
    filterStatus: ECampaignStatus | '',
    setFilterStatusCallback: (status: ECampaignStatus | '') => void,
    menuConfig: IHomepageDropdownMenuConfig[],
    searchBarPlaceholder: string
}

export const GeneralHeaderSideMenu: FC<IGeneralHeaderSideMenuProps> = memo(props => {
    const {
        sideMenuClasses,
        handleClose,
        setSearchValueCallback,
        searchValue,
        applyFilter,
        isSideMenu,
        userName,
        menuConfig,
        serviceCenterLabel,
        setFilterByNewestCallback,
        setFilterByGiftTypeCallback,
        isFilterByNewest,
        filterByGiftType,
        filterStatus,
        setFilterStatusCallback,
        areFiltersAbleToBeOpened,
        searchBarPlaceholder
    } = props

    return (
        <div
            className={sideMenuClasses.menuWrap}
        >
            <div className={'content'}>
                <div className="header">
                    <img src={MainLogo} alt='logo' />

                    <CloseIcon
                        className={'closeIcon'}
                        onClick={handleClose}
                    />
                </div>

                {
                    areFiltersAbleToBeOpened && (
                        <div className="searchWrap">
                            <ContainerGeneralHeaderSearchBar
                                setSearchValueCallback={setSearchValueCallback}
                                searchValue={searchValue}
                                applyFilter={applyFilter}
                                textFieldClassName={'searchBar'}
                                isSideMenu={isSideMenu}
                                placeholder={searchBarPlaceholder}
                            />
                        </div>
                    )
                }

                <div className={clsx('userInfoWrap', {userInfoWrapWithoutSearchbar: !areFiltersAbleToBeOpened})}>
                    <div className="userInfoHeader">
                        <ContainerCustomAvatar/>

                        <Typography className={'nameTitle'}>
                            {
                                userName
                            }
                        </Typography>
                    </div>

                    <ul className="userInfoMenu">
                        {
                            menuConfig.map(item => (
                                <li onClick={item.action} key={item.label}>
                                    <Typography className={'label'}>
                                        {item?.icon} {item.label}
                                    </Typography>
                                </li>
                            ))
                        }
                    </ul>
                </div>

                {
                    areFiltersAbleToBeOpened && (
                        <div className="filterWrap">
                            <ContainerGeneralHeaderSideMenuFilter
                                setFilterByNewestCallback={setFilterByNewestCallback}
                                setFilterByGiftTypeCallback={setFilterByGiftTypeCallback}
                                isFilterByNewest={isFilterByNewest}
                                filterByGiftType={filterByGiftType}
                                filterStatus={filterStatus}
                                setFilterStatusCallback={setFilterStatusCallback}
                            />
                        </div>
                    )
                }
            </div>

            <div className="actionsWrap">
                <Button className={'serviceCenterButton'}>
                    {serviceCenterLabel}
                </Button>
            </div>
        </div>
    )
})
import {FC, memo} from 'react'
import {IContainerGeneralHeaderFilterProps} from './ContainerGeneralHeaderFilter'
import AppBar from '@material-ui/core/AppBar'
import Typography from '@material-ui/core/Typography'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import SearchIcon from '@material-ui/icons/Search'
import CloseIcon from '@material-ui/icons/Close'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import {CircularProgress} from '@material-ui/core'

export interface IGeneralHeaderFilterProps extends IContainerGeneralHeaderFilterProps {
    filterClasses: any,
    appBarClasses: any,
    filterPerLabel: string,
    categoryLabel: string,
    statusLabel: string,
    applyFilter: () => void,
    isMyCampaignsListInPending: boolean
}

export const GeneralHeaderFilter: FC<IGeneralHeaderFilterProps> = memo(props => {
    const {
        filterClasses,
        appBarClasses,
        currentFilterTabIndex,
        handleFilterTabChange,
        filterPerLabel,
        categoryLabel,
        statusLabel,
        setFilterModeCallback,
        applyFilter,
        isMyCampaignsListInPending,
    } = props

    return (
        <div className={filterClasses.filterWrap}>
            <div className={'part'}>
                <AppBar position="static" classes={appBarClasses} className={filterClasses.appBar}>
                    <div>
                        <Tabs
                            value={currentFilterTabIndex}
                            onChange={handleFilterTabChange}
                        >
                            <Tab
                                disabled className={filterClasses.tab} label={<Typography>{filterPerLabel}</Typography>}
                            />
                            <Tab
                                className={filterClasses.tab}
                                label={<Typography>{categoryLabel} <ArrowDropDownIcon/></Typography>}
                            />
                            <Tab
                                className={filterClasses.tab}
                                label={<Typography>{statusLabel} <ArrowDropDownIcon/></Typography>}
                            />
                        </Tabs>
                    </div>
                </AppBar>
            </div>

            <div className={'part'}>
                <ul className="buttonsWrap">
                    <li onClick={() => !isMyCampaignsListInPending && applyFilter()}>
                        {
                            isMyCampaignsListInPending ?
                                (
                                    <CircularProgress
                                        className={'icon'}
                                    />
                                ) :
                                (
                                    <SearchIcon
                                        className={'icon'}
                                    />
                                )
                        }
                    </li>

                    <li onClick={() => setFilterModeCallback(false)}>
                        <CloseIcon
                            className={'icon'}
                        />
                    </li>
                </ul>
            </div>
        </div>
    )
})

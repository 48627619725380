import {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {useFormik} from 'formik'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {useFormStyles} from 'deziro-web-app-components/components/login/styles/form.styles'
import {Theme} from '@material-ui/core'
import clsx from 'clsx'
import FormProvider from 'deziro-web-app-components/components/login/form/components/formik/FormProvider'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import FormikTextField from 'deziro-web-app-components/components/login/form/components/formik/FormikTextField'
import {EMonth} from 'dst-react-core/core.types'
import FormikSelect from 'deziro-web-app-components/components/login/form/components/formik/FormikSelect'
import FormikChipSwitch from 'deziro-web-app-components/components/login/form/components/formik/FormikChipSwitch'
import {EPersonGender} from 'dst-react-core/person/person.types'
import GenericCheckbox from 'deziro-web-app-components/shared/utils/GenericCheckbox'
import GenericLink from 'deziro-web-app-components/shared/utils/GenericLink'
import {useDispatch} from 'react-redux'
import {enum2Array, numberDigitFormat} from 'dst-react-core/utils/shared.utils'

export interface ICampaignRegistrationFormProps {
    verificationSchemas?: any,
    campaign?: any,
    isRegistrationInPending?: boolean,
    registerUser?: any
}

const CampaignRegistrationForm: FC<ICampaignRegistrationFormProps> = (props) => {
    const {
        verificationSchemas,
        campaign,
        isRegistrationInPending,
        registerUser,
    } = props

    const {
        vSchema,
        vString,
        vEmail,
        vMonth,
        vYear,
        vMonthDay,
        vEnum,
        vBoolean,
        vTrue,
    } = verificationSchemas

    const {t} = useTranslation()

    const dispatch = useDispatch()

    const formClasses = useFormStyles()

    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

    const formik = useFormik({
        initialValues: {
            name: '',
            surname: '',
            email: '',
            password: '',
            day: new Date().getDate() + '',
            month: new Date().getMonth() + '',
            year: new Date().getFullYear() + '',
            code: '',
            gender: 'MALE',
            marketingConsent: true,
            termsAndConditionsConsent: false,
        },
        validationSchema: vSchema ? vSchema({
            name: vString.required(),
            surname: vString.required(),
            email: vEmail.required(),
            password: vString.required(),
            month: vMonth.required(),
            year: vYear.required(),
            day: vMonthDay('month', 'year').required(),
            code: vString.required(),
            gender: vEnum(EPersonGender),
            marketingConsent: vBoolean,
            termsAndConditionsConsent: vTrue,
        }) : undefined,
        onSubmit: ({code, email, password, day, month, year, ...rest}) => {
            const payload = {
                ...rest,
                birthDate: `${year}-${numberDigitFormat(1 + +month, 2)}-${numberDigitFormat(+day, 2)}`,
                principal: {email, password},
            }
            campaign && dispatch(registerUser(payload, campaign.id, code))
        },
        validateOnMount: true,
    })

    return (
        <>
            <FormProvider className={clsx(formClasses.form, 'registerForm')}>
                <Typography>{t('auth.sign_up.what_name')}</Typography>
                <Box className={clsx(formClasses.inputRow, 'adjusted')}>
                    <FormikTextField
                        formik={formik}
                        fieldName='name'
                        label={t('string.name')}
                        className={formClasses.input}
                    />
                    <FormikTextField
                        formik={formik}
                        fieldName='surname'
                        label={t('string.last_name')}
                        className={formClasses.input}
                    />
                </Box>
                <Typography>{t('auth.sign_up.what_email')}</Typography>
                <FormikTextField
                    formik={formik}
                    fieldName='email'
                    label={t('auth.sign_up.insert_email')}
                    className={formClasses.input}
                />
                <Typography>{t('auth.sign_up.create_pass')}</Typography>
                <FormikTextField
                    formik={formik}
                    fieldName='password'
                    label={t('auth.sign_up.insert_pass')}
                    className={formClasses.input}
                    type='password'
                />
                <Typography>{t('auth.sign_up.when_born')}</Typography>
                <Box className={formClasses.inputRow}>
                    <FormikTextField
                        formik={formik}
                        fieldName='day'
                        label={t(isMobile ? 'date.day_short' : 'date.day')}
                        className={formClasses.input}
                        type={'number'}
                    />
                    <FormikSelect
                        options={enum2Array(EMonth).map(([value, key]) => ({
                            value,
                            label: key,
                        }))}
                        formik={formik}
                        fieldName='month'
                        className={formClasses.input}
                        label={t(isMobile ? 'date.month_short' : 'date.month')}
                    />
                    <FormikTextField
                        formik={formik}
                        fieldName='year'
                        label={t(isMobile ? 'date.year_short' : 'date.year')}
                        className={formClasses.input}
                    />
                </Box>
                <Typography>{t('auth.code.associate')}</Typography>
                <FormikTextField
                    formik={formik}
                    fieldName='code'
                    label={t('auth.code.insert')}
                    className={formClasses.input}
                />
                <Typography>{t('string.sex')}</Typography>
                <FormikChipSwitch
                    formik={formik}
                    fieldName='gender'
                    options={Object.entries(EPersonGender).map(([value, key]) => ({
                        value,
                        render: t(`gender.${key.toLowerCase()}`),
                    }))}
                    className={formClasses.input}
                />
            </FormProvider>

            <Box>
                <GenericCheckbox
                    name={'marketingConsent'}
                    checked={formik.values.marketingConsent}
                    onChange={formik.handleChange}
                    label={t('auth.sign_up.send_marketing')}
                />
                <GenericCheckbox
                    name={'termsAndConditionsConsent'}
                    checked={formik.values.termsAndConditionsConsent}
                    onChange={formik.handleChange}
                    label={
                        <>
                            {t('terms.terms')}&nbsp;
                            <GenericLink>{t('terms.link.terms')}</GenericLink>&nbsp;
                            {t('terms.and')}&nbsp;
                            <GenericLink>{t('terms.link.policy')}</GenericLink>&nbsp;
                        </>
                    }
                />
            </Box>

            <Button
                className={'big'}
                variant='contained'
                onClick={() => formik.handleSubmit()}
                disabled={!formik.values.termsAndConditionsConsent || isRegistrationInPending}
            >
                {t('auth.login.log_in')}
            </Button>
        </>
    )
}

export default CampaignRegistrationForm

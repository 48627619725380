import * as React from 'react'
import {useTranslation} from 'react-i18next'
import {EGiftType} from 'dst-react-core/campaign/campaign.types'
import clsx from 'clsx'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import {RatingStars} from '../components/RatingStars'
import {ICampaignPageSectionContent} from 'dst-react-core/campaign/campaign.types'
import {IReview} from 'dst-react-core/review/review.types'
import {ISectionProps} from '../../../models/wish-page-core.types'
import {IValuationDialogProps} from '../../../models/valuatuion-dialog.types'
import { TGiftOverlayClasses } from 'deziro-web-app-components/shared/styles/wish-page.styles'

interface ICampaignGiftSectionProps extends ISectionProps {
    campaignSectionClasses: any,
    giftSectionClasses: any,
    content: ICampaignPageSectionContent | null,
    valuateCampaign: (value: number) => void,
    giftType: EGiftType | null,
    review: IReview | null | undefined,
    openValuationDialog: (props?: (IValuationDialogProps | undefined)) => void,
    partnerLogo: string | null,
    starsCount: number,
    handleValuate: () => void,
    giftView: JSX.Element,
    giftOverlayClasses?: TGiftOverlayClasses,
}

export const CampaignGiftSection = React.forwardRef<HTMLDivElement | null, ICampaignGiftSectionProps>((props, ref) => {
    const {
        campaignSectionClasses,
        giftSectionClasses,
        content,
        giftView,
        review,
        partnerLogo,
        starsCount,
        handleValuate,
        preview,
        giftOverlayClasses
    } = props


    const {t} = useTranslation()

    return (
        <div className={clsx(campaignSectionClasses.root, giftSectionClasses.root)} ref={ref}>
            <Box className={clsx(
                campaignSectionClasses.content,
                campaignSectionClasses.giftSection,
                {[giftOverlayClasses?.overlayWrapper!]: preview}
            )}>
                <div className={giftOverlayClasses?.overlay} />
                <Box className="info">
                    <Avatar src={partnerLogo ?? ''}/>
                    <Box>
                        <Typography className="title" variant={'h4'}>
                            {content?.titleText}
                        </Typography>
                        <Typography className="subtitle" variant={'subtitle1'}>
                            {content?.subtitleText}
                        </Typography>
                    </Box>
                </Box>
                {giftView}
                <Box className="review">
                    <Typography variant={'h6'} className="title reviewTitle">
                        {content?.reviewTitleText}
                    </Typography>
                    <Box className="rating">
                        <RatingStars rating={starsCount}/>
                        <Typography className="subtitle" variant={'subtitle1'}>
                            {t('section.gift.reviews')}
                        </Typography>
                    </Box>
                    <Typography variant={'subtitle1'} className="reviewText">
                        {content?.reviewBodyText}
                    </Typography>
                    <Button
                        variant={'contained'}
                        className="button"
                        disabled={!!review}
                        onClick={handleValuate}
                    >{t('section.gift.evaluate_now')}</Button>
                </Box>
            </Box>
        </div>
    )
})

import {
    CAMPAIGN_ACCESS_POST_API,
    CAMPAIGN_BY_URL_GET_API,
    CAMPAIGN_HAS_ACCESSED_GET_API,
    CAMPAIGN_PAGE_DATA_GET_API,
} from './campaign.api.constants'
import {combineEpics} from 'redux-observable'
import {mergeMapFetchEpics} from 'dst-react-core/fetch/fetch.call'
import {
    campaignAccessCodeSuccessEpic, campaignFetchFailureEpic,
    campaignUserRegistrationSuccessEpic,
    confirmCampaignRegistrationSuccessEpic,
} from './campaign.epics'
import {ECampaignPageName} from 'dst-react-core/campaign/campaign.types'
import {IAction} from 'dst-react-core/core.types'
import {fetchActionGenerator} from 'dst-react-core/fetch/fetch.utils'

export const [
    campaignHasAccessAction,
    campaignHasAccessActionBuilder,
    campaignHasAccessActionEpic,
    isCampaignHasAccessActionInPending
] = fetchActionGenerator('CAMPAIGN_HAS_ACCESS', CAMPAIGN_HAS_ACCESSED_GET_API)

export const [
    campaignRequestAccessAction,
    campaignRequestAccessActionBuilder,
    campaignRequestAccessActionEpic,
    isCampaignRequestAccessActionInPending,
] = fetchActionGenerator('CAMPAIGN_ACCESS', CAMPAIGN_ACCESS_POST_API)

export const [
    campaignPageDataAction,
    campaignPageDataActionBuilder,
    campaignPageDataActionEpic,
] = fetchActionGenerator('CAMPAIGN_PAGE_DATA', CAMPAIGN_PAGE_DATA_GET_API, mergeMapFetchEpics)

export const [
    campaignFetchAction,
    campaignFetchBuilder,
    campaignFetchEpic,
] = fetchActionGenerator('CAMPAIGN_FETCH', CAMPAIGN_BY_URL_GET_API)

export const CLEAR_CAMPAIGN_PAGE_DATA_TYPE = 'CLEAR_CAMPAIGN_PAGE_DATA_TYPE'
export const clearCampaignPageData = (page: ECampaignPageName): IAction => ({
    type: CLEAR_CAMPAIGN_PAGE_DATA_TYPE,
    payload: page,
})

export const CLEAR_CAMPAIGN_WISH_PAGE = 'CLEAR_CAMPAIGN_WISH_PAGE'
export const clearCampaignWishPage = {
    type: CLEAR_CAMPAIGN_WISH_PAGE,
}

export const CLEAR_CAMPAIGN_DATA_TYPE = 'CLEAR_CAMPAIGN_DATA_TYPE'
export const clearCampaignData = (): IAction => ({
    type: CLEAR_CAMPAIGN_DATA_TYPE,
})

const campaignsActions = combineEpics(
    campaignAccessCodeSuccessEpic,
    campaignUserRegistrationSuccessEpic,
    confirmCampaignRegistrationSuccessEpic,
    campaignHasAccessActionEpic,
    campaignRequestAccessActionEpic,
    campaignPageDataActionEpic,
    campaignFetchEpic,
    campaignFetchFailureEpic,
)

export default campaignsActions
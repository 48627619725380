import {useState} from 'react'
import {useCampaignReview} from '../../../../campaign.hooks'
import {ISectionProps} from 'deziro-web-app-components/models/wish-page-core.types'
import {EGiftType, ICampaignPageSectionContent} from 'dst-react-core/campaign/campaign.types'
import {IReview} from 'dst-react-core/review/review.types'
import {IValuationDialogProps} from 'deziro-web-app-components/models/valuatuion-dialog.types'
import {CampaignGiftSection} from 'deziro-web-app-components/components/sections/gift-section/CampaignGiftSection'
import * as React from 'react'

interface IContainerCampaignGiftSectionProps extends ISectionProps {
    campaignSectionClasses: any,
    giftSectionClasses: any,
    content: ICampaignPageSectionContent | null,
    valuateCampaign: (value: number) => void,
    giftType: EGiftType | null,
    review: IReview | null | undefined,
    openValuationDialog: (props?: (IValuationDialogProps | undefined)) => void,
    partnerLogo: string | null,
    giftView: JSX.Element
}

export const ContainerCampaignGiftSection = React.forwardRef<HTMLDivElement | null, IContainerCampaignGiftSectionProps>((props, ref) => {
    const {
        openValuationDialog,
        valuateCampaign,
        giftView
    } = props

    const [starsCount, setStarsCount] = useState(0)

    useCampaignReview((summary) => {
        setStarsCount(summary.medianRating)
    })

    const handleValuate = () => {
        openValuationDialog({
            onValuate: valuateCampaign,
        })
    }

    return (
        <CampaignGiftSection
            {...props}
            starsCount={starsCount}
            handleValuate={handleValuate}
            ref={ref}
            giftView={giftView}
        />
    )
})
import {FC, memo} from 'react'
import {useCustomRatingStyles} from './custom-rating.styles'
import Rating from '@material-ui/lab/Rating'
import Typography from '@material-ui/core/Typography'

interface ICustomRatingProps {
    rating: number
}

export const CustomRating: FC<ICustomRatingProps> = memo(props => {
    const {
        rating
    } = props

    const classes = useCustomRatingStyles()

    return (
        <Typography className={classes.reviewWrap}>
            <Rating
                value={rating}
                readOnly
                precision={0.5}
                className={classes.rating}
                size={'small'}
            />
            <span className={classes.title}>{rating}</span>
        </Typography>
    )
})
import {fetchActionFactory, simpleActionGenerator} from 'dst-react-core/fetch/fetch.action.factory'
import {isActionInPending} from 'dst-react-core/spinner/redux/spinner.reducers'
import {IActionFactoryReturn} from 'dst-react-core/fetch/fetch.types'
import {
    LOGIN_USER_API, RESET_PASSWORD_CONFIRM_POST,
    RESET_PASSWORD_REQUEST_POST,
    USER_REGISTER_API,
    USER_REGISTRATION_CONFIRM_API,
} from './auth.api.constants'
import {combineEpics} from 'redux-observable'
import {
    userAuthorizedSuccessEpic,
    userAutoLogInSuccessEpic,
    userAutoLogInFailureEpic,
    userLogOutEpic, resetPasswordConfirmSuccessEpic,
} from './auth.epics'
import {switchMapFetchEpics} from 'dst-react-core/fetch/fetch.call'
import { triggerSnackbarEpicCreator } from 'dst-react-core/fetch/fetch.epic.creators'

export const AUTH_LOGIN_ACTION_TYPE = 'LOGIN_ACTION_TYPE'
export const AUTH_LOGIN_ACTION_REQUEST_ID = 'LOGIN_ACTION_REQUEST_ID'
export const authAuthorizeUserAction: IActionFactoryReturn = fetchActionFactory(LOGIN_USER_API, AUTH_LOGIN_ACTION_TYPE)
export const isAuthLoginActionInPending = isActionInPending(authAuthorizeUserAction.pendingActionTypeWithSpinner, AUTH_LOGIN_ACTION_REQUEST_ID)


export const AUTH_REGISTRATION_ACTION_TYPE = 'AUTH_REGISTRATION_ACTION_TYPE'
export const AUTH_REGISTRATION_ACTION_REQUEST_ID = 'AUTH_REGISTRATION_ACTION_REQUEST_ID'
export const authRegistrationAction: IActionFactoryReturn = fetchActionFactory(USER_REGISTER_API, AUTH_REGISTRATION_ACTION_TYPE)
export const isRegistrationActionInPending = isActionInPending(authRegistrationAction.pendingActionTypeWithSpinner, AUTH_REGISTRATION_ACTION_REQUEST_ID)
export const registrationSuccessEpic = triggerSnackbarEpicCreator(authRegistrationAction.successActionType, 'auth.sign_up.success')


export const AUTH_CONFIRM_REGISTRATION_ACTION_TYPE = 'AUTH_CONFIRM_REGISTRATION_ACTION_TYPE'
export const AUTH_CONFIRM_REGISTRATION_ACTION_REQUEST_ID = 'AUTH_CONFIRM_REGISTRATION_ACTION_REQUEST_ID'
export const authConfirmRegistrationAction: IActionFactoryReturn = fetchActionFactory(USER_REGISTRATION_CONFIRM_API, AUTH_CONFIRM_REGISTRATION_ACTION_TYPE)
export const isConfirmRegistrationActionInPending = isActionInPending(authConfirmRegistrationAction.pendingActionTypeWithSpinner, AUTH_CONFIRM_REGISTRATION_ACTION_REQUEST_ID)


export const AUTH_RESET_PASSWORD_REQUEST_ACTION_TYPE = 'AUTH_RESET_PASSWORD_REQUEST_ACTION_TYPE'
export const AUTH_RESET_PASSWORD_REQUEST_ACTION_REQUEST_ID = 'AUTH_RESET_PASSWORD_REQUEST_ACTION_REQUEST_ID'
export const authResetPasswordRequestAction: IActionFactoryReturn = fetchActionFactory(RESET_PASSWORD_REQUEST_POST, AUTH_RESET_PASSWORD_REQUEST_ACTION_TYPE)
export const isAuthResetPasswordRequestActionInPending = isActionInPending(authResetPasswordRequestAction.pendingActionTypeWithSpinner, AUTH_RESET_PASSWORD_REQUEST_ACTION_REQUEST_ID)
export const authResetPasswordRequestSuccessSnack = triggerSnackbarEpicCreator(authResetPasswordRequestAction.successActionType, 'auth.reset_password.email_sent')


export const AUTH_RESET_PASSWORD_CONFIRM_ACTION_TYPE = 'AUTH_RESET_PASSWORD_CONFIRM_ACTION_TYPE'
export const AUTH_RESET_PASSWORD_CONFIRM_ACTION_REQUEST_ID = 'AUTH_RESET_PASSWORD_CONFIRM_ACTION_REQUEST_ID'
export const authResetPasswordConfirmAction: IActionFactoryReturn = fetchActionFactory(RESET_PASSWORD_CONFIRM_POST, AUTH_RESET_PASSWORD_CONFIRM_ACTION_TYPE)
export const isAuthResetPasswordConfirmActionInPending = isActionInPending(authResetPasswordConfirmAction.pendingActionTypeWithSpinner, AUTH_RESET_PASSWORD_CONFIRM_ACTION_REQUEST_ID)
export const authResetPasswordConfirmSuccessSnack = triggerSnackbarEpicCreator(authResetPasswordConfirmAction.successActionType, 'auth.reset_password.success')


export const AUTH_USER_LOG_OUT_ACTION_TYPE = 'LOG_OUT_ACTION_TYPE'
export const authUserLogOutAction = {
    type: AUTH_USER_LOG_OUT_ACTION_TYPE,
}

export const AUTH_USER_NO_AUTH_TOKEN = 'AUTH_USER_NO_AUTH_TOKEN' 
export const noAuthTokenAction = simpleActionGenerator(AUTH_USER_NO_AUTH_TOKEN)

const authActions = combineEpics(
    userAuthorizedSuccessEpic,
    userAutoLogInSuccessEpic,
    userLogOutEpic,
    userAutoLogInFailureEpic,
    resetPasswordConfirmSuccessEpic,
    registrationSuccessEpic,
    authResetPasswordRequestSuccessSnack,
    authResetPasswordConfirmSuccessSnack,
    switchMapFetchEpics(authAuthorizeUserAction.pendingActionTypeWithSpinner),
    switchMapFetchEpics(authRegistrationAction.pendingActionTypeWithSpinner),
    switchMapFetchEpics(authConfirmRegistrationAction.pendingActionTypeWithSpinner),
    switchMapFetchEpics(authResetPasswordRequestAction.pendingActionTypeWithSpinner),
    switchMapFetchEpics(authResetPasswordConfirmAction.pendingActionTypeWithSpinner),
)

export default authActions
import makeStyles from '@material-ui/core/styles/makeStyles'
import {EXTRA_COLORS_PALETTE} from '../../../../../../material.theme'
import {flex, font, important, resetUl} from '../../../../../../dst-react-core/utils/styles.utils'

export const useGeneralHeaderFilterStyles = makeStyles((theme) => ({
    tab: {
        height: '100%',
        borderRight: `2px solid ${EXTRA_COLORS_PALETTE.GENERAL_BORDER.DEFAULT_WITH_OPACITY}`,
        fontWeight: 500,
        fontFamily: font('DMSans'),
        fontSize: 14,
        color: 'rgba(255, 255, 255, 0.5)',
        letterSpacing: '0.1px',
        textTransform: 'initial',
    },
    appBar: {
        '& .MuiTabs-root, & .MuiTab-root, & .MuiTabs-flexContainer': {
            height: '100%',
        },
        '& .MuiTabs-indicator': {
            backgroundColor: important('transparent'),
        },
        '& .Mui-selected': {
            background: EXTRA_COLORS_PALETTE.BACKGROUND.DEFAULT,
            color: '#fff',
        },
    },
    filterWrap: {
        width: '100%',
        height: '100%',
        ...flex(),
        '& .part': {
            '& ul.buttonsWrap': {
                ...flex(),
                ...resetUl(),
                height: '100%',
                '& li': {
                    ...flex(true),
                    height: '100%',
                    padding: '0 30px',
                    cursor: 'pointer',
                    transition: 'all ease 0.4s',
                    '&:first-child': {
                        borderRight: `1px solid ${EXTRA_COLORS_PALETTE.GENERAL_BORDER.DEFAULT_WITH_OPACITY}`,
                    },
                    '&:hover': {
                        background: theme.palette.background.default,
                    },
                    '& .icon': {
                        width: 30
                    }
                },
            },
        },
    },
}))
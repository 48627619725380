import * as React from 'react'
import {memo, useRef} from 'react'
import {useTranslation} from 'react-i18next'
import {triggerSuccess} from 'dst-react-core/components/toast/GenericSnackbar'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import {analyticClickDecorator} from 'dst-react-core/google-analytics/gmt.init'

interface ICodeCopyFieldProps {
    code: string
}

const useCopyCodeFieldClasses = makeStyles(theme => ({
    root: {
        display: 'grid',
        gridTemplateColumns: 'auto 154px',
        gridTemplateRows: '1fr 1fr',
        gridTemplateAreas:
            '". ."' +
            '"fullWidth fullWidth"',
        '& .instruction': {
            gridArea: 'fullWidth',

            color: theme.palette.grey[300],
            fontWeight: 500,
            lineHeight: '16px',
            fontSize: 12,
        },
        gap: theme.spacing(2),
        marginBottom: theme.spacing(4),
        '& .MuiTextField-root': {
            width: '100%',
            '& .MuiInputBase-root': {
                height: 48,
            },
        },
        '& .MuiButton-root': {
            height: 48,
            width: '100%',
        },
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: '1fr',
            gridTemplateRows: 'repeat(3, 1fr)',
            gridTemplateAreas:
                '"."' +
                '"fullWidth"' +
                '"."',
            '& .buttonWrapper': {
                margin: 'auto',
                '& .MuiButton-root': {
                    width: 154,
                },
            },
            '& .instruction': {
                textAlign: 'center',
            },
        },
    },
}))

export const CodeCopyField: React.FunctionComponent<ICodeCopyFieldProps> = memo(({code}) => {

    const classes = useCopyCodeFieldClasses()

    const {t} = useTranslation()

    const inputRef = useRef<HTMLInputElement>(null)

    const handleFocus = () => {
        if (inputRef.current) {
            const input = inputRef.current
            input.select()
        }
    }

    const handleCopy = () => {
        if (inputRef.current) {
            const input = inputRef.current
            input.select()
            document.execCommand('copy')
            input.blur()
            triggerSuccess(t('section.gift.copy.activation_key_success'))
        }
    }

    return (
        <Box className={classes.root}>
            <TextField
                variant="outlined"
                size="small"
                inputRef={inputRef}
                value={code}
                onClick={handleFocus} // not onFocus to avoid removing selection on second click
            />
            <Box className="buttonWrapper">
                <Button variant="contained" onClick={analyticClickDecorator('copy-voucher-click', handleCopy)}>
                    {t('section.gift.copy.copy')}
                </Button>
            </Box>
            <Typography className="instruction">{t('section.gift.copy.instruction')}</Typography>
        </Box>
    )
})

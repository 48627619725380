import {FC, memo} from 'react'
import {IContainerProfileChangePasswordProps} from './ContainerProfileChangePassword'
import {ContainerChangePasswordForm} from './password-form/ContainerChangePasswordForm'
import Typography from '@material-ui/core/Typography'

export interface IProfileChangePasswordProps extends IContainerProfileChangePasswordProps {
    profileChangePasswordClasses: any,
    profileClasses: any,
    modifyPasswordLabel: string,
    modifyPasswordTipLabel: string
}

export const ProfileChangePassword: FC<IProfileChangePasswordProps> = memo(props => {
    const {
        profileChangePasswordClasses,
        profileClasses,
        modifyPasswordLabel,
        modifyPasswordTipLabel
    } = props

    return (
        <div className={profileChangePasswordClasses.changePasswordWrap}>
            <div className="titleWrap">
                <div className={profileClasses.titleWrap}>
                    <Typography className={'title'}>
                        {modifyPasswordLabel}
                    </Typography>

                    <Typography className={'subtitle'}>
                        {modifyPasswordTipLabel}
                    </Typography>
                </div>
            </div>

            <div className="formWrap">
                <ContainerChangePasswordForm/>
            </div>
        </div>
    )
})
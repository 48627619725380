import * as Yup from 'yup'
import dayjs from 'dayjs'
import {API_DATE_FORMAT} from '../../dst-react-core/core.constants'
import {numberDigitFormat} from '../../dst-react-core/utils/shared.utils'
import {I18nT} from '../../dst-react-core/core.types'
import {WEBAPP_VALIDATION_CONSTANTS} from '../shared.constants'
import {yupPrimitiveFieldSchemeBuilder} from '../../dst-react-core/formik/yup-primitive-field-schema-builder'
import {YupSchema} from '../../dst-react-core/formik/schema-validation.hook'
import {isNumber} from '../../dst-react-core/utils/js/number.util'
import {CHANGE_PASSWORD_FORM_FIELDS} from '../../web-app/general/dashboard/profile/components/change-password/password-form/change-password-form.contants'

// Prefix v is shortcut of 'validation'
// todo: update with i18n error text
export const vSchema = Yup.object
export const vString = Yup.string()
export const vNumber = Yup.number()
export const vMonthDay = (month: string, year: string) => vNumber
    .test({
        name: 'is-valid-month-day',
        message: 'It is not valid month day',
        test: function (value) {
            const format = API_DATE_FORMAT
            // we should increase month because in js it starts with 0 and in real life with 1
            const date = `${this.parent[year]}-${numberDigitFormat((this.parent[month] ?? 0) + 1, 2)}-${numberDigitFormat(value ?? 0, 2)}`
            return dayjs(date, format).format(format) === date
        },
    })
export const vMonth = vNumber.min(0).max(11)
export const vYear = vNumber.min(1900).max(2021)
export const vSameAs = (field: string) => vString.oneOf([Yup.ref(field), null], 'Values should match')
export const vEmail = vString.email()
export const vEnum = (value: object) => vString.oneOf(Object.keys(value))
export const vBoolean = Yup.boolean()
export const vTrue = vBoolean.oneOf([true], 'The terms and conditions must be accepted.')

export const getYupRequiredString = (t: I18nT, requiredI18nKey?: string, unlimited = false): YupSchema => {
    return yupPrimitiveFieldSchemeBuilder(t, {
        isRequired: true,
        isString: true,
        min: WEBAPP_VALIDATION_CONSTANTS.minStringLength,
        max: unlimited ? WEBAPP_VALIDATION_CONSTANTS.unlimitedLength : WEBAPP_VALIDATION_CONSTANTS.maxStringLength,
        requiredI18nKey,
    })
}

export const getYupRequiredDayNumber = (t: I18nT): YupSchema => {
    return Yup.number()
        .min(WEBAPP_VALIDATION_CONSTANTS.minDayNumber, t('validation.min_value'))
        .test((value, context) => {
            const maxDayNumberInCurrentMonth = new Date(context.parent.year, context.parent.month + 1, 0).getDate()

            return (isNumber(value) && ((value as number) <= maxDayNumberInCurrentMonth)) || context.createError({
                message: t('validation.max_value'),
            })
        })
}

export const getYupRequiredYear = (t: I18nT, requiredI18nKey?: string): YupSchema => {
    return yupPrimitiveFieldSchemeBuilder(t, {
        isRequired: true,
        isString: false,
        min: WEBAPP_VALIDATION_CONSTANTS.minYear,
        max: new Date().getFullYear(),
        requiredI18nKey,
    })
}

export const getYupRequiredMonth = (t: I18nT, requiredI18nKey?: string): YupSchema => {
    return yupPrimitiveFieldSchemeBuilder(t, {
        isRequired: true,
        isString: false,
        min: WEBAPP_VALIDATION_CONSTANTS.minMonth,
        max: WEBAPP_VALIDATION_CONSTANTS.maxMonth,
        requiredI18nKey,
    })
}

export const getYupRequiredEmail = (t: I18nT): YupSchema => {
    return Yup.string()
        .min(WEBAPP_VALIDATION_CONSTANTS.minStringLength, t('validation.min_value'))
        .max(WEBAPP_VALIDATION_CONSTANTS.maxStringLength, t('validation.min_value'))
        .email()
}

export const getYupRequiredConfirmPassword = (t: I18nT): YupSchema => {
    return Yup.string()
        .min(WEBAPP_VALIDATION_CONSTANTS.minPasswordStringLength, t('validation.min_value'))
        .max(WEBAPP_VALIDATION_CONSTANTS.maxPasswordStringLength, t('validation.min_value'))
        .test((value, context) => {
            return value === context.parent[CHANGE_PASSWORD_FORM_FIELDS.newPassword] || context.createError({
                message: t('validation.check_password_warning'),
            })
        })
}



import React, {useCallback, useMemo} from 'react'
import PersonIcon from '@material-ui/icons/Person'
import HomeIcon from '@material-ui/icons/Home'
import LogOutIcon from '../../../../../../shared/components/muiIcons/LogOutIcon'
import {useTranslation} from 'react-i18next'
import {useLogout} from '../../../homepage/hooks/logout.hook'
import {useHistory} from 'react-router'
import {GENERAL_DASHBOARD_SUBROUTING} from '../../../general-dashboard-routes'

export interface IHomepageDropdownMenuConfig {
    label: string,
    action: () => void,
    icon: JSX.Element,
    className?: string
}


export const useHomepageMenuConfig = (): IHomepageDropdownMenuConfig[] => {
    const {t} = useTranslation()
    const history = useHistory()

    const logoutCallback = useLogout()

    const goToProfile = useCallback(
        () => {
            history.push(GENERAL_DASHBOARD_SUBROUTING.profile.path)
        },
        [history],
    )

    const goToHomepage = useCallback(
        () => {
            history.push(GENERAL_DASHBOARD_SUBROUTING.homepage.path)
        },
        [history],
    )

    return useMemo(() => [
        {
            label: t('general_dashboard.your_profile'),
            action: goToProfile,
            icon: <PersonIcon className={'icon'}/>,
        },
        {
            label: t('header.user.homepage'),
            action: goToHomepage,
            icon: <HomeIcon className={'icon'}/>,
        },
        {
            label: t('header.user.logout'),
            action: logoutCallback,
            icon: <LogOutIcon className={'icon'}/>,
            className: 'logoutMenuItem',
        },
    ], [t, goToProfile, goToHomepage, logoutCallback])
}
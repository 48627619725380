import makeStyles from '@material-ui/core/styles/makeStyles'
import {DEFAULT_PROFILE_SUB_PAGES_GAP} from './components/shared/profile-sub-header/sub-header.styles'
import {flex, important} from '../../../../dst-react-core/utils/styles.utils'

export const useProfileSubPageFormStyles = makeStyles(theme => ({
    formWrap: {
        margin: `${DEFAULT_PROFILE_SUB_PAGES_GAP}px 0`,
        '& .birthDateFieldsWrap': {
            ...flex(true, false, 30),
            [theme.breakpoints.down('sm')]: {
              gap: 10
            },
            '& > *': {
                '&:first-child, &:last-child': {
                    width: '45%',
                },
            },
        },
        '& .saveDataButton': {
            color: theme.palette.text.primary,
            marginTop: DEFAULT_PROFILE_SUB_PAGES_GAP,
            height: 50,
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        },
    },
    formField: {
        marginTop: 30,
        '&:first-child': {
            marginTop: 0,
        },
        '& .title': {
            paddingBottom: 20,
            fontSize: 16,
            color: theme.palette.text.primary,
        },
        '& .field': {
            width: '100%',
        },
    },
    genderChipList: {
        ...flex(true, false, 15, 'initial'),
        '& .MuiChip-root': {
            padding: '5px 10px'
        },
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'space-between',
        },
    },
    passwordField: {
        '& input[type=password]': {
            letterSpacing: important(0)
        }
    }
}))
import {FC} from 'react'
import {AccessedCampaigns} from './AccessedCampaigns'
import {useAccessedCampaignStyles} from './accessed-campaign.styles'
import {useTranslation} from 'react-i18next'
import {useScreen} from 'dst-react-core/core.hooks'

export interface IContainerAccessedCampaignsProps {

}

export const ContainerAccessedCampaigns: FC<IContainerAccessedCampaignsProps> = props => {
    const accessedCampaignClasses = useAccessedCampaignStyles()
    const {t} = useTranslation()

    const titleLabel = t('general_dashboard.accessed_campaigns.title')
    const welcomeLabel = t('string.welcome')
    const subTitleLabel = t('general_dashboard.accessed_campaigns.sub_title')

    const {isMobile} = useScreen()

    return (
        <AccessedCampaigns
            {...props}
            accessedCampaignClasses={accessedCampaignClasses}
            titleLabel={titleLabel}
            subTitleLabel={subTitleLabel}
            isMobile={isMobile}
            welcomeLabel={welcomeLabel}
        />
    )
}
import {ObjectSchema} from 'yup'
import {useFormik} from 'formik'

interface IDefaultFormikInitialValues {
    [key: string]: string
}

export function useDefaultFormik<T = IDefaultFormikInitialValues>(
    initialValues: T,
    submitHandler: (values: T) => void,
    validationSchema?:  ObjectSchema<any, any, any, any>
) {
    return useFormik<T>({
        initialValues,
        onSubmit: (values) => submitHandler(values),
        validationSchema: validationSchema,
        validateOnBlur: true,
        validateOnMount: true,
        validateOnChange: true,
        enableReinitialize: true
    })
}
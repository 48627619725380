import {Action} from 'redux'
import {TFunction} from 'react-i18next'

export interface IRootState {
    spinners: string[]
    currentDialog: any | null
}

export interface IRouteId {
    id: string
}

export interface IAction extends Action {
    payload?: any
    meta?: any
    key?: any
}

export enum EErrorType {
    WARN = 'WARN',
    ERROR = 'ERROR'
}

export enum EMonth {
    JANUARY,
    FEBRUARY,
    MARCH,
    APRIL,
    MAY,
    JUNE,
    JULY,
    AUGUST,
    SEPTEMBER,
    OCTOBER,
    NOVEMBER,
    DECEMBER,
}

export interface IRequestErrorBody {
    detail: string,
    error: string,
    message: string
}

export type I18nT = TFunction<'translation'>

export interface IOption {
    label: string
    value: string,
    disabled?: boolean
}

export enum ETranslation {
    EN_US = 'en-US',
    IT = 'it',
    EN = 'en'
}
import {useDispatch} from 'react-redux'
import {useCallback} from 'react'
import {downloadImageActionBuilder} from './image.utils'

export const useDownloadImage = (imageId: number = -1) => {
    const dispatch = useDispatch()

    return useCallback(
        (_imageId = imageId) => {
            dispatch(downloadImageActionBuilder(_imageId))
        },
        [dispatch, imageId]
    )
}
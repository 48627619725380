import {NEVER, Observable} from 'rxjs'
import {mergeMap} from 'rxjs/operators'
import {IAction} from 'dst-react-core/core.types'
import {ofType} from 'redux-observable'
import {
    AUTH_USER_LOG_OUT_ACTION_TYPE,
    authAuthorizeUserAction,
    authResetPasswordConfirmAction,
    authUserLogOutAction,
} from './auth.actions'
import {
    KEEP_CONNECTED_INTERVAL_HANDLE,
    LAST_ACTION_KEY,
    X_AUTH_TOKEN_LABEL_SAVED,
} from 'dst-react-core/core.constants'
import {authLoginUser} from '../auth.utils'
import {myPersonFetchAction} from 'dst-react-core/person/redux/person.actions'
import {checkLastAction} from 'shared/shared.utils'
import {
    ECLEXIA_ACCESS_TOKEN,
    ECLEXIA_ACCESS_TOKEN_EXPIRATION_DATE,
} from 'dst-react-core/eclexia/eclexia.constans'
import {BrowserRouterHistory} from 'dst-react-core/routing/route'
import {WEB_APP_PATH, WEB_APP_SUBROUTING} from '../../../web-app.routes'
import {authEclexiaUser} from 'dst-react-core/eclexia/eclexia.utils'
import {extractCampaignName, insertId} from 'dst-react-core/utils/shared.utils'

export const userAuthorizedSuccessEpic = (action$: Observable<IAction>) => {
    return action$.pipe(
        ofType(authAuthorizeUserAction.successActionType),
        mergeMap((action: IAction) => {
            localStorage.setItem(X_AUTH_TOKEN_LABEL_SAVED, action.meta.meta.responseHeaders.xAuthToken)
            return [authLoginUser()]
        }),
    )
}

export const userAutoLogInSuccessEpic = (action$: Observable<IAction>) => {
    return action$.pipe(
        ofType(myPersonFetchAction.successActionType),
        mergeMap(() => {
            if (localStorage.getItem(LAST_ACTION_KEY)) {
                const interval = setInterval(checkLastAction, 10 * 1000) // 10s
                localStorage.setItem(KEEP_CONNECTED_INTERVAL_HANDLE, interval + '')
            }
            return [authEclexiaUser()]
        }),
    )
}

export const userAutoLogInFailureEpic = (action$: Observable<IAction>) => {
    return action$.pipe(
        ofType(myPersonFetchAction.failureActionType),
        mergeMap(() => [authUserLogOutAction]),
    )
}

export const userLogOutEpic = (action$: Observable<IAction>) => {
    return action$.pipe(
        ofType(AUTH_USER_LOG_OUT_ACTION_TYPE),
        mergeMap(() => {

            // remove login data from localStorage
            localStorage.removeItem(X_AUTH_TOKEN_LABEL_SAVED)
            localStorage.removeItem(ECLEXIA_ACCESS_TOKEN)
            localStorage.removeItem(ECLEXIA_ACCESS_TOKEN_EXPIRATION_DATE)

            // remove auto logout interval
            const interval = localStorage.getItem(KEEP_CONNECTED_INTERVAL_HANDLE)
            interval && clearInterval(+interval)
            localStorage.removeItem(KEEP_CONNECTED_INTERVAL_HANDLE)
            localStorage.removeItem(LAST_ACTION_KEY)

            const id = extractCampaignName(BrowserRouterHistory.location.pathname)
            if (!id) BrowserRouterHistory.push(WEB_APP_PATH)
            else BrowserRouterHistory.push(insertId(WEB_APP_SUBROUTING.campaign.path, id))
            return NEVER
        }),
    )
}

export const resetPasswordConfirmSuccessEpic = (action$: Observable<IAction>) => {
    return action$.pipe(
        ofType(authResetPasswordConfirmAction.successActionType),
        mergeMap(() => {
            const id = extractCampaignName(BrowserRouterHistory.location.pathname)
            if (!id) BrowserRouterHistory.push(WEB_APP_PATH)
            else BrowserRouterHistory.push(insertId(WEB_APP_SUBROUTING.campaign.path, id))
            return NEVER
        }),
    )
}
import {IRouter} from '../../../../dst-react-core/routing/routes.types'
import {GENERAL_AUTH_PASSWORD_RESET_PATH} from '../general-auth.routes'
import {EMAIL_PATH, PASSWORD_PATH} from '../../../web-app.routes'

export const GENERAL_AUTH_PASSWORD_EMAIL_PATH = `${GENERAL_AUTH_PASSWORD_RESET_PATH}${EMAIL_PATH}`
export const GENERAL_AUTH_PASSWORD_PASSWORD_PATH = `${GENERAL_AUTH_PASSWORD_RESET_PATH}${PASSWORD_PATH}`

export const GENERAL_AUTH_REST_PASSWORD_ROUTE = Object.freeze<IRouter>({
    path: GENERAL_AUTH_PASSWORD_RESET_PATH,
    description: 'Web app general auth reset password route',
    subrouting: {
        email: {
            path: GENERAL_AUTH_PASSWORD_EMAIL_PATH,
            description: 'Web app general auth reset reset-password email route',
        },
        password: {
            path: GENERAL_AUTH_PASSWORD_PASSWORD_PATH,
            description: 'Web app general auth reset-password password route',
        },
    },
})

export const GENERAL_AUTH_RESET_PASSWORD_SUBROUTING = GENERAL_AUTH_REST_PASSWORD_ROUTE.subrouting
import {IRouter} from '../dst-react-core/routing/routes.types'

export const WEB_APP_PATH = '/'
export const GENERAL_PATH = `${WEB_APP_PATH}general`
export const CAMPAIGN_PATH = `${WEB_APP_PATH}:id`
export const AUTH_PATH = '/auth'
export const LOGIN_PATH = '/login'
export const REGISTRATION_PATH = '/registration'
export const CODE_ASSIGNMENT_PATH = '/code-assignment'
export const RESET_PASSWORD_PATH = '/reset-password'
export const EMAIL_PATH = '/email'
export const PASSWORD_PATH = '/password'
export const CHANGE_EMAIL_CONFIRM_PATH = '/change-email-confirm'

export const WEB_APP_ROUTE = Object.freeze<IRouter>({
    path: WEB_APP_PATH,
    description: 'Web app main route',
    subrouting: {
        campaign: {
            path: CAMPAIGN_PATH,
            description: 'Web app general route',
        },
        general: {
            path: GENERAL_PATH,
            description: 'Web app campaign route',
        },
    },
})

export const WEB_APP_SUBROUTING = WEB_APP_ROUTE.subrouting
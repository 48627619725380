import * as React from 'react'
import {useAuthFooterStyles} from './auth-footer.styles'
import {useTranslation} from 'react-i18next'

const AuthFooter: React.FunctionComponent = () => {

    const classes = useAuthFooterStyles()

    const {t} = useTranslation()

    return (
        <footer className={classes.root}>
            {t('copyright')}
        </footer>
    )
}

export default AuthFooter

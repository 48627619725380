import {FC, useCallback} from 'react'
import {GeneralHeaderFilterStatusTab} from './GeneralHeaderFilterStatusTab'
import {useStatusTabStyles} from './status-tab.styles'
import {useTranslation} from 'react-i18next'
import {SelectableChip} from '../../../../../../../../shared/components/common/selectable-chip/SelectableChip'
import {ECampaignStatus} from '../../../../../../../../dst-react-core/campaign/campaign.types'

export interface IContainerGeneralHeaderFilterStatusTabProps {
    filterStatus: ECampaignStatus | '',
    setFilterStatusCallback: (status: ECampaignStatus | '') => void,
}

export const ContainerGeneralHeaderFilterStatusTab: FC<IContainerGeneralHeaderFilterStatusTabProps> = props => {
    const {
        filterStatus,
        setFilterStatusCallback,
    } = props


    const statusTabClasses = useStatusTabStyles()
    const {t} = useTranslation()

    const onGoingLabel = t('general_dashboard.filter.statuses.on_going')
    const terminateLabel = t('general_dashboard.filter.statuses.terminate')

    const getStatusChip = useCallback(
        (value: ECampaignStatus | '', label: string) => {
            const isSelected = filterStatus === value

            return (
                <SelectableChip
                    label={label}
                    selected={isSelected}
                    handleClick={() => setFilterStatusCallback(isSelected ? '' : value)}
                />
            )
        },
        [filterStatus, setFilterStatusCallback],
    )

    return (
        <GeneralHeaderFilterStatusTab
            {...props}
            statusTabClasses={statusTabClasses}
            onGoingLabel={onGoingLabel}
            terminateLabel={terminateLabel}
            getStatusChip={getStatusChip}
        />
    )
}
import makeStyles from '@material-ui/core/styles/makeStyles'
import {flex, resetUl} from 'dst-react-core/utils/styles.utils'
import {EXTRA_COLORS_PALETTE} from 'material.theme'
import {DEFAULT_PROFILE_SUB_PAGES_GAP} from '../shared/profile-sub-header/sub-header.styles'
import {CSSDependsOnBrowserFlexGapSupporting} from 'dst-react-core/utils/css/supports-flex-gap.util'

export const useProfileAccountOverviewStyles = makeStyles((theme) => ({
    overviewWrap: {
        ...flex(),
        flexDirection: 'column',
        '& .overviewContent': {
            marginTop: DEFAULT_PROFILE_SUB_PAGES_GAP,
            '& .registrationDateWrap': {
                margin: '60px 0',
                '& .registrationDate': {
                    ...flex(true, false, 0, 'initial'),
                    ...CSSDependsOnBrowserFlexGapSupporting(
                        {
                            gap: 20,
                        },
                    ),
                    '& .calendarIcon': {
                        color: theme.palette.primary.main,
                        ...CSSDependsOnBrowserFlexGapSupporting(
                            {},
                            {
                                marginRight: 20,
                            },
                        ),
                    },
                },
            },
            '& .extraInfo': {
                marginTop: DEFAULT_PROFILE_SUB_PAGES_GAP,
                '& .extraInfoList': {
                    ...resetUl(),
                    '& li': {
                        ...flex(true, false, 0, 'initial'),
                        padding: '15px 0',
                        borderBottom: `1px solid ${EXTRA_COLORS_PALETTE.GENERAL_BORDER.DEFAULT_WITH_OPACITY}`,
                        ...CSSDependsOnBrowserFlexGapSupporting(
                            {
                                gap: 20,
                            },
                        ),
                        '& .label, & .value': {
                            fontWeight: 400,
                            fontSize: 14,
                            lineHeight: '20px',
                        },
                        '& .value': {
                            color: theme.palette.text.secondary,
                            ...CSSDependsOnBrowserFlexGapSupporting(
                                {},
                                {
                                    marginLeft: 20
                                }
                            ),
                        },
                    },
                },
            },
        },
        '& .overviewActions': {
            '& .buttonsWrap': {
                margin: `${DEFAULT_PROFILE_SUB_PAGES_GAP}px 0`,
                '& .seeProfileButton': {
                    color: theme.palette.text.primary,
                    padding: '0 30px',
                    [theme.breakpoints.down('sm')]: {
                        width: '100%',
                    },

                },
            },
            '& .marketingConsentWrap': {
                '& .applyMarketingConsentBlock': {
                    margin: `${DEFAULT_PROFILE_SUB_PAGES_GAP}px 0`,
                    '& .applyMarketingConsent': {
                        ...flex(true, false, 10, 'initial'),
                        '& .text': {
                            color: theme.palette.text.secondary,
                            fontSize: 14,
                            letterSpacing: '0.25px',
                            lineHeight: '20px',
                        },
                        '& .checkbox': {
                            alignSelf: 'flex-start',
                        },
                    },
                },
            },
        },
    },
}))
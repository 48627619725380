import {HOMEPAGE_INITIAL_STATE} from '../homepage.constants'
import {IAction} from 'dst-react-core/core.types'
import {HomepageState, IMyCampaign} from '../homepage.types'
import {myCampaignsAction} from './homepage.actions'
import {fixNumber} from 'dst-react-core/utils/numbers-utils/fix-number.util'

export const homepageReducer = (state: HomepageState = HOMEPAGE_INITIAL_STATE, action: IAction): HomepageState => {
    switch (action.type) {
        case myCampaignsAction.successActionType: {
            return {
                ...state,
                myCampaigns: (action.payload || []).map((c: IMyCampaign) => {
                    return ({
                        ...c,
                        reviewRating: fixNumber(c.reviewRating),
                    })
                }),
            }
        }
        default:
            return state
    }
}
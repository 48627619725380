import {FormikErrors, FormikTouched} from 'formik/dist/types'
import GenericSelect, { IGenericSelectProps } from 'deziro-web-app-components/shared/utils/GenericSelect'

interface IFormikSelectProps extends IGenericSelectProps {
    formik: {
        handleChange: {
            (e: React.ChangeEvent<any>): void
            <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void
        };
        handleBlur: {
            (e: React.FocusEvent<any>): void
            <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void
        },
        touched: FormikTouched<any>
        values: {
            [field: string]: any;
        }
        errors: FormikErrors<any>
    }
    fieldName: string
}

const FormikSelect: React.FunctionComponent<IFormikSelectProps> = props => {

    const {
        formik,
        fieldName,
        ...rest
    } = props

    return (
        <GenericSelect
            name={fieldName}
            value={formik.values[fieldName]}
            onBlur={formik.handleBlur}
            helperText={formik.touched[fieldName] && formik.errors[fieldName]}
            onChange={formik.handleChange}
            {...rest}
        />
    )
}

export default FormikSelect

import {FC, useCallback, useEffect, useRef, useState} from 'react'
import {useGeneralHeaderSearchBarStyles} from './searchbar.styles'
import {GeneralHeaderSearchBar} from './GeneralHeaderSearchBar'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {isMyCampaignsActionInPending} from '../../../homepage/redux/homepage.actions'
import {useScreen} from 'dst-react-core/core.hooks'

export interface IContainerGeneralHeaderSearchBarProps {
    searchValue: string
    setSearchValueCallback: (value: string) => void,
    applyFilter: (keyword?: string) => void,
    textFieldClassName?: string,
    isSideMenu: boolean,
    placeholder?: string
}

export const ContainerGeneralHeaderSearchBar: FC<IContainerGeneralHeaderSearchBarProps> = props => {
    const {applyFilter} = props
    const {t} = useTranslation()
    const searchBarClasses = useGeneralHeaderSearchBarStyles()
    const [isChipShown, setChipShown] = useState(false)
    const [chipLabel, setChipLabel] = useState('')
    const isMyCampaignsListInPending = useSelector(isMyCampaignsActionInPending)
    const {isMobile} = useScreen()
    const searchbarInputRef = useRef<HTMLInputElement | null>(null)

    const setChipShownCallback = useCallback(
        (isChipShown: boolean) => {
            setChipShown(isChipShown)
        },
        [setChipShown],
    )

    const setChipLabelCallback = useCallback(
        (label: string) => {
            setChipLabel(label)
        },
        [setChipLabel],
    )

    useEffect(
        () => {
            if (chipLabel) {
                applyFilter(chipLabel)
            }
        },
        [chipLabel, applyFilter],
    )

    useEffect(
        () => {
            /** Input focus on chip delete  */
            const input = searchbarInputRef.current?.querySelector('input')

            if (!isChipShown) {
                input?.focus()

                if (isMobile) {
                    input?.blur()
                }
            }
        },
        [searchbarInputRef, isChipShown, isMobile],
    )

    const searchbarPlaceholderLabel = t('general_dashboard.searchbar_placeholder')

    return (
        <GeneralHeaderSearchBar
            {...props}
            searchBarClasses={searchBarClasses}
            isChipShown={isChipShown}
            setChipShownCallback={setChipShownCallback}
            chipLabel={chipLabel}
            setChipLabelCallback={setChipLabelCallback}
            searchbarPlaceholderLabel={searchbarPlaceholderLabel}
            searchbarInputRef={searchbarInputRef}
            isMyCampaignsListInPending={isMyCampaignsListInPending}
        />
    )
}
export const X_AUTH_TOKEN_LABEL_SAVED = 'X-Auth-Token_Winner'
export const KEEP_CONNECTED_INTERVAL_HANDLE = 'KeepConnectedIntervalHandle'
export const LAST_ACTION_KEY = 'LastActionKey'

// API
export const CONTENT_TYPE_KEY = 'Content-Type'
export const X_AUTH_TOKEN_KEY = 'X-Auth-Token'
export const AUTHORIZATION_KEY = 'Authorization'
export const ACCEPT_KEY = 'Accept'

// Headers values
export const XLSX_CONTENT_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
export const JSON_CONTENT_TYPE = 'application/json'

export const PLACEHOLDER_PIC_URL = 'https://alphafa.com/wp-content/uploads/2018/09/placeholder-square.jpg'

export const LOGIN_TIMEOUT_TIME_SHORT_MS = 3600000 // 1 Hour
export const LOGIN_TIMEOUT_TIME_LONG_MS = 86400000 // 1 Day

// Should be used with 'dayjs.format(...)'
export const DATE_FORMAT = 'DD.MM.YY'
export const DATE_FORMAT_EXPANDED = 'DD.MM.YYYY'
export const API_DATE_FORMAT = 'YYYY-MM-DD'
export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm'
export const DATE_TIME_FORMAT_WITH_SECONDS = 'YYYY-MM-DD HH:mm:ss'

// Regexps 
export const AL_NUM_WITH_PUNCTUATION_REGEXP = /^[\w\sА-Яа-я.,/#!$%^&*;:{}=\-`~()+]*$/
export const AL_NUM_REGEXP = /^[A-Za-z0-9\sА-Яа-я][^_.,/#!$%^&*;:{}=\-`~()+]*$/
export const NO_WWW_REGEXP = /^(?!www$).*$/i
export const NUM_REGEXP = /^[1-9]*$/

export const INPUT_NUMBER_DISABLED_VALUES = ['-', '+', 'e', '.']

export const DESKTOP_DEFAULT_SCREEN = 1680
export const LAPTOP_DEFAULT_SCREEN = 1536
export const LAPTOP_SMALL_SCREEN = 1440

export enum EInputType {
    PASSWORD_KEY = 'password',
    TEXT_KEY = 'text',
    EMAIL_KEY = 'email',
    NUMBER_KEY = 'number'
}

export const DEZIRO_PROJECT_NAME = 'deziro'
export const SUB_DOMAIN = 'www.'
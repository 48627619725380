import {FC, memo} from 'react'
import {IContainerProfileAccountOverviewProps, IUserExtraDataItem} from './ContainerProfileAccountOverview'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import {AccountOverviewUserExtraData} from './pure-components/AccountOverviewUserExtraDataList'
import {AccessOverviewMarketingConsentControl} from './pure-components/AccessOverviewMarketingConsentControl'

export interface IProfileAccountOverviewProps extends IContainerProfileAccountOverviewProps {
    overviewClasses: any,
    accountOverviewLabel: string,
    registrationDateString: string,
    accountSummaryLabel: string,
    userExtraData: IUserExtraDataItem[],
    seeProfile: string,
    goToEditProfile: () => void,
    marketingConsentLabel: string,
    marketingConsentTextLabel: string,
    setMarketingConsentCallback: (e: React.ChangeEvent<HTMLInputElement>) => void,
    isMarketingConsentAllowed: boolean,
    profileClasses: any
}

export const ProfileAccountOverview: FC<IProfileAccountOverviewProps> = memo(props => {
    const {
        overviewClasses,
        accountOverviewLabel,
        registrationDateString,
        accountSummaryLabel,
        userExtraData,
        seeProfile,
        goToEditProfile,
        marketingConsentLabel,
        marketingConsentTextLabel,
        setMarketingConsentCallback,
        isMarketingConsentAllowed,
        profileClasses
    } = props

    return (
        <div className={overviewClasses.overviewWrap}>
            <div className="overviewContent">
                <div className={profileClasses.titleWrap}>
                    <Typography className={'title'}>
                        {accountOverviewLabel}
                    </Typography>

                    <Typography className={'subtitle'}>
                        Lorem ipsum.
                    </Typography>
                </div>

                <div className={'registrationDateWrap'}>
                    <Typography className={'registrationDate'}>
                        <CalendarTodayIcon className={'calendarIcon'}/>
                        <span>{registrationDateString}</span>
                    </Typography>
                </div>

                <div className={profileClasses.titleWrap}>
                    <Typography className={'summaryTitle'}>
                        {
                            accountSummaryLabel
                        }
                    </Typography>
                </div>

                <div className={'extraInfo'}>
                    <AccountOverviewUserExtraData
                        userExtraData={userExtraData}
                    />
                </div>

            </div>

            <div className="overviewActions">
                <div className="buttonsWrap">
                    <Button
                        onClick={goToEditProfile}
                        variant={'outlined'}
                        size={'small'}
                        className={'seeProfileButton'}
                    >
                        {seeProfile}
                    </Button>
                </div>

                <div className="marketingConsentWrap">
                    <div className={profileClasses.titleWrap}>
                        <Typography className={'summaryTitle'}>
                            {
                                marketingConsentLabel
                            }
                        </Typography>
                    </div>

                    <div className="applyMarketingConsentBlock">
                        <AccessOverviewMarketingConsentControl
                            isMarketingConsentAllowed={isMarketingConsentAllowed}
                            setMarketingConsentCallback={setMarketingConsentCallback}
                            marketingConsentTextLabel={marketingConsentTextLabel}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
})
import {HttpMethods, IRequestModel} from '../../fetch/fetch.types'
import {SEARCH_ENDPOINT} from '../../core.api.constants'

export const REVIEWS_ENDPOINT = '/reviews'
export const SUMMARY_ENDPOINT = '/summary'
export const FIND_MY_REVIEW_BY_CAMPAIGN_ENDPOINT = '/findMyReviewByCampaign'
export const WRITE_ENDPOINT = '/write'


/**
 * @description Review => /reviews/summary?campaignId=62
 * @return { medianRating: number, amount: number }
 */
export const REVIEW_SUMMARY_GET_API: IRequestModel = {
    url: `${REVIEWS_ENDPOINT}${SUMMARY_ENDPOINT}`,
    method: HttpMethods.GET,
}

/**
 * @description Review => /reviews/search/findMyReviewByCampaign?campaignId=62
 * @return Review
 */
export const MY_REVIEW_BY_CAMPAIGN_GET_API: IRequestModel = {
    url: `${REVIEWS_ENDPOINT}${SEARCH_ENDPOINT}${FIND_MY_REVIEW_BY_CAMPAIGN_ENDPOINT}`,
    method: HttpMethods.GET,
}

/**
 * @description Review => /reviews/write?campaignId=62&rating=5
 * @return Review
 */
export const WRITE_REVIEW_POST_API: IRequestModel = {
    url: `${REVIEWS_ENDPOINT}${WRITE_ENDPOINT}`,
    method: HttpMethods.POST,
}

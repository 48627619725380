import {IAction} from '../../core.types'
import {IEclexiaFullVideo, IEclexiaVideosState} from '../eclexia.types'
import {eclexiaGetSpecifiedVideoAction} from './eclexia.actions'

export const eclexiaVideoReducer = (state: IEclexiaVideosState | null = null, action: IAction): IEclexiaVideosState | null => {
    switch (action.type) {
        case eclexiaGetSpecifiedVideoAction.successActionType: {
            const result = action.payload.Result as IEclexiaFullVideo
            return {
                ...state,
                [result.id]: result,
            }
        }
        default:
            return state
    }
}
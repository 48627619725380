import {useRef} from 'react'
import {useHistory} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {GENERAL_AUTH_PASSWORD_RESET_PATH} from '../../general-auth.routes'
import Button from '@material-ui/core/Button'
import {ILoginFormValues} from 'deziro-web-app-components/components/login/form/LoginForm'
import {useDispatch, useSelector} from 'react-redux'
import {authorizeUser} from 'web-app/components/auth/auth.utils'
import {isAuthLoginActionInPending} from 'web-app/components/auth/redux/auth.actions'
import {updateLastAction} from 'dst-react-core/utils/shared.utils'
import {isLoginAttemptFailedSelector} from 'web-app/components/auth/redux/auth.selectors'
import Typography from '@material-ui/core/Typography'
import {EToastColor} from 'dst-react-core/components/toast/toast.types'
import {LoginFormContainer} from 'deziro-web-app-components/components/login/form/LoginFormContainer'
import {vSchema, vEmail, vString} from 'shared/formik/validation.yup'

const GeneralLoginForm: React.FunctionComponent = () => {
    const {t} = useTranslation()

    const dispatch = useDispatch()

    const history = useHistory()

    const submitButtonRef = useRef<HTMLButtonElement | null>(null)

    const isLoginAttemptFailed = useSelector(isLoginAttemptFailedSelector)

    const isLoginInPending = useSelector(isAuthLoginActionInPending)

    const handleSubmit = (values: ILoginFormValues, staySignedIn: boolean) => {
        !staySignedIn && updateLastAction(true)
        dispatch(authorizeUser(values.email, values.password))
    }

    return (
        <>
            <LoginFormContainer
                onSubmit={handleSubmit}
                vSchema={vSchema}
                vEmail={vEmail}
                vString={vString}
                submitButtonRef={submitButtonRef}
                isGeneralLogin
            />
            <Button onClick={() => history.push(GENERAL_AUTH_PASSWORD_RESET_PATH)}>
                {t('auth.login.forgot_password')}
            </Button>

            {isLoginAttemptFailed && (
                <Typography style={{color: EToastColor.Error}}>{t('validation.invalid_credentials')}</Typography>
            )}

            <Button
                ref={submitButtonRef}
                className={'big'}
                variant='contained'
                disabled={isLoginInPending}
            >
                {t('auth.login.log_in')}
            </Button>
        </>
    )
}

export default GeneralLoginForm

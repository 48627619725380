import {IRouter} from 'dst-react-core/routing/routes.types'
import {GENERAL_AUTH_PATH} from '../general.routes'
import {CHANGE_EMAIL_CONFIRM_PATH, LOGIN_PATH, RESET_PASSWORD_PATH} from '../../web-app.routes'

export const GENERAL_AUTH_LOGIN_PATH = `${GENERAL_AUTH_PATH}${LOGIN_PATH}`
export const GENERAL_AUTH_PASSWORD_RESET_PATH = `${GENERAL_AUTH_PATH}${RESET_PASSWORD_PATH}`
export const GENERAL_AUTH_CHANGE_EMAIL_CONFIRM = `${GENERAL_AUTH_PATH}${CHANGE_EMAIL_CONFIRM_PATH}`

export const GENERAL_AUTH_ROUTE = Object.freeze<IRouter>({
    path: GENERAL_AUTH_PATH,
    description: 'Web app general auth route',
    subrouting: {
        login: {
            path: GENERAL_AUTH_LOGIN_PATH,
            description: 'Web app general auth login route',
        },
        reset_password: {
            path: GENERAL_AUTH_PASSWORD_RESET_PATH,
            description: 'Web app general auth reset reset-password route',
        },
        change_email_confirm: {
            path: GENERAL_AUTH_CHANGE_EMAIL_CONFIRM,
            description: 'Web app general auth change email confirm route'
        }
    },
})

export const GENERAL_AUTH_SUBROUTING = GENERAL_AUTH_ROUTE.subrouting
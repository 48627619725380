import {IVouchersState} from '../voucher.types'
import {IAction} from '../../core.types'
import {getVoucherByGiftIdAction} from './voucher.actions'

export const voucherReducer = (state: IVouchersState | null = null, action: IAction) => {
    switch (action.type) {
        case getVoucherByGiftIdAction.successActionType: {
            const id = action.meta.meta.queryParameter.giftId
            return {
                ...state,
                [id]: action.payload,
            }
        }
        default:
            return state
    }
}
import {FC, useCallback, useMemo, useState} from 'react'
import {ProfileAccountOverview} from './ProfileAccountOverview'
import {useProfileAccountOverviewStyles} from './account-overview.styles'
import {useSelector} from 'react-redux'
import {personSelector} from 'web-app/components/auth/redux/auth.selectors'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router'
import {GENERAL_PROFILE_SUBROUTING} from '../../profile-routes'
import {useUpdateUser} from '../../hooks/update-user.hook'
import {IPerson} from 'dst-react-core/person/person.types'
import {useProfileStyles} from '../../profile.styles'
import dayjs from 'dayjs'

export interface IContainerProfileAccountOverviewProps {

}

export interface IUserExtraDataItem {
    label: string,
    value: string
}

export const ContainerProfileAccountOverview: FC<IContainerProfileAccountOverviewProps> = props => {
    const overviewClasses = useProfileAccountOverviewStyles()
    const profileClasses = useProfileStyles()

    const person = useSelector(personSelector)
    const {t} = useTranslation()
    const history = useHistory()

    const [isMarketingConsentAllowed, setMarketingConsent] = useState(person?.marketingConsent)

    const {updatePerson} = useUpdateUser()

    const accountOverviewLabel = t('general_dashboard.account_overview')
    const accountSummaryLabel = t('general_dashboard.account_summary')
    const seeProfile = t('general_dashboard.see_profile')
    const marketingConsentLabel = t('general_dashboard.marketing_consent')
    const marketingConsentTextLabel = t('general_dashboard.marketing_consent_text')

    const registeredSince = t('general_dashboard.registered_since')
    const registrationDay = dayjs(person?.created).format('DD')
    const registrationMonth = t(`date.months.${dayjs(person?.created).format('MMMM').toLowerCase()}`)
    const registrationYear = dayjs(person?.created).format('YYYY')
    const accountCreationDate = `${registeredSince} ${registrationDay} ${registrationMonth} ${registrationYear}`

    const registrationDateString = person?.created ? accountCreationDate : '-'
    const userExtraData: IUserExtraDataItem[] = useMemo(
        () => [
            {
                label: t('string.email'),
                value: person?.principal?.email || '-',
            },
        ],
        [person, t],
    )
    const personId = useMemo(() => person?.id, [person])

    const goToEditProfile = useCallback(
        () => {
            history.push(GENERAL_PROFILE_SUBROUTING.profile.path)
        },
        [history],
    )

    const setMarketingConsentCallback = useCallback(
        ({target}: React.ChangeEvent<HTMLInputElement>) => {
            if (personId) {
                setMarketingConsent(target.checked)
                updatePerson(personId, { marketingConsent: target.checked } as IPerson)
            }
        }, [setMarketingConsent, personId, updatePerson],
    )

    return (
        <ProfileAccountOverview
            {...props}
            overviewClasses={overviewClasses}
            accountOverviewLabel={accountOverviewLabel}
            registrationDateString={registrationDateString}
            accountSummaryLabel={accountSummaryLabel}
            userExtraData={userExtraData}
            seeProfile={seeProfile}
            goToEditProfile={goToEditProfile}
            marketingConsentLabel={marketingConsentLabel}
            marketingConsentTextLabel={marketingConsentTextLabel}
            setMarketingConsentCallback={setMarketingConsentCallback}
            isMarketingConsentAllowed={!!isMarketingConsentAllowed}
            profileClasses={profileClasses}
        />
    )
}
import makeStyles from '@material-ui/core/styles/makeStyles'
import {EXTRA_COLORS_PALETTE, GENERAL_HEADER_FILTER_GRADIENT} from '../../../../../../material.theme'
import {flex, font, important} from '../../../../../../dst-react-core/utils/styles.utils'

export const useGeneralHeaderMenuStyles = makeStyles(() => ({
    mainMenu: {
        height: '100%',
        listStyle: 'none',
        margin: 0,
        padding: 0,
        ...flex(),
        '& li': {
            padding: '0 30px',
            borderLeft: `1px solid ${EXTRA_COLORS_PALETTE.GENERAL_BORDER.DEFAULT_WITH_OPACITY}`,
            ...flex(true, true),
            '&:first-child': {
                borderLeft: 0,
            },
            '& .userInfoSubMenuWrap': {
                ...flex(true, false, 5),
                '& .arrowDownIcon': {
                    color: '#fff',
                    fontSize: 15,
                    marginLeft: 5,
                },
                '& .dropDownTitle': {
                    fontWeight: 500,
                    color: EXTRA_COLORS_PALETTE.GREY.GENERAL_TEXT,
                    fontSize: 14,
                    lineHeight: '24px',
                    fontFamily: font('DM Sans'),
                },
            },
        },
        '& .menuButton': {
            cursor: 'pointer',
            transition: 'all ease 0.4s',
            ...flex(true, true),
            '&:hover': {
                background: 'rgba(255, 255, 255, 0.04)',
            },
            '& .icon': {
                color: EXTRA_COLORS_PALETTE.GREY.GENERAL_TEXT,
            },
        },
        '& .menuButtonActive': {
            background: important(GENERAL_HEADER_FILTER_GRADIENT),
        },
        '& .sideMenuButton': {
            background: important(EXTRA_COLORS_PALETTE.PRIMARY.MAIN),
            color: important('#fff'),
            minWidth: 200,
            display: 'none',
            '&:hover': {
                background: important(EXTRA_COLORS_PALETTE.PRIMARY.DARK),
            },
        },
    },
    dropDownMenu: {
        '& .MuiMenu-paper': {
            background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #030208',
            borderRadius: 16,
            color: '#fff',
            fontSize: 16,
            lineHeight: 24,
            fontFamily: font('DM Sans'),
            width: 200,
            '& .logoutMenuItem': {
                color: EXTRA_COLORS_PALETTE.ERROR.MAIN,
            },
            '& .menuItemText': {
                verticalAlign: 'middle',
                '& .icon': {
                    fontSize: 18,
                    marginRight: 5,
                    verticalAlign: 'sub',
                },
            },
        },
    },
}))
import './i18n'
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.scss'
import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
import MainContainer from './MainContainer'
import 'normalize.css'

/** Disable log & debug in production */
if (process.env.NODE_ENV !== 'development') {
    console.debug = () => {
    }
    console.log = () => {
    }
}

ReactDOM.render(
    (<MainContainer />),
    document.getElementById('root') as HTMLElement,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

import makeStyles from '@material-ui/core/styles/makeStyles'
import {flex} from '../../../../../../../dst-react-core/utils/styles.utils'
import {EXTRA_COLORS_PALETTE} from '../../../../../../../material.theme'

export const DEFAULT_PROFILE_SUB_PAGES_GAP = 50

export const useProfileSubHeaderStyles = makeStyles(theme => ({
    header: {
        borderBottom: `1px solid ${EXTRA_COLORS_PALETTE.GENERAL_BORDER.DEFAULT_WITH_OPACITY}`,
        paddingBottom: DEFAULT_PROFILE_SUB_PAGES_GAP,
        width: '100%',
        ...flex(true),
        [theme.breakpoints.down('sm')]: {
            borderBottom: 'none',
        },
        '& .user': {
            ...flex(true, false, 40),
            '& .avatar': {},
            '& .info': {
                '& .userName': {
                    fontWeight: 700,
                    fontSize: 36,
                    [theme.breakpoints.down('sm')]: {
                        fontSize: 24,
                    }
                },
                '& .extraInfo': {
                    fontWeight: 400,
                    fontSize: 16,
                    color: theme.palette.text.secondary,
                    [theme.breakpoints.down('sm')]: {
                        fontSize: 14,
                    }
                },
            },
            [theme.breakpoints.down('sm')]: {
                gap: 20
            }
        },
        '& .actions': {
            ...flex(),
            height: '100%',
            alignItems: 'flex-end',
            '& .logout': {
                color: theme.palette.error.dark,
            },
        },
    },
}))
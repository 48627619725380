import {Redirect, Route, Switch, useHistory, useLocation, useParams} from 'react-router-dom'
import {useCampaignTheme} from './campaign-auth.theme'
import {IRouteId} from 'dst-react-core/core.types'
import {CAMPAIGN_AUTH_SUBROUTING} from './campaign-auth.routes'
import {ThemeProvider} from '@material-ui/core/styles'
import CodeAssignment from './code-assignment/CodeAssignment'
import CampaignAuthResetPasswordRoute from './reset-password/CampaignAuthResetPasswordRoute'
import {insertId} from 'dst-react-core/utils/shared.utils'
import {CampaignLoginContainer} from './login/CampaignLoginContainer'
import {IAccessessLoadedProps} from '../CampaignRoute'
import {useEffect} from 'react'
import {X_AUTH_TOKEN_LABEL_SAVED} from 'dst-react-core/core.constants'
import {CAMPAIGN_SUBROUTING} from '../campaign.routes'
import {CHANGE_EMAIL_CONFIRM_PATH, REGISTRATION_PATH, RESET_PASSWORD_PATH} from '../../web-app.routes'
import {isBoolean} from 'dst-react-core/utils/js/boolean.util'

const CampaignAuthRoute: React.FunctionComponent<IAccessessLoadedProps> = (props) => {
    const {hasAccess, isLoggedIn} = props
    const {id} = useParams<IRouteId>()
    const history = useHistory()
    const location = useLocation()

    useEffect(() => {
        const token = localStorage.getItem(X_AUTH_TOKEN_LABEL_SAVED)

        if (isLoggedIn || token) {
            if (isBoolean(hasAccess)) {
                if (hasAccess) {
                    history.push(insertId(CAMPAIGN_SUBROUTING.wish_page.path, id))
                } else {
                    history.push(insertId(CAMPAIGN_AUTH_SUBROUTING.code_assignment.path, id))
                }
            }
        } else {
            if (!includesAuthPaths()) {
                history.push(insertId(CAMPAIGN_AUTH_SUBROUTING.login.path, id))
            }
        }

        function includesEndpoint(endpoint: string): boolean {
            return location.pathname.includes(endpoint)
        }

        function includesAuthPaths(): boolean {
            return [REGISTRATION_PATH, RESET_PASSWORD_PATH, CHANGE_EMAIL_CONFIRM_PATH].some(includesEndpoint)
        }

    }, [isLoggedIn, hasAccess, history, id, location.pathname])

    const theme = useCampaignTheme()

    if (isLoggedIn === null) return null

    return (
        <ThemeProvider theme={theme}>
            <Switch>

                {/* ROUTE TO CAMPAIGN AUTH LOGIN */}
                <Route path={CAMPAIGN_AUTH_SUBROUTING.login.path}>
                    <CampaignLoginContainer/>
                </Route>
                {/* ROUTE TO CAMPAIGN AUTH RESET PASSWORD */}
                <Route path={CAMPAIGN_AUTH_SUBROUTING.reset_password.path}>
                    <CampaignAuthResetPasswordRoute/>
                </Route>

                {/* ROUTE TO CAMPAIGN AUTH  ASSIGN CODE */}
                <Route path={CAMPAIGN_AUTH_SUBROUTING.code_assignment.path}>
                    <CodeAssignment/>
                </Route>

                {/* ROUTE TO CAMPAIGN AUTH REGISTRATION */}
                <Route path={CAMPAIGN_AUTH_SUBROUTING.registration.path}>
                    <CampaignLoginContainer registrationStep/>
                </Route>

                 {/*DEFAULT*/}
                <Route>
                    <Redirect to={insertId(CAMPAIGN_AUTH_SUBROUTING.login.path, id)}/>
                </Route>

            </Switch>
        </ThemeProvider>
    )
}

export default CampaignAuthRoute

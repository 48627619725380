import * as React from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {AUTH_PATH, GENERAL_PATH, WEB_APP_PATH} from 'web-app/web-app.routes'
import Box from '@material-ui/core/Box'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import FilterListIcon from '@material-ui/icons/FilterList'
import SearchIcon from '@material-ui/icons/Search'
import Button from '@material-ui/core/Button'
import defaultLogoUrl from 'assets/images/logo.png'
import {AppBarProps} from '@material-ui/core/AppBar/AppBar'
import {DASHBOARD_PATH} from 'web-app/general/general.routes'
import {useHeaderStyles} from 'deziro-web-app-components/shared/styles/header.styles'

const GenericHeader: React.FunctionComponent<AppBarProps> = props => {

    const classes = useHeaderStyles()

    const logoUrl = defaultLogoUrl

    const history = useHistory()

    const pathname = useLocation().pathname

    const isAuth = pathname.includes(AUTH_PATH)

    const isCampaignAuth = isAuth && !pathname.includes(GENERAL_PATH)

    const isDashboard = pathname.includes(DASHBOARD_PATH)

    return (
        // if campaign auth - bg color should be transparent with no shadow
        <AppBar
            color="default"
            position="relative"
            {...isCampaignAuth && {
                style: {
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                },
            }}
            {...props}
        >
            <Box className={classes.side}>
                {/* LOGO */}
                <Box
                    className={classes.logo}
                    style={{backgroundImage: `url(${logoUrl})`}}
                    onClick={() => history.push(WEB_APP_PATH)}
                />
            </Box>
            {isDashboard && <Toolbar>
                <IconButton><FilterListIcon /></IconButton>
                <IconButton><SearchIcon /></IconButton>
                <Button variant="contained" color="primary">CENTRO Assistenza</Button>
            </Toolbar>}
        </AppBar>
    )
}

export default GenericHeader

import { X_AUTH_TOKEN_LABEL_SAVED } from 'dst-react-core/core.constants'
import { useQuery } from 'dst-react-core/core.hooks'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { authIsLoggedInSelector } from 'web-app/components/auth/redux/auth.selectors'
import { authChangeEmailConfirm } from '../general-auth.utils'

export const ChangeEmailConfirm = () => {
    const query = useQuery()
    const history = useHistory()
    const authToken = localStorage.getItem(X_AUTH_TOKEN_LABEL_SAVED)
    const confirmToken = query.get('confirmToken')
    const dispatch = useDispatch()
    const isConfirmationSucceeded = useSelector(authIsLoggedInSelector)

    useEffect(() => {
        if (!authToken) {
            history.push('/general/auth/login')
        }

        if (confirmToken) {
            dispatch(authChangeEmailConfirm(confirmToken))
        }
    }, [dispatch, confirmToken, history, authToken])

    useEffect(() => {
        console.log(isConfirmationSucceeded)
        if (isConfirmationSucceeded) {
            // const timerId = setTimeout(() => history.push('/general/auth/login'), 10000)

            return () => {
                localStorage.removeItem(X_AUTH_TOKEN_LABEL_SAVED)
                // clearTimeout(timerId)
            }
        }
    }, [isConfirmationSucceeded, history])

    if (isConfirmationSucceeded) {
        return <h1>Email successfuly change. Redirecting to login page...</h1>
    }

    if (!confirmToken) {
        return <h1>Not found</h1>
    }

    return <div>hello world</div>
}

import {FC} from 'react'
import {useGeneralFooterStyles} from './footer.styles'
import {WishPageFooter} from 'deziro-web-app-components/components/footer/WishPageFooter'
import {useCampaignFooterStylesWrapper} from 'web-app/campaign/whish-page/wish-page.styles'

export const ContainerGeneralFooter: FC = () => {
    const footerClasses = useGeneralFooterStyles()
    const footerCampaignClasses = useCampaignFooterStylesWrapper()

    return (
        <WishPageFooter
            anchors={{} as any}
            mainWrapClassName={footerClasses.mainFooterWrap}
            infoWrapClassName={footerClasses.info}
            linksWrapClassName={footerClasses.links}
            networksClassName={footerClasses.networks}
            campaignClasses={footerCampaignClasses}
        />
    )
}
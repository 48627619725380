import makeStyles from '@material-ui/core/styles/makeStyles'
import {EXTRA_COLORS_PALETTE, GENERAL_HEADER_FILTER_GRADIENT} from 'material.theme'
import {flex} from 'dst-react-core/utils/styles.utils'

export const useGeneralHeaderStyles = makeStyles((theme) => ({
    headerWrap: {
        width: '100%',
        ...flex(),
        flexDirection: 'column',
        '& .filterTabs': {
            background: EXTRA_COLORS_PALETTE.BACKGROUND.DEFAULT,
            '& .accordion': {
                border: 'none',
                boxShadow: 'none',
                margin: 0,
                padding: 0,
                '& .summary': {
                    display: 'none',
                },
                '& .tabsWrap': {
                    width: '100%',
                    boxSizing: 'border-box',
                    color: '#fff',
                    padding: '20px 0 13px',
                    '& > *': {
                        paddingLeft: 165,
                    },
                },
            },
        },
    },
    header: {
        background: EXTRA_COLORS_PALETTE.BACKGROUND.DEFAULT,
        ...flex(true),
        height: 90,
        color: '#fff',
        '& .headerPart': {
            height: '100%',
            ...flex(true),
            '& .logoWrap': {
                padding: theme.spacing(3),
                flexBasis: '5%',
            },
            '& > .logoWrap > svg': {
                [theme.breakpoints.down('sm')]: {
                    maxWidth: 114
                },
                '& path:last-child': {
                    fill: 'linear-gradient(42.09deg, #36C7E0 31.89%, #7F8BFF 50.71%, #572AFF 70.27%)'
                }
            }
        },
        '& .filterPart': {
            background: GENERAL_HEADER_FILTER_GRADIENT,
            flexBasis: '85%',
        },
    },
}))
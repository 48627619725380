import {useMemo} from 'react'
import {EGiftType} from '../../../../../dst-react-core/campaign/campaign.types'
import {useTranslation} from 'react-i18next'
import {IOption} from '../../../../../dst-react-core/core.types'

export const useGiftTypeOptions = (): IOption[] => {
    const {t} = useTranslation()

    return useMemo(
        () => [
            {
                label: t('gift_type.single_video'),
                value: EGiftType.SINGLE_VIDEO,
            },
            {
                label: t('gift_type.multi_video'),
                value: EGiftType.MULTI_VIDEO,
            },
            {
                label: t('gift_type.single_podcast'),
                value: EGiftType.SINGLE_PODCAST,
            },
            {
                label: t('gift_type.multi_podcast'),
                value: EGiftType.MULTI_PODCAST,
            },
            {
                label: t('gift_type.livestream'),
                value: EGiftType.LIVESTREAM,
            },
            {
                label: t('gift_type.voucher'),
                value: EGiftType.VOUCHER,
            },
            {
                label: t('gift_type.file'),
                value: EGiftType.FILE,
            },
        ],
        [t],
    )
}
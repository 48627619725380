import makeStyles from '@material-ui/core/styles/makeStyles'

export const useAccessedCampaignStyles = makeStyles((theme) => ({
    campaignWrap: {
        width: '80%',
        height: '100%',
        margin: '0 auto',
        [theme.breakpoints.down('md')]: {
            width: '90%',
        },
        '& .titleWrap': {
            width: '100%',
            paddingTop: 160,
            [theme.breakpoints.down('md')]: {
                paddingTop: 100,
            },
            [theme.breakpoints.down('sm')]: {
                paddingTop: 50,
            },
            '& .title': {
                lineHeight: '56px',
                letterSpacing: -1.5,
                fontWeight: 700,
                fontSize: 90,
                color: '#fff',
                [theme.breakpoints.down('md')]: {
                    fontSize: 66,
                },
                [theme.breakpoints.down('sm')]: {
                    fontSize: 48,
                },
            },
            '& .subTitle': {
                fontWeight: 400,
                fontSize: 24,
                color: 'rgba(255, 255, 255, 0.5);',
                lineHeight: '96px'
            },
            '& .title, .subTitle': {
                display: 'block',
                textAlign: 'center'
            }
        }
    }
}))
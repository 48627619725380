import * as React from 'react'
import {GENERIC_DIALOG, TEST_DIALOG, VALUATION_DIALOG} from './dialogs.constants'
import {GenericDialog} from './dialogs/GenericDialog'
import {TestDialog} from './dialogs/TestDialog'
import ValuationDialog from './dialogs/valuation-dialog/ValuationDialog'

/**
 * @description
 * Insert here the Dialog Case
 */
export const dialogRenderFactory = (dialogType: string, meta?: any): any => {
    switch (dialogType) {
        case GENERIC_DIALOG:
            return ({
                component: <GenericDialog {...meta} />,
                customDialogProps: {disableEscapeKeyDown: false, disableBackdropClick: false},
            })
        case TEST_DIALOG:
            return ({
                component: <TestDialog {...meta} />,
                customDialogProps: {disableEscapeKeyDown: false, disableBackdropClick: false},
            })
        case VALUATION_DIALOG:
            return ({
                component: <ValuationDialog {...meta} />,
                customDialogProps: {disableEscapeKeyDown: false, disableBackdropClick: false},
            })
        default:
            return <React.Fragment />
    }
}

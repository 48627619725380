import {
    DEZIRO_FACEBOOK_LINK,
    DEZIRO_INSTAGRAM_LINK,
    DEZIRO_LINKEDIN_LINK,
    DEZIRO_YOUTUBE_LINK,
    DEZIRO_CAREERS_LINK,
    DEZIRO_FOR_COMPANIES_LINK,
    DEZIRO_FOR_CREATORS_LINK,
    DEZIRO_HISTORY_LINK,

} from 'deziro-web-app-components/shared/shared.constants'
import {ReactComponent as FacebookIcon} from 'deziro-web-app-components/assets/footerNetworks/facebook.svg'
import {ReactComponent as LinkedInIcon} from 'deziro-web-app-components/assets/footerNetworks/linkedin.svg'
import {ReactComponent as InstagramIcon} from 'deziro-web-app-components/assets/footerNetworks/instagram.svg'
import {ReactComponent as YouTubeIcon} from 'deziro-web-app-components/assets/footerNetworks/youtube.svg'

export const socialLinks = [
    {
        href: DEZIRO_FACEBOOK_LINK,
        icon: <FacebookIcon className={'networkIcon'}/>,
        title: 'Facebook',
    },
    {
        href: DEZIRO_LINKEDIN_LINK,
        icon: <LinkedInIcon className={'networkIcon'}/>,
        title: 'LinkedIn',
    },
    {
        href: DEZIRO_INSTAGRAM_LINK,
        icon: <InstagramIcon className={'networkIcon'}/>,
        title: 'Instagram',
    },
    {
        href: DEZIRO_YOUTUBE_LINK,
        icon: <YouTubeIcon className={'networkIcon'}/>,
        title: 'Youtube',
    },
]

export const deziroLinks = [
    {
        href: DEZIRO_HISTORY_LINK,
        title: 'footer.history.history',
    },
    {
        href: DEZIRO_CAREERS_LINK,
        title: 'footer.history.careers',
    },
    {
        href: DEZIRO_FOR_CREATORS_LINK,
        title: 'footer.history.for_creators',
    },
    {
        href: DEZIRO_FOR_COMPANIES_LINK,
        title: 'footer.history.for_companies',
    },
]
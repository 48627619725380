import {FC, memo} from 'react'
import Box from '@material-ui/core/Box'
import {WishPageHeader} from 'deziro-web-app-components/components/header/WishPageHeader'
import {GENERAL_DASHBOARD_SUBROUTING} from '../../general/dashboard/general-dashboard-routes'
import {WishPageFooter} from 'deziro-web-app-components/components/footer/WishPageFooter'
import {CampaignHeaderSection} from 'deziro-web-app-components/components/sections/CampaignHeaderSection'
import {ContainerCampaignGiftSection} from './components/sections/gift-section/ContainerCampaignGiftSection'
import {CampaignCrossSellingSection} from 'deziro-web-app-components/components/sections/CampaignCrossSellingSection'
import {CampaignFaqSection} from 'deziro-web-app-components/components/sections/CampaignFaqSection'
import {IPerson} from 'dst-react-core/person/person.types'
import {IReview} from 'dst-react-core/review/review.types'
import {IFetchAction} from 'dst-react-core/fetch/fetch.types'
import {EGiftType, ICampaignPageSectionContent} from 'dst-react-core/campaign/campaign.types'
import {IValuationDialogProps} from 'deziro-web-app-components/models/valuatuion-dialog.types'
import {ISectionAnchors} from 'deziro-web-app-components/models/wish-page-core.types'
import {ContainerBannerCell} from './components/sections/components/ContainerBannerCell'

interface IWishPageProps {
    wishPageClasses: any,
    campaignHeaderClasses: any,
    campaignFooterClasses: any,
    headerSectionClasses: any,
    giftSectionClasses: any,
    crossSellingSectionClasses: any,
    faqSectionClasses: any,
    campaignSectionClasses: any,
    person: IPerson | null,
    review: IReview | null | undefined,
    valuateCampaign: (value: number) => (0 | IFetchAction | null | false),
    handleLogOut: () => void,
    headerContent: ICampaignPageSectionContent | null,
    giftContent: ICampaignPageSectionContent | null,
    crossSellingContent: ICampaignPageSectionContent | null,
    faqContent: ICampaignPageSectionContent | null,
    giftType: EGiftType | null,
    openValuationDialog: (props?: (IValuationDialogProps | undefined)) => void,
    partnerLogo: string | null,
    giftView: JSX.Element,
    anchors: ISectionAnchors
}

export const WishPage: FC<IWishPageProps> = memo((props) => {
    const {
        wishPageClasses,
        campaignHeaderClasses,
        campaignFooterClasses,
        headerSectionClasses,
        giftSectionClasses,
        crossSellingSectionClasses,
        faqSectionClasses,
        campaignSectionClasses,
        person,
        review,
        valuateCampaign,
        handleLogOut,
        headerContent,
        giftContent,
        crossSellingContent,
        faqContent,
        giftType,
        openValuationDialog,
        partnerLogo,
        giftView,
        anchors,
    } = props

    return (
        <Box className={wishPageClasses.root}>
            <WishPageHeader
                anchors={anchors}
                campaignHeaderClasses={campaignHeaderClasses}
                person={person}
                review={review}
                valuateCampaign={valuateCampaign}
                handleLogout={handleLogOut}
                profilePath={GENERAL_DASHBOARD_SUBROUTING.profile.path}
                dashboardPath={GENERAL_DASHBOARD_SUBROUTING.homepage.path}
            />
            <Box className={wishPageClasses.page}>
                <CampaignHeaderSection
                    ref={anchors.home}
                    anchors={anchors}
                    content={headerContent}
                    campaignSectionClasses={campaignSectionClasses}
                    headerSectionClasses={headerSectionClasses}
                />
                <ContainerCampaignGiftSection
                    ref={anchors.wish}
                    anchors={anchors}
                    content={giftContent}
                    giftType={giftType}
                    openValuationDialog={openValuationDialog}
                    partnerLogo={partnerLogo}
                    campaignSectionClasses={campaignSectionClasses}
                    giftSectionClasses={giftSectionClasses}
                    review={review}
                    valuateCampaign={valuateCampaign}
                    giftView={giftView}
                />
                <CampaignCrossSellingSection
                    ref={anchors.ads}
                    anchors={anchors}
                    content={crossSellingContent}
                    campaignSectionClasses={campaignSectionClasses}
                    crossSellingSectionClasses={crossSellingSectionClasses}
                    ContainerBannerCell={ContainerBannerCell}
                />
                <CampaignFaqSection
                    ref={anchors.faq}
                    anchors={anchors}
                    content={faqContent}
                    faqSectionClasses={faqSectionClasses}
                    campaignSectionClasses={campaignSectionClasses}
                />
                <WishPageFooter anchors={anchors} campaignClasses={campaignFooterClasses}/>
            </Box>
        </Box>
    )
})

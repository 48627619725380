import * as React from 'react'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import {IEclexiaFullVideo} from 'dst-react-core/eclexia/eclexia.types'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AddRoundedIcon from '@material-ui/icons/AddRounded'
import Typography from '@material-ui/core/Typography'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Box from '@material-ui/core/Box'

dayjs.extend(duration)

interface IVideoCourseItemProps {
    item: IEclexiaFullVideo | null
    expanded: boolean
    onSelect: () => void
    onExpand?: (expanded: boolean) => void
    icon?: React.ReactNode
}

export const MultiplyGiftItem: React.FunctionComponent<IVideoCourseItemProps> = React.memo(props => {

    const {
        expanded,
        item,
        onSelect,
        onExpand,
        icon,
    } = props

    const handlerSelect: React.MouseEventHandler<HTMLElement> = (e) => {
        e.stopPropagation()
        onSelect()
    }

    const handleExpand = (event: React.ChangeEvent<{}>, expanded: boolean) => {
        if (onExpand) {
            onExpand(expanded)
        }
    }

    const duration = dayjs.duration((item?.duration ?? 0) * 1000).format('HH:mm:ss')

    return (
        <Box>
            <Accordion
                expanded={expanded}
                onChange={handleExpand}
                elevation={24}
                TransitionProps={{
                    unmountOnExit: true,
                }}
            >
                <AccordionSummary
                    expandIcon={<AddRoundedIcon />}
                >
                    <Box onClick={handlerSelect}>
                        {icon}
                        <Typography className="title" variant={'h6'}>{item?.title}</Typography>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography className="subtitle">{item?.description}</Typography>
                    <Typography variant={'caption'}>{duration}</Typography>
                </AccordionDetails>
            </Accordion>
        </Box>
    )
})

import {HttpMethods, IRequestModel} from '../../fetch/fetch.types'
import {SEARCH_ENDPOINT} from '../../core.api.constants'

export const VOUCHERS_ENDPOINT = '/vouchers'
export const FIND_REDEMEED_VOUCHER_ENDPOINT = '/findRedemeedVoucher'


/**
 * @description Voucher => /vouchers/search/findRedemeedVoucher?giftId=278
 * @return Voucher
 */
export const VOUCHER_GET_API: IRequestModel = {
    url: `${VOUCHERS_ENDPOINT}${SEARCH_ENDPOINT}${FIND_REDEMEED_VOUCHER_ENDPOINT}`,
    method: HttpMethods.GET
}
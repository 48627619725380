import {useSelector} from 'react-redux'
import {campaignMainPageSelector, campaignSectionSelector} from '../../../redux/current-campaign.selectors'
import {ECampaignPageSection} from 'dst-react-core/campaign/campaign.types'
import {useBackgroundType} from '../../../campaign.hooks'
import {
    useCrossSellingSectionStyles, useFaqSectionStyles,
    useGiftSectionStyles,
    useHeaderSectionStyles,
} from 'deziro-web-app-components/shared/styles/campaign-wish-page-section.styles'

export const useHeaderSectionStylesWrapper = () => {

    const sectionValues = useSelector(campaignSectionSelector(ECampaignPageSection.HEADER))

    const globalValues = useSelector(campaignMainPageSelector)?.global

    const bg = useBackgroundType(sectionValues?.style)

    const contentValues = sectionValues?.content
    const styleValues = sectionValues?.style

    return useHeaderSectionStyles({
        globalValues,
        bg,
        contentValues,
        styleValues,
    })

}

export const useGiftSectionStylesWrapper = () => {

    const sectionValues = useSelector(campaignSectionSelector(ECampaignPageSection.GIFT))

    const globalValues = useSelector(campaignMainPageSelector)?.global

    const bg = useBackgroundType(sectionValues?.style)

    const contentValues = sectionValues?.content
    const styleValues = sectionValues?.style

    return useGiftSectionStyles({
        globalValues,
        bg,
        contentValues,
        styleValues,
    })
}

export const useCrossSellingSectionStylesWrapper = () => {

    const sectionValues = useSelector(campaignSectionSelector(ECampaignPageSection.CROSS_SELLING))

    const globalValues = useSelector(campaignMainPageSelector)?.global

    const bg = useBackgroundType(sectionValues?.style)

    const contentValues = sectionValues?.content

    return useCrossSellingSectionStyles({
        globalValues,
        contentValues,
        bg,
    })
}

export const useFaqSectionStylesWrapper = () => {
    const sectionValues = useSelector(campaignSectionSelector(ECampaignPageSection.FAQ))

    const globalValues = useSelector(campaignMainPageSelector)?.global

    const bg = useBackgroundType(sectionValues?.style)

    const contentValues = sectionValues?.content
    const styleValues = sectionValues?.style

    return useFaqSectionStyles({
        globalValues,
        contentValues,
        styleValues,
        bg,
    })
}
import {FC, useCallback} from 'react'
import {GeneralHeaderFilterCategoryTab} from './GeneralHeaderFilterCategoryTab'
import {useGeneralHeaderFilterCategoryTabStyles} from './category-tab.styles'
import {useTranslation} from 'react-i18next'
import {EGiftType} from '../../../../../../../../dst-react-core/campaign/campaign.types'
import {SelectableChip} from '../../../../../../../../shared/components/common/selectable-chip/SelectableChip'
import {useGiftTypeOptions} from '../../../../../homepage/hooks/gift-type-options.hook'
import {IOption} from '../../../../../../../../dst-react-core/core.types'

export interface IContainerGeneralHeaderFilterCategoryTabProps {
    setFilterByNewestCallback: (isByNewest: boolean | undefined) => void,
    setFilterByGiftTypeCallback: (giftType: EGiftType | '') => void,
    isFilterByNewest: boolean | undefined,
    filterByGiftType: EGiftType | '',
}

export const ContainerGeneralHeaderFilterCategoryTab: FC<IContainerGeneralHeaderFilterCategoryTabProps> = props => {
    const categoryTabClasses = useGeneralHeaderFilterCategoryTabStyles()
    const {t} = useTranslation()

    const getCategoryChip = useCallback(
        function <T = any, Q = any>(value: T, label: string, setStateCallback: (value: T | Q) => void, state: T, defaultValue: Q) {
            const isSelected = state === value

            return (
                <SelectableChip
                    label={t(label)}
                    selected={isSelected}
                    handleClick={() => setStateCallback(isSelected ? defaultValue : value)}
                />
            )
        },
        [t],
    )

    const giftTypeChipConfig: IOption[] = useGiftTypeOptions()

    const sortByLabel = t('general_dashboard.filter.sort_by')
    const creationDateLabel = t('general_dashboard.filter.creation_date')

    return (
        <GeneralHeaderFilterCategoryTab
            {...props}
            categoryTabClasses={categoryTabClasses}
            getCategoryChip={getCategoryChip}
            sortByLabel={sortByLabel}
            creationDateLabel={creationDateLabel}
            giftTypeChipConfig={giftTypeChipConfig}
        />
    )
}
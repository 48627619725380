import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import PersonRoundedIcon from '@material-ui/icons/PersonRounded'
import * as React from 'react'
import HomeIcon from '@material-ui/icons/Home'
import {LogOutIcon} from 'deziro-web-app-components/shared/icons/LogoutIcon'
import makeStyles from '@material-ui/core/styles/makeStyles'
import {important} from 'dst-react-core/utils/styles.utils'
import clsx from 'clsx'

export interface IWishPageHeaderMenuItem {
    icon: JSX.Element,
    title: string,
    redirectPath: string,
    action?: () => void,
    className?: string
}

interface IUseWishPageHeaderDropdownMenuParams {
    youAccountPath: string,
    homepagePath: string,
    logoutAction: () => void,
}

const useWishPageHeaderDropdownMenuClasses = makeStyles(theme => ({
    logout: {
        color: theme.palette.error.main,
        '& .MuiSvgIcon-root': {
            color: important(theme.palette.error.main),
        },
    },
}))

export const useWishPageHeaderDropdownMenu = (params: IUseWishPageHeaderDropdownMenuParams): IWishPageHeaderMenuItem[] => {
    const {t} = useTranslation()

    const classes = useWishPageHeaderDropdownMenuClasses()

    return useMemo(
        () => [
            {
                title: t('header.user.your_account'),
                icon: <PersonRoundedIcon/>,
                redirectPath: params.youAccountPath,
            },
            {
                title: t('header.user.homepage'),
                icon: <HomeIcon/>,
                redirectPath: params.homepagePath,
            },
            {
                title: t('header.user.logout'),
                icon: <LogOutIcon/>,
                redirectPath: '',
                action: params.logoutAction,
                className: clsx('logout', classes.logout),
            },
        ],
        [t, params, classes],
    )
}
import * as React from 'react'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import AddRoundedIcon from '@material-ui/icons/AddRounded'
import {memo, useState} from 'react'
import Box from '@material-ui/core/Box'

interface IFaqCardProps {
    topic?: string
    previewText: string
    expandedText: React.ReactNode,
    link?: {
        title: string,
        href: string
    }
}

export const FaqCard: React.FunctionComponent<IFaqCardProps> = memo(props => {

    const [expanded, setExpanded] = useState(false)

    const {
        topic,
        previewText,
        expandedText,
        link,
    } = props

    return (
        <Box>
            <Accordion
                expanded={expanded}
                onChange={() => setExpanded(state => !state)}
                elevation={24}
                TransitionProps={{
                    unmountOnExit: true,
                }}
                className="questionCard"
            >
                <AccordionSummary
                    expandIcon={<AddRoundedIcon/>}
                >
                    {topic && <Typography className="topic" variant={'overline'}>{topic}</Typography>}
                    <Typography className="previewText" variant={'h6'}>
                        {previewText}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography className="expandedText">{expandedText} {
                        link &&
                        <a href={link.href} target={'_blank'} rel="noreferrer">{link.title}</a>
                    }</Typography>
                </AccordionDetails>
            </Accordion>
        </Box>
    )
})

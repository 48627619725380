export const DEZIRO_CONTACT_US_EMAIL = 'hello@deziro.me'
export const DEZIRO_ME = 'https://deziro.me/'
export const MAILTO_DEZIRO_CONTACT_US = `mailto:${DEZIRO_CONTACT_US_EMAIL}`

export const DEZIRO_INSTAGRAM_LINK = 'https://www.instagram.com/deziro_wish/'
export const DEZIRO_FACEBOOK_LINK = 'https://www.facebook.com/Deziro-105273495132101'
export const DEZIRO_LINKEDIN_LINK = 'https://www.linkedin.com/company/dezir-o/'
export const DEZIRO_YOUTUBE_LINK = 'https://www.youtube.com/channel/UC69_iJIvHlYamwPPuL33dFQ'

export const DEZIRO_HISTORY_LINK = 'https://deziro.me/chi-siamo/'
export const DEZIRO_FOR_CREATORS_LINK = 'https://deziro.me/creator/'
export const DEZIRO_FOR_COMPANIES_LINK = 'https://deziro.me/business/'
export const DEZIRO_CAREERS_LINK = 'https://deziro.me/wish/'

export const DEZIRO_PRIVACY_POLICY_LINK = 'https://deziro.me/privacy-policy/'
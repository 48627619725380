import * as React from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import List from '@material-ui/core/List'
import {useHistory} from 'react-router'
import {memo} from 'react'
import {IWishPageHeaderMenuItem} from '../../hooks/wish-page-header-dropdown-menu.hook'

interface IWishPageHeaderUserActionsProps {
    menuItems: IWishPageHeaderMenuItem[]
}

export const WishPageHeaderUserActions: React.FunctionComponent<IWishPageHeaderUserActionsProps> = memo(props => {
    const {
        menuItems,
    } = props

    const history = useHistory()

    return (
        <List>
            {
                menuItems.map(item => (
                    <ListItem
                        button
                        onClick={() => item?.action ? item.action() : (item.redirectPath && history.push(item.redirectPath))}
                        className={item?.className}
                    >
                        <ListItemText
                            primary={<>
                                {item.icon}
                                {item.title}
                            </>}
                        />
                    </ListItem>
                ))
            }
        </List>
    )
})

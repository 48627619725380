import * as React from 'react'
import {useFormik} from 'formik'
import {useTranslation} from 'react-i18next'
import {useFormStyles} from 'deziro-web-app-components/components/login/styles/form.styles'
import {vEmail, vSchema} from 'shared/formik/validation.yup'
import FormProvider from 'deziro-web-app-components/components/login/form/components/formik/FormProvider'
import Button from '@material-ui/core/Button'
import FormikTextField from 'deziro-web-app-components/components/login/form/components/formik/FormikTextField'
import {authResetPasswordRequest} from '../../../auth.utils'
import {useDispatch, useSelector} from 'react-redux'
import {isAuthResetPasswordRequestActionInPending} from '../../../redux/auth.actions'
import {currentCampaignSelector} from 'web-app/campaign/redux/current-campaign.selectors'
import { ContainerLoader } from 'dst-react-core/spinner/ContainerLoader'

const ResetPasswordEmailForm: React.FunctionComponent = () => {

    const {t} = useTranslation()

    const dispatch = useDispatch()

    const formClasses = useFormStyles()

    const isResetPasswordRequestInPending = useSelector(isAuthResetPasswordRequestActionInPending)

    const campaign = useSelector(currentCampaignSelector)

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: vSchema({
            email: vEmail.required(),
        }),
        onSubmit: (values) => {
            dispatch(authResetPasswordRequest(values.email, campaign?.id))
        },
        validateOnMount: true,
    })

    return (
        <>
            <FormProvider className={formClasses.form}>
                <FormikTextField
                    fieldName="email"
                    formik={formik}
                    className={formClasses.input}
                    label={t('string.email')}
                />
            </FormProvider>
            <Button
                className={'big'}
                variant="contained"
                onClick={() => formik.handleSubmit()}
                disabled={isResetPasswordRequestInPending}
                startIcon={isResetPasswordRequestInPending ? <ContainerLoader size={12} /> : null}
            >{t('string.send')}</Button>
        </>
    )
}

export default ResetPasswordEmailForm

import * as React from 'react'
import {useTranslation} from 'react-i18next'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import {DeziroIcon} from '../../shared/icons/DeziroIcon'
import {ISectionProps} from '../../models/wish-page-core.types'
import {
    DEZIRO_PRIVACY_POLICY_LINK, MAILTO_DEZIRO_CONTACT_US,
} from '../../shared/shared.constants'
import {deziroLinks, socialLinks} from 'deziro-web-app-components/components/footer/footerLinks'

interface IWishPageFooterProps extends ISectionProps {
    mainWrapClassName?: string,
    infoWrapClassName?: string,
    linksWrapClassName?: string,
    campaignClasses: any,
    networksClassName?: string

}

export const WishPageFooter: React.FunctionComponent<IWishPageFooterProps> = (props) => {
    const {
        mainWrapClassName,
        infoWrapClassName,
        linksWrapClassName,
        campaignClasses,
        networksClassName,
    } = props

    const {t} = useTranslation()

    const linkStyles = {
        textDecoration: 'none',
        color: 'inherit',
    }

    return (
        <Box className={clsx(campaignClasses.root, mainWrapClassName)}>
            <Box className={campaignClasses.content}>
                <Box className={clsx(campaignClasses.info, infoWrapClassName)}>
                    <Box className={clsx(campaignClasses.links, linksWrapClassName)}>
                        <Box className="contacts">
                            <Box className="text">
                                <b>{t('footer.contact.any_questions')}</b><br/>
                            </Box>
                            <a
                                href={MAILTO_DEZIRO_CONTACT_US}
                                target={'_blank'}
                                rel="noreferrer"
                                style={linkStyles}
                            >
                                <Button className='contactUsButton' variant="contained" color='primary'>
                                    {t('footer.contact.contact_us')}
                                </Button>
                            </a>
                        </Box>
                    </Box>

                    <Box className={campaignClasses.linksWrap}>
                        <Box className={clsx(campaignClasses.list, 'deziroLinks')}>
                            {deziroLinks.map((link) => (
                                <a
                                    href={link.href}
                                    target='_blank'
                                    rel='noreferrer'
                                    style={linkStyles}
                                    key={link.title}
                                >
                                    <Typography variant='body1' className='item'>
                                        {t(link.title)}
                                    </Typography>
                                </a>
                            ))}
                        </Box>
                        <Box className={clsx(campaignClasses.list, 'social')}>
                            {socialLinks.map((link) => (
                                <a
                                    href={link.href}
                                    target='_blank'
                                    rel='noreferrer'
                                    style={linkStyles}
                                    key={link.title}
                                >
                                    <Box className={networksClassName}>
                                        {link.icon}
                                        <Typography variant='body1' className={clsx('item')}>
                                            {link.title}
                                        </Typography>
                                    </Box>
                                </a>
                            ))}
                        </Box>
                    </Box>
                </Box>
                <Box className={campaignClasses.copyright}>
                    <Box>
                        <DeziroIcon/>
                    </Box>
                    <Box>
                        <Typography
                            variant={'body1'}
                        >{t('copyright')}</Typography>
                    </Box>
                    <Box className="terms">
                        <Typography
                            variant={'body1'}
                            className="item"
                        >{t('terms.terms_and_conditions')}</Typography>
                        <a
                            href={DEZIRO_PRIVACY_POLICY_LINK}
                            target='_blank'
                            rel='noreferrer'
                            style={linkStyles}
                        >
                            <Typography
                                variant={'body1'}
                                className="item"
                            >{t('terms.privacy_policy')}</Typography>
                        </a>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

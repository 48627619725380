import {FC, memo} from 'react'
import {IContainerChangePasswordFormProps} from './ContainerChangePasswordForm'
import {FormikContextType} from 'formik'
import {IChangePasswordFormValues} from './change-password-form.types'
import {CHANGE_PASSWORD_FORM_FIELDS} from './change-password-form.contants'
import Button from '@material-ui/core/Button'
import {ContainerProfileTextField} from '../../shared/form-controls/ContainerProfileTextField'

export interface IChangePasswordFormProps extends IContainerChangePasswordFormProps {
    profileSubPageFormClasses: any,
    formik: FormikContextType<IChangePasswordFormValues>,
    currentPasswordLabel: string
    newPasswordLabel: string
    confirmPasswordLabel: string
    setPasswordLabel: string,
}

export const ChangePasswordForm: FC<IChangePasswordFormProps> = memo(props => {
    const {
        profileSubPageFormClasses,
        formik,
        currentPasswordLabel,
        newPasswordLabel,
        confirmPasswordLabel,
        setPasswordLabel,
    } = props

    return (
        <form className={profileSubPageFormClasses.formWrap}>
            <ContainerProfileTextField
                formik={formik}
                title={currentPasswordLabel}
                fieldName={CHANGE_PASSWORD_FORM_FIELDS.oldPassword}
                isPassword
                withPlaceholder
            />

            <ContainerProfileTextField
                formik={formik}
                title={newPasswordLabel}
                fieldName={CHANGE_PASSWORD_FORM_FIELDS.newPassword}
                isPassword
                withPlaceholder
            />

            <ContainerProfileTextField
                formik={formik}
                title={confirmPasswordLabel}
                fieldName={CHANGE_PASSWORD_FORM_FIELDS.newPasswordCheck}
                isPassword
                withPlaceholder
            />

            <Button
                className={'saveDataButton'}
                variant={'contained'}
                onClick={() => formik.handleSubmit()}
            >
                {setPasswordLabel}
            </Button>
        </form>
    )
})
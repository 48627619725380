import {Epic, ofType, StateObservable} from 'redux-observable'
import {NEVER, Observable} from 'rxjs'
import {ajax} from 'rxjs/ajax'
import {map, mergeMap, switchMap} from 'rxjs/operators'
import i18n from 'i18next'
import {IAction, IRootState} from '../core.types'
import {triggerError, triggerSuccess} from '../components/toast/GenericSnackbar'
import {BrowserRouterHistory} from '../routing/route'
import {HttpMethods, IDownloadAction} from './fetch.types'
import {SERVER_BASE_URL} from './fetch.api'
import {CONTENT_TYPE_KEY, X_AUTH_TOKEN_KEY, XLSX_CONTENT_TYPE} from '../core.constants'
import {downloadFile} from '../utils/shared.utils'

/** Epic creator, which follows action, triggers snackbar and makes redirects */
export const triggerSnackbarEpicCreator = (
    actionType: string,
    i18nKey: string,
    isSuccess = true,
    redirectUrl?: string
): Epic => {
    return (action$: Observable<IAction>) => {
        return action$.pipe(
            ofType(actionType),
            switchMap(() => {
                isSuccess ? triggerSuccess(i18n.t(i18nKey)) : triggerError(i18n.t(i18nKey))
                redirectUrl && BrowserRouterHistory.push(redirectUrl)

                return NEVER
            }),
        )
    }
}

/** Epic creator, which follows action, and do 'something' */
type EpicCreatorAction = IAction

export const epicCreator = (actionType: string, callback: (action: EpicCreatorAction, state: IRootState) => any): Epic => {
    return (action$: Observable<EpicCreatorAction>, state$: StateObservable<IRootState>) => {
        return action$.pipe(
            ofType(actionType),
            mergeMap((action) => {
                callback(action, state$.value)

                return NEVER
            }),
        )
    }
}

/** Epic creator, which follows action, and downloads the file */
export const DOWNLOAD_SUCCESS_ACTION_TYPE = 'DOWNLOAD_SUCCESS_ACTION_TYPE'

export const downloadFileUrlBuilder = (endpoint: string, params = {} as any, baseUrl = SERVER_BASE_URL) => {
    const url = new window.URL(`${baseUrl}${endpoint}`)
    Object.keys(params).forEach(key => url.searchParams.append(key, (params as any)[key]))

    return url.href
}

export const downloadFileEpicCreator = (actionType: string): Epic => {
    return (action$: Observable<IDownloadAction>) => {
        return action$.pipe(
            ofType(actionType),
            mergeMap(
                (action) => {
                    return ajax(
                        {
                            url: action.meta.url,
                            headers: {
                                [X_AUTH_TOKEN_KEY]: action.meta.token,
                                [CONTENT_TYPE_KEY]: action.meta.contentType || XLSX_CONTENT_TYPE,
                            },
                            responseType: 'arraybuffer',
                            method: HttpMethods.GET,
                        },
                    ).pipe(
                        map(res => {
                            const buffer = res.response
                            const urlToDownload = window.URL.createObjectURL(new Blob([buffer], {
                                type: action.meta.contentType || XLSX_CONTENT_TYPE,
                            }))

                            downloadFile(urlToDownload, `${action.meta.fileName || 'file'}`)

                            window.URL.revokeObjectURL(urlToDownload)

                            return {
                                type: DOWNLOAD_SUCCESS_ACTION_TYPE,
                            } as IAction
                        }),
                    )
                },
            ),
        )
    }
}
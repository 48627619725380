import makeStyles from '@material-ui/core/styles/makeStyles'

export const useResetPasswordStyles = makeStyles(theme => ({
    root: {
        background: theme.palette.background.default,
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    },
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    content: {
        maxWidth: 645,
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(4),
        color: theme.palette.text.primary,
        [theme.breakpoints.up('sm')]: {
            justifyContent: 'center'
        },
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(7.5, 3),
        },
    },
}))
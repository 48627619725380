import {FC} from 'react'
import {Profile} from './Profile'
import {useProfileStyles} from './profile.styles'
import {useScreen} from 'dst-react-core/core.hooks'

export const ContainerProfile: FC = () => {
    const profileClasses = useProfileStyles()

    const {isMobile} = useScreen()

    return (
        <Profile
            profileClasses={profileClasses}
            isMobile={isMobile}
        />
    )
}
import {LAPTOP_DEFAULT_SCREEN} from '../core.constants'

export const important = (str: any) => `${str}!important`

export const font = (...fonts: (string | undefined | null)[]) => [
    ...fonts.map(f => `"${f}"`),
    'Arial',
].join(',')

export const responsive = (stylesBlock = {} as { [x: string]: any }, maxWidth = LAPTOP_DEFAULT_SCREEN) => {
    return {
        [`@media only screen and (max-width: ${maxWidth}px)`]: {
            ...stylesBlock,
        },
    }
}

/** Only for basic flex templates. Do not extend this func. */
export const flex = (
    areItemsVerticalCentered?: boolean,
    areItemsHorizontalCentered?: boolean,
    gap?: number,
    customJustifyContent?:
        'initial' | 'flex-start' | 'flex-end' | 'space-around',
) => (
    {
        display: 'flex',
        justifyContent: customJustifyContent || (areItemsHorizontalCentered ? 'center' : 'space-between'),
        alignItems: areItemsVerticalCentered ? 'center' : 'initial',
        gap,
    }
)

export const resetUl = () => ({
    padding: 0,
    margin: 0,
    listStyle: 'none',
})

export const hideOverflowedText = () => ({
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    }
)

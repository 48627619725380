import {fetchActionGenerator} from '../../../../../dst-react-core/fetch/fetch.utils'
import {MY_CAMPAIGNS_API} from './homepage.api.constants'
import {combineEpics} from 'redux-observable'

export const [
    myCampaignsAction,
    myCampaignsActionBuilder,
    myCampaignsActionActionEpic,
    isMyCampaignsActionInPending,
] = fetchActionGenerator('GET_MY_CAMPAIGN', MY_CAMPAIGNS_API)

const homepageActions = combineEpics(
    myCampaignsActionActionEpic,
)

export default homepageActions
import {AjaxError} from 'rxjs/ajax'
import {IAction} from '../core.types'
import {TYPE_FETCH_CONST} from '../fetch/fetch.action.factory'

// BASE STATUS CODE KEY
export const STATUS_CODE_KEY = '{statusCode}'

export enum EStatusCodes {
    SUCCESS = 200,
    UNAUTHORIZED = 401,
    FORBIDDEN = 403,
    NOT_FOUND = 404,
    NOT_ACCEPTABLE = 460,
    BAD_REQUEST = 400,
    INTERNAL_SERVER = 500
}

// BASE STATUS CODE ACTION TYPE
const STATUS_CODE_BASE_ACTION_TYPE = `${TYPE_FETCH_CONST}${STATUS_CODE_KEY}_GENERIC_ACTION`

/**
 * @description
 * Return a string that rapresent the action type related to a statusCode
 * @param statusCode
 * @return string
 */
export const httpStatusCodeHandlerActionTypeBuilder = (statusCode: number): string =>
    STATUS_CODE_BASE_ACTION_TYPE.replace(STATUS_CODE_KEY, `${statusCode}`)

/**
 * @description
 * Return an action related to a specific code type (not the actual)
 * @param error
 * @return IAction | null
 */
export const httpStatusCodeHandlerActionBuilder = (error: AjaxError): IAction | null =>
    error ? {type: httpStatusCodeHandlerActionTypeBuilder(error.status), payload: error} : null

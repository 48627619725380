import makeStyles from '@material-ui/core/styles/makeStyles'
import {important} from 'dst-react-core/utils/styles.utils'

export const useFormStyles = makeStyles(theme => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        '& > :first-child': {
            marginTop: important(0),
        },
        '& > :last-child': {
            marginBottom: 0,
        },
        marginTop: theme.spacing(7, '!important'),
        marginBottom: theme.spacing(3.75, '!important'),
        '&.registerForm': {

        }
    },
    input: {
        marginTop: important(`${theme.spacing(2)}px`),
        '.registerForm > &': {
            paddingBottom: 16
        },
    },
    lightModeInput: {
        color: theme.palette.text.primary,
    },
    inputRow: {
        display: 'flex',
        gap: theme.spacing(3),
        '.registerForm > &': {
            paddingBottom: 16
        },
        '& > *': {
            width: '100%',
        },
        '&.adjusted': {
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                gap: 0,
            },
        },
    },
}))

export type TFormClasses = ReturnType<typeof useFormStyles>
import * as React from 'react'
import {FC, memo, useRef, useState} from 'react'
import {ButtonProps} from '@material-ui/core/Button/Button'
import {MenuProps} from '@material-ui/core/Menu/Menu'
import Button from '@material-ui/core/Button'
import {Menu} from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'

interface IMenuButtonProps extends Omit<ButtonProps, 'ref' | 'onCLick'> {
    menu?: React.ReactNode
    menuProps?: Omit<MenuProps,
        'children' |
        'open' |
        'onClose' |
        'anchorEl'>
}

const useMenuButtonStyles = makeStyles(() => ({
    menu: {
        borderRadius: 16,
        padding: 0,
        '& ul': {
            padding: 0,
        },
    },
}))

export const MenuButton: FC<IMenuButtonProps> = memo(props => {

    const {
        menu,
        menuProps,
        ...buttonProps
    } = props

    const classes = useMenuButtonStyles()

    const anchorEl = useRef<null | HTMLButtonElement>(null)

    const [open, setOpen] = useState(false)

    const handleClose = () => setOpen(false)

    return (
        <>
            <Button
                onClick={() => setOpen(true)}
                buttonRef={anchorEl}
                {...buttonProps}
            />
            <Menu
                key={anchorEl.current + ''}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                anchorEl={anchorEl.current}
                children={<div>{menu}</div>} // div need to avoid error with menu ref getting
                PaperProps={{
                    style: {minWidth: anchorEl.current?.clientWidth},
                    className: classes.menu,
                }}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                {...menuProps}
            />
        </>
    )
})

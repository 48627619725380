import {FC, useEffect, useMemo, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
    useCampaignFooterStylesWrapper,
    useCampaignHeaderStylesWrapper,
    useWishPageStyleWrapper,
} from './wish-page.styles'
import {clearCampaignWishPage} from '../redux/campaign.actions'
import {
    useCampaignImageWrapper,
    useCampaignVideo,
    useCampaignVideos,
    useCampaignVouchers,
    useCurrentCampaign,
    useValuate,
} from '../campaign.hooks'
import {getCampaignPageDataBuilder} from '../campaigns.utils'
import {ECampaignPageName, ECampaignPageSection, EGiftType} from 'dst-react-core/campaign/campaign.types'
import {getGiftsBuilder} from 'dst-react-core/gift/gift.utils'
import {
    campaignSectionSelector,
    currentCampaignGiftsSelector,
    currentCampaignReviewSelector,
    currentCampaignTypeSelector,
} from '../redux/current-campaign.selectors'
import {
    useCrossSellingSectionStylesWrapper,
    useFaqSectionStylesWrapper,
    useGiftSectionStylesWrapper,
    useHeaderSectionStylesWrapper,
} from './components/sections/campaign-wish-page-section.styles'
import {personSelector} from '../../components/auth/redux/auth.selectors'
import {authUserLogOutAction} from '../../components/auth/redux/auth.actions'
import {useValuationDialog} from 'shared/dialog-provider/dialog.hooks'
import {SingleVideoGiftSection} from 'deziro-web-app-components/components/sections/gift-section/SingleVideoGiftSection'
import {VideoCourseGiftSection} from 'deziro-web-app-components/components/sections/gift-section/VideoCourseGiftSection'
import {ContainerFileGiftSection} from './components/sections/gift-section/ContainerFileGiftSection'
import {MultiPodcastsGiftSection} from 'deziro-web-app-components/components/sections/gift-section/MultiPodcastsGiftSection'
import {SinglePodcastGiftSection} from 'deziro-web-app-components/components/sections/gift-section/SinglePodcastGiftSection'
import {VoucherGIftSection} from 'deziro-web-app-components/components/sections/gift-section/VoucherGIftSection'
import {WishPage} from './WishPage'
import {useCampaignSectionStyles} from 'deziro-web-app-components/shared/styles/campaign-section.styles'
import {ISectionAnchors} from 'deziro-web-app-components/models/wish-page-core.types'
import {ContainerVoucherAccordion} from './components/sections/components/ContainerVoucherAccordion'
import {ContainerLiveStreaming} from './components/sections/gift-section/ContainerLiveStreaming'

export const ContainerWishPage: FC = () => {
    const dispatch = useDispatch()
    const classes = useWishPageStyleWrapper()

    // Cleanup campaign main page data
    useEffect(() => () => {
        dispatch(clearCampaignWishPage)
    }, [dispatch])

    useCurrentCampaign(campaign => {
        dispatch(getCampaignPageDataBuilder(campaign.id, ECampaignPageName.MAIN))
        dispatch(getGiftsBuilder(campaign.id))
    })

    const gifts = useSelector(currentCampaignGiftsSelector)

    const campaignHeaderClasses = useCampaignHeaderStylesWrapper()
    const campaignFooterClasses = useCampaignFooterStylesWrapper()
    const campaignSectionsClasses = useCampaignSectionStyles()
    const headerSectionClasses = useHeaderSectionStylesWrapper()
    const giftSectionClasses = useGiftSectionStylesWrapper()
    const crossSellingSectionClasses = useCrossSellingSectionStylesWrapper()
    const faqSectionClasses = useFaqSectionStylesWrapper()

    const person = useSelector(personSelector)
    const review = useSelector(currentCampaignReviewSelector)

    const valuateCampaign = useValuate()
    const handleLogOut = () => dispatch(authUserLogOutAction)

    const headerContent = useSelector(campaignSectionSelector(ECampaignPageSection.HEADER))?.content ?? null
    const giftContent = useSelector(campaignSectionSelector(ECampaignPageSection.GIFT))?.content ?? null
    const crossSellingContent = useSelector(campaignSectionSelector(ECampaignPageSection.CROSS_SELLING))?.content ?? null
    const faqContent = useSelector(campaignSectionSelector(ECampaignPageSection.FAQ))?.content ?? null

    const giftType = useSelector(currentCampaignTypeSelector)

    const openValuationDialog = useValuationDialog()

    const partnerLogo = useCampaignImageWrapper(giftContent?.giftPartnerLogoImage?.id ?? null)

    const singleVideo = useCampaignVideo(gifts?.[0]?.eclexiaVideoId)
    const videoCourse = useCampaignVideos(gifts?.map(g => g.eclexiaVideoId) ?? [])
    const podcastCourse = useCampaignVideos(gifts?.map(g => g.eclexiaVideoId) ?? [])
    const podcast = useCampaignVideo(gifts?.[0]?.eclexiaVideoId)
    const vouchers = useCampaignVouchers(gifts?.map(g => ({id: g.id, position: g.position})) ?? [])

    const giftView: JSX.Element = useMemo(() => {
        switch (giftType) {
            case EGiftType.SINGLE_VIDEO:
                return <SingleVideoGiftSection video={singleVideo}/>
            case EGiftType.MULTI_VIDEO:
                return <VideoCourseGiftSection videos={videoCourse}/>
            case EGiftType.FILE:
                return <ContainerFileGiftSection/>
            case EGiftType.MULTI_PODCAST:
                return <MultiPodcastsGiftSection podcasts={podcastCourse}/>
            case EGiftType.SINGLE_PODCAST:
                return <SinglePodcastGiftSection podcast={podcast}/>
            case EGiftType.VOUCHER:
                return <VoucherGIftSection vouchers={vouchers} ContainerVoucherAccordion={ContainerVoucherAccordion}/>
            case EGiftType.LIVESTREAM:
                return <ContainerLiveStreaming/>
            default:
                return <></>
        }
    }, [giftType, singleVideo, videoCourse, podcastCourse, podcast, vouchers])

    const headerRef = useRef(null)
    const giftRef = useRef(null)
    const crossRef = useRef(null)
    const faqRef = useRef(null)

    const anchors: ISectionAnchors = {
        home: headerRef,
        wish: giftRef,
        ads: crossRef,
        faq: faqRef,
    }

    return (
        <WishPage
            anchors={anchors}
            giftView={giftView}
            partnerLogo={partnerLogo}
            openValuationDialog={openValuationDialog}
            faqContent={faqContent}
            crossSellingContent={crossSellingContent}
            giftContent={giftContent}
            headerContent={headerContent}
            handleLogOut={handleLogOut}
            valuateCampaign={valuateCampaign}
            person={person}
            review={review}
            campaignHeaderClasses={campaignHeaderClasses}
            campaignFooterClasses={campaignFooterClasses}
            headerSectionClasses={headerSectionClasses}
            giftSectionClasses={giftSectionClasses}
            crossSellingSectionClasses={crossSellingSectionClasses}
            faqSectionClasses={faqSectionClasses}
            campaignSectionClasses={campaignSectionsClasses}
            wishPageClasses={classes}
            giftType={giftType}
        />
    )
}
import {makeStyles} from '@material-ui/core/styles'
import generalAuthBackgroundSrc from 'assets/images/auth/auth-background.png'

export const useGeneralLoginStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        background: theme.palette.background.default,
        backgroundImage: `url(${generalAuthBackgroundSrc})`,
        backgroundRepeat: 'no-repeat',
        // todo: Decide to use 'cover' or '100% 100%'
        backgroundSize: '100% 100%',
        padding: theme.spacing(2.5),
        [theme.breakpoints.up('sm')]: {
            alignItems: 'center'
        }
    },
    paper: {
        background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), #030208',
        maxHeight: 586,
        '& > *:not(:first-child)': {
            marginTop: theme.spacing(1),
        },
        '& > *:not(:last-child)': {
            marginBottom: theme.spacing(1),
        },
    },
    logo: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        flexDirection: 'column',
        margin: theme.spacing(4, 0, 6),
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(3),
        },
        '& > img': {
            maxWidth: 142,
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        textAlign: 'center',
    },

}))
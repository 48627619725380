import {useTranslation} from 'react-i18next'
import {useFormStyles} from 'deziro-web-app-components/components/login/styles/form.styles'
import {useFormik} from 'formik'
import {vSameAs, vSchema, vString} from 'shared/formik/validation.yup'
import FormProvider from 'deziro-web-app-components/components/login/form/components/formik/FormProvider'
import Button from '@material-ui/core/Button'
import PasswordInput from 'deziro-web-app-components/components/login/form/components/PasswordInput'
import {authResetPasswordConfirm} from '../../../auth.utils'
import {useQuery} from 'dst-react-core/core.hooks'
import {useDispatch, useSelector} from 'react-redux'
import {isAuthResetPasswordConfirmActionInPending} from '../../../redux/auth.actions'

const ResetPasswordPasswordForm: React.FunctionComponent = () => {
    const {t} = useTranslation()

    const dispatch = useDispatch()

    const query = useQuery()

    const formClasses = useFormStyles()

    const isResetPasswordConfirmInPending = useSelector(isAuthResetPasswordConfirmActionInPending)

    const formik = useFormik({
        initialValues: {
            password: '',
            confirmToken: query.get('token') ?? '',
            password_repeat: '',
        },
        validationSchema: vSchema({
            password: vString.required(),
            password_repeat: vSameAs('password').required(t('error.required_field', { FIELD: t('string.confirm_password')})),
        }),
        onSubmit: ({password, confirmToken}) => {
            dispatch(authResetPasswordConfirm(confirmToken, password))
        },
        validateOnMount: true,
    })

    return (
        <>
            <FormProvider className={formClasses.form}>
                <PasswordInput
                    name="password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={formik.touched.password && formik.errors.password}
                    variant="outlined"
                    className={formClasses.input}
                    label={t('string.password')}
                    value={formik.values.password}
                />
                <PasswordInput
                    name="password_repeat"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={formik.touched.password_repeat && formik.errors.password_repeat}
                    variant="outlined"
                    className={formClasses.input}
                    label={t('string.confirm_password')}
                    value={formik.values.password_repeat}
                />
            </FormProvider>
            <Button
                className={'big'}
                variant="contained"
                onClick={() => formik.handleSubmit()}
                disabled={isResetPasswordConfirmInPending || !!Object.keys(formik.errors).length}
            >{t('string.send')}</Button>
        </>
    )
}

export default ResetPasswordPasswordForm

import makeStyles from '@material-ui/core/styles/makeStyles'
import {flex, important} from 'dst-react-core/utils/styles.utils'
import {EXTRA_COLORS_PALETTE} from 'material.theme'

export const useGeneralFooterStyles = makeStyles(() => ({
    mainFooterWrap: {
        background: important('linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #030208'),
        color: important('#fff'),
        '& .text': {
            color: important('#fff'),
        },
        '& .social': {
            '& .MuiSvgIcon-root': {
                color: 'rgba(255, 255, 255, .38)',
            },
        },
    },
    info: {
        paddingBottom: 50,
        borderBottom: important(`1px solid ${EXTRA_COLORS_PALETTE.GENERAL_BORDER.DEFAULT_WITH_OPACITY}`),
    },
    linksWrap: {
        ...flex(),
    },
    links: {
        '& .item': {},
    },
    networks: {
        '& .networkIcon': {
            '& path': {
                fill: '#fff',
            },
        },
        '& .item': {},
    },
}))

import {IRouter} from '../../../../dst-react-core/routing/routes.types'
import {GENERAL_DASHBOARD_SUBROUTING} from '../general-dashboard-routes'

const PROFILE_PATH = GENERAL_DASHBOARD_SUBROUTING.profile.path

export const ACCOUNT_OVERVIEW_ENDPOINT = '/account-overview'
export const PERSONAL_PROFILE_ENDPOINT = '/personal-profile'
export const PASSWORD_CHANGE_ENDPOINT = '/password-change'

const PROFILE_ACCOUNT_OVERVIEW_PATH = `${PROFILE_PATH}${ACCOUNT_OVERVIEW_ENDPOINT}`
const PROFILE_PERSONAL_PROFILE_PATH = `${PROFILE_PATH}${PERSONAL_PROFILE_ENDPOINT}`
const PROFILE_PASSWORD_PATH = `${PROFILE_PATH}${PASSWORD_CHANGE_ENDPOINT}`

export const PROFILE_ROUTES = Object.freeze<IRouter>({
    path: PROFILE_PATH,
    description: 'Profile sub routes',
    subrouting: {
        overview: {
            path: PROFILE_ACCOUNT_OVERVIEW_PATH,
            description: 'Account Overview',
        },
        profile: {
            path: PROFILE_PERSONAL_PROFILE_PATH,
            description: 'Personal Profile',
        },
        password: {
            path: PROFILE_PASSWORD_PATH,
            description: 'Profile Password Change',
        },
    },
})

export const GENERAL_PROFILE_SUBROUTING = PROFILE_ROUTES.subrouting
import makeStyles from '@material-ui/core/styles/makeStyles'
import {flex} from 'dst-react-core/utils/styles.utils'
import {cssBackgroundUrl} from 'dst-react-core/utils/shared.utils'
import playButtonBackgroundSrc from 'deziro-web-app-components/assets/images/profile.png'

export const useEclexiaPlayerStyles = makeStyles(() => ({
    wrapper: {
        position: 'relative',
        paddingBottom: '56.25%',
        overflow: 'hidden',
        borderRadius: 8,
        width: '100%',
        margin: '20px 0',
        '& .overlayWrap': {
            width: '100%',
            height: 'inherit',
            '& .liveOverlay': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                ...flex(true, true),
                backgroundImage: cssBackgroundUrl(playButtonBackgroundSrc),
                backgroundColor: '#000',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: '95% 95%',
                '& .playButton': {
                    width: 80,
                    height: 80,
                    borderRadius: '50%',
                    background: '#000',
                    ...flex(true, true),
                    '& > *': {
                        color: '#fff',
                    },
                },
            },
        },
    },
    iframe: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        border: 'none',
    },
    mockedPlayer: {
        border: '2px dashed #000',
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        borderRadius: 10,
        ...flex(true, true),
        '& .playIcon': {
            fontSize: 60,
        },
    },
}))
import makeStyles from '@material-ui/core/styles/makeStyles'
import {flex, important, resetUl} from '../../../../../../dst-react-core/utils/styles.utils'
import {EXTRA_COLORS_PALETTE} from '../../../../../../material.theme'

export const useGeneralHeaderSideMenuStyles = makeStyles(theme => ({
    menuWrap: {
        background: theme.palette.background.default,
        height: '100%',
        ...flex(),
        flexDirection: 'column',
        '& .content': {
            background: theme.palette.background.default,
            padding: 20,
            ...flex(),
            flexDirection: 'column',
            '& .header': {
                ...flex(true),
                '& .closeIcon': {
                    color: theme.palette.text.secondary,
                },
            },
            '& .searchWrap': {
                '& .searchBar': {
                    margin: '30px 0',
                },
            },
            '& .userInfoWrapWithoutSearchbar': {
                marginTop: 50
            },
            '& .userInfoWrap': {
                '& .userInfoHeader': {
                    ...flex(true, false, 20, 'initial'),
                    '& .nameTitle': {
                        fontSize: 16,
                    },
                },
                '& .userInfoMenu': {
                    ...flex(),
                    ...resetUl(),
                    marginTop: 15,
                    flexDirection: 'column',
                    paddingBottom: 15,
                    borderBottom: `1px solid ${EXTRA_COLORS_PALETTE.GENERAL_BORDER.DEFAULT_WITH_OPACITY}`,
                    '& li': {
                        padding: '10px 0',
                        cursor: 'pointer',
                        '& .label': {
                            verticalAlign: 'middle',
                            color: theme.palette.text.secondary,
                            fontSize: 14,
                            '& .icon': {
                                marginRight: 5,
                                fontSize: 20,
                            },
                        },
                    },
                },
            },
            '& .filterWrap': {
                borderTop: `1px solid ${'#313035'}`,

            },
        },
        '& .actionsWrap': {
            display: 'block',
            marginTop: 'auto',
            '& .serviceCenterButton': {
                width: '100%',
                borderRadius: important(0),
                background: important(EXTRA_COLORS_PALETTE.PRIMARY.MAIN),
                color: important('#fff'),
                height: 60,
                display: 'none',
                '&:hover': {
                    background: important(EXTRA_COLORS_PALETTE.PRIMARY.DARK),
                },
            },
        },
    },
}))
import {combineEpics} from 'redux-observable'
import {epicCreator, triggerSnackbarEpicCreator} from '../../../../../dst-react-core/fetch/fetch.epic.creators'
import {personUpdateAction} from '../../../../../dst-react-core/person/redux/person.actions'
import {principalUpdateAction} from '../../../../../dst-react-core/principal/redux/principal.actions'
import {fetchActionGenerator} from '../../../../../dst-react-core/fetch/fetch.utils'
import {CHANGE_PASSWORD_API} from './profile.api.constants'
import {BrowserRouterHistory} from '../../../../../dst-react-core/routing/route'
import {GENERAL_PROFILE_SUBROUTING} from '../profile-routes'

export const [
    changePasswordAction,
    changePasswordActionBuilder,
    changePasswordActionEpic,
] = fetchActionGenerator('CHANGE_PASSWORD', CHANGE_PASSWORD_API)


export const profileActions = combineEpics(
    triggerSnackbarEpicCreator(personUpdateAction.successActionType, 'general_dashboard.edit_person_success'),
    triggerSnackbarEpicCreator(principalUpdateAction.successActionType, 'general_dashboard.edit_person_success'),
    triggerSnackbarEpicCreator(changePasswordAction.successActionType, 'general_dashboard.password_success'),
    epicCreator(changePasswordAction.successActionType, () => {
        BrowserRouterHistory.push(GENERAL_PROFILE_SUBROUTING.overview.path)
    }),
    changePasswordActionEpic,
)
import * as React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import { ChangeEmailConfirm } from './change-email-confirm/ChangeEmailConfirm'
import {GENERAL_AUTH_SUBROUTING} from './general-auth.routes'
import GeneralLogin from './login/GeneralLogin'
import GeneralAuthResetPasswordRoute from './reset-password/GeneralAuthResetPasswordRoute'

const GeneralAuthRoute: React.FunctionComponent = () => {
    return (
        <Switch>

            {/* ROUTE TO GENERAL AUTH LOGIN*/}
            <Route path={GENERAL_AUTH_SUBROUTING.login.path}>
                <GeneralLogin />
            </Route>

            {/* ROUTE TO GENERAL AUTH RESET PASSWORD*/}
            <Route path={GENERAL_AUTH_SUBROUTING.reset_password.path}>
                <GeneralAuthResetPasswordRoute />
            </Route>

            <Route path={GENERAL_AUTH_SUBROUTING.change_email_confirm.path}>
                <ChangeEmailConfirm />
            </Route>

            {/* DEFAULT */}
            <Route>
                <Redirect to={GENERAL_AUTH_SUBROUTING.login.path} />
            </Route>

        </Switch>
    )
}

export default GeneralAuthRoute

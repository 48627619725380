import * as React from 'react'
import { insertId } from 'dst-react-core/utils/shared.utils'
import GenericAnnotation from 'deziro-web-app-components/shared/utils/GenericAnnotation'
import {Link} from 'react-router-dom'
import { AuthSideContainer } from './components/AuthSideContainer'
import { CampaignLoginFormContainer } from 'deziro-web-app-components/components/login/CampaignLoginFormContainer'
import { useTranslation } from 'react-i18next'
import CampaignRegistrationForm from './form/CampaignRegistrationForm'
import {memo} from 'react'

export interface ICampaignLogin {
    paths: any
    id: string
    authorizeUser: any
    isLoginInPending: boolean
    isLoginAttemptFailed: boolean
    verificationSchemas: any
    loginPath: string
    classes: any
    registrationStep?: boolean,
    campaign: any
    isRegistrationInPending: any
    setRegistrationStep?: (evt: React.MouseEvent<HTMLAnchorElement>) => void
    registerUser: any
    loginLogo?: string
}

const CampaignLogin: React.FC<ICampaignLogin> = memo((props) => {
    const {
        id,
        paths,
        authorizeUser,
        isLoginAttemptFailed,
        isLoginInPending,
        verificationSchemas,
        loginPath,
        classes,
        registrationStep,
        campaign,
        isRegistrationInPending,
        registerUser,
        setRegistrationStep,
        loginLogo
    } = props

    const {t} = useTranslation()

    return (
        <AuthSideContainer
            classes={classes}
            path={loginPath}
            loginLogo={loginLogo}
        >
            <GenericAnnotation
                heading={registrationStep ? t('auth.sign_up.title') : t('auth.login.title_platform')}
                hint={registrationStep ? 
                    t('auth.sign_up.hint') : (
                        <>
                            {t('auth.login.no_account')}&nbsp;
                            <Link
                                to={paths?.registration?.path ? insertId(paths?.registration?.path, id) : ''}
                                onClick={setRegistrationStep}
                            >
                                {t('auth.login.register_now')}
                            </Link>
                        </>
                    )
                }
                sm
            />
            {registrationStep ? (
                <CampaignRegistrationForm
                    verificationSchemas={verificationSchemas}
                    campaign={campaign}
                    isRegistrationInPending={isRegistrationInPending}
                    registerUser={registerUser}
                />
            ) : (
                <CampaignLoginFormContainer
                    isLoginAttemptFailed={isLoginAttemptFailed}
                    isLoginInPending={isLoginInPending}
                    authorizeUser={authorizeUser}
                    resetPwdPath={paths?.reset_password?.path}
                    verificationSchemas={verificationSchemas}
                />
            )}
        </AuthSideContainer>
    )
})

export default CampaignLogin

import makeStyles from '@material-ui/core/styles/makeStyles'

export const useHeaderStyles = makeStyles(theme => ({
    campaignHeader: {
        backgroundColor: '#FEFEFE',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        height: 88,
        [theme.breakpoints.down('md')]: {
            height: 50,
        },
    },
    logo: {
        width: 125,
        backgroundRepeat: 'no-repeat',
        cursor: 'pointer',
        padding: theme.spacing(2),
        backgroundSize: 'contain',
        backgroundPosition: 'center left',
        marginLeft: 10,
    },
    side: {
        display: 'flex',
        '& > *': {
            height: '100%',
        },
        '& .MuiButton-root': {
            borderRadius: 0,
            boxShadow: 'none',
            color: theme.palette.grey[800],
            '& .MuiButton-startIcon': {
                color: theme.palette.primary.main,
            },
            '& .MuiButton-endIcon': {
                color: theme.palette.text.disabled,
            },
        },
    },
    primaryButton: {
        minWidth: 240,
    },
    actionButton: {
        padding: theme.spacing(0, 3.5),
        borderLeft: `0.5px ${theme.palette.text.disabled} solid`,
        '&:nth-child(2)': {
            borderLeft: 'unset'
        }
    },
    burger: {
        color: theme.palette.text.hint,
        width: 64,
    },
    drawer: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
        justifyContent: 'space-between',
        color: theme.palette.grey[800],
        '& .MuiAvatar-root': {
            backgroundColor: theme.palette.primary.main,
        },
        '& .MuiButton-root': {
            width: '100%',
            height: 40,
            borderRadius: 0,
            color: theme.palette.grey[800],
            paddingLeft: theme.spacing(4),
            '& .MuiButton-label': {
                justifyContent: 'start',
            },
        },
        '& .MuiListItem-root': {
            '& .MuiListItemText-root .MuiSvgIcon-root': {
                color: theme.palette.text.hint,
                marginRight: theme.spacing(1),
            },
            '& .MuiListItemIcon-root': {
                color: theme.palette.primary.main,
            },
        },
        '& .MuiDivider-root': {
            margin: theme.spacing(3, 0, 2),
        },
        '& .MuiCollapse-container': {
            color: theme.palette.text.hint,
            '&.padding .MuiListItem-root': {
                paddingLeft: theme.spacing(4),
            },
        },
    },
    drawerLogo: {
        height: 64,
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(1),
    },
}))
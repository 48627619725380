import {combineEpics} from 'redux-observable'
import {IActionFactoryReturn} from '../../fetch/fetch.types'
import {fetchActionFactory} from '../../fetch/fetch.action.factory'
import {isActionInPending} from '../../spinner/redux/spinner.reducers'
import {mergeMapFetchEpics} from '../../fetch/fetch.call'
import {DOWNLOAD_IMAGE_GET_API} from './image.api.constants'

export const DOWNLOAD_IMAGE_ACTION_TYPE = 'DOWNLOAD_IMAGE_ACTION_TYPE'
export const DOWNLOAD_IMAGE_ACTION_REQUEST_ID = 'DOWNLOAD_IMAGE_ACTION_REQUEST_ID'
export const downloadAction: IActionFactoryReturn = fetchActionFactory(DOWNLOAD_IMAGE_GET_API, DOWNLOAD_IMAGE_ACTION_TYPE)
export const isDownloadActionInPending = isActionInPending(downloadAction.pendingActionTypeWithSpinner, DOWNLOAD_IMAGE_ACTION_REQUEST_ID)

const imagesActions = combineEpics(
    mergeMapFetchEpics(downloadAction.pendingActionTypeWithSpinner),
)

export default imagesActions
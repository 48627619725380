import { changeEmailConfirmAction, CHANGE_EMAIL_CONFIRM_ACTION_TYPE_REQUEST_ID } from './redux/general-auth.actions'

export const authChangeEmailConfirm = (confirmToken: string,) => {
    return changeEmailConfirmAction.build(
        null,
        CHANGE_EMAIL_CONFIRM_ACTION_TYPE_REQUEST_ID,
        {},
        {},
        { confirmToken }
    )
}

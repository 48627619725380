import * as React from 'react'
import {memo, useMemo} from 'react'
import {EIconFilStyle, ESocialNetwork, ISocialNetworkLink} from 'dst-react-core/campaign/campaign.types'
import {prepareExternalLink} from 'dst-react-core/utils/shared.utils'
import Button from '@material-ui/core/Button'
import {ReactComponent as FacebookIcon} from 'deziro-web-app-components/assets/networks/facebook.svg'
import {ReactComponent as TwitterIcon} from 'deziro-web-app-components/assets/networks/twitter.svg'
import {ReactComponent as InstagramIcon} from 'deziro-web-app-components/assets/networks/instagram.svg'
import {ReactComponent as LinkedInIcon} from 'deziro-web-app-components/assets/networks/linkedin.svg'
import {ReactComponent as YouTubeIcon} from 'deziro-web-app-components/assets/networks/youtube.svg'
import {ReactComponent as TwitchIcon} from 'deziro-web-app-components/assets/networks/twitch.svg'
import {analyticClickDecorator} from 'dst-react-core/google-analytics/gmt.init'

interface ISocialNetworkButtonProps {
    type: EIconFilStyle,
    className?: string
    socialNetwork: ISocialNetworkLink
}

export const SocialNetworkButton: React.FunctionComponent<ISocialNetworkButtonProps> = memo(props => {

    const {
        type,
        className,
        socialNetwork,
    } = props

    const Icon = useMemo(() => {
        switch (socialNetwork.socialNetwork) {
            case ESocialNetwork.FACEBOOK:
                return FacebookIcon
            case ESocialNetwork.TWITTER:
                return TwitterIcon
            case ESocialNetwork.INSTAGRAM:
                return InstagramIcon
            case ESocialNetwork.LINKEDIN:
                return LinkedInIcon
            case ESocialNetwork.YOUTUBE:
                return YouTubeIcon
            case ESocialNetwork.TWITCH:
                return TwitchIcon
        }
    }, [socialNetwork.socialNetwork])

    const variant = useMemo(() => {
        switch (type) {
            case EIconFilStyle.OUTLINE:
                return 'outlined'
            default:
                return 'contained'
        }
    }, [type])

    const clickHandler = () => {
        window.location.href = prepareExternalLink(socialNetwork.url)
    }

    return (
        <Button className={className} variant={variant} onClick={analyticClickDecorator('social-click', clickHandler)}>
            <Icon className={'icon'}/>
        </Button>
    )
})

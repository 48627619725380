import {combineEpics} from 'redux-observable'
import {IActionFactoryReturn} from '../../fetch/fetch.types'
import {fetchActionFactory} from '../../fetch/fetch.action.factory'
import {MY_PERSON_FETCH_API, PERSON_DELETE_API, PERSON_UPDATE_API} from './person.api.constants'
import {isActionInPending} from '../../spinner/redux/spinner.reducers'
import {switchMapFetchEpics} from '../../fetch/fetch.call'

/** MY PERSONS */
export const MY_PERSON_FETCH_ACTION = 'MY_PERSON_FETCH_ACTION'
export const MY_PERSON_FETCH_ACTION_REQUEST_ID = 'MY_PERSON_FETCH_ACTION_REQUEST_ID'

export const myPersonFetchAction: IActionFactoryReturn = fetchActionFactory(MY_PERSON_FETCH_API, MY_PERSON_FETCH_ACTION)
export const isMyPersonFetchActionInPending = isActionInPending(myPersonFetchAction.pendingActionTypeWithSpinner, MY_PERSON_FETCH_ACTION_REQUEST_ID)

/** DELETE PERSON */
export const PERSON_DELETE_ACTION = 'PERSON_DELETE_ACTION'
export const PERSON_DELETE_ACTION_REQUEST_ID = 'PERSON_DELETE_ACTION_REQUEST_ID'

export const personDeleteAction: IActionFactoryReturn = fetchActionFactory(PERSON_DELETE_API, PERSON_DELETE_ACTION)
export const isPersonDeleteActionInPending = isActionInPending(personDeleteAction.pendingActionTypeWithSpinner, PERSON_DELETE_ACTION_REQUEST_ID)

/** UPDATE PERSON */
export const PERSON_UPDATE_ACTION = 'PERSON_UPDATE_ACTION'
export const PERSON_UPDATE_ACTION_REQUEST_ID = 'PERSON_UPDATE_ACTION_REQUEST_ID'

export const personUpdateAction: IActionFactoryReturn = fetchActionFactory(PERSON_UPDATE_API, PERSON_UPDATE_ACTION)
export const isPersonUpdateActionInPending = isActionInPending(personUpdateAction.pendingActionTypeWithSpinner, PERSON_UPDATE_ACTION_REQUEST_ID)

const personActions = combineEpics(
    switchMapFetchEpics(personDeleteAction.pendingActionTypeWithSpinner),
    switchMapFetchEpics(personUpdateAction.pendingActionTypeWithSpinner),
)

export default personActions
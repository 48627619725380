import {isObject} from './object.util'

type ICheckDifferencesReturnedType = {
    areObjectsDifferent: boolean,
    differentProps: string[]
}

/**
 * Returns true and an array of props if more one field in objects are different
 * @param objectToCompare
 * @param basicObject
 * @param ignoreKeys to ignore props with such keys
 */
// todo @dima replace on back-office
export const checkDifferences = (objectToCompare: any, basicObject: any, ignoreKeys: string[] = []): ICheckDifferencesReturnedType => {
    if (!isObject(objectToCompare) || !isObject(basicObject)) return {
        areObjectsDifferent: false,
        differentProps: [],
    }

    let areObjectsDifferent = false
    const differentProps: string[] = []

    for (const key in basicObject) {
        if (basicObject.hasOwnProperty(key) && objectToCompare.hasOwnProperty(key) && !ignoreKeys.includes(key)) {
            if (basicObject[key] !== objectToCompare[key]) {
                areObjectsDifferent = true
                differentProps.push(key)
            }
        }
    }
    return {
        areObjectsDifferent,
        differentProps,
    }
}

export const cutObjectFields = (object: any, fieldsToBeCut: string[], deleteAllExcept = false) => {
    if(!isObject(object)) return null

    const objectCopy = {...object}

    for (const key in objectCopy) {
        if (objectCopy.hasOwnProperty(key)) {
            const included = fieldsToBeCut.includes(key)

            if(deleteAllExcept ? !included : included) {
                delete objectCopy[key]
            }
        }
    }

    return objectCopy
}
import {IRouter} from '../../../dst-react-core/routing/routes.types'
import {GENERAL_DASHBOARD_PATH} from '../general.routes'

export const HOMEPAGE_PATH = `${GENERAL_DASHBOARD_PATH}/homepage`
export const PROFILE_PATH = `${GENERAL_DASHBOARD_PATH}/profile`

export const GENERAL_DASHBOARD_ROUTE = Object.freeze<IRouter>({
    path: GENERAL_DASHBOARD_PATH,
    description: 'Web app dashboard route',
    subrouting: {
        profile: {
            path: PROFILE_PATH,
            description: 'Web app dashboard profile route',
        },
        homepage: {
            path: HOMEPAGE_PATH,
            description: 'Web app dashboard homepage route',
        },
    },
})

export const GENERAL_DASHBOARD_SUBROUTING = GENERAL_DASHBOARD_ROUTE.subrouting
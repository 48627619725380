import React, {FC} from 'react'
import Typography from '@material-ui/core/Typography'
import {IProfileNavigationConfig} from '../hooks/profile-navigation.hook'

interface IProfileNavigationMobileTextContentProps {
    navigationClasses: any,
    item: IProfileNavigationConfig
}

export const ProfileNavigationMobileTextContent: FC<IProfileNavigationMobileTextContentProps> = props => {
    const {
        navigationClasses,
        item,
    } = props

    return (
        <Typography className={navigationClasses.mobileMenuItemContent}>
            <span className={'iconWrap'}>
                {item?.icon}
            </span>
            {item?.title}
        </Typography>
    )
}
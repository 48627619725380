import {Provider} from 'react-redux'
import {GenericToastContainer} from 'dst-react-core/components/toast/GenericToastContainer'
import {MuiThemeProvider} from '@material-ui/core'
import {themeOptions} from 'material.theme'
import {DialogProvider} from 'shared/dialog-provider/DialogProvider'
import {Router} from 'react-router-dom'
import {Suspense} from 'react'
import {store} from 'redux/app.store'
import WebApp from 'web-app/WebApp'
import {BrowserRouterHistory} from 'dst-react-core/routing/route'
import './App.scss'

const MainContainer: React.FC = () => {
    return (
        <Provider store={store}>
            <MuiThemeProvider theme={themeOptions}>

                {/* APP ROUTING */}
                <Router history={BrowserRouterHistory}>
                    <Suspense fallback={<div>Loading web app...</div>}>
                        <WebApp/>
                    </Suspense>
                </Router>

                {/* SNACKBAR PROVIDER */}
                <GenericToastContainer/>

                {/* DIALOG PROVIDER */}
                <DialogProvider/>

            </MuiThemeProvider>
        </Provider>
    )
}

export default MainContainer

import Box from '@material-ui/core/Box'
import {FC, memo} from 'react'
import {ContainerAccessedCampaigns} from './accessed-campaigns/ContainerAccessedCampaigns'

export interface IHomepageProps {
    homepageClasses: any,
}

export const Homepage: FC<IHomepageProps> = memo((props) => {
    const {
        homepageClasses,
    } = props

    return (
        <Box className={homepageClasses.homepageMainWrap}>
            <ContainerAccessedCampaigns/>
        </Box>
    )
})

export const PROFILE_FORM_FIELDS = Object.freeze({
    name: 'name',
    surname: 'surname',
    email: 'email',
    gender: 'gender',
    birthDate: 'birthDate',
    number: 'dayNumber',
    month: 'month',
    year: 'year',
    get birthDateNumber() {
        return `${this.birthDate}.${this.number}`
    },
    get birthDateMonth() {
        return `${this.birthDate}.${this.month}`
    },
    get birthDateYear() {
        return `${this.birthDate}.${this.year}`
    },
})

import * as React from 'react'
import {Redirect, Route, Switch, useHistory, useLocation} from 'react-router'
import {WEB_APP_SUBROUTING} from './web-app.routes'
import GeneralRoute from './general/GeneralRoute'
import CampaignRoute from './campaign/CampaignRoute'


const WebAppRoute: React.FunctionComponent = () => {

    // Removes '#/' in end of url
    const {pathname, hash, search} = useLocation()
    const history = useHistory()
    if (hash === '#/') history.replace(`${pathname}${search}`)

    return (
        // SEQUENCE IS IMPORTANT
        <Switch>

            {/* ROUTE TO GENERAL*/}
            <Route path={WEB_APP_SUBROUTING.general.path}>
                <GeneralRoute />
            </Route>

            {/* ROUTE TO CAMPAIGN */}
            <Route path={WEB_APP_SUBROUTING.campaign.path}>
                <CampaignRoute />
            </Route>

            {/* DEFAULT */}
            <Route>
                <Redirect to={WEB_APP_SUBROUTING.general.path} />
            </Route>

        </Switch>
    )
}

export default WebAppRoute

import {FC, memo} from 'react'
import {FormikContextType, getIn} from 'formik'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import {PROFILE_FORM_FIELDS} from '../profile-form.constants'
import {IProfileFormValues} from '../profile-form.types'
import GenericSelect from 'deziro-web-app-components/shared/utils/GenericSelect'
import {MONTHS_OPTIONS} from 'dst-react-core/utils/date-utils/months-names.util'

interface IProfileFormBirthDateFieldProps {
    title: string,
    formik: FormikContextType<IProfileFormValues>,
    profileFormClasses: any
}

export const ProfileFormBirthDateField: FC<IProfileFormBirthDateFieldProps> = memo(props => {
    const {
        title,
        formik,
        profileFormClasses,
    } = props

    return (
        <div className={profileFormClasses.formField}>
            <Typography
                className={'title'}
            >
                {title}
            </Typography>

            <div className="birthDateFieldsWrap">
                <TextField
                    className={'field'}
                    fullWidth
                    variant={'outlined'}
                    value={getIn(formik.values, PROFILE_FORM_FIELDS.birthDateNumber)}
                    name={PROFILE_FORM_FIELDS.birthDateNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={getIn(formik.touched, PROFILE_FORM_FIELDS.birthDateNumber) && !!getIn(formik.errors, PROFILE_FORM_FIELDS.birthDateNumber)}
                    helperText={getIn(formik.touched, PROFILE_FORM_FIELDS.birthDateNumber) && getIn(formik.errors, PROFILE_FORM_FIELDS.birthDateNumber)}
                />

                <GenericSelect
                    className={'field'}
                    fullWidth
                    variant={'outlined'}
                    value={getIn(formik.values, PROFILE_FORM_FIELDS.birthDateMonth)}
                    name={PROFILE_FORM_FIELDS.birthDateMonth}
                    onChange={(e) => {
                        formik.setFieldValue(PROFILE_FORM_FIELDS.birthDateMonth, +(e.target.value as string))
                    }}
                    onBlur={formik.handleBlur}
                    error={getIn(formik.touched, PROFILE_FORM_FIELDS.birthDateMonth) && !!getIn(formik.errors, PROFILE_FORM_FIELDS.birthDateMonth)}
                    helperText={getIn(formik.touched, PROFILE_FORM_FIELDS.birthDateMonth) && getIn(formik.errors, PROFILE_FORM_FIELDS.birthDateMonth)}
                    options={MONTHS_OPTIONS}
                />

                <TextField
                    className={'field'}
                    fullWidth
                    variant={'outlined'}
                    value={getIn(formik.values, PROFILE_FORM_FIELDS.birthDateYear)}
                    name={PROFILE_FORM_FIELDS.birthDateYear}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={getIn(formik.touched, PROFILE_FORM_FIELDS.birthDateYear) && !!getIn(formik.errors, PROFILE_FORM_FIELDS.birthDateYear)}
                    helperText={getIn(formik.touched, PROFILE_FORM_FIELDS.birthDateYear) && getIn(formik.errors, PROFILE_FORM_FIELDS.birthDateYear)}
                />
            </div>
        </div>
    )
})
import makeStyles from '@material-ui/core/styles/makeStyles'
import {flex, hideOverflowedText} from 'dst-react-core/utils/styles.utils'
import {CSSDependsOnBrowserFlexGapSupporting} from 'dst-react-core/utils/css/supports-flex-gap.util'

export const useAccessedCampaignItemStyles = makeStyles((theme) => ({
    card: {
        width: '100%',
        background: 'rgba(0, 0, 0, 0.38)',
        backgroundFilter: 'blur(50px)',
        borderRadius: 16,
        padding: 24,
        boxSizing: 'border-box',
        ...flex(false, false, 0, 'initial'),
        flexDirection: 'column',
        position: 'relative',
        overflow: 'hidden',
        ...CSSDependsOnBrowserFlexGapSupporting(
            {
                gap: 25
            },
            {
                '& > div': {
                    marginTop: 25,
                    '&:first-child': {
                        marginTop: 0
                    }
                }
            }
        ),
        '&.disabled::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            backgroundColor: 'rgba(100,100,100,.3)',
        },
        '&:not(.disabled):after': {
            transition: 'all ease 0.4s',
            background: 'rgba(255, 255, 255, 0.08)',
            width: '100%',
            height: 0,
            position: 'absolute',
            bottom: 0,
            left: 0,
            content: '""',
            zIndex: 2,
        },
        '&:not(.disabled):hover': {
            '&:after': {
                height: '100%',
            },
            '& .previewImg': {
                transform: 'scale(1.1)',
                filter: 'brightness(80%)',
            },
        },
        '& .cardHeader': {
            ...flex(true),
            '& .infoWrap': {
                ...flex(true, false, 10, 'initial'),
                width: '75%',
                '& .logoWrap': {
                    width: '30%',
                    '& .logo': {
                        width: 56,
                        height: 56,
                        background: theme.palette.text.primary,
                        color: '#000',
                        borderRadius: '50%',
                        overflow: 'hidden',
                        ...flex(true, true),
                        '& .logoImg': {
                            width: 'inherit',
                            height: 'inherit',
                        },
                    },
                },
                '& .contentWrap': {
                    flexDirection: 'column',
                    width: '70%',
                    ...hideOverflowedText(),
                    '& .title': {
                        color: theme.palette.text.primary,
                        fontSize: 16,
                        lineHeight: '24px',
                        ...hideOverflowedText(),
                    },
                    '& .campaignType': {
                        color: theme.palette.text.secondary,
                        letterSpacing: '.4px',
                        fontSize: 12,
                        lineHeight: '16px',
                        fontWeight: 500,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 4,
                        '& .icon': {
                            width: 14,
                            height: 14,
                            lineHeight: '16px'
                        }
                    },
                },
            },
            '& .chipWrap': {
                width: '25%',
            },
        },
        '&.disabled .chipWrap .MuiChip-root': {
            backgroundColor: '#CCC',
            color: '#252525',
        },
        '& .cardPreview': {
            borderRadius: 16,
            width: '100%',
            overflow: 'hidden',
            height: 240,
            '& .previewImg': {
                width: '100%',
                height: 'inherit',
                transition: 'all ease 0.4s',
                objectFit: 'cover',
            },
            '& .defaultPreviewImg': {
                width: '70%',
                display: 'block',
                margin: '0 auto',
            },
        },
        '& .cardInfo': {
            '& .title': {
                color: theme.palette.text.primary,
                fontSize: 24,
                fontWeight: 700,
                lineHeight: '32px',
                ...hideOverflowedText(),
            },
            '& .review': {},
        },
        '& .cardActions': {
            '& .seeWishButton': {
                height: 60,
                position: 'relative',
                zIndex: 5,
                color: theme.palette.text.primary
            },
        },
        '&.disabled .seeWishButton': {
            backgroundColor: 'gray',
        },
        '&.disabled .MuiRating-decimal': {
            color: '#EFDE851A',
        },
    },
}))
import {FC, useCallback} from 'react'
import {FileGiftSection} from 'deziro-web-app-components/components/sections/gift-section/FileGiftSection'
import {useCampaignImageWrapper, useCurrentCampaign} from 'web-app/campaign/campaign.hooks'
import {giftFileDownloadBuilder} from 'dst-react-core/gift-file/gift-file.utils'
import {downloadFile} from 'dst-react-core/utils/shared.utils'
import {useDispatch, useSelector} from 'react-redux'
import {
    campaignSectionSelector,
    currentCampaignGiftsSelector,
    currentCampaignSelector,
} from 'web-app/campaign/redux/current-campaign.selectors'
import {isGiftFileDownloadActionInPending} from 'dst-react-core/gift-file/redux/gift-file.actions'
import {campaignGiftFileSelector} from 'web-app/campaign/redux/campaign-gift.selectors'
import {ECampaignPageSection} from 'dst-react-core/campaign/campaign.types'

export const ContainerFileGiftSection: FC = () => {
    const dispatch = useDispatch()

    const campaign = useSelector(currentCampaignSelector)

    const gifts = useSelector(currentCampaignGiftsSelector)

    const isFileDownloadInPending = useSelector(isGiftFileDownloadActionInPending)

    const fileUrl = useSelector(campaignGiftFileSelector(campaign?.id))

    const content = useSelector(campaignSectionSelector(ECampaignPageSection.GIFT))?.content ?? null

    const preview = useCampaignImageWrapper(content?.giftPreviewImage?.id)

    useCurrentCampaign(campaign => {
        !isFileDownloadInPending && dispatch(giftFileDownloadBuilder(campaign.id))
    })

    const downloadFileHandler = useCallback(() => {
        (gifts?.[0].fileName && fileUrl) && downloadFile(fileUrl, gifts[0].fileName)
    }, [fileUrl, gifts])

    return (
        <FileGiftSection
            preview={preview}
            downloadFileHandler={downloadFileHandler}
        />
    )
}
import i18n from 'i18next'
import {Observable} from 'rxjs'
import {IAction} from '../../core.types'
import {ofType} from 'redux-observable'
import {reviewWritePostAction} from './review.actions'
import {mergeMap} from 'rxjs/operators'
import {getCampaignReviewSummaryBuilder} from '../review.utils'
import {triggerSuccess} from '../../components/toast/GenericSnackbar'

export const reviewWriteSuccessEpic = (action$: Observable<IAction>) => {
    return action$.pipe(
        ofType(reviewWritePostAction.successActionType),
        mergeMap((action: IAction) => {
            const campaignId = action.meta.meta.queryParameter.campaignId
            triggerSuccess(i18n.t('success.review_write'))
            return [getCampaignReviewSummaryBuilder(campaignId)]
        }),
    )
}
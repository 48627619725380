import i18n from 'i18next'
import {NEVER, Observable} from 'rxjs'
import {
    FAILURE,
    IFailureFetchAction,
} from '../fetch/fetch.types'
import {combineEpics} from 'redux-observable'
import {filter, switchMap} from 'rxjs/operators'
import {TYPE_FETCH_CONST} from '../fetch/fetch.action.factory'
import {triggerError} from '../components/toast/GenericSnackbar'
import {EStatusCodes} from './error.constants'
import {arrayBuffer2JSON} from '../fetch/fetch.utils'
import {ECLEXIA_KEY} from '../eclexia/eclexia.constans'

/**
 * @description
 * Epic that take all fetch.failure and dispatch a personalized from his response
 */
export const internalServerErrorHandlingEpic = (action$: Observable<IFailureFetchAction>) => {
    return action$.pipe(
        filter((action: IFailureFetchAction) => {
                return (
                    (action.type.includes(TYPE_FETCH_CONST) && action.type.includes(FAILURE)) &&
                    !(action.payload?.status === EStatusCodes.UNAUTHORIZED) &&   // Should be redirected to login page, no snack need
                    !(action.payload?.status === EStatusCodes.NOT_FOUND) && // Content not found, should be handled another way
                    !(action?.payload?.status === EStatusCodes.FORBIDDEN) && // Content is forbidden
                    !(action?.meta?.meta?.requestModel?.responseType === 'arraybuffer') && // Download actions contains buffer in place message so it should be handled in another way
                    !(action?.meta?.meta?.requestModel?.responseType === 'blob') && // Download actions contains buffer in place message so it should be handled in another way
                    !(action?.meta?.meta?.requestModel?.url === '/' && action.payload?.status === EStatusCodes.BAD_REQUEST)
                )
            },
        ),
        switchMap((failureAction: IFailureFetchAction) => {
            const isEclexiaError = (failureAction?.payload?.request?.url?.includes(ECLEXIA_KEY))
            const errorMessageCode = failureAction?.payload?.response?.error

            const eclexiaRequestErrorPayload = failureAction?.payload?.response
            const eclexiaRequestErrorMessage =
                (eclexiaRequestErrorPayload?.StatusCode && eclexiaRequestErrorPayload?.Message)
                    ? `${eclexiaRequestErrorPayload?.StatusCode}: ${eclexiaRequestErrorPayload?.Message}` :
                    i18n.t('error.eclexia_default_error')

            const errorForUser = isEclexiaError ? eclexiaRequestErrorMessage : i18n.t(`error.${errorMessageCode}`)

            triggerError(errorForUser, errorMessageCode)

            return NEVER
        }),
    )
}

/**
 * @description
 * Epic that take fetch.failure which response type is arraybuffer and dispatch a personalized from his response
 */
export const internalServerDownloadArrayBufferErrorHandlingEpic = (action$: Observable<IFailureFetchAction>) => {
    return action$.pipe(
        filter((action: IFailureFetchAction) =>
            action.type.includes(FAILURE) &&
            action.type.includes(TYPE_FETCH_CONST) &&
            action.meta.meta.requestModel.responseType === 'arraybuffer',
        ),
        switchMap((failureAction: IFailureFetchAction) => {
            console.log(failureAction)
            const error = arrayBuffer2JSON(failureAction.payload.response)
            const errorForUser = i18n.t(`error.${error.error}`)
            triggerError(errorForUser, error.error)
            return NEVER
        }),
    )
}

/**
 * @description
 * Epic that take fetch.failure which response type is blob and dispatch a personalized from his response
 */
export const internalServerDownloadBlobErrorHandlingEpic = (action$: Observable<IFailureFetchAction>) => {
    return action$.pipe(
        filter((action: IFailureFetchAction) =>
            action.type.includes(FAILURE) &&
            action.type.includes(TYPE_FETCH_CONST) &&
            action.meta.meta.requestModel.responseType === 'blob',
        ),
        switchMap((failureAction: IFailureFetchAction) => {
            console.log(failureAction)
            failureAction.payload.response?.text()
                .then((r: string) => {
                    const error = JSON.parse(r)
                    const errorForUser = i18n.t(`error.${error.error}`)
                    triggerError(errorForUser, error.error)
                })
            return NEVER
        }),
    )
}

const failureActions = combineEpics(
    internalServerErrorHandlingEpic,
    internalServerDownloadArrayBufferErrorHandlingEpic,
    internalServerDownloadBlobErrorHandlingEpic,
)

export default failureActions
import {IWebAppRootState} from '../../../redux/app.reducers'
import {
    ECampaignPageSection, EGiftType,
    ICampaign,
    ICampaignPage,
    ICampaignPageSection,
    IWebAppCurrentCampaign, IGift,
} from 'dst-react-core/campaign/campaign.types'
import {createSelector} from 'reselect'
import {IReview, IReviewSummary} from 'dst-react-core/review/review.types'
import {campaignAccessPageSelector} from 'deziro-web-app-components/redux/selectors'

export const currentCampaignStateSelector = (state: IWebAppRootState): IWebAppCurrentCampaign | null => state.campaign?.currentCampaign ?? null

export const campaignHasAccessSelector = createSelector(
    currentCampaignStateSelector,
    (currentCampaign: IWebAppCurrentCampaign | null): boolean | null => currentCampaign?.hasAccess ?? null,
)

export const campaignMainPageSelector = createSelector(
    currentCampaignStateSelector,
    (currentCampaign: IWebAppCurrentCampaign | null): (ICampaignPage | null) => currentCampaign?.mainPage ?? null,
)

export const campaignSectionSelector = (sectionName: ECampaignPageSection) => createSelector(
    campaignMainPageSelector,
    (mainPage: ICampaignPage | null): ICampaignPageSection | null => mainPage?.sections.find(section => section.name === sectionName) ?? null,
)

export const campaignAccessPageSectionSelector = createSelector(
    campaignAccessPageSelector,
    (accessPage: ICampaignPage | null): ICampaignPageSection | null => accessPage?.sections.find(section => section.name === ECampaignPageSection.ENTIRE_PAGE) ?? null,
)

export const currentCampaignSelector = createSelector(
    currentCampaignStateSelector,
    (currentCampaign: IWebAppCurrentCampaign | null): ICampaign | null => currentCampaign?.campaign ?? null,
)

export const currentCampaignIdSelector = createSelector(
    currentCampaignSelector,
    (campaign: ICampaign | null): number | null => campaign?.id ?? null,
)

export const currentCampaignTypeSelector = createSelector(
    currentCampaignSelector,
    (campaign: ICampaign | null): EGiftType | null => campaign?.giftType ?? null,
)

export const currentCampaignGiftsSelector = createSelector(
    currentCampaignStateSelector,
    (currentCampaign: IWebAppCurrentCampaign | null): IGift[] | null => currentCampaign?.gifts ?? null,
)

export const currentCampaignReviewSelector = createSelector(
    currentCampaignStateSelector,
    (currentCampaign: IWebAppCurrentCampaign | null): IReview | null | undefined => currentCampaign?.review,
)

export const currentCampaignReviewSummarySelector = createSelector(
    currentCampaignStateSelector,
    (currentCampaign: IWebAppCurrentCampaign | null): IReviewSummary | null => currentCampaign?.reviewSummary ?? null,
)

export const streamGiftSelector = createSelector(
    currentCampaignStateSelector,
    (campaign: IWebAppCurrentCampaign | null): IGift | null => campaign?.gifts ? (campaign?.gifts.length === 1 ? campaign?.gifts[0] : null) : null,
)

export const liveProgramGuidSelector = createSelector(
    currentCampaignStateSelector,
    (campaign: IWebAppCurrentCampaign | null): string | null => campaign?.liveProgramGuid ?? null,
)
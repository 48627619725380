import {IRouter} from '../../../dst-react-core/routing/routes.types'
import {CODE_ASSIGNMENT_PATH, LOGIN_PATH, REGISTRATION_PATH, RESET_PASSWORD_PATH} from '../../web-app.routes'
import {CAMPAIGN_AUTH_PATH} from '../campaign.routes'

export const CAMPAIGN_AUTH_LOGIN_PATH = `${CAMPAIGN_AUTH_PATH}${LOGIN_PATH}`
export const CAMPAIGN_AUTH_REGISTRATION_PATH = `${CAMPAIGN_AUTH_PATH}${REGISTRATION_PATH}`
export const CAMPAIGN_AUTH_ASSIGN_CODE_PATH = `${CAMPAIGN_AUTH_PATH}${CODE_ASSIGNMENT_PATH}`
export const CAMPAIGN_AUTH_PASSWORD_RESET_PATH = `${CAMPAIGN_AUTH_PATH}${RESET_PASSWORD_PATH}`

export const CAMPAIGN_AUTH_ROUTE = Object.freeze<IRouter>({
    path: CAMPAIGN_AUTH_PATH,
    description: 'Web app campaign auth route',
    subrouting: {
        login: {
            path: CAMPAIGN_AUTH_LOGIN_PATH,
            description: 'Web app campaign auth login route',
        },
        registration: {
            path: CAMPAIGN_AUTH_REGISTRATION_PATH,
            description: 'Web app campaign auth registration route',
        },
        code_assignment: {
            path: CAMPAIGN_AUTH_ASSIGN_CODE_PATH,
            description: 'Web app campaign auth code assignment route',
        },
        reset_password: {
            path: CAMPAIGN_AUTH_PASSWORD_RESET_PATH,
            description: 'Web app campaign auth reset reset-reset-password route',
        },
    },
})

export const CAMPAIGN_AUTH_SUBROUTING = CAMPAIGN_AUTH_ROUTE.subrouting
import * as React from 'react'
import {
    EBannerLine,
    EIconFilStyle,
    IBanner, ICampaignPageSectionContent,
} from 'dst-react-core/campaign/campaign.types'
import clsx from 'clsx'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import {SocialNetworkButton} from './components/SocialNetworkButton'
import {ISectionProps} from '../../models/wish-page-core.types'
import {FC} from 'react'
import {prepareBanners} from './sections.utils'

interface ICampaignCrossSellingSection extends ISectionProps {
    content: ICampaignPageSectionContent | null,
    campaignSectionClasses: any,
    crossSellingSectionClasses: any,
    ContainerBannerCell: FC<{banner: IBanner, preview?: boolean}>
}

export const CampaignCrossSellingSection = React.forwardRef<HTMLDivElement | null, ICampaignCrossSellingSection>((props, ref) => {
    const {
        campaignSectionClasses,
        content,
        crossSellingSectionClasses,
        ContainerBannerCell,
        preview
    } = props

    const banners = prepareBanners(content?.bannerDesigns)

    const socialNetworks = content?.socialNetworkLinks ?? []

    return (
        <div className={clsx(campaignSectionClasses.root, crossSellingSectionClasses.root)} ref={ref}>
            <Box className={clsx(campaignSectionClasses.content, campaignSectionClasses.crossSellingSection)}>
                <Box className="heading">
                    <Typography className="title" variant={'h4'}>
                        {content?.titleText}
                    </Typography>
                    <Typography className="subtitle" variant={'subtitle1'}>
                        {content?.subtitleText}
                    </Typography>
                </Box>
                <Box className="grid">
                    {Object.keys(banners).map((line) => banners[line as EBannerLine] &&
                        <Box className="_row" key={line}>
                            {banners[line as EBannerLine].map((banner: IBanner) => (
                                <ContainerBannerCell
                                    banner={banner}
                                    key={banner.id}
                                    preview={preview}
                                />
                            ))}
                        </Box>)}
                </Box>
                <Box className="socialBar">
                    {socialNetworks.map(sn =>
                        <SocialNetworkButton
                            type={content?.socialIconStyle ?? EIconFilStyle.FILL}
                            socialNetwork={sn}
                            className="link"
                            key={sn.id}
                        />,
                    )}
                </Box>
            </Box>
        </div>
    )
})

import * as React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { Theme } from '@material-ui/core'

interface IGenericAnnotationProps {
    heading: React.ReactNode
    hint: React.ReactNode
    sm?: boolean
}

const useAnnotationStyles = makeStyles<Theme, {sm: boolean}>(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: theme.spacing(4, 0, 0),
        textAlign: 'center',
        gap: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left!important',
        },
        '& > .title': {
            textAlign: ({sm}) => sm ? 'left' : 'center',
            color: 'rgba(255, 255, 255, .95)',
            fontWeight: 700,
            fontSize: 24,
            lineHeight: '32px',
            letterSpacing: .18,
        },
        '& > .subtitle': {
            textAlign: ({sm}) => sm ? 'left' : 'center',
            fontSize: 16,
            lineHeight: '24px',
            letterSpacing: .15,
            color: 'rgba(255, 255, 255, .5)',
        },
        '& > *': {
            width: '100%',
        },
    },
}))

const GenericAnnotation: React.FunctionComponent<IGenericAnnotationProps> = props => {

    const {
        heading,
        hint,
        sm,
    } = props

    const classes = useAnnotationStyles({sm: sm!})

    return (
        <Box className={classes.root}>
            <Typography
                className="title"
                variant={sm ? 'h4' : 'h2'}
            >{heading}</Typography>
            <Typography
                className="subtitle"
                variant={'caption'}
            >{hint}</Typography>
        </Box>
    )
}

export default GenericAnnotation

import {NEVER, Observable} from 'rxjs'
import {IAction} from '../../../dst-react-core/core.types'
import {ofType} from 'redux-observable'
import {campaignRequestAccessAction, campaignFetchAction, campaignPageDataAction} from './campaign.actions'
import {mergeMap} from 'rxjs/operators'
import {campaignCheckAccessActionBuilder, campaignLinkGenerator} from '../campaigns.utils'
import {authConfirmRegistrationAction, authRegistrationAction} from '../../components/auth/redux/auth.actions'
import {BrowserRouterHistory} from '../../../dst-react-core/routing/route'
import {CAMPAIGN_SUBROUTING} from '../campaign.routes'
import {WEB_APP_PATH} from '../../web-app.routes'
import {insertId} from '../../../dst-react-core/utils/shared.utils'

export const campaignAccessCodeSuccessEpic = (action$: Observable<IAction>) => {
    return action$.pipe(
        ofType(campaignRequestAccessAction.successActionType),
        mergeMap((action: IAction) => {
            const campaignId = action.meta.meta.queryParameter.campaignId
            return [campaignCheckAccessActionBuilder(campaignId)]
        }),
    )
}

export const campaignUserRegistrationSuccessEpic = (action$: Observable<IAction>) => {
    return action$.pipe(
        ofType(authRegistrationAction.successActionType),
        mergeMap(() => {
            const campaignUrl = campaignLinkGenerator()
            campaignUrl && BrowserRouterHistory.push(insertId(CAMPAIGN_SUBROUTING.auth.path, campaignUrl))
            return NEVER
        }),
    )
}

export const confirmCampaignRegistrationSuccessEpic = (action$: Observable<IAction>) => {
    return action$.pipe(
        ofType(authConfirmRegistrationAction.successActionType, authConfirmRegistrationAction.failureActionType),
        mergeMap(() => {
            const campaignUrl = campaignLinkGenerator()
            campaignUrl && BrowserRouterHistory.push(insertId(CAMPAIGN_SUBROUTING.auth.path, campaignUrl))
            return NEVER
        }),
    )
}

export const campaignFetchFailureEpic = (action$: Observable<IAction>) => {
    return action$.pipe(
        ofType(
            campaignFetchAction.failureActionType,
            campaignPageDataAction.failureActionType,
        ),
        mergeMap(() => {
            BrowserRouterHistory.push(WEB_APP_PATH)
            return NEVER
        }),
    )
}
import {useEffect} from 'react'
import {Redirect, Route, Switch, useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {IRouteId} from '../../dst-react-core/core.types'
import {CAMPAIGN_SUBROUTING} from './campaign.routes'
import {authIsLoggedInSelector} from '../components/auth/redux/auth.selectors'
import CampaignAuthRoute from './auth/CampaignAuthRoute'
import {
    campaignCheckAccessActionBuilder,
    getCampaignPageDataBuilder,
} from './campaigns.utils'
import {campaignHasAccessSelector} from './redux/current-campaign.selectors'
import {ECampaignPageName} from '../../dst-react-core/campaign/campaign.types'
import {clearCampaignData, clearCampaignPageData} from './redux/campaign.actions'
import {useCurrentCampaign} from './campaign.hooks'
import {insertId} from '../../dst-react-core/utils/shared.utils'
import WishPageRoute from './whish-page/WishPageRoute'
import {FC} from 'react'

export interface IAccessessLoadedProps {
    hasAccess: boolean | null,
    isLoggedIn: boolean
}

const CampaignRoute: FC = () => {
    const {id} = useParams<IRouteId>()

    const dispatch = useDispatch()

    const isLoggedIn = useSelector(authIsLoggedInSelector)

    const hasAccess = useSelector(campaignHasAccessSelector)

    useEffect(() => {
        return () => {
            dispatch(clearCampaignPageData(ECampaignPageName.ACCESS))
            dispatch(clearCampaignData())
        }
    }, [dispatch])

    useCurrentCampaign(campaign => {
        dispatch(getCampaignPageDataBuilder(campaign.id, ECampaignPageName.ACCESS))
    }, [dispatch])

    useCurrentCampaign(campaign => {
        if (isLoggedIn)
            dispatch(campaignCheckAccessActionBuilder(campaign.id))
    }, [isLoggedIn])

    if (isLoggedIn === null) {
        return null
    }

    return (
        <Switch>

            {/* ROUTE TO CAMPAIGN AUTH */}
            <Route path={CAMPAIGN_SUBROUTING.auth.path}>
                <CampaignAuthRoute hasAccess={hasAccess} isLoggedIn={isLoggedIn!}/>
            </Route>

            {/* ROUTE TO CAMPAIGN WISH PAGE*/}
            <Route path={CAMPAIGN_SUBROUTING.wish_page.path}>
                <WishPageRoute hasAccess={hasAccess} isLoggedIn={isLoggedIn!}/>
            </Route>

            {/* DEFAULT */}
            <Route>
                <Redirect to={insertId(CAMPAIGN_SUBROUTING.auth.path, id)}/>
            </Route>

        </Switch>
    )
}

export default CampaignRoute

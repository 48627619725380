export const ECLEXIA_KEY = 'eclexia'
export const ECLEXIA_PLAYER_URL = process.env.REACT_APP_ECLEXIA_PLAYER_URL
export const ECLEXIA_LOGIN_URL = process.env.REACT_APP_ECLEXIA_LOGIN_SERVER_URL
export const ECLEXIA_API_URL = process.env.REACT_APP_ECLEXIA_API_BASE_URL

export const ECLEXIA_ACCESS_TOKEN = 'Eclexia-Access-Token'
export const ECLEXIA_ACCESS_TOKEN_EXPIRATION_DATE = 'Eclexia-Access-Token-Expiration-Date'

export const ECLEXIA_CREDENTIALS = {
    grant_type: process.env.REACT_APP_ECLEXIA_LOGIN_GRANT_TYPE,
    scope: process.env.REACT_APP_ECLEXIA_LOGIN_SCOPE,
    client_id: process.env.REACT_APP_ECLEXIA_LOGIN_CLIENT_ID,
    client_secret: process.env.REACT_APP_ECLEXIA_LOGIN_CLIENT_SECRET,
}

export const ECLEXIA_OWNER = {
    platform_id: process.env.REACT_APP_ECLEXIA_LOGIN_PLATFORM_ID,
    account_id: process.env.REACT_APP_ECLEXIA_LOGIN_ACCOUNT_ID,
    owner_email: process.env.REACT_APP_ECLEXIA_LOGIN_EMAIL,
    source_type: process.env.REACT_APP_ECLEXIA_LOGIN_SOURCE_TYPE,
    is_global: process.env.REACT_APP_ECLEXIA_LOGIN_IS_GLOBAL,
}

export const ECLEXIA_ENCODING_PARAMETER = {
    watermark_id: 0,
    pixel_aspect_ratio: 'auto',
    deinterlacing: false,
    group_id: 0,
    cut: false,
    delivery_type_id: 0,
    indexer: {
        do_indexer: false,
    },
}

export const MP4_240P = 'mp4-240p'
export const MP4_360P = 'mp4-360p'
export const MP4_480P = 'mp4-480p'
export const MP4_720P = 'mp4-720p'
export const MP3 = 'mp3'
export const AAC = 'aac'

export const PLATFORMS_ENDPOINT = '/platforms'
export const VIDEOS_ENDPOINT = '/videos'
export const LIVE_CHANNELS_ENDPOINT = '/liveChannels'
export const LIVE_PROGRAM_ENDPOINT = '/livePrograms'
export const IMAGES_ENDPOINT = '/images'
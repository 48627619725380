import {FC, useMemo} from 'react'
import {GeneralHeaderSideMenuFilter} from './GeneralHeaderSideMenuFilter'
import {useGeneralHeaderSideMenuFilter} from './side-menu-filter.styles'
import {useTranslation} from 'react-i18next'
import {ECampaignStatus, EGiftType} from '../../../../../../../dst-react-core/campaign/campaign.types'
import {useGiftTypeOptions} from '../../../../homepage/hooks/gift-type-options.hook'
import {IOption} from '../../../../../../../dst-react-core/core.types'

export interface IContainerGeneralHeaderSideMenuFilterProps {
    setFilterByNewestCallback: (isByNewest: boolean | undefined) => void,
    setFilterByGiftTypeCallback: (giftType: EGiftType | '') => void,
    isFilterByNewest: boolean | undefined,
    filterByGiftType: EGiftType | '',
    filterStatus: ECampaignStatus | '',
    setFilterStatusCallback: (status: ECampaignStatus | '') => void,
}

export const ContainerGeneralHeaderSideMenuFilter: FC<IContainerGeneralHeaderSideMenuFilterProps> = props => {
    const sideMenuClasses = useGeneralHeaderSideMenuFilter()

    const {t} = useTranslation()

    const creationDateLabel = t('general_dashboard.filter.creation_date')
    const filterPerLabel = t('general_dashboard.filter.per')
    const categoryLabel = t('general_dashboard.filter.category')
    const statusLabel = t('general_dashboard.filter.status')

    const statusOptions: IOption[] = useMemo(
        () => [
            {
                label: t('general_dashboard.filter.statuses.on_going'),
                value: ECampaignStatus.PUBLISHED
            },
            {
                label: t('general_dashboard.filter.statuses.terminate'),
                value: ECampaignStatus.CLOSED
            },
        ],
        [t],
    )

    const creationDateOptions: IOption[] = useMemo(
        () => [
            {
                label: t('general_dashboard.filter.categories.most_recent'),
                value: 'true'
            },
            {
                label: t('general_dashboard.filter.categories.less_recent'),
                value: 'false'
            },
        ],
        [t],
    )

    const giftTypeOptions: IOption[] = useGiftTypeOptions()

    return (
        <GeneralHeaderSideMenuFilter
            {...props}
            sideMenuClasses={sideMenuClasses}
            creationDateLabel={creationDateLabel}
            filterPerLabel={filterPerLabel}
            categoryLabel={categoryLabel}
            statusLabel={statusLabel}
            creationDateOptions={creationDateOptions}
            statusOptions={statusOptions}
            giftTypeOptions={giftTypeOptions}
        />
    )
}
import {FC} from 'react'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

interface IGenericToastContainerProps {
}

const GenericToastContainerFC: FC<IGenericToastContainerProps> = () => (
    <ToastContainer style={{width: '100%'}} />
)

export const GenericToastContainer = GenericToastContainerFC
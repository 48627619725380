import {useTranslation} from 'react-i18next'
import ResetPasswordPasswordForm from './components/ResetPasswordPasswordForm'
import ResetPasswordAnnotation from '../components/ResetPasswordAnnotation'

const ResetPasswordPassword: React.FunctionComponent = () => {
    const {t} = useTranslation()

    return (
        <>
            <ResetPasswordAnnotation hint={t('auth.reset_password.password.hint')} />
            <ResetPasswordPasswordForm />
        </>
    )
}

export default ResetPasswordPassword

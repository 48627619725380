import {fetchActionGenerator} from '../../fetch/fetch.utils'
import {GIFTS_GET_API} from './gift.api.constants'
import {combineEpics} from 'redux-observable'

export const [giftsFetchAction, giftsFetchBuilder, giftsFetchEpic] = fetchActionGenerator('GIFTS_FETCH', GIFTS_GET_API)

const giftActions = combineEpics(
    giftsFetchEpic
)

export default giftActions
import * as React from 'react'
import {useTranslation} from 'react-i18next'
import Box from '@material-ui/core/Box'
import clsx from 'clsx'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import {ICampaignPageSectionContent} from 'dst-react-core/campaign/campaign.types'
import {scrollToElement} from 'dst-react-core/utils/shared.utils'
import {analyticClickDecorator} from 'dst-react-core/google-analytics/gmt.init'
import {ISectionProps} from '../../models/wish-page-core.types'

interface ICampaignHeaderSectionProps extends ISectionProps {
    campaignSectionClasses: any,
    headerSectionClasses: any,
    content: ICampaignPageSectionContent | null
}

export const CampaignHeaderSection = React.forwardRef<HTMLDivElement | null, ICampaignHeaderSectionProps>((props, ref) => {

    const {
        anchors,
        campaignSectionClasses,
        headerSectionClasses,
        content
    } = props

    const {t} = useTranslation()

    return (
        <div className={clsx(campaignSectionClasses.root, headerSectionClasses.root)} ref={ref}>
            <Box className={clsx(campaignSectionClasses.content, campaignSectionClasses.headerSection)}>
                <Box className="side">
                    <Typography className="title" variant={'h2'}>
                        {content?.titleText}
                    </Typography>
                    <Typography className="subtitle" variant={'h5'}>
                        {content?.subtitleText}
                    </Typography>
                    <Typography className="paragraph" variant={'subtitle1'}>
                        {content?.paragraphText}
                    </Typography>
                    <Box className={campaignSectionClasses.buttonRow}>
                        <Button
                            variant={'contained'}
                            onClick={analyticClickDecorator('cta-hero-click', () => scrollToElement(anchors.wish.current as Element))}
                        >{t('section.header.experience_begins')}</Button>
                    </Box>
                </Box>
            </Box>
        </div>
    )
})

import {useSelector} from 'react-redux'
import {useCampaignImageWrapper} from '../campaign.hooks'
import {campaignMainPageSelector} from '../redux/current-campaign.selectors'
import {
    useCampaignFooterStyles,
    useCampaignHeaderStyles,
    useWishPageStyle,
} from 'deziro-web-app-components/shared/styles/wish-page.styles'

export const useCampaignHeaderStylesWrapper = (bg?: string | null, text?: string | null, separator?: string | null) => {

    const values = useSelector(campaignMainPageSelector)

    const logo = useCampaignImageWrapper(values?.global.logoImage?.id ?? null)

    return useCampaignHeaderStyles({
        values,
        logo,
        bg,
        text,
        separator,
    })
}

export const useWishPageStyleWrapper = () => {
    const values = useSelector(campaignMainPageSelector)

    return useWishPageStyle({
        values
    })
}

export const useCampaignFooterStylesWrapper = () => {
    const values = useSelector(campaignMainPageSelector)

    return useCampaignFooterStyles({
        values
    })
}
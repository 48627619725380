import {useDispatch, useSelector} from 'react-redux'
import {downloadImageActionBuilder} from 'dst-react-core/image/image.utils'
import {useEffect, useState} from 'react'

export type ImageSelectorCreator = (id: number) => (state: unknown) => string

export const useCampaignImage = (id: number | null | undefined, fetchIfAbsent: boolean = true, campaignImageSelector: ImageSelectorCreator) => {
    const _image = useSelector(campaignImageSelector(id as number))

    const [image, setImage] = useState<string | null>(null)

    const dispatch = useDispatch()

    useEffect(() => {
        if (!_image && id && fetchIfAbsent)
            dispatch(downloadImageActionBuilder(id))
        setImage(_image)
    }, [_image, dispatch, id, fetchIfAbsent])

    return image
}
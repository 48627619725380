import {combineEpics} from 'redux-observable'
import {IActionFactoryReturn} from '../../fetch/fetch.types'
import {fetchActionFactory} from '../../fetch/fetch.action.factory'
import {switchMapFetchEpics} from '../../fetch/fetch.call'
import {isActionInPending} from '../../spinner/redux/spinner.reducers'
import {myPersonFetchAction} from '../../person/redux/person.actions'
import {MY_PRINCIPAL_FETCH_API, PRINCIPAL_UPDATE_API} from './principal.api.constants'

export const MY_PRINCIPAL_FETCH_ACTION = 'MY_PRINCIPAL_FETCH_ACTION'
export const MY_PRINCIPAL_FETCH_ACTION_REQUEST_ID = 'MY_PRINCIPAL_FETCH_ACTION_REQUEST_ID'
export const myPrincipalFetchAction: IActionFactoryReturn = fetchActionFactory(MY_PRINCIPAL_FETCH_API, MY_PRINCIPAL_FETCH_ACTION)
export const isMyPrincipalFetchActionInPending = isActionInPending(myPrincipalFetchAction.pendingActionTypeWithSpinner, MY_PRINCIPAL_FETCH_ACTION_REQUEST_ID)

export const PRINCIPAL_UPDATE_ACTION = 'PRINCIPAL_UPDATE_ACTION'
export const PRINCIPAL_UPDATE_ACTION_REQUEST_ID = 'PRINCIPAL_UPDATE_ACTION_REQUEST_ID'
export const principalUpdateAction: IActionFactoryReturn = fetchActionFactory(PRINCIPAL_UPDATE_API, PRINCIPAL_UPDATE_ACTION)
export const isPrincipalUpdateActionInPending = isActionInPending(principalUpdateAction.pendingActionTypeWithSpinner, PRINCIPAL_UPDATE_ACTION_REQUEST_ID)

/**
 * @description Action to call and notify (to redux) the users has logged in
 */
export const BACK_OFFICE_USER_LOGGED_IN = 'BACK_OFFICE_USER_LOGGED_IN'
export const backOfficeUserLoggedInAction = () => ({
    type: BACK_OFFICE_USER_LOGGED_IN,
})

/**
 * @description Action to call and notify (to redux) the users has logged out
 */
export const BACK_OFFICE_USER_LOGGED_OUT = 'BACK_OFFICE_USER_LOGGED_OUT'
export const backOfficeUserLoggedOUTAction = () => ({
    type: BACK_OFFICE_USER_LOGGED_OUT,
})

/**
 * @description Action to call and notify (to redux) the user wants to logout and reset reset-reset-password
 */
export const BACK_OFFICE_REQUEST_PASSWORD_RESET = 'BACK_OFFICE_REQUEST_PASSWORD_RESET'
export const backOfficeRequestPasswordResetAction = () => ({
    type: BACK_OFFICE_REQUEST_PASSWORD_RESET,
})

export const BACK_OFFICE_SAVE_X_AUTH_TOKEN = 'BACK_OFFICE_SAVE_X_AUTH_TOKEN'
export const backOfficeSaveXAuthToken = (payload: string) => ({
    type: BACK_OFFICE_SAVE_X_AUTH_TOKEN,
    payload,
})

export const KEEP_USER_CONNECTED_ACTION = 'KEEP_USER_CONNECTED_ACTION'
export const keepUserConnectedAction = () => ({
    type: KEEP_USER_CONNECTED_ACTION,
})

/**
 * @description Action for saving on redux the authorization token.
 * check also into principal.pic.ts there is an epic to get token local storage, and save it on redux
 * If the token is present we call backOfficeUserLoggedINAction
 */
export const CHECK_AUTH_TOKEN_START_UP = 'CHECK_AUTH_TOKEN_START_UP'
export const check_auth_token_start_up = () => ({
    type: CHECK_AUTH_TOKEN_START_UP,
})

const principalActions = combineEpics(
    switchMapFetchEpics(myPrincipalFetchAction.pendingActionTypeWithSpinner),
    switchMapFetchEpics(myPersonFetchAction.pendingActionTypeWithSpinner),
    switchMapFetchEpics(principalUpdateAction.pendingActionTypeWithSpinner),
)

export default principalActions
import {combineReducers} from 'redux'
import {IRootState} from '../dst-react-core/core.types'
import {currentDialogReducer} from '../shared/dialog-provider/redux/dialogs.reducers'
import {ICurrentDialog} from '../shared/dialog-provider/dialogs.types'
import {spinnersReducer} from '../dst-react-core/spinner/redux/spinner.reducers'
import {IAuthState} from '../web-app/components/auth/auth.types'
import {authReducer} from '../web-app/components/auth/redux/auth.reducers'
import {IWebAppCampaignState} from '../dst-react-core/campaign/campaign.types'
import {campaignReducer} from '../web-app/campaign/redux/campaign.reducers'
import {IImagesState} from '../dst-react-core/image/image.types'
import {imageReducer} from '../dst-react-core/image/redux/image.reducer'
import {eclexiaVideoReducer} from '../dst-react-core/eclexia/redux/eclexiaVideo.reducer'
import {IEclexiaVideosState} from '../dst-react-core/eclexia/eclexia.types'
import {IGiftFilesState} from '../dst-react-core/gift-file/gift-file.types'
import {giftFileReducer} from '../dst-react-core/gift-file/redux/gift-file.reducer'
import {IVouchersState} from '../dst-react-core/voucher/voucher.types'
import {voucherReducer} from '../dst-react-core/voucher/redux/voucher.reducer'
import {HomepageState} from '../web-app/general/dashboard/homepage/homepage.types'
import {homepageReducer} from '../web-app/general/dashboard/homepage/redux/homepage.reducer'

export interface IWebAppRootState extends IRootState {
    currentDialog: ICurrentDialog | null
    auth: IAuthState | null
    campaign: IWebAppCampaignState | null
    images: IImagesState | null
    eclexiaVideos: IEclexiaVideosState | null
    giftFiles: IGiftFilesState | null
    vouchers: IVouchersState | null,
    homepage: HomepageState
}

export const appReducer = combineReducers<IWebAppRootState>({
    spinners: spinnersReducer,
    currentDialog: currentDialogReducer,
    auth: authReducer,
    campaign: campaignReducer,
    images: imageReducer,
    eclexiaVideos: eclexiaVideoReducer,
    giftFiles: giftFileReducer,
    vouchers: voucherReducer,
    homepage: homepageReducer
})

export const imageSelector = (state: IWebAppRootState): IImagesState | null => state.images ?? null
export const allVideosSelector = (state: IWebAppRootState): IEclexiaVideosState | null => state.eclexiaVideos ?? null
export const allVouchersSelector = (state: IWebAppRootState): IVouchersState | null => state.vouchers ?? null

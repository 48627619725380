import {FC} from 'react'
import {ChangePasswordForm} from './ChangePasswordForm'
import {useProfileSubPageFormStyles} from '../../../profile-sub-page-form.styles'
import {useDefaultFormik} from '../../../../../../../dst-react-core/formik/default-formik.hook'
import {ChangePasswordFormField, IChangePasswordFormValues} from './change-password-form.types'
import {useSchemaValidation} from '../../../../../../../dst-react-core/formik/schema-validation.hook'
import {getYupRequiredConfirmPassword, getYupRequiredString} from '../../../../../../../shared/formik/validation.yup'
import {useTranslation} from 'react-i18next'
import {useDispatch} from 'react-redux'
import {changePasswordBuilder} from '../../../profile.utils'

export interface IContainerChangePasswordFormProps {

}

export const ContainerChangePasswordForm: FC<IContainerChangePasswordFormProps> = props => {
    const profileSubPageFormClasses = useProfileSubPageFormStyles()
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const Schema = useSchemaValidation<ChangePasswordFormField>({
        oldPassword: getYupRequiredString(t),
        newPassword: getYupRequiredString(t),
        newPasswordCheck: getYupRequiredConfirmPassword(t),
    })

    const changePassword = (currentPassword: string, newPassword: string) => {
        dispatch(changePasswordBuilder(currentPassword, newPassword))
    }
    const currentPasswordLabel = t('general_dashboard.old_password')
    const newPasswordLabel = t('general_dashboard.new_password')
    const confirmPasswordLabel = t('general_dashboard.check_password')
    const setPasswordLabel = t('general_dashboard.set_new_password')

    const formik = useDefaultFormik<IChangePasswordFormValues>(
        {
            oldPassword: '',
            newPassword: '',
            newPasswordCheck: '',
        },
        values => {
            changePassword(values.oldPassword, values.newPasswordCheck)
        },
        Schema,
    )

    return (
        <ChangePasswordForm
            {...props}
            profileSubPageFormClasses={profileSubPageFormClasses}
            formik={formik}
            currentPasswordLabel={currentPasswordLabel}
            newPasswordLabel={newPasswordLabel}
            confirmPasswordLabel={confirmPasswordLabel}
            setPasswordLabel={setPasswordLabel}
        />
    )
}
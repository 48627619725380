import React, {FC, memo} from 'react'
import {IProfileNavigationMenuProps} from '../ProfileNavigationMenu'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import Button from '@material-ui/core/Button'
import {IProfileNavigationConfig} from '../hooks/profile-navigation.hook'
import clsx from 'clsx'
import {ProfileNavigationMobileTextContent} from './ProfileNavigationMobileTextContent'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

interface IProfileNavigationMobileMenuProps extends IProfileNavigationMenuProps {
    anchorMobileMenuEl: HTMLElement | null
    handleMobileMenuClick: (event: React.MouseEvent<HTMLButtonElement>) => void,
    handleMobileMenuClose: () => void,
    selectedMobileItem: IProfileNavigationConfig,
    setSelectedMobileItemCallback: (menuItem: IProfileNavigationConfig) => void
}

export const ProfileNavigationMobileMenu: FC<IProfileNavigationMobileMenuProps> = memo(props => {
    const {
        anchorMobileMenuEl,
        handleMobileMenuClick,
        handleMobileMenuClose,
        menuItems,
        goToPage,
        selectedMobileItem,
        setSelectedMobileItemCallback,
        navigationClasses,
        getIsMenuItemActive,
    } = props

    return (
        <div className={navigationClasses.mobileMenuWrap}>
            <Button
                fullWidth
                className={'menuButton'}
                aria-controls="profile-navigation-menu"
                aria-haspopup="true"
                onClick={handleMobileMenuClick}
            >
                <ProfileNavigationMobileTextContent
                    navigationClasses={navigationClasses}
                    item={selectedMobileItem}
                />
                <ArrowDropDownIcon />
            </Button>
            <Menu
                className={navigationClasses.mobileMenu}
                id="profile-navigation-menu"
                anchorEl={anchorMobileMenuEl}
                keepMounted
                open={Boolean(anchorMobileMenuEl)}
                onClose={handleMobileMenuClose}
            >
                {
                    menuItems.map(item => (
                        <MenuItem
                            className={clsx('menuItem', {menuItemActive: getIsMenuItemActive(item.link)})}
                            key={item.link + Math.random()}
                            onClick={
                                () => {
                                    setSelectedMobileItemCallback(item)
                                    goToPage(item.link)
                                    handleMobileMenuClose()
                                }
                            }
                        >
                            <ProfileNavigationMobileTextContent
                                navigationClasses={navigationClasses}
                                item={item}
                            />
                        </MenuItem>
                    ))
                }
            </Menu>
        </div>
    )
})
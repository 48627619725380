import {I18nT} from '../core.types'
import * as Yup from 'yup'
import {YupSchema} from './schema-validation.hook'

export interface IYupPrimitiveFieldSchemeBuilderOptions {
    isString: boolean,
    min: number,
    max: number,
    isRequired: boolean,
    requiredI18nKey?: string
}

export const yupPrimitiveFieldSchemeBuilder = (t: I18nT, options: IYupPrimitiveFieldSchemeBuilderOptions): YupSchema => {
    const primitiveSchema = (options.isString ? Yup.string() : Yup.number())
        .min(options.min, t('validation.min_value'))
        .max(options.max, t('validation.max_value'))

    return (
        options.isRequired ?
            primitiveSchema.required(t(options.requiredI18nKey || 'validation.required_field')) :
            primitiveSchema.optional()
    )
}

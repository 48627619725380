import TagManager from 'react-gtm-module'
import {MouseEventHandler} from 'react'

declare global {
    interface Window {
        dataLayer: Record<string, unknown>[]
    }
}

const tagManagerArgs = {
    gtmId: process.env.REACT_APP_ANALYTICS_GTM_ID as string,
}
TagManager.initialize(tagManagerArgs)

export const pushAnalytics = (event: string, eventProps?: object) => {
    window.dataLayer.push({event, ...eventProps})
}

export const analyticClick = (clickTarget: string, meta: object = {}) => {
    pushAnalytics('click', {clickTarget, meta})
}

export const analyticClickDecorator = (clickTarget: string, callback?: MouseEventHandler, meta?: object): MouseEventHandler => (e) => {
    analyticClick(clickTarget, meta)
    // decorator may have no callback function, just for test
    callback && callback(e)
}
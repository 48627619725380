import { IRouteId } from 'dst-react-core/core.types'
import { useParams } from 'react-router-dom'
import { CAMPAIGN_AUTH_SUBROUTING } from '../campaign-auth.routes'
import CampaignLogin from 'deziro-web-app-components/components/login/CampaignLogin'
import { authorizeUser, registerUser } from 'web-app/components/auth/auth.utils'
import { useSelector } from 'react-redux'
import { isAuthLoginActionInPending, isRegistrationActionInPending } from 'web-app/components/auth/redux/auth.actions'
import { isLoginAttemptFailedSelector } from 'web-app/components/auth/redux/auth.selectors'
import {
    vBoolean,
    vEmail,
    vEnum,
    vMonth,
    vMonthDay,
    vSchema,
    vString,
    vTrue,
    vYear,
} from 'shared/formik/validation.yup'
import { CAMPAIGN_AUTH_LOGIN_PATH } from 'web-app/campaign/auth/campaign-auth.routes'
import {FC, useMemo} from 'react'
import { currentCampaignSelector } from 'web-app/campaign/redux/current-campaign.selectors'

export interface ICampaignLoginContainer {
    registrationStep?: boolean
}

export const CampaignLoginContainer: FC<ICampaignLoginContainer> = ({registrationStep}) => {
    const { id } = useParams<IRouteId>()

    const isLoginInPending = useSelector(isAuthLoginActionInPending)

    const isLoginAttemptFailed = useSelector(isLoginAttemptFailedSelector)

    const isRegistrationInPending = useSelector(isRegistrationActionInPending) as boolean

    const campaign = useSelector(currentCampaignSelector) as any


    const verificationSchemas = useMemo(
        () => ({
            vSchema,
            vEmail,
            vString,
            vBoolean,
            vEnum,
            vMonth,
            vMonthDay,
            vTrue,
            vYear
        }),
        []
    )

    return (
        <CampaignLogin
            classes=''
            paths={CAMPAIGN_AUTH_SUBROUTING}
            id={id}
            authorizeUser={authorizeUser}
            isLoginInPending={isLoginInPending}
            isLoginAttemptFailed={isLoginAttemptFailed}
            verificationSchemas={verificationSchemas}
            loginPath={CAMPAIGN_AUTH_LOGIN_PATH}
            campaign={campaign}
            isRegistrationInPending={isRegistrationInPending}
            registerUser={registerUser}
            registrationStep={registrationStep}
        />
    )
}

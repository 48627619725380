import makeStyles from '@material-ui/core/styles/makeStyles'
import {flex, resetUl} from '../../../../../../../../dst-react-core/utils/styles.utils'

export const useSideMenuFilterSelectStyles = makeStyles(theme => ({
    accordion: {
        padding: 0,
        boxShadow: 'none',
        '& .summary': {
            padding: 0,
            justifyContent: 'flex-start',
            '& .title': {
                fontWeight: 500,
                fontSize: 14,
                color: theme.palette.text.primary,
            },
            '& .MuiAccordionSummary-content': {
                flexGrow: 0,
            },
            '& .icon': {
                color: theme.palette.text.secondary,
            },
        },
        '& .options': {
            ...resetUl(),
            ...flex(),
            flexDirection: 'column',
            width: '100%',
            '& li': {
                color: theme.palette.text.secondary,
                padding: '5px 0',
                width: '100%',
                fontSize: 14,
                fontWeight: 500,
                cursor: 'pointer',
            },
            '& .selected': {
                color: theme.palette.primary.main,
            },
        },
        '& .MuiAccordionDetails-root': {
            padding: 0,
        },
    },
}))
import {ChangeEvent, FC, useCallback, useEffect, useMemo, useState} from 'react'
import {Header} from './Header'
import {useGeneralHeaderStyles} from './header.styles'
import {ECampaignStatus, EGiftType} from '../../../../../dst-react-core/campaign/campaign.types'
import {useGetMyCampaigns} from '../../homepage/hooks/get-my-campaign.hook'
import {useScreen} from '../../../../../dst-react-core/core.hooks'
import {useLocation} from 'react-router'
import {HOMEPAGE_PATH} from '../../general-dashboard-routes'

export const ContainerHeader: FC = () => {
    const headerClasses = useGeneralHeaderStyles()

    const location = useLocation()

    const [isSearchMode, setSearchMode] = useState(false)
    const [isFilterMode, setFilterMode] = useState(false)

    const [searchValue, setSearchValue] = useState<string>('')
    const [filterStatus, setFilterStatus] = useState<ECampaignStatus | ''>('')
    const [isFilterByNewest, setFilterByNewest] = useState<boolean | undefined>(undefined)
    const [filterByGiftType, setFilterByGiftType] = useState<EGiftType | ''>('')

    const [isSideMenuOpen, setOpenSideMenu] = useState(false)

    const [currentFilterTabIndex, setCurrentFilterTabIndex] = useState(1)

    const {getIsCurrentScreenLessOrEqual} = useScreen()
    const isSideMenu = getIsCurrentScreenLessOrEqual(1060)

    const handleFilterTabChange = (event: ChangeEvent<{}>, newTabIndex: number) => {
        setCurrentFilterTabIndex(newTabIndex)
    }

    const areCampaignsInSearch = useMemo(
        () => isSearchMode || isFilterMode,
        [isSearchMode, isFilterMode],
    )

    const setSearchModeCallback = useCallback(
        (isSearchMode: boolean) => {
            setSearchMode(isSearchMode)
        },
        [setSearchMode],
    )

    const setFilterModeCallback = useCallback(
        (isFilterMode: boolean) => {
            setFilterMode(isFilterMode)
        },
        [setFilterMode],
    )

    const setSearchValueCallback = useCallback(
        (value: string) => {
            setSearchValue(value)
        },
        [setSearchValue],
    )

    const setFilterStatusCallback = useCallback(
        (status: ECampaignStatus | '') => {
            setFilterStatus(status)
        },
        [setFilterStatus],
    )

    const setFilterByNewestCallback = useCallback(
        (isByNewest: boolean | undefined) => {
            setFilterByNewest(isByNewest)
        },
        [setFilterByNewest],
    )

    const setFilterByGiftTypeCallback = useCallback(
        (giftType: EGiftType | '') => {
            setFilterByGiftType(giftType)
        },
        [setFilterByGiftType],
    )

    const getMyCampaignsCallback = useGetMyCampaigns()

    const toggleSideMenu = useCallback(
        (open: boolean) => {
            setOpenSideMenu(open)
        },
        [setOpenSideMenu],
    )

    const applyFilter = useCallback(
        (keyword?: string) => {
            getMyCampaignsCallback({
                statuses: [filterStatus],
                giftTypes: [filterByGiftType],
                sortDirection: (typeof isFilterByNewest === 'boolean') ? (isFilterByNewest ? 'ASC' : 'DESC') : undefined,
                name: keyword || searchValue,
            })
        },
        /** Ignore searchValue in deps cause we do not need to make requests on search value change  */
        // eslint-disable-next-line
        [getMyCampaignsCallback, filterStatus, filterByGiftType, isFilterByNewest],
    )

    useEffect(
        () => {
            /** Apply filters on OnChange only if mobile view */
            if (isSideMenu) {
                applyFilter()
            }
        },
        [applyFilter, isSideMenu],
    )

    const areFiltersAbleToBeOpened = useMemo(
        () => {
            return location.pathname.includes(HOMEPAGE_PATH)
        },
        [location],
    )

    return (
        <Header
            headerClasses={headerClasses}
            isSearchMode={isSearchMode}
            setSearchModeCallback={setSearchModeCallback}
            isFilterMode={isFilterMode}
            setFilterModeCallback={setFilterModeCallback}
            setSearchValueCallback={setSearchValueCallback}
            searchValue={searchValue}
            areCampaignsInSearch={areCampaignsInSearch}
            currentFilterTabIndex={currentFilterTabIndex}
            handleFilterTabChange={handleFilterTabChange}
            filterStatus={filterStatus}
            isFilterByNewest={isFilterByNewest}
            filterByGiftType={filterByGiftType}
            setFilterStatusCallback={setFilterStatusCallback}
            setFilterByNewestCallback={setFilterByNewestCallback}
            setFilterByGiftTypeCallback={setFilterByGiftTypeCallback}
            applyFilter={applyFilter}
            isSideMenu={isSideMenu}
            toggleSideMenu={toggleSideMenu}
            isSideMenuOpen={isSideMenuOpen}
            areFiltersAbleToBeOpened={areFiltersAbleToBeOpened}
        />
    )
}
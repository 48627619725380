import makeStyles from '@material-ui/core/styles/makeStyles'
import {flex} from 'dst-react-core/utils/styles.utils'
import {CSSDependsOnBrowserFlexGapSupporting} from 'dst-react-core/utils/css/supports-flex-gap.util'

export const useAccessedCampaignListStyles = makeStyles((theme) => {
    return ({
        listWrap: {
            padding: '160px 0',
            width: '100%',
            ...flex(false, false, 0, 'initial'),
            flexWrap: 'wrap',
            ...CSSDependsOnBrowserFlexGapSupporting(
                {
                    gap: '50px',
                },
            ),
            '& .listItem': {
                ...flex(true, true),
                width: '30%',
                [theme.breakpoints.down('md')]: {
                    width: '45%',
                },
                [theme.breakpoints.down('sm')]: {
                    width: '100%',
                },
                ...CSSDependsOnBrowserFlexGapSupporting(
                    {},
                    {
                        marginLeft: 30,
                        marginTop: 30
                    },
                ),
            },
            [theme.breakpoints.down('md')]: {
                padding: '100px 0',
            },
            [theme.breakpoints.down('sm')]: {
                padding: '60px 0',
            },
        },
        noItemsTitle: {
            width: '100%',
            display: 'block',
            textAlign: 'center',
            fontSize: 20,
            color: theme.palette.text.secondary,
            padding: '20px 0',
        },
    })
})
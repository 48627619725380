import * as React from 'react'
import {FC, memo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {Theme} from '@material-ui/core'
import Box from '@material-ui/core/Box'
import clsx from 'clsx'
import AppBar from '@material-ui/core/AppBar'
import IconButton from '@material-ui/core/IconButton'
import {WishPageHeaderUserActions} from './components/WishPageHeaderUserActions'
import {WishPageHeaderRateActions} from './components/WishPageHeaderRateActions'
import {WishPageHeaderNavigation} from './components/WIshPageHeaderNavigation'
import {WishPageHeaderLogo} from './components/WishPageHeaderLogo'
import StarRoundedIcon from '@material-ui/icons/StarRounded'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import MenuIcon from '@material-ui/icons/Menu'
import {IPerson} from 'dst-react-core/person/person.types'
import {IReview} from 'dst-react-core/review/review.types'
import {WishPageHeaderDrawer} from './WishPageHeaderDrawer'
import {ISectionProps} from '../../models/wish-page-core.types'
import {useHeaderStyles} from '../../shared/styles/header.styles'
import {MenuButton} from '../../shared/components/menu-button/MenuButton'
import {useWishPageHeaderDropdownMenu} from '../hooks/wish-page-header-dropdown-menu.hook'
import Avatar from '@material-ui/core/Avatar'
import {WishPageHeaderWishButton} from './components/WIshPageHeaderWishButton'

interface IWishPageHeaderProps extends ISectionProps {
    campaignHeaderClasses: any
    person: IPerson | null,
    review: IReview | null | undefined,
    valuateCampaign: (value: number) => void,
    handleLogout: () => any,
    profilePath: string,
    dashboardPath: string
}

export const WishPageHeader: FC<IWishPageHeaderProps> = memo((props) => {
    const {
        anchors,
        person,
        review,
        campaignHeaderClasses,
        valuateCampaign,
        handleLogout,
        profilePath,
        dashboardPath,
    } = props


    const classes = useHeaderStyles()

    const isSideMenu = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

    const {t} = useTranslation()

    const [open, setOpen] = useState(false)

    const toggleDrawer = (open: boolean) => () => {
        setOpen(open)
    }

    const menuItems = useWishPageHeaderDropdownMenu({
        homepagePath: dashboardPath,
        youAccountPath: profilePath,
        logoutAction: handleLogout,
    })

    return (
        <AppBar
            color="default"
            position="sticky"
            className={classes.campaignHeader}
        >
            {/* ANCHOR NAVIGATION */}
            <Box className={classes.side}>
                {/* LOGO */}
                <WishPageHeaderLogo
                    campaignHeaderClasses={campaignHeaderClasses} element={anchors.home.current as Element}
                />
                {isSideMenu ?
                    <WishPageHeaderDrawer
                        anchors={anchors}
                        open={open}
                        onToggle={toggleDrawer}
                        campaignHeaderClasses={campaignHeaderClasses}
                        person={person}
                        review={review}
                        valuateCampaign={valuateCampaign}
                        menuItems={menuItems}
                    /> : <WishPageHeaderNavigation anchors={anchors} campaignClasses={campaignHeaderClasses}/>}
            </Box>
            {/* ACTION PANEL */}
            <Box className={classes.side}>
                {isSideMenu ? <>
                    <IconButton className={classes.burger} onClick={toggleDrawer(true)}>
                        <MenuIcon/>
                    </IconButton>
                </> : <>
                    {/** Wait 2 phase */}
                    {/*<MenuButton*/}
                    {/*    className={clsx(classes.actionButton, campaignClasses.actionButton)}*/}
                    {/*    startIcon={<ShareIcon/>}*/}
                    {/*    endIcon={<ArrowDropDownIcon/>}*/}
                    {/*    menu={<WishPageHeaderShareActions/>}*/}
                    {/*    disabled*/}
                    {/*    menuProps={{*/}
                    {/*        className: campaignClasses.menu,*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    {t('header.share.share')}*/}
                    {/*</MenuButton>*/}
                    <MenuButton
                        className={clsx(classes.actionButton, campaignHeaderClasses.actionButton, {disabled: !!review})}
                        startIcon={<StarRoundedIcon/>}
                        endIcon={<ArrowDropDownIcon/>}
                        menu={<WishPageHeaderRateActions valuateCampaign={valuateCampaign}/>}
                        menuProps={{
                            className: campaignHeaderClasses.menu,
                        }}
                        disabled={!!review}
                    >
                        {t('header.rate.rate')}
                    </MenuButton>
                    <MenuButton
                        className={clsx(classes.actionButton, campaignHeaderClasses.actionButton, 'profile')}
                        startIcon={
                            <Avatar>{person?.name.charAt(0)}</Avatar>
                        }
                        endIcon={<ArrowDropDownIcon/>}
                        menu={
                            <WishPageHeaderUserActions
                                menuItems={menuItems}
                            />
                        }
                        menuProps={{
                            className: campaignHeaderClasses.menu,
                        }}
                    >
                        {`${person?.name} ${person?.surname}`}
                    </MenuButton>

                    <WishPageHeaderWishButton
                        element={anchors.wish.current as Element} campaignHeaderClasses={campaignHeaderClasses}
                    />
                </>}
            </Box>
        </AppBar>
    )
})

import * as React from 'react'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import {SvgIconProps} from '@material-ui/core/SvgIcon/SvgIcon'

const LogOutIcon: React.FunctionComponent<SvgIconProps> = props => <SaveAltIcon
    {...props}
    style={{
        transform: 'rotate(-90deg)',
        ...props.style,
    }}
/>

export default LogOutIcon

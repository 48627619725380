import * as React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {ContainerHomepage} from './homepage/ContainerHomepage'
import {ContainerProfile} from './profile/ContainerProfile'
import {GENERAL_DASHBOARD_SUBROUTING} from './general-dashboard-routes'

const GeneralDashboardRoute: React.FunctionComponent = () => {

    return (
        <Switch>

            {/* ROUTE TO GENERAL DASHBOARD HOMEPAGE */}
            <Route exact path={GENERAL_DASHBOARD_SUBROUTING.homepage.path}>
                <ContainerHomepage/>
            </Route>

            {/* ROUTE TO GENERAL DASHBOARD PROFILE */}
            <Route path={GENERAL_DASHBOARD_SUBROUTING.profile.path}>
                <ContainerProfile/>
            </Route>

            {/* DEFAULT */}
            <Route>
                <Redirect to={GENERAL_DASHBOARD_SUBROUTING.homepage.path}/>
            </Route>

        </Switch>
    )
}

export default GeneralDashboardRoute

/**
 *
 * @description
 * The CONSTANTS API is used by dst-core.call and api factory to dynamically create endpoints to be called
 * passing in addition to method, headers, endpoint and query parameter.
 * We can define a different url than the base one by passing a different url in the fetchAction
 **
 * @example
 * export const API_PROFILE: IRequestModel = {
 *  url: '/example/{exampleID}',
 *  method: 'GET',
 *  headers : {
 *      'Content-Type': 'application/json'
 *  }
 * }
 *
 */
export const SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL

import {FC, useEffect} from 'react'
import {EclexiaPlayer} from 'deziro-web-app-components/shared/components/eclexia-player/EclexiaPlayer'
import {useDispatch, useSelector} from 'react-redux'
import {liveProgramGuidSelector, streamGiftSelector} from '../../../../redux/current-campaign.selectors'
import {eclexiaGetLiveProgram} from 'dst-react-core/eclexia/eclexia.utils'

export const ContainerLiveStreaming: FC = () => {
    const dispatch = useDispatch()

    const streamGift = useSelector(streamGiftSelector)
    const liveProgramGuid = useSelector(liveProgramGuidSelector)

    useEffect(
        () => {
            streamGift && dispatch(eclexiaGetLiveProgram(+(streamGift.eclexiaProgramId as string)))
        },
        [dispatch, streamGift],
    )

    return (
        <EclexiaPlayer guid={liveProgramGuid as string} live/>
    )
}
import {IRouter} from '../../dst-react-core/routing/routes.types'
import {AUTH_PATH, GENERAL_PATH} from '../web-app.routes'

export const GENERAL_AUTH_PATH = `${GENERAL_PATH}${AUTH_PATH}`
export const DASHBOARD_PATH = '/dashboard'
export const GENERAL_DASHBOARD_PATH = `${GENERAL_PATH}${DASHBOARD_PATH}`

export const GENERAL_ROUTE = Object.freeze<IRouter>({
    path: GENERAL_PATH,
    description: 'Web app general route',
    subrouting: {
        auth: {
            path: GENERAL_AUTH_PATH,
            description: 'Web app general auth route',
        },
        dashboard: {
            path: GENERAL_DASHBOARD_PATH,
            description: 'Web app dashboard route',
        },
    },
})

export const GENERAL_SUBROUTING = GENERAL_ROUTE.subrouting
import * as React from 'react'
import {useTranslation} from 'react-i18next'
import ResetPasswordEmailForm from './components/ResetPasswordEmailForm'
import ResetPasswordAnnotation from '../components/ResetPasswordAnnotation'

const ResetPasswordEmail: React.FunctionComponent = () => {

    const {t} = useTranslation()

    return (
        <>
            <ResetPasswordAnnotation hint={t('auth.reset_password.email.hint')} />
            <ResetPasswordEmailForm />
        </>
    )
}

export default ResetPasswordEmail

import {FC} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import * as React from 'react'
import {GENERAL_PROFILE_SUBROUTING} from './profile-routes'
import {ContainerProfileAccountOverview} from './components/account-overview/ContainerProfileAccountOverview'
import {ContainerProfilePersonalProfile} from './components/personal-profile/ContainerProfilePersonalProfile'
import {ContainerProfileChangePassword} from './components/change-password/ContainerProfileChangePassword'

export const ProfileRoutes: FC = () => {
    return (
        <Switch>

            <Route path={GENERAL_PROFILE_SUBROUTING.overview.path}>
                <ContainerProfileAccountOverview/>
            </Route>

            <Route path={GENERAL_PROFILE_SUBROUTING.profile.path}>
                <ContainerProfilePersonalProfile/>
            </Route>

            <Route path={GENERAL_PROFILE_SUBROUTING.password.path}>
                <ContainerProfileChangePassword/>
            </Route>

            {/* DEFAULT */}
            <Route>
                <Redirect to={GENERAL_PROFILE_SUBROUTING.overview.path}/>
            </Route>

        </Switch>
    )
}
import { ILoginFormValues } from 'deziro-web-app-components/components/login/form/LoginForm'
import { IRouteId } from 'dst-react-core/core.types'
import { updateLastAction } from 'dst-react-core/utils/shared.utils'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import CampaignLoginForm from 'deziro-web-app-components/components/login/CampaignLoginForm'
import { FC } from 'react'

export interface ICampaignLoginFormContainerProps {
    authorizeUser: any
    isLoginInPending: boolean
    isLoginAttemptFailed: boolean
    resetPwdPath: string
    verificationSchemas: any
}

export const CampaignLoginFormContainer: FC<ICampaignLoginFormContainerProps> = (props) => {
    const { authorizeUser, isLoginAttemptFailed, isLoginInPending, resetPwdPath, verificationSchemas } = props
    const [keepLoggedIn, setKeepLoggedIn] = useState(false)

    const { id } = useParams<IRouteId>()

    const { t } = useTranslation()

    const dispatch = useDispatch()

    const submitButtonRef = useRef<HTMLButtonElement | null>(null)

    const handleSubmit = (values: ILoginFormValues, staySignedIn: boolean) => {
        !staySignedIn && updateLastAction(true)
        dispatch(authorizeUser(values.email, values.password))
    }

    const handleKeepLoggedIn = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = target

        setKeepLoggedIn(checked)
    }

    return (
        <CampaignLoginForm
            submitButtonRef={submitButtonRef}
            isLoginFailed={isLoginAttemptFailed}
            isLoginInPending={isLoginInPending}
            forgotPassword={t('auth.login.forgot_password')}
            clickHere={t('string.click_here')}
            invalidCreds={t('validation.invalid_credentials')}
            logIn={t('auth.login.log_in')}
            path={resetPwdPath}
            id={id}
            handleKeepLoggedIn={handleKeepLoggedIn}
            keepLoggedIn={keepLoggedIn}
            onSubmit={handleSubmit}
            vSchema={verificationSchemas.vSchema}
            vString={verificationSchemas.vString}
            vEmail={verificationSchemas.vEmail}
        />
    )
}

import {IResponseDataModelMeta, IResponseNoLinkMeta} from '../fetch/fetch.types'
import {IImage} from '../image/image.types'
import {IReview, IReviewSummary} from '../review/review.types'
import {IFAQItemLink} from 'deziro-web-app-components/components/sections/hooks/faq-content.hook'

export enum EGiftType {
    SINGLE_VIDEO = 'SINGLE_VIDEO',
    MULTI_VIDEO = 'MULTI_VIDEO',
    SINGLE_PODCAST = 'SINGLE_PODCAST',
    MULTI_PODCAST = 'MULTI_PODCAST',
    LIVESTREAM = 'LIVESTREAM',
    VOUCHER = 'VOUCHER',
    FILE = 'FILE'
}

export enum ECampaignStatus {
    DRAFT = 'DRAFT',
    PUBLISHED = 'PUBLISHED',
    PAUSED = 'PAUSED',
    CLOSED = 'CLOSED',
    IN_PROGRESS = 'IN_PROGRESS'
}

export enum ECampaignPageSection {
    HEADER = 'HEADER',
    GIFT = 'GIFT',
    CROSS_SELLING = 'CROSS_SELLING',
    FAQ = 'FAQ',
    ENTIRE_PAGE = 'ENTIRE_PAGE'
}

export enum EAccessCodeType {
    SINGLE_USE = 'SINGLE_USE',
    MULTI_USE = 'MULTI_USE'
}

export enum EIconFilStyle {
    FILL = 'FILL',
    OUTLINE = 'OUTLINE'
}

export enum ECampaignPageName {
    ACCESS = 'ACCESS',
    MAIN = 'MAIN'
}

export enum EBannerLine {
    ONE = 'ONE',
    TWO = 'TWO',
    THREE = 'THREE'
}

export enum EBannerType {
    LINK = 'LINK',
    CAMPAIGN = 'CAMPAIGN',
    PRESENT = 'PRESENT'
}

export enum EBackgroundType {
    COLOR = 'COLOR',
    IMAGE = 'IMAGE',
}

export interface ICampaignPageMeta extends IResponseNoLinkMeta {
    missingFields: string[]
    publishable: boolean
    editable: boolean
}

// Experimental, should be updated or confirmed
export interface IVoucher extends ICampaignPageMeta {
    position: number
    titleText: string
    descriptionText: string
    termsAndConditionsText: string
    visible: boolean
    coverImage: IImage | null
    logoImage: IImage | null
}

export interface IBanner extends ICampaignPageMeta {
    visible: boolean
    line: EBannerLine
    type: EBannerType
    url: string | null
    position: number
    previewImage: IImage | null
    giftedCampaign: ICampaign | null
    present: string | null
}

export enum ESocialNetwork {
    FACEBOOK = 'FACEBOOK',
    TWITTER = 'TWITTER',
    INSTAGRAM = 'INSTAGRAM',
    LINKEDIN = 'LINKEDIN',
    YOUTUBE = 'YOUTUBE',
    TWITCH = 'TWITCH'
}

export interface ISocialNetworkLink extends IResponseNoLinkMeta {
    socialNetwork: ESocialNetwork
    url: string
}

export interface ICampaignPageGlobal extends ICampaignPageMeta {
    inputTextColorHex: string | null
    globalFont: string | null
    logoIconImage: IImage | null
    logoImage: IImage | null
    paragraphFont: string | null
    subtitleFont: string | null
    titleFont: string | null
}

export interface ICampaignPageSectionContent extends ICampaignPageMeta {
    giftPartnerLogoImage: IImage | null
    giftPreviewImage: IImage | null
    paragraphColorHex: string | null
    paragraphText: string | null
    reviewBodyColorHex: string | null
    reviewBodyText: string | null
    reviewTitleColorHex: string | null
    reviewTitleText: string | null
    socialIconColorHex: string | null
    socialIconStyle: EIconFilStyle | null
    subtitleColorHex: string | null
    subtitleText: string | null
    titleColorHex: string | null
    titleText: string | null
    socialNetworkLinks: ISocialNetworkLink[]
    bannerDesigns: IBanner[]
    voucherDesigns: IVoucher[]
}

export interface ICampaignPageSectionStyle extends ICampaignPageMeta {
    backgroundType: EBackgroundType
    backgroundImage: IImage | null
    backgroundColorHex: string | null
    buttonBackgroundColorHex: string | null
    buttonLinkColorHex: string | null
    linkColorHex: string | null
    sideImage: IImage | null
}

export interface ICampaignPageSection extends ICampaignPageMeta {
    name: ECampaignPageSection
    content: ICampaignPageSectionContent
    style: ICampaignPageSectionStyle
}

export interface ICampaignPage extends ICampaignPageMeta {
    name: ECampaignPageName
    global: ICampaignPageGlobal
    sections: ICampaignPageSection[]
}

export interface ICampaign extends IResponseDataModelMeta {
    name: string
    label: string
    startDate: Date
    endDate: Date
    giftType: EGiftType
    status: ECampaignStatus
    companyName?: string
    url?: string
    accessCodeAmount?: number
    accessCodeRedemptions: number
    accessCodeMaxRedemptions: number
    accessCodePrefix?: string
    accessCodeType?: EAccessCodeType
}

export interface IGift extends IResponseDataModelMeta {
    position: number,
    giftType: EGiftType,
    eclexiaVideoId: string | null,
    eclexiaChannelId: string | null,
    eclexiaProgramId: string | null,
    eclexiaPreLiveImageId: string | null,
    eclexiaPostLiveImageId: string | null,
    eclexiaFileSize: number | null,
    fileName: string | null,
    streamStart: string | null,
    streamEnd: string | null,
    name: string | null,
    description: string | null,
    streamers: string[] | null,
    file: string | null
}

export interface IFaqRecord {
    previewText: string
    expandedText: string
    link?: IFAQItemLink,
    topic?: string
}

export interface IWebAppCurrentCampaign {
    campaign?: ICampaign | null
    hasAccess?: boolean | null
    accessPage?: ICampaignPage | null
    mainPage?: ICampaignPage | null
    gifts?: IGift[] | null
    reviewSummary?: IReviewSummary | null
    review?: IReview | null,
    liveProgramGuid?: string | null
}

export interface IWebAppCampaignState {
    currentCampaign: IWebAppCurrentCampaign | null,
}
import makeStyles from '@material-ui/core/styles/makeStyles'
import { campaignAccessPageSelector } from 'deziro-web-app-components/redux/selectors'
import { cssBackgroundUrl } from 'dst-react-core/utils/shared.utils'
import {useMemo} from 'react'
import {useSelector} from 'react-redux'
import { useCampaignImageWrapper } from './auth-header.hooks'

export const useCampaignAuthHeaderStyles = (loginLogo?: string) => {

    const values = useSelector(campaignAccessPageSelector)

    const logo = useCampaignImageWrapper(values?.global.logoImage?.id ?? null)

    return useMemo(() => makeStyles(() => ({
        root: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            position: 'relative',
            height: 88
        },
        logo: {
            backgroundImage: cssBackgroundUrl(logo || loginLogo!) ?? '',
            height: 88
        },
    })), [logo, loginLogo])()
}

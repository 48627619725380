import Box from '@material-ui/core/Box'
import WebAppRoute from './WebAppRoute'
import {useWebAppStyles} from './web-app.styles'
import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {authLoginUser, confirmRegistration} from './components/auth/auth.utils'
import {X_AUTH_TOKEN_LABEL_SAVED} from '../dst-react-core/core.constants'
import {useQuery} from '../dst-react-core/core.hooks'
import {noAuthTokenAction} from './components/auth/redux/auth.actions'
import {useHistory} from 'react-router-dom'
import {pushAnalytics} from 'dst-react-core/google-analytics/gmt.init'


const WebApp: React.FunctionComponent = () => {

    const classes = useWebAppStyles()

    const query = useQuery()

    const dispatch = useDispatch()
    const history = useHistory()

    // register page views in GTM
    useEffect(() => {
        if (history?.location.pathname) {
            pushAnalytics('pageview')
        }
    }, [history.location.pathname])

    // Make initial user fetch
    useEffect(() => {
        const token = localStorage.getItem(X_AUTH_TOKEN_LABEL_SAVED)
            if (token) {
                dispatch(authLoginUser())
            } else {
                dispatch(noAuthTokenAction)
            }
    }, [dispatch])

    // Maybe we should confirm registration
    useEffect(() => {
        const campaignId = query.get('campaignId')
        const token = query.get('token')
        campaignId && token && dispatch(confirmRegistration(campaignId, token))
    }, [query, dispatch])

    return (
        <Box className={classes.root}>
            <WebAppRoute />
        </Box>
    )
}

export default WebApp

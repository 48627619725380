import * as Yup from 'yup'

export type YupSchema = Yup.AnySchema

type ICustomField<PropertyName extends string> = {
    [key in PropertyName]: YupSchema
}

export function useSchemaValidation<T extends string>(fields: ICustomField<T>) {
    return Yup.object().shape(fields)
}
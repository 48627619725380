import {FC} from 'react'
import {useCampaignImageWrapper} from '../../../../campaign.hooks'
import {VoucherAccordion} from 'deziro-web-app-components/components/sections/components/VoucherAccordion'
import * as React from 'react'
import {IContainerVoucherAccordionProps} from 'deziro-web-app-components/models/container-voucher-accordion.types'

export const ContainerVoucherAccordion: FC<IContainerVoucherAccordionProps> = ({design, voucher}) => {
    const partnerLogo = useCampaignImageWrapper(design?.logoImage?.id)

    const cover = useCampaignImageWrapper(design?.coverImage?.id)

    return (
        <VoucherAccordion
            voucher={voucher.voucher} design={voucher.design} key={voucher?.voucher?.id ?? ''} partnerLogo={partnerLogo} cover={cover}
        />
    )
}
import {FC} from 'react'
import {ProfileChangePassword} from './ProfileChangePassword'
import {useProfileChangePasswordStyles} from './change-password.styles'
import {useProfileStyles} from '../../profile.styles'
import {useTranslation} from 'react-i18next'

export interface IContainerProfileChangePasswordProps {

}

export const ContainerProfileChangePassword: FC<IContainerProfileChangePasswordProps> = props => {
    const profileChangePasswordClasses = useProfileChangePasswordStyles()
    const profileClasses = useProfileStyles()

    const {t} = useTranslation()

    const modifyPasswordLabel = t('general_dashboard.modify_password')
    const modifyPasswordTipLabel = t('general_dashboard.modify_password_tip')

    return (
        <ProfileChangePassword
            {...props}
            profileChangePasswordClasses={profileChangePasswordClasses}
            profileClasses={profileClasses}
            modifyPasswordLabel={modifyPasswordLabel}
            modifyPasswordTipLabel={modifyPasswordTipLabel}
        />
    )
}
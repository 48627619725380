import React from 'react'
import {
    authAuthorizeUserAction,
    AUTH_LOGIN_ACTION_REQUEST_ID,
    authRegistrationAction,
    AUTH_REGISTRATION_ACTION_REQUEST_ID,
    authConfirmRegistrationAction,
    AUTH_CONFIRM_REGISTRATION_ACTION_REQUEST_ID,
    authResetPasswordRequestAction,
    AUTH_RESET_PASSWORD_REQUEST_ACTION_REQUEST_ID,
    authResetPasswordConfirmAction,
    AUTH_RESET_PASSWORD_CONFIRM_ACTION_REQUEST_ID,
} from './redux/auth.actions'
import {
    MY_PERSON_FETCH_ACTION_REQUEST_ID,
    myPersonFetchAction, PERSON_UPDATE_ACTION_REQUEST_ID, personUpdateAction,
} from 'dst-react-core/person/redux/person.actions'
import {X_AUTH_TOKEN_KEY} from 'dst-react-core/core.constants'
import {credentials2BasicToken} from 'dst-react-core/utils/shared.utils'
import {IPerson} from 'dst-react-core/person/person.types'
import {
    PRINCIPAL_UPDATE_ACTION_REQUEST_ID,
    principalUpdateAction,
} from 'dst-react-core/principal/redux/principal.actions'
import {IPrincipal} from 'dst-react-core/principal/principal.types'

export const authorizeUser = (email: string, password: string) => {
    return authAuthorizeUserAction.build(null,
        AUTH_LOGIN_ACTION_REQUEST_ID,
        // X_AUTH_TOKEN_KEY should be null because this request shouldn`t have it
        {Authorization: credentials2BasicToken(email, password), [X_AUTH_TOKEN_KEY]: 'null'},
    )
}

export const registerUser = (payload: object, campaignId: React.ReactText, accessKey: string) => {
    return authRegistrationAction.build(
        JSON.stringify(payload),
        AUTH_REGISTRATION_ACTION_REQUEST_ID,
        // X_AUTH_TOKEN_KEY should be null because this request shouldn`t have it
        {[X_AUTH_TOKEN_KEY]: 'null'},
        {},
        {campaignId, accessKey},
    )
}

export const confirmRegistration = (campaignId: React.ReactText, confirmToken: string) => {
    return authConfirmRegistrationAction.build(
        null,
        AUTH_CONFIRM_REGISTRATION_ACTION_REQUEST_ID,
        // X_AUTH_TOKEN_KEY should be null because this request shouldn`t have it
        {[X_AUTH_TOKEN_KEY]: 'null'},
        {},
        {campaignId, confirmToken},
    )
}

export const authLoginUser = () => {
    return myPersonFetchAction.build(null,
        MY_PERSON_FETCH_ACTION_REQUEST_ID,
        {}, {},
        {projection: 'PersonData'},
    )
}

export const authResetPasswordRequest = (email: string, campaignId?: React.ReactText | null) => {
    return authResetPasswordRequestAction.build(
        null,
        AUTH_RESET_PASSWORD_REQUEST_ACTION_REQUEST_ID,
        {}, {},
        {email, ...campaignId && {campaignId}},
    )
}

export const authResetPasswordConfirm = (confirmToken: string, password: string) => {
    return authResetPasswordConfirmAction.build(
        null,
        AUTH_RESET_PASSWORD_CONFIRM_ACTION_REQUEST_ID,
        {}, {},
        {confirmToken, password},
    )
}

export const updatePersonBuilder = (
    personId: number,
    newPerson: IPerson,
) => personUpdateAction.build(
    newPerson,
    PERSON_UPDATE_ACTION_REQUEST_ID,
    {},
    {
        id: personId,
    },
    {
        projection: 'PersonData',
    },
)

export const updatePrincipalBuilder = (
    principalId: number,
    newPrincipal: IPrincipal
) => principalUpdateAction.build(
    newPrincipal,
    PRINCIPAL_UPDATE_ACTION_REQUEST_ID,
    {},
    {
        id: principalId
    }
)
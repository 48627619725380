import {FC, useEffect} from 'react'
import {Homepage} from './Homepage'
import {useGeneralHomepageStyles} from './homepage.styles'
import {useGetMyCampaigns} from './hooks/get-my-campaign.hook'
import {useSelector} from 'react-redux'
import {myCampaignsSelector} from './redux/homepage.selectors'

export const ContainerHomepage: FC = () => {
    const homepageClasses = useGeneralHomepageStyles()

    const myCampaigns = useSelector(myCampaignsSelector)

    const getMyCampaignsCallback = useGetMyCampaigns()

    useEffect(
        () => {
            /** getting campaigns */
            if (!myCampaigns) {
                getMyCampaignsCallback()
            }
        },
        [getMyCampaignsCallback, myCampaigns],
    )

    return (
        <Homepage
            homepageClasses={homepageClasses}
        />
    )
}
import * as React from 'react'
import {useFormik} from 'formik'
import {useTranslation} from 'react-i18next'
import {useQuery} from 'dst-react-core/core.hooks'
import {useFormStyles} from 'deziro-web-app-components/components/login/styles/form.styles'
import FormProvider from 'deziro-web-app-components/components/login/form/components/formik/FormProvider'
import GenericLink from 'deziro-web-app-components/shared/utils/GenericLink'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import FormikTextField from 'deziro-web-app-components/components/login/form/components/formik/FormikTextField'
import {useDispatch, useSelector} from 'react-redux'
import {campaignAccessActionBuilder} from 'web-app/campaign/campaigns.utils'
import {isCampaignRequestAccessActionInPending} from 'web-app/campaign/redux/campaign.actions'
import {currentCampaignSelector} from 'web-app/campaign/redux/current-campaign.selectors'
import {vSchema, vString} from 'shared/formik/validation.yup'
import {MAILTO_DEZIRO_CONTACT_US} from 'deziro-web-app-components/shared/shared.constants'

const CodeAssignmentForm: React.FunctionComponent = () => {

    const query = useQuery()

    const formClasses = useFormStyles()

    const dispatch = useDispatch()

    const {t} = useTranslation()

    const isAccessInPending = useSelector(isCampaignRequestAccessActionInPending)

    const campaign = useSelector(currentCampaignSelector)

    const formik = useFormik({
        initialValues: {
            code: query.get('code') ?? '',
        },
        validationSchema: vSchema({
            code: vString.required(),
        }),
        onSubmit: (values) => {
            campaign && dispatch(campaignAccessActionBuilder(campaign.id, values.code))
        },
        validateOnMount: true,
    })

    return (
        <>
            <FormProvider className={formClasses.form}>
                <FormikTextField
                    formik={formik}
                    fieldName="code"
                    className={formClasses.input}
                    label={t('auth.code.code')}
                />
            </FormProvider>
            <Typography variant={'caption'}>
                {t('auth.code.problem')}&nbsp;
                <GenericLink
                    href={MAILTO_DEZIRO_CONTACT_US}
                    mailto
                >{t('string.click_here')}</GenericLink>
            </Typography>
            <Button
                className={'big'}
                variant="contained"
                onClick={() => formik.handleSubmit()}
                disabled={isAccessInPending}
            >{t('auth.code.submit')}</Button>
        </>
    )
}

export default CodeAssignmentForm

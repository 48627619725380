import {HttpMethods, IRequestModel} from 'dst-react-core/fetch/fetch.types'
import {AUTH_ENDPOINT, PUBLIC_ENDPOINT, REGISTER_ENDPOINT} from 'dst-react-core/core.api.constants'


export const REGISTRATION_CONFIRM_ENDPOINT = '/registration-confirm'
export const WEBAPP_ENDPOINT = '/webapp'
export const RESET_PASSWORD_REQUEST_ENDPOINT = '/reset-password-request'
export const RESET_PASSWORD_CONFIRM_ENDPOINT = '/reset-password-confirm'

export const LOGIN_USER_API: IRequestModel = {
    url: '/',
    method: HttpMethods.GET,
}

/**
 * @description Register user => /auth/public/register?campaignId={ID}&accessKey={KEY}
 * @return User
 */
export const USER_REGISTER_API: IRequestModel = {
    url: `${AUTH_ENDPOINT}${PUBLIC_ENDPOINT}${REGISTER_ENDPOINT}`,
    method: HttpMethods.POST,
    headers: {
        'Content-Type': 'application/json',
    },
}

/**
 * @description Confirm user registration => /auth/public/webapp/registration-confirm?confirmToken={TOKEN}&campaignId={ID}
 * @return None
 */
export const USER_REGISTRATION_CONFIRM_API: IRequestModel = {
    url: `${AUTH_ENDPOINT}${PUBLIC_ENDPOINT}${WEBAPP_ENDPOINT}${REGISTRATION_CONFIRM_ENDPOINT}`,
    method: HttpMethods.POST,
}

/**
 * @description Request reset password => /auth/public/webapp/reset-password-request?email=j.manzin@dstech.it
 * @return None
 */
export const RESET_PASSWORD_REQUEST_POST: IRequestModel = {
    url: `${AUTH_ENDPOINT}${PUBLIC_ENDPOINT}${RESET_PASSWORD_REQUEST_ENDPOINT}`,
    method: HttpMethods.POST,
}

/**
 * @description Request confirm password => /auth/public/reset-password-confirm?confirmToken=ad7787d0-d6b0-4624-9317-87d3e2a6e3ca&password=Ciao1234!
 * @return None
 */
export const RESET_PASSWORD_CONFIRM_POST: IRequestModel = {
    url: `${AUTH_ENDPOINT}${PUBLIC_ENDPOINT}${RESET_PASSWORD_CONFIRM_ENDPOINT}`,
    method: HttpMethods.POST,
}
import makeStyles from '@material-ui/core/styles/makeStyles'
import {flex, hideOverflowedText, important, resetUl} from 'dst-react-core/utils/styles.utils'
import {EXTRA_COLORS_PALETTE, GENERAL_HEADER_FILTER_GRADIENT} from '../../../../../material.theme'
import {CSSDependsOnBrowserFlexGapSupporting} from 'dst-react-core/utils/css/supports-flex-gap.util'

export const useProfileNavigationStyles = makeStyles((theme) => ({
    navigationMenu: {
        ...resetUl(),
        ...flex(),
        flexDirection: 'column',
        '& li': {
            boxSizing: 'border-box',
            padding: 12,
            borderRadius: 25,
            background: 'transparent',
            cursor: 'pointer',
            maxWidth: 270,
            marginTop: 10,
            ...hideOverflowedText(),
            '& .content': {
                ...flex(true, false, 0, 'initial'),
                ...CSSDependsOnBrowserFlexGapSupporting(
                    {
                        gap: 10
                    },
                ),
                '& .icon': {
                    color: theme.palette.text.primary,
                },
                '& .title': {
                    color: theme.palette.text.secondary,
                    fontSize: 16,
                    transition: 'all ease 0.4s',
                    ...hideOverflowedText(),
                    ...CSSDependsOnBrowserFlexGapSupporting(
                        {},
                        {
                            marginLeft: 10
                        }
                    ),
                },
            },
            '&:hover': {
                '& .title': {
                    color: important(theme.palette.text.primary),
                },
            },

        },
        '& li.active': {
            background: theme.palette.primary.main,
        },
    },
    mobileMenuWrap: {
        '& .menuButton': {
            padding: important('0 15px'),
            height: 60,
            background: GENERAL_HEADER_FILTER_GRADIENT,
            '& .MuiButton-label': {
                justifyContent: 'initial',
            },
        },
    },
    mobileMenu: {
        '& .MuiMenu-paper': {
            background: GENERAL_HEADER_FILTER_GRADIENT,
            width: '85%',
            marginTop: 70,
        },
        '& .menuItem': {
            padding: '15px',
            background: 'transparent',
            transition: 'all ease 0.3s',
        },
        '& .menuItemActive': {
            padding: '15px',
            background: EXTRA_COLORS_PALETTE.GENERAL_BORDER.DEFAULT_WITH_OPACITY,
        },
    },
    mobileMenuItemContent: {
        ...flex(true, false, 10, 'initial'),
        fontSize: 14,
        color: theme.palette.text.secondary,
        width: '100%',
        '& .iconWrap': {
            color: theme.palette.text.primary,
            '& > *': {
                fontSize: 20,
            },
        },
    },
}))
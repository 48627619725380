import {useDispatch, useSelector} from 'react-redux'
import {
    campaignSectionSelector, currentCampaignGiftsSelector,
    currentCampaignIdSelector,
    currentCampaignReviewSelector,
    currentCampaignReviewSummarySelector,
    currentCampaignSelector,
} from './redux/current-campaign.selectors'
import {
    campaignEclexiaVideoSelector,
    campaignEclexiaVideosSelector, campaignEclexiaVouchersSelector,
    campaignImageSelector, campaignVoucherSelector,
} from './redux/campaign-gift.selectors'
import {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {
    EBackgroundType,
    ECampaignPageSection,
    ICampaign,
    ICampaignPageSectionStyle,
} from 'dst-react-core/campaign/campaign.types'
import {
    campaignLinkGenerator,
    getCampaignByUrlBuilder,
} from './campaigns.utils'
import {IEclexiaFullVideo} from 'dst-react-core/eclexia/eclexia.types'
import {eclexiaActionWithTokenUpdate} from 'dst-react-core/eclexia/redux/eclexia.actions'
import {eclexiaGetSpecifiedVideoBuilder} from 'dst-react-core/eclexia/eclexia.utils'
import {IReview, IReviewSummary} from 'dst-react-core/review/review.types'
import {useEqualObjectMemo} from 'dst-react-core/core.hooks'
import {
    getCampaignReviewBuilder,
    getCampaignReviewSummaryBuilder,
    writeReview,
} from 'dst-react-core/review/review.utils'
import {IVoucherGift} from 'dst-react-core/voucher/voucher.types'
import {getVoucherByGiftIdBuilder} from '../../dst-react-core/voucher/voucher.utils'
import {allVideosSelector} from '../../redux/app.reducers'
import {useCampaignImage} from 'deziro-web-app-components/components/hooks/campaign-image.hook'

export const useCampaignImageWrapper = (id: number | null | undefined, fetchIfAbsent: boolean = true) => {
    return useCampaignImage(id, fetchIfAbsent, campaignImageSelector as any)
}

export const useCampaignVoucher = (id: number | null | undefined, fetchIfAbsent: boolean = true) => {
    const _voucher = useSelector(campaignVoucherSelector(id))

    const [voucher, setVoucher] = useState<null | IVoucherGift>(null)

    const dispatch = useDispatch()

    useEffect(() => {
        if (!_voucher && id && fetchIfAbsent)
            dispatch(getVoucherByGiftIdBuilder(id))
        setVoucher(_voucher)
    }, [_voucher, dispatch, id, fetchIfAbsent])

    return voucher
}

export const useCampaignVideo = (id: string | null | undefined, fetchIfAbsent: boolean = true) => {
    const _video = useSelector(campaignEclexiaVideoSelector(id))

    const [video, setVideo] = useState<IEclexiaFullVideo | null>(null)

    const dispatch = useDispatch()

    useEffect(() => {
        if (!_video && id && fetchIfAbsent)
            dispatch(eclexiaActionWithTokenUpdate(eclexiaGetSpecifiedVideoBuilder(id)))
        setVideo(_video)
    }, [_video, dispatch, id, fetchIfAbsent])

    return video
}

export const useCampaignVideos = (ids: (string | null | undefined)[], fetchIfAbsent: boolean = true) => {
    const dispatch = useDispatch()
    const allVideos = useSelector(allVideosSelector)

    const _ids = useEqualObjectMemo(ids)

    const _videos = useEqualObjectMemo(useSelector(campaignEclexiaVideosSelector(ids)))

    const [videos, setVideos] = useState<(IEclexiaFullVideo | null)[]>(ids.map(() => null)) // should be the same size as ids array

    const inPendingIdsRef = useRef<string[]>([])

    useEffect(() => {
        const inPendingIds = inPendingIdsRef.current
        setVideos(_videos.map((video, i) => {
            const id = _ids[i]
            if (!id) return null
            if (video) inPendingIds.splice(inPendingIds.indexOf(id), 1)
            if (!video && fetchIfAbsent && !inPendingIds.includes(id)) {
                dispatch(eclexiaActionWithTokenUpdate(eclexiaGetSpecifiedVideoBuilder(id)))
                inPendingIds.push(id)
            }
            return video
        }))
        // allVideos is required to recall effect on videos array change
    }, [_videos, dispatch, _ids, fetchIfAbsent, allVideos])

    return videos
}

export const useCampaignVouchers = (orderedIds: { id: number | undefined, position: number | undefined }[], fetchIfAbsent: boolean = true) => {
    const gifts = useSelector(currentCampaignGiftsSelector)
    const dispatch = useDispatch()

    const _orderedIds = useEqualObjectMemo(orderedIds)

    const _vouchers = useEqualObjectMemo(useSelector(campaignEclexiaVouchersSelector(orderedIds.map(oId => oId.id))))

    const voucherDesigns = useSelector(campaignSectionSelector(ECampaignPageSection.GIFT))?.content.voucherDesigns

    const [vouchers, setVouchers] = useState<(IVoucherGift | null)[]>(orderedIds.map(() => null)) // should be the same size as ids array

    const inPendingIdsRef = useRef<number[]>([])

    useEffect(() => {
        const inPendingIds = inPendingIdsRef.current
        setVouchers(_vouchers.map((voucher, i) => {
            const id = _orderedIds[i].id
            if (!id) return null
            if (voucher) inPendingIds.splice(inPendingIds.indexOf(id), 1)
            if (!voucher && fetchIfAbsent && !inPendingIds.includes(id)) {
                dispatch(getVoucherByGiftIdBuilder(id))
                inPendingIds.push(id)
            }
            return voucher
        }))
        // gifts is required to recall effect on gifts array change
    }, [_vouchers, dispatch, _orderedIds, fetchIfAbsent, gifts])

    // Gift position is the same as design position
    // so we need to have gift position (from arguments) and compare it with design position
    return _orderedIds.map((oId, i) => ({
        voucher: vouchers[i],
        design: voucherDesigns?.find(d => d.position === oId.position),
    }))
}

export const useCurrentCampaign = (effect: (campaign: ICampaign) => void, deps: Array<any> = []) => {

    const dispatch = useDispatch()

    const campaign = useSelector(currentCampaignSelector)

    // If no deps list - disable reinitializing
    // eslint-disable-next-line
    const callback = useCallback(effect, deps)

    useEffect(() => {
        if (campaign) {
            callback(campaign)
        } else {
            dispatch(getCampaignByUrlBuilder(campaignLinkGenerator()))
        }
    }, [dispatch, campaign, callback])
}

export const useCampaignReview = (effect: (summary: IReviewSummary, review: IReview | null) => void, deps?: Array<any>) => {

    const dispatch = useDispatch()

    const review = useSelector(currentCampaignReviewSelector)

    const summary = useSelector(currentCampaignReviewSummarySelector)

    // If no deps list - disable reinitializing
    // eslint-disable-next-line
    const callback = useCallback(effect, deps ?? [])

    useCurrentCampaign((campaign) => {
        if (review === undefined)
            dispatch(getCampaignReviewBuilder(campaign.id))
        if (!summary)
            dispatch(getCampaignReviewSummaryBuilder(campaign.id))
        if (summary && review !== undefined)
            callback(summary, review)
    }, [summary, review, callback])
}

export const useBackgroundType = (styles?: ICampaignPageSectionStyle | null) => {
    const isBgTypeImage = styles?.backgroundType === EBackgroundType.IMAGE

    const bgImage = useCampaignImageWrapper(styles?.backgroundImage?.id ?? null, isBgTypeImage)

    return useMemo(() => {
        if (!styles) return ''
        if (isBgTypeImage)
            return `url(${bgImage})`
        else
            return styles.backgroundColorHex + ''
    }, [styles, bgImage, isBgTypeImage])
}

export const useValuate = () => {
    const dispatch = useDispatch()

    const campaignId = useSelector(currentCampaignIdSelector)
    const review = useSelector(currentCampaignReviewSelector)

    return (value: number) => campaignId && !review && dispatch(writeReview(campaignId, value))
}
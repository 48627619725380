import * as React from 'react'
import {memo, useEffect, useState} from 'react'
import clsx from 'clsx'
import {analyticClickDecorator} from 'dst-react-core/google-analytics/gmt.init'
import {checkIsInViewport, debounce, scrollToElement} from 'dst-react-core/utils/shared.utils'
import Button from '@material-ui/core/Button'
import {useTranslation} from 'react-i18next'
import {EAnchor, ISectionAnchors} from '../../../models/wish-page-core.types'

interface IWIshPageHeaderNavigationProps {
    anchors: ISectionAnchors,
    campaignClasses: any
}

export const WishPageHeaderNavigation: React.FunctionComponent<IWIshPageHeaderNavigationProps> = memo(({anchors, campaignClasses}) => {

    const [activeSection, setActiveSection] = useState<EAnchor>(EAnchor.HOME)

    const {t} = useTranslation()

    const navigateTo = (anchor: EAnchor) => {
        setActiveSection(anchor)
        scrollToElement(anchors[anchor].current as Element)
    }

    // Changes highlighted navigation buttons depending on which section is in viewport
    useEffect(() => {
        const updateNavigationStatus = debounce(() => {
            for (const _key in anchors) {
                if (anchors.hasOwnProperty(_key)) {
                    const key = _key as EAnchor
                    if (checkIsInViewport(anchors[key]?.current as HTMLElement)) {
                        setActiveSection(key)
                        break
                    }
                }
            }
        }, 100)
        const elem = window
        const event = 'wheel'
        elem.addEventListener(event, updateNavigationStatus)
        return () => {
            elem.removeEventListener(event, updateNavigationStatus)
        }
    }, [anchors])

    return (
        <>
            {/* NAVIGATION */}
            <Button
                className={clsx(campaignClasses.navigationButton, {active: activeSection === EAnchor.HOME})}
                onClick={analyticClickDecorator('anchor-menu-click', () => navigateTo(EAnchor.HOME))}
            >
                {t('header.navigation.home')}
            </Button>
            <Button
                className={clsx(campaignClasses.navigationButton, {active: activeSection === EAnchor.WISH})}
                onClick={analyticClickDecorator('anchor-menu-click', () => navigateTo(EAnchor.WISH))}
            >
                {t('header.navigation.wish')}
            </Button>
            <Button
                className={clsx(campaignClasses.navigationButton, {active: activeSection === EAnchor.ADS})}
                onClick={analyticClickDecorator('anchor-menu-click', () => navigateTo(EAnchor.ADS))}
            >
                {t('header.navigation.ads')}
            </Button>
            <Button
                className={clsx(campaignClasses.navigationButton, {active: activeSection === EAnchor.FAQ})}
                onClick={analyticClickDecorator('anchor-menu-click', () => navigateTo(EAnchor.FAQ))}
            >
                {t('header.navigation.faq')}
            </Button>
        </>
    )
})

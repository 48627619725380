import React from 'react'
import {
    eclexiaGetSpecifiedVideoActionBuilder,
    eclexiaGetVideosActionBuilder, eclexiaLoginActionBuilder, eclexiaGetLiveProgramActionBuilder,
} from './redux/eclexia.actions'
import {CONTENT_TYPE_KEY, JSON_CONTENT_TYPE} from '../core.constants'
import {IQueryParameter} from '../fetch/fetch.types'
import {IAction} from '../core.types'
import {ECLEXIA_CREDENTIALS, ECLEXIA_PLAYER_URL} from './eclexia.constans'

export const authEclexiaUser = (actions?: IAction[]) => {
    return eclexiaLoginActionBuilder(ECLEXIA_CREDENTIALS, {}, {}, {}, {actions})
}

const buildEclexiaGettingVideoAction = (queryParams: IQueryParameter) => {
    return eclexiaGetVideosActionBuilder(
        null,
        {
            [CONTENT_TYPE_KEY]: JSON_CONTENT_TYPE,
        },
        {},
        {...queryParams},
    )
}

export const eclexiaGetVideosBuilder = (pageSize?: number, page?: number, searchText?: string) => {
    /** APIs have a lot of query params. Check swagger: GET /apirest/v4/Videos */
    return buildEclexiaGettingVideoAction(
        {
            ...(page ? {page} : {}),
            ...(searchText ? {search_text: searchText} : {}),
            ...(pageSize ? {element: pageSize} : {}),
        },
    )
}

export const eclexiaGetSpecifiedVideoBuilder = (eclexiaVideoId: React.ReactText) => {
    return eclexiaGetSpecifiedVideoActionBuilder(
        null,
        {},
        {id: eclexiaVideoId},
    )
}

export const getEclexiaIFrameUrl = (guid: string, audioOnly: boolean = false, live: boolean = false) =>
    `${ECLEXIA_PLAYER_URL}/${live ? 'live' : 'video'}/${guid}?a=1&ap=1&sp=0&ao=${+audioOnly}&smf=1&roc=1&eaty=0&rn=4`

export const eclexiaGetLiveProgram = (liveProgramId: number) => eclexiaGetLiveProgramActionBuilder({}, {}, {id: liveProgramId})
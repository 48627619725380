import ChipSwitch, {IChipSwitchProps} from 'deziro-web-app-components/shared/utils/ChipSwitch'
import {FormikErrors, FormikTouched} from 'formik/dist/types'

interface IFormikChipSwitchProps extends IChipSwitchProps {
    formik: {
        setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<FormikErrors<any>> | Promise<void>
        touched: FormikTouched<any>
        values: {
            [field: string]: any;
        }
        errors: FormikErrors<any>
    }
    fieldName: string
}

const FormikChipSwitch: React.FunctionComponent<IFormikChipSwitchProps> = props => {

    const {
        formik,
        fieldName,
        ...rest
    } = props

    return (
        <ChipSwitch
            onChange={value => formik.setFieldValue(fieldName, value)}
            value={formik.values[fieldName]}
            {...rest}
        />
    )
}

export default FormikChipSwitch

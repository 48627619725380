import {HttpMethods, IRequestModel} from '../../../dst-react-core/fetch/fetch.types'
import {PUBLIC_ENDPOINT, SEARCH_ENDPOINT} from '../../../dst-react-core/core.api.constants'

export const CAMPAIGNS_ENDPOINT = '/campaigns'
export const FIND_BY_URL_ENDPOINT = '/findByUrl'
export const CAMPAIGN_PAGES_ENDPOINT = '/campaignPages'
export const PAGE_DATA_ENDPOINT = '/pageData'

/**
 * @description Check user => /campaigns/has-accessed-campaign?campaignId=25
 * @return Boolean
 */
export const CAMPAIGN_HAS_ACCESSED_GET_API: IRequestModel = {
    url: `${CAMPAIGNS_ENDPOINT}/has-accessed-campaign`,
    method: HttpMethods.GET,
}

/**
 * @description Request Access => /campaigns/access?campaignId=1&accessKey=DFTPNK1
 * @return None
 */
export const CAMPAIGN_ACCESS_POST_API: IRequestModel = {
    url: `${CAMPAIGNS_ENDPOINT}/access`,
    method: HttpMethods.POST,
}

/**
 * @description Get campaign by url => /campaigns/public/search/findByUrl?url=dstech.localhost:3000/26maggio2013
 * @return Campaign
 */
export const CAMPAIGN_BY_URL_GET_API: IRequestModel = {
    url: `${CAMPAIGNS_ENDPOINT}${PUBLIC_ENDPOINT}${SEARCH_ENDPOINT}${FIND_BY_URL_ENDPOINT}`,
    method: HttpMethods.GET,
}

/**
 * @description Get campaign page date => /campaigns/public/pageData
 * @return Campaign Page
 */
export const CAMPAIGN_PAGE_DATA_GET_API: IRequestModel = {
    url: `${CAMPAIGN_PAGES_ENDPOINT}${PUBLIC_ENDPOINT}${PAGE_DATA_ENDPOINT}`,
    method: HttpMethods.GET,
}

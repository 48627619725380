import {MutableRefObject} from 'react'

export enum EAnchor {
    HOME = 'home',
    WISH = 'wish',
    ADS = 'ads',
    FAQ = 'faq'
}

export type ISectionAnchors = {
    [key in EAnchor]: MutableRefObject<HTMLDivElement | null>
}

export interface ISectionProps {
    anchors: ISectionAnchors,
    preview?: boolean
}
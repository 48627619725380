import {INPUT_NUMBER_DISABLED_VALUES, LAST_ACTION_KEY} from '../core.constants'

export const DEVELOPMENT = 'development'
export const PRODUCTION = 'production'

export const isInDevMode = (): boolean => {
    return process.env.NODE_ENV === DEVELOPMENT
}

export const isInProductionMode = (): boolean => {
    return process.env.NODE_ENV === PRODUCTION
}

/**
 * 'KEY_NAME' -> 'Key name'
 * @param key
 */
export const key2prettyName = (key: string): string =>
    (key.charAt(0).toUpperCase() + key.slice(1).toLowerCase())
        .replaceAll('_', ' ')


export const updateLastAction = (force?: boolean) => {
    if (!(force || localStorage.getItem(LAST_ACTION_KEY))) return
    localStorage.setItem(LAST_ACTION_KEY, `${Date.now()}`)
}


/**
 * Inserts id inside string, returns new string
 * @param str string to insert
 * @param id value to insert
 *
 * @example insertId('/campaign/:id/auth', 12) === '/campaign/12/auth'
 *
 */
export const insertId = (str: string, id: string) => str.replace(':id', id)


/**
 * Extracts id from url
 * @param str url
 * @example extractId('/12/auth') === '12'
 */
export const extractId = (str: string): string | null => str.match(/\d+/)?.[0] ?? null


/**
 * Extracts campaign name from url
 * @param str url
 * @example extractId('/CampaignName/auth') === 'CampaignName'
 */
export const extractCampaignName = (str: string): string | null => str.split('/')[1]


/**
 * Returns s string with fixed numbers
 * @example numberDigitFormat(2,2) // 02
 * @param num Number to format
 * @param minIntCount Min number of digits before dot
 * @param minFloatCount Min number of digits after dot
 */
export const numberDigitFormat = (num: number, minIntCount: number = 1, minFloatCount: number = 1): string =>
    num.toLocaleString('en-US', {
        minimumIntegerDigits: minIntCount,
        useGrouping: false,
        maximumFractionDigits: minFloatCount,
    })


/**
 * Checks is string is number, returns boolean
 * @param value
 */
export const checkIsStringNumber = (value: string): boolean =>
    !isNaN(Number(value))


/**
 * Turns Enum to array
 * @param value
 */
export const enum2Array = (value: object): [string, any][] =>
    Object.keys(value)
        .filter(checkIsStringNumber)
        .map((key: string) => [key, value[key as keyof typeof value]])

/**
 * Converts email and password to Basic token
 * @param email
 * @param password
 */
export const credentials2BasicToken = (email: string, password: string): string =>
    'Basic ' + btoa(`${email}:${password}`)

export const inputNumberKeydownCallback = (e: any) => {
    if (INPUT_NUMBER_DISABLED_VALUES.includes(e.key)) {
        e.preventDefault()
    }
}

/**
 * Adds url prefix if string without it
 * @param url
 */
export const prepareExternalLink = (url: string) => url.match(/^(http:\/\/|https:\/\/|www\.)/) ? url : `http://${url}`

/**
 * Returns true if object`s values are equal
 * @param obj1
 * @param obj2
 */
export const checkIsObjectEqual = (obj1: object, obj2: object) => JSON.stringify(obj1) === JSON.stringify(obj2)

/**
 * Converts blob to file url
 * @param blob
 */
export const blob2url = (blob: Blob) => window.URL.createObjectURL(blob)

/**
 * Starts file downloading
 * @param url
 * @param name
 */
export const downloadFile = (url: string, name: string) => {
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', name)

    document.body.appendChild(link)
    link.click()

    link.remove()
}

/**
 * Scrolls to HTML Element
 * @param el HTML Element
 */
export const scrollToElement = (el: Element) => {
    el.scrollIntoView({behavior: 'smooth'})
}

/**
 * Returns new debounced function
 * @param f
 * @param ms
 */
export const debounce = <T extends []>(f: (...args: T) => any, ms: number): (...args: T) => void => {
    let isCooldown = false

    return (...args: T) => {
        if (isCooldown) return
        isCooldown = true
        setTimeout(() => isCooldown = false, ms)

        f(...args)
    }

}

/**
 * Returns true if element`s left and right top corners in viewport
 * @param el HTML element
 */
export const checkIsInViewport = (el: HTMLElement) => {
    if (!el) return false

    const rect = el.getBoundingClientRect()
    const vWidth = window.innerWidth || document.documentElement.clientWidth
    const vHeight = window.innerHeight || document.documentElement.clientHeight
    const efp = (x: number, y: number) => document.elementFromPoint(x, y)

    // Return false if it's not in the viewport
    if (rect.right < 0 || rect.bottom < 0
        || rect.left > vWidth || rect.top > vHeight)
        return false

    // Return true if any of its four corners are visible
    return (
        el.contains(efp(rect.left, rect.top))
        || el.contains(efp(rect.right, rect.top))
        || el.contains(efp(rect.left, rect.bottom))
        || el.contains(efp(rect.right, rect.bottom))
    )
}

/**
 * Returns valid multiple query string value ('param1,param2,param3')
 * @param params are string array
 */
export const joinMultipleQueryParams = (params: string[]) => {
    return params.join(',')
}

export const cssBackgroundUrl = (url: string) => `url(${url})`

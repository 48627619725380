import {FC, memo} from 'react'
import {IContainerProfileSubHeaderProps} from './ContainerProfileSubHeader'
import {ContainerCustomAvatar} from '../../../../../../../shared/components/common/avatar/ContainerCustomAvatar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import LogOutIcon from '../../../../../../../shared/components/muiIcons/LogOutIcon'
import {PROFILE_SUB_HEADER_MEASUREMENTS} from './profile-sub-header.constants'

interface IProfileSubHeaderProps extends IContainerProfileSubHeaderProps {
    subHeaderClasses: any,
    personName: string,
    logout: () => void,
    logoutLabel: string,
    isMobile: boolean
}

export const ProfileSubHeader: FC<IProfileSubHeaderProps> = memo(props => {
    const {
        subHeaderClasses,
        personName,
        logout,
        logoutLabel,
        isMobile,
    } = props

    return (
        <div className={subHeaderClasses.header}>
            <div className={'user'}>
                <div className="avatar">
                    <ContainerCustomAvatar
                        size={isMobile ? PROFILE_SUB_HEADER_MEASUREMENTS.avatarMobileSize : PROFILE_SUB_HEADER_MEASUREMENTS.avatarSize}
                    />
                </div>
                <div className="info">
                    <Typography className={'userName'}>
                        {personName}
                    </Typography>

                    <Typography className={'extraInfo'}>
                        Lorem ipsum dolor sit amet, consectetur.
                    </Typography>
                </div>
            </div>
            {
                !isMobile && (
                    <div className="actions">
                        <Button
                            startIcon={<LogOutIcon/>}
                            onClick={logout}
                            className={'logout'}
                        >
                            {logoutLabel}
                        </Button>
                    </div>
                )
            }
        </div>
    )
})
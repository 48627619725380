import {imageSelector, IWebAppRootState} from 'redux/app.reducers'
import {HomepageState, IMyCampaign} from '../homepage.types'
import {createSelector} from 'reselect'
import {IImagesState} from 'dst-react-core/image/image.types'

export const homepageRootSelector = (state: IWebAppRootState): HomepageState => state.homepage

export const myCampaignsSelector = createSelector(
    homepageRootSelector,
    (homepage: HomepageState): IMyCampaign[] | null => homepage?.myCampaigns ?? null,
)

export const myCampaignsImageSelector = (id: number) => createSelector(
    imageSelector,
    (images: IImagesState | null): string => images?.[id] || '',
)
import * as React from 'react'
import {Redirect, Route, Switch, useParams} from 'react-router-dom'
import {useResetPasswordStyles} from '../../../components/auth/reset-password/reset-password.styles'
import {CAMPAIGN_AUTH_RESET_PASSWORD_SUBROUTING} from './campaign-auth-reset-rassword.routes'
import {IRouteId} from 'dst-react-core/core.types'
import ResetPasswordEmail from '../../../components/auth/reset-password/email/ResetPasswordEmail'
import ResetPasswordPassword from '../../../components/auth/reset-password/password/ResetPasswordPassword'
import Box from '@material-ui/core/Box'
import {insertId} from 'dst-react-core/utils/shared.utils'
import { CampaignAuthHeaderContainer } from 'deziro-web-app-components/components/login/components/CampaignAuthHeaderContainer'
import { CAMPAIGN_AUTH_LOGIN_PATH } from 'web-app/campaign/auth/campaign-auth.routes'

const CampaignAuthResetPasswordRoute: React.FunctionComponent = () => {

    const {id} = useParams<IRouteId>()

    const classes = useResetPasswordStyles()

    return (
        <Box className={classes.root}>
            <CampaignAuthHeaderContainer path={CAMPAIGN_AUTH_LOGIN_PATH} />
            <Box className={classes.container}>
                <Box className={classes.content}>
                    <Switch>

                        {/* ROUTE TO GENERAL AUTH LOGIN*/}
                        <Route path={CAMPAIGN_AUTH_RESET_PASSWORD_SUBROUTING.email.path}>
                            <ResetPasswordEmail />
                        </Route>

                        {/* ROUTE TO GENERAL AUTH PASSWORD*/}
                        <Route path={CAMPAIGN_AUTH_RESET_PASSWORD_SUBROUTING.password.path}>
                            <ResetPasswordPassword />
                        </Route>

                        {/* DEFAULT */}
                        <Route>
                            <Redirect to={insertId(CAMPAIGN_AUTH_RESET_PASSWORD_SUBROUTING.email.path, id)} />
                        </Route>

                    </Switch>
                </Box>
            </Box>
        </Box>
    )
}

export default CampaignAuthResetPasswordRoute

import makeStyles from '@material-ui/core/styles/makeStyles'
import {flex, important} from '../../../../../../dst-react-core/utils/styles.utils'
import {GENERAL_HEADER_FILTER_GRADIENT} from '../../../../../../material.theme'

export const useGeneralHeaderSearchBarStyles = makeStyles((theme) => ({
    searchbarWrap: {
        width: '100%',
        ...flex(true),
        '& .searchInputWrap': {
            '& .searchInput': {
                height: '90px',
                color: '#fff',
                paddingLeft: 30,
            },
            '& .chip': {
                marginRight: 10,
            },
        },
        '& .searchInputWrapMobile': {
            border: important(0),
            outline: 'none',
            '& .searchInput': {
                padding: important('10px 15px'),
                height: important('auto'),
                background: GENERAL_HEADER_FILTER_GRADIENT,
                color: theme.palette.text.primary,
                borderRadius: 12,
            },
            '& .endAdornment': {
                '& .icon': {
                    width: important('25px'),
                    height: important('25px'),
                    cursor: 'pointer',
                },
            },
        },
    },
}))
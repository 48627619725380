import * as React from 'react'
import {useTranslation} from 'react-i18next'
import clsx from 'clsx'
import {scrollToElement} from 'dst-react-core/utils/shared.utils'
import Button from '@material-ui/core/Button'
import {useHeaderStyles} from '../../../shared/styles/header.styles'
import {memo} from 'react'

interface IWIshPageHeaderWishButtonProps {
    element?: Element,
    campaignHeaderClasses: any
}

export const WishPageHeaderWishButton: React.FunctionComponent<IWIshPageHeaderWishButtonProps> = memo(({element, campaignHeaderClasses}) => {

    const {t} = useTranslation()

    const classes = useHeaderStyles({})

    return (
        <Button
            variant={'contained'}
            className={clsx(classes.primaryButton, campaignHeaderClasses.primaryButton)}
            onClick={() => scrollToElement(element as Element)}
        >{t('header.my_wish')}</Button>
    )
})

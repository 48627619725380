import {HttpMethods, IRequestModel} from '../../fetch/fetch.types'
import {DOWNLOAD_ENDPOINT, FIND_ALL_ENDPOINT, SEARCH_ENDPOINT, UPLOAD_ENDPOINT} from '../../core.api.constants'

export const GIFT_FILES_ENDPOINT = '/giftFiles'
export const VALIDATE_NAME_ENDPOINT = '/validate-name'

/**
 * @description Get all gift files => /giftFiles/search/findAll
 * @return File[]
 */
export const GIFT_FILES_ALL_GET_API: IRequestModel = {
    url: `${GIFT_FILES_ENDPOINT}${SEARCH_ENDPOINT}${FIND_ALL_ENDPOINT}`,
    method: HttpMethods.GET,
}

/**
 * @description Validate gift filename => /giftFiles/validate-name?name=test.png
 * @return boolean
 */
export const GIFT_FILES_VALIDATE_NAME_API: IRequestModel = {
    url: `${GIFT_FILES_ENDPOINT}${VALIDATE_NAME_ENDPOINT}`,
    method: HttpMethods.GET,
}

/**
 * @description Upload gift file => /giftFiles/upload
 * @return FIle
 */
export const GIFT_FILES_UPLOAD_API: IRequestModel = {
    url: `${GIFT_FILES_ENDPOINT}${UPLOAD_ENDPOINT}`,
    method: HttpMethods.POST,
}

/**
 * @description Download gift file => /giftFiles/download?campaignId=1
 * @return File data
 */
export const GIFT_FILES_DOWNLOAD_API: IRequestModel = {
    url: `${GIFT_FILES_ENDPOINT}${DOWNLOAD_ENDPOINT}`,
    method: HttpMethods.GET,
    responseType: 'blob',
}

/**
 * @description Delete gift file => /giftFiles/12
 * @return None
 */
export const GIFT_FILES_DELETE_API: IRequestModel = {
    url: `${GIFT_FILES_ENDPOINT}/{id}`,
    method: HttpMethods.DELETE,
}



import * as React from 'react'
import Box from '@material-ui/core/Box'
import makeStyles from '@material-ui/core/styles/makeStyles'
import StarRoundedIcon from '@material-ui/icons/StarRounded'
import clsx from 'clsx'

interface IValuationStarsProps {
    onSelect?: (value: number) => void
    value?: number
}

const useValuationStarsStyles = makeStyles(theme => {

    const starSize = '32px'

    return ({
        root: {
            display: 'flex',
        },
        star: {
            transition: '200ms ease',
            color: theme.palette.grey[300],
            width: `calc(${starSize} + ${theme.spacing(3)}px)`,
            cursor: 'pointer',
            '& svg': {
                height: starSize,
                width: starSize,
            },
            '&:last-child': {
                width: starSize,
            },
            '*:hover > &, &.active': {
                color: theme.palette.primary.main,
            },
            '&:hover ~ *': {
                color: theme.palette.grey[300],
            },
        },
    })
})

const ValuationStars: React.FunctionComponent<IValuationStarsProps> = props => {

    const {
        onSelect,
        value = 0,
    } = props

    const classes = useValuationStarsStyles()

    return (
        <Box className={classes.root}>
            {[...new Array(5)].map((_, i) => <Box
                key={i}
                className={clsx(classes.star, {active: value > i})}
                onClick={() => onSelect && onSelect(i + 1)}
            ><StarRoundedIcon /></Box>)}
        </Box>
    )
}

export default ValuationStars

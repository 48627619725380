import * as React from 'react'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch} from 'react-redux'
import {closeCurrentDialog} from '../../redux/dialogs.actions'
import {useDialogStyles} from '../../dialog.styles'
import clsx from 'clsx'
import Box from '@material-ui/core/Box'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import CloseIcon from '@material-ui/icons/Close'
import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import ValuationStars from './components/ValuationStars'
import ValuationText from './components/ValuationText'

export interface IValuationDialogProps {
    className?: string
    onValuate: (value: number) => void
}

const ValuationDialog: React.FunctionComponent<IValuationDialogProps> = props => {

    const {
        className,
        onValuate,
    } = props

    const dispatch = useDispatch()

    const {t} = useTranslation()

    const classes = useDialogStyles()

    const [value, setValue] = useState<number>(0)

    const handleClose = () => {
        dispatch(closeCurrentDialog())
    }

    const handleValuate = () => {
        onValuate(value)
        handleClose()
    }

    return (
        <Box className={clsx(classes.dialogWrap, className)}>
            <DialogContent>
                <CloseIcon className={classes.closeIcon} onClick={handleClose} />
                <DialogTitle
                    className={classes.dialogTitle}
                    disableTypography
                >{t('dialog.valuation.title')}</DialogTitle>
                <ValuationStars onSelect={setValue} value={value} />
                <ValuationText value={value} />
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button onClick={handleClose} type="button">{t('string.cancel')}</Button>
                <Button
                    onClick={handleValuate}
                    variant={'contained'}
                >{t('dialog.valuation.valuate')}</Button>
            </DialogActions>
        </Box>
    )
}

export default ValuationDialog

import {HttpMethods, IRequestModel} from '../../fetch/fetch.types'
import {LIVE_PROGRAM_ENDPOINT, VIDEOS_ENDPOINT} from '../eclexia.constans'

export const ECLEXIA_LOGIN_API: IRequestModel = {
    url: '',
    method: HttpMethods.POST,
}

export const ECLEXIA_GET_VIDEOS_API: IRequestModel = {
    url: VIDEOS_ENDPOINT,
    method: HttpMethods.GET,
}

export const ECLEXIA_GET_SPECIFIED_VIDEO_API: IRequestModel = {
    url: `${VIDEOS_ENDPOINT}/{id}`,
    method: HttpMethods.GET,
}

export const GET_SPECIFIED_LIVE_PROGRAM_API: IRequestModel = {
    url: `${LIVE_PROGRAM_ENDPOINT}/{id}`,
    method: HttpMethods.GET
}

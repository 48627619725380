import {IAuthState} from '../auth.types'
import {AUTH_INITIAL_STATE} from '../auth.constants'
import {IAction} from 'dst-react-core/core.types'
import {myPersonFetchAction, personUpdateAction} from 'dst-react-core/person/redux/person.actions'
import {AUTH_USER_LOG_OUT_ACTION_TYPE, authAuthorizeUserAction, AUTH_USER_NO_AUTH_TOKEN} from './auth.actions'

export const authReducer = (state: IAuthState | null = AUTH_INITIAL_STATE, action: IAction): IAuthState | null => {
    const _state = state as IAuthState
    switch (action.type) {
        case myPersonFetchAction.successActionType: {
            return {..._state, person: action.payload, isLoggedIn: true}
        }
        case personUpdateAction.successActionType: {
            return {
                ..._state,
                person: action.payload
            }
        }
        case AUTH_USER_NO_AUTH_TOKEN:
        case AUTH_USER_LOG_OUT_ACTION_TYPE:
        case myPersonFetchAction.failureActionType: {
            return {..._state, person: null, isLoggedIn: false}
        }
        case authAuthorizeUserAction.failureActionType: {
            return {
                ..._state,
                isLoginAttemptFailed: true
            }
        }
        case authAuthorizeUserAction.successActionType: {
            return {
                ..._state,
                isLoginAttemptFailed: false
            }
        }
        default:
            return {..._state}
    }
}
import * as React from 'react'
import {useTranslation} from 'react-i18next'
import {PLACEHOLDER_PIC_URL} from 'dst-react-core/core.constants'
import clsx from 'clsx'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import {analyticClickDecorator} from 'dst-react-core/google-analytics/gmt.init'
import {memo} from 'react'

interface IFileGiftSectionProps {
    preview: string | null,
    downloadFileHandler: () => void
}

export const FileGiftSection: React.FunctionComponent<IFileGiftSectionProps> = memo((props) => {
    const {
        preview,
        downloadFileHandler
    } = props

    const {t} = useTranslation()

    return (
        <Box className={clsx('gift', 'fileGift')}>
            <Box className="preview">
                <img src={preview ?? PLACEHOLDER_PIC_URL} alt="file" />
            </Box>
            <Box className="file">
                <Button
                    variant={'contained'}
                    className="button"
                    onClick={analyticClickDecorator('download-file-click', downloadFileHandler)}
                >{t('string.download')}</Button>
            </Box>
        </Box>
    )
})

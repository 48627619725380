import {useDispatch} from 'react-redux'
import {useCallback} from 'react'
import {updatePersonBuilder, updatePrincipalBuilder} from '../../../../components/auth/auth.utils'
import {IPerson} from '../../../../../dst-react-core/person/person.types'
import {IPrincipal} from '../../../../../dst-react-core/principal/principal.types'

export const useUpdateUser = () => {
    const dispatch = useDispatch()

    const updatePrincipal = useCallback(
        (principalId: number, newPrincipal: IPrincipal) => {
            dispatch(updatePrincipalBuilder(principalId, newPrincipal))
        },
        [dispatch],
    )

    const updatePerson = useCallback(
        (personId: number, newPerson: IPerson) => {
            dispatch(updatePersonBuilder(personId, newPerson))
        },
        [dispatch],
    )

    return {
        updatePrincipal,
        updatePerson,
    }
}
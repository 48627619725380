import makeStyles from '@material-ui/core/styles/makeStyles'
import {flex} from 'dst-react-core/utils/styles.utils'
import backgroundImageSrc from 'assets/images/general/profile/profile.png'

export const useProfileStyles = makeStyles(theme => ({
    profileWrap: {
        width: '100%',
        height: '100%',
        color: theme.palette.text.primary,
        background: theme.palette.background.default,
        backgroundImage: `url(${backgroundImageSrc})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right bottom',
        padding: '100px 0 130px',
        [theme.breakpoints.down('sm')]: {
            padding: '40px 0 50px',
        },
        '& .profileHolder': {
            width: '85%',
            margin: '0 auto',
            ...flex(),
            gap: 20,
            '& .pagePart': {
                '&:first-child': {
                    width: '30%',
                    [theme.breakpoints.down('sm')]: {
                        width: '100%',
                    }
                },
                '&:last-child': {
                    width: '70%',
                    [theme.breakpoints.down('sm')]: {
                        width: 0,
                    }
                },
                '& .navigationMenu': {
                    [theme.breakpoints.down('sm')]: {

                    }
                }
            },
        },
    },
    titleWrap: {
        '& .title': {
            fontWeight: 700,
            fontSize: 24,
        },
        '& .subtitle': {
            fontWeight: 400,
            fontSize: 14,
            color: theme.palette.text.secondary,
        },
        '& .summaryTitle': {
            fontWeight: 400,
            fontSize: 20,
        },
    },
}))
import { useCampaignImage } from 'deziro-web-app-components/components/hooks/campaign-image.hook'
import { campaignImageSelector } from 'deziro-web-app-components/redux/selectors'
import { EBackgroundType, ICampaignPageSectionStyle } from 'dst-react-core/campaign/campaign.types'
import { useMemo } from 'react'

export const useCampaignImageWrapper = (id: number | null | undefined, fetchIfAbsent: boolean = true) => {
    return useCampaignImage(id, fetchIfAbsent, campaignImageSelector as any)
}

export const useBackgroundType = (styles?: ICampaignPageSectionStyle | null) => {
    const isBgTypeImage = styles?.backgroundType === EBackgroundType.IMAGE

    const bgImage = useCampaignImageWrapper(styles?.backgroundImage?.id ?? null, isBgTypeImage)

    return useMemo(() => {
        if (!styles) return ''
        if (isBgTypeImage) return `url(${bgImage})`
        else return styles.backgroundColorHex + ''
    }, [styles, bgImage, isBgTypeImage])
}

import {useTranslation} from 'react-i18next'
import {useMemo} from 'react'
import {IFaqRecord} from 'dst-react-core/campaign/campaign.types'
import {DEZIRO_CONTACT_US_EMAIL, DEZIRO_ME} from '../../../shared/shared.constants'

export interface IFAQItemLink {
    title: string,
    href: string
}

export const useFAQContent: () => IFaqRecord[] = () => {
    const {t} = useTranslation()


    return useMemo(() => {
        const content: IFaqRecord[] = []

        for (let i = 1; i <= 7; i++) {
            content.push({
                previewText: t(`faq_content.${i}.title`),
                expandedText: t(`faq_content.${i}.description`),
                topic: t(`faq_content.${i}.topic`)
            })
        }

        content[0].link = {href: DEZIRO_ME, title: 'deziro.me'}
        content[5].link = {href: `mailto:${DEZIRO_CONTACT_US_EMAIL}`, title: DEZIRO_CONTACT_US_EMAIL}

        return content
    }, [t])
}
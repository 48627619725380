import makeStyles from '@material-ui/core/styles/makeStyles'
import mainHomepageBackgroundSrc from '../../../../assets/images/general/homepage/main-background.png'

export const useGeneralHomepageStyles = makeStyles(() => ({
    homepageMainWrap: {
        width: '100%',
        backgroundImage: `url(${mainHomepageBackgroundSrc})`,
        background: '#0F0E14',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
}))
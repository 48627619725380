import makeStyles from '@material-ui/core/styles/makeStyles'

export const useGeneralDashboardStyles = makeStyles(() => ({
    dashboardWrap: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        '& .footer': {
            marginTop: 'auto',
        },
        '& .mainContent': {
        },
    },
}))
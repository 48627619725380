import * as React from 'react'
import {useHistory} from 'react-router-dom'
import Link, {LinkProps} from '@material-ui/core/Link'

export interface IGenericLinkProps extends LinkProps {
    mailto?: boolean
}

const GenericLink: React.FunctionComponent<IGenericLinkProps> = props => {

    const history = useHistory()

    const preventDefault = (event: React.SyntheticEvent) => {
        if (props.mailto && props.href) {
            window.location.href = props.href

            return
        }

        event.preventDefault()
        props.href && history.push(props.href)
    }

    return (
        <Link onClick={preventDefault} {...props}>
            {props.children}
        </Link>
    )
}

export default GenericLink

import {IFetchAction, WITH_NO_SPINNER} from '../../fetch/fetch.types'
import {combineEpics, Epic, ofType} from 'redux-observable'
import {GLOBAL_SPINNER_ID, spinnerRemove} from './spinner.action'
import {filter, flatMap, map} from 'rxjs/operators'
import {Observable} from 'rxjs'
import {BACK_OFFICE_USER_LOGGED_IN, BACK_OFFICE_USER_LOGGED_OUT} from '../../principal/redux/principal.actions'
import {TYPE_FETCH_CONST} from '../../fetch/fetch.action.factory'
import {fetchEpicsWithSpin} from '../../fetch/fetch.call'

/**
 * Removes the global spinner when the specified actions have been dispatched
 */
export const globalSpinnerRemoveEpic = (action$: Observable<IFetchAction>) =>
    action$.pipe(
        ofType(
            BACK_OFFICE_USER_LOGGED_IN,
            BACK_OFFICE_USER_LOGGED_OUT,
        ),
        map(() => spinnerRemove(GLOBAL_SPINNER_ID)),
    )

/**
 * @category general epics to intercept all fetch request, action are filtered by WITH_NO_SPINNER const and TYPE_FETCH_CONST
 * @param action$
 */
export const generalSpinnerEpics = (action$: Observable<IFetchAction>) =>
    action$.pipe(
        filter((action: IFetchAction) => action.type.includes(WITH_NO_SPINNER) && action.type.includes(TYPE_FETCH_CONST)),
        flatMap(fetchEpicsWithSpin),
    )

const spinnerActions: Epic = combineEpics(
    globalSpinnerRemoveEpic,
    generalSpinnerEpics,
)

export default spinnerActions
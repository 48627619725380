import {combineEpics, Epic} from 'redux-observable'
import failureActions from './error-handling/error.epics'
import spinnerActions from './spinner/redux/spinner.epics'
import principalActions from './principal/redux/principal.actions'
import personActions from './person/redux/person.actions'

export const rootEpics: Epic = combineEpics(
    failureActions,
    spinnerActions,
    principalActions,
    personActions,
)
import {FC, memo} from 'react'
import {IContainerAccessedCampaignItemProps} from './ContainerAccessedCampaignItem'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import Button from '@material-ui/core/Button'
import {CustomRating} from 'shared/components/common/rating/CustomRating'
import defaultPreviewImageSrc from 'assets/images/logo.png'
import clsx from 'clsx'
import {ECampaignStatus} from 'dst-react-core/campaign/campaign.types'

interface IAccessedCampaignItemProps extends IContainerAccessedCampaignItemProps {
    accessedCampaignItemClasses: any,
    viewWishLabel: string,
    giftTypeTitle: JSX.Element,
    chipLabel: string,
    campaignLogoImageSrc: string,
    campaignPreviewImageSrc: string
}

export const AccessedCampaignItem: FC<IAccessedCampaignItemProps> = memo(props => {
    const {
        accessedCampaignItemClasses,
        campaignLogoImageSrc,
        campaignName,
        campaignDescription,
        campaignPreviewImageSrc,
        campaignReview,
        viewWishLabel,
        goToCampaignPage,
        giftTypeTitle,
        chipLabel,
        campaignStatus,
    } = props

    const isCampaignDisabled =
        campaignStatus === ECampaignStatus.CLOSED ||
        campaignStatus === ECampaignStatus.DRAFT ||
        campaignStatus === ECampaignStatus.PAUSED

    return (
        <div className={clsx(accessedCampaignItemClasses.card, {disabled: isCampaignDisabled})}>
            <div className="cardHeader">
                <div className={'infoWrap'}>
                    <div className={'logoWrap'}>
                        <div className="logo">
                            <img
                                src={campaignLogoImageSrc}
                                alt={'logo'}
                                className={'logoImg'}
                            />
                        </div>
                    </div>

                    <div className={'contentWrap'}>
                        <Typography className={'title'}>
                            {campaignName}
                        </Typography>

                        <Typography className={'campaignType'}>
                            {giftTypeTitle}
                        </Typography>
                    </div>
                </div>

                <div className={'chipWrap'}>
                    <Chip label={chipLabel} color={'primary'}/>
                </div>
            </div>
            <div className="cardPreview">
                <img
                    className={clsx('previewImg', {defaultPreviewImg: !campaignPreviewImageSrc})}
                    src={campaignPreviewImageSrc || defaultPreviewImageSrc}
                    alt={'preview'}
                />
            </div>
            <div className="cardInfo">
                <Typography className={'title'}>
                    {campaignDescription}
                </Typography>

                <Typography className={'review'}>
                    <CustomRating rating={campaignReview}/>
                </Typography>
            </div>
            <div className="cardActions">
                <Button
                    variant={'contained'}
                    fullWidth
                    className={'seeWishButton'}
                    disabled={isCampaignDisabled}
                    onClick={goToCampaignPage}
                >
                    {viewWishLabel}
                </Button>
            </div>
        </div>
    )
})

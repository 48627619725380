import React, {FC, memo} from 'react'
import {IContainerSideMenuFilterSelectProps} from './ContainerSideMenuFilterSelect'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import clsx from 'clsx'

interface ISideMenuFilterSelectProps extends IContainerSideMenuFilterSelectProps {
    sideMenuFilterSelectClasses: any,
}

export const SideMenuFilterSelect: FC<ISideMenuFilterSelectProps> = memo(props => {
    const {
        title,
        sideMenuFilterSelectClasses,
        options,
        selectedValue,
        onChange,
        resetValue
    } = props

    return (
        <Accordion
            className={sideMenuFilterSelectClasses.accordion}
        >
            <AccordionSummary
                className={'summary'}
                expandIcon={<ArrowDropDownIcon className={'icon'}/>}
            >
                <Typography className={'title'}>
                    {title}
                </Typography>
            </AccordionSummary>

            <AccordionDetails>
                <ul className={'options'}>
                    {
                        options.map(opt => {
                            const isSelected = opt.value === `${selectedValue}`

                            return (
                                <li
                                    key={opt.value + Math.random()}
                                    className={clsx({selected: isSelected})}
                                    onClick={() => onChange(isSelected ? resetValue : opt.value)}
                                >
                                    {
                                        opt.label
                                    }
                                </li>
                            )
                        })
                    }
                </ul>
            </AccordionDetails>
        </Accordion>
    )
})

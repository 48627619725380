import makeStyles from '@material-ui/core/styles/makeStyles'
import {GENERAL_HEADER_FILTER_GRADIENT} from '../../../../../../material.theme'

export const useGeneralHeaderFilterAppBarStyles = makeStyles(() => ({
    root: {
        background: GENERAL_HEADER_FILTER_GRADIENT,
        zIndex: 1,
        height: '100%',
        '&.MuiPaper-root': {
        },
    },
}))
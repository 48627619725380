import {FC, memo} from 'react'
import {IContainerAccessedCampaignListProps} from './ContainerAccessedCampaignList'
import {ContainerAccessedCampaignItem} from './campaign-item/ContainerAccessedCampaignItem'
import Typography from '@material-ui/core/Typography'
import {IMyCampaign} from '../../homepage.types'

export interface IAccessedCampaignListProps extends IContainerAccessedCampaignListProps {
    campaignListClasses: any,
    myCampaigns: IMyCampaign[] | null,
    noCampaignsFoundLabel: string,
    goToCampaignPage: (campaign: IMyCampaign) => void
}

export const AccessedCampaignList: FC<IAccessedCampaignListProps> = memo(props => {
    const {
        campaignListClasses,
        myCampaigns,
        noCampaignsFoundLabel,
        goToCampaignPage,
    } = props

    return (
        <div className={campaignListClasses.listWrap}>
            {
                myCampaigns?.map((campaign: IMyCampaign) => (
                    <div className={'listItem'} key={campaign.id}>
                        <ContainerAccessedCampaignItem
                            campaignName={campaign.companyName}
                            campaignDescription={campaign.title}
                            campaignLogoImageId={campaign.logoImageId}
                            campaignPreviewImageId={campaign.giftPreviewImageId}
                            campaignReview={campaign.reviewRating}
                            campaignGiftType={campaign.giftType}
                            campaignStatus={campaign.status}
                            goToCampaignPage={() => goToCampaignPage(campaign)}
                        />
                    </div>
                ))
            }
            {
                !myCampaigns?.length && (
                    <Typography className={campaignListClasses.noItemsTitle}>
                        {noCampaignsFoundLabel}
                    </Typography>
                )
            }
        </div>
    )
})
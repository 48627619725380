import makeStyles from '@material-ui/core/styles/makeStyles'
import {
    ICampaignPageGlobal,
    ICampaignPageSectionContent,
    ICampaignPageSectionStyle,
} from 'dst-react-core/campaign/campaign.types'
import {useMemo} from 'react'
import {darken, Theme} from '@material-ui/core/styles'
import {font} from 'dst-react-core/utils/styles.utils'
import {cssBackgroundUrl} from 'dst-react-core/utils/shared.utils'
import {useCampaignImage} from 'deziro-web-app-components/components/hooks/campaign-image.hook'

export interface IBaseSectionStylesIncomingSettings {
    styleValues?: ICampaignPageSectionStyle,
    contentValues?: ICampaignPageSectionContent,
    globalValues?: ICampaignPageGlobal,
    bg?: string
}

export const useHeaderSectionStyles = (settings: IBaseSectionStylesIncomingSettings) => {
    return useMemo(() => makeStyles(() => ({
        root: {
            background: settings?.bg,
            fontFamily: font(settings?.globalValues?.globalFont),
            '& .title': {
                color: settings?.contentValues?.titleColorHex,
                fontFamily: font(settings?.globalValues?.titleFont),
            },
            '& .subtitle': {
                color: settings?.contentValues?.subtitleColorHex,
                fontFamily: font(settings?.globalValues?.subtitleFont),
            },
            '& .paragraph': {
                color: settings?.contentValues?.paragraphColorHex,
                fontFamily: font(settings?.globalValues?.paragraphFont),
            },
            '& .MuiButton-contained': {
                backgroundColor: settings?.styleValues?.buttonBackgroundColorHex,
                color: settings?.styleValues?.buttonLinkColorHex,
            },
            '& .MuiButton-text': {
                color: settings?.styleValues?.linkColorHex,
            },
        },
    })), [settings])()
}

export const useGiftSectionStyles = (setting: IBaseSectionStylesIncomingSettings) => {

    return useMemo(() => makeStyles(() => ({
        root: {
            background: setting?.bg,
            fontFamily: font(setting?.globalValues?.globalFont),
            '& .title': {
                color: setting?.contentValues?.titleColorHex,
                fontFamily: font(setting?.globalValues?.titleFont),
            },
            '& .subtitle': {
                color: setting?.contentValues?.subtitleColorHex,
                fontFamily: font(setting?.globalValues?.subtitleFont),
            },
            '& .stars ': {
                color: setting?.globalValues?.inputTextColorHex,
                '& .counter': {
                    color: setting?.styleValues?.linkColorHex,
                },
                '& .MuiSvgIcon-root': {
                    color: setting?.contentValues?.subtitleColorHex,
                    '&.filled': {
                        color: setting?.styleValues?.linkColorHex,
                    },
                },
            },
            '& .reviewTitle': {
                color: setting?.contentValues?.reviewTitleColorHex,
            },
            '& .reviewText': {
                color: setting?.contentValues?.reviewBodyColorHex,
                fontFamily: font(setting?.globalValues?.globalFont),
            },
            '& .button': {
                backgroundColor: setting?.styleValues?.buttonBackgroundColorHex,
                color: setting?.styleValues?.buttonLinkColorHex,
            },
            '& .gift': {
                '& .MuiAccordion-root': {
                    '& .MuiSvgIcon-root': {
                        color: setting?.globalValues?.inputTextColorHex,
                    },
                    '& .MuiAccordionDetails-root': {
                        '& .MuiTypography-caption': {
                            color: setting?.globalValues?.inputTextColorHex,
                        },
                    },
                },
            },
            '& .voucherCard': {
                '& .topic': {
                    color: setting?.globalValues?.inputTextColorHex,
                },
                '& .MuiSvgIcon-root': {
                    color: setting?.styleValues?.linkColorHex,
                },
                '& .previewText': {
                    fontFamily: font(setting?.globalValues?.titleFont),
                },
                '& .description': {
                    fontFamily: font(setting?.globalValues?.subtitleFont),
                },
                '& .expandedText': {
                    color: darken(setting?.globalValues?.inputTextColorHex ?? '#000', 0.8),
                },
                '& .MuiButton-contained': {
                    backgroundColor: setting?.styleValues?.buttonBackgroundColorHex,
                    color: setting?.styleValues?.buttonLinkColorHex,
                },
            },
        },
    })), [setting])()

}

export const useCrossSellingSectionStyles = (setting: IBaseSectionStylesIncomingSettings) => {
    return useMemo(() => makeStyles(() => ({
        root: {
            background: setting?.bg,
            fontFamily: font(setting?.globalValues?.globalFont),
            '& .title': {
                color: setting?.contentValues?.titleColorHex,
                fontFamily: font(setting?.globalValues?.titleFont),
            },
            '& .subtitle': {
                color: setting?.contentValues?.subtitleColorHex,
                fontFamily: font(setting?.globalValues?.subtitleFont),
            },
            '& .socialBar': {
                color: setting?.globalValues?.inputTextColorHex,
                '& .MuiButton-contained': {
                    borderColor: 'transparent',
                    backgroundColor: setting?.contentValues?.socialIconColorHex,
                    '& .icon': {
                        '& path': {
                            fillOpacity: 0.7
                        }
                    },
                },
                '& .MuiButton-outlined': {
                    borderColor: setting?.contentValues?.socialIconColorHex,
                    '& .icon': {
                        '& path': {
                            fill: setting?.contentValues?.socialIconColorHex,
                        }
                    },
                },
            },
        },
    })), [setting])()

}

export const useFaqSectionStyles = (setting: IBaseSectionStylesIncomingSettings) => {
    return useMemo(() => makeStyles(() => ({
        root: {
            background: setting?.bg,
            fontFamily: font(setting?.globalValues?.globalFont),
            '& .title': {
                color: setting?.contentValues?.titleColorHex,
                fontFamily: font(setting?.globalValues?.titleFont),
            },
            '& .subtitle': {
                color: setting?.contentValues?.subtitleColorHex,
                fontFamily: font(setting?.globalValues?.subtitleFont),
            },
            '& .questionCard': {
                boxShadow: '0px 8px 24px rgba(52, 54, 78, 0.15)',
                '& .topic': {
                    color: setting?.styleValues?.linkColorHex,
                    fontWeight: 500,
                    fontSize: 10,
                    lineHeight: '16px',
                    letterSpacing: '1.5px',
                    textTransform: 'uppercase'
                },
                '& .MuiSvgIcon-root': {
                    color: setting?.styleValues?.linkColorHex,
                },
                '& .previewText': {
                    color: darken(setting?.globalValues?.inputTextColorHex ?? '#000', 0.7),
                    fontWeight: 'bold',
                    fontSize: 20,
                    lineHeight: '24px',
                    letterSpacing: '.15px'
                },
                '& .expandedText': {
                    color: darken(setting?.globalValues?.inputTextColorHex ?? '#000', 0.8),
                },
            },
        },
    })), [setting])()
}

export const useEntirePageStyles = (settings: IBaseSectionStylesIncomingSettings, selector: any) => {
    const sideImageId = settings?.styleValues?.sideImage?.id

    const imgUrl = useCampaignImage(sideImageId, true, selector as any)

    return useMemo(() => makeStyles((theme: Theme) => ({
        root: {
            width: '100%',
            height: '100%',
            display: 'flex',
            position: 'relative',
        },
        side: {
            width: '50%',
            height: '100%',
            overflow: 'auto',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            display: 'flex',
            flexDirection: 'column',
            '&.right': {
                backgroundImage: cssBackgroundUrl(imgUrl!),
                height: '100%',
            },
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                '&.right': {
                    display: 'none',
                },
            },
        },
        contentWrapper: {
            height: '100%',
            overflow: 'auto',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            background: settings?.bg,
            '&::-webkit-scrollbar': {
                width: 8,
                background: 'transparent',
            },
            '&::-webkit-scrollbar-track': {
                background: 'transparent',

            },
            '&::-webkit-scrollbar-thumb': {
                background: settings?.globalValues?.inputTextColorHex,
                borderWidth: 0,
            },
        },
        content: {
            height: 'fit-content',
            padding: theme.spacing(3, 4, 0),
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(3, 2, 0),
            },
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            // if block is too small min height should be 100% of wrapper - footer height - header height
            minHeight: 'calc(100% - 105px - 88px)',
        },
        paper: {
            width: '100%',
            maxWidth: 590,
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(3),

            '& span > a, .MuiIconButton-label': {
                color: (settings?.styleValues?.linkColorHex),
            },

            '& input:checked': {
                color: settings?.globalValues?.inputTextColorHex,
            },

            '& > label > span.Mui-checked > span': {
                color: settings?.globalValues?.inputTextColorHex,
            },
        },
    })), [settings, imgUrl])()
}

export type TEntirePageClasses = ReturnType<typeof useEntirePageStyles>
import * as React from 'react'
import {memo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {IVoucherGift} from 'dst-react-core/voucher/voucher.types'
import {IVoucher} from 'dst-react-core/campaign/campaign.types'
import Box from '@material-ui/core/Box'
import Accordion from '@material-ui/core/Accordion'
import AddRoundedIcon from '@material-ui/icons/AddRounded'
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded'
import Typography from '@material-ui/core/Typography'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Avatar from '@material-ui/core/Avatar'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import {CodeCopyField} from './CodeCopyField'

interface IVoucherAccordionProps {
    voucher: IVoucherGift | null | undefined
    design: IVoucher | null | undefined,
    partnerLogo: string | null,
    cover: string | null
}

export const VoucherAccordion: React.FunctionComponent<IVoucherAccordionProps> = memo((props) => {
    const {
        partnerLogo,
        cover,
        voucher,
        design
    } = props

    const {t} = useTranslation()

    const [expanded, setExpanded] = useState(false)

    return ((voucher && design) ?
        <Box>
            <Accordion
                expanded={expanded}
                onChange={(e, expanded) => setExpanded(expanded)}
                elevation={24}
                TransitionProps={{
                    unmountOnExit: true,
                }}
                className="voucherCard"
            >
                <AccordionSummary expandIcon={expanded ? <RemoveRoundedIcon /> : <AddRoundedIcon />}>
                    <Avatar src={partnerLogo ?? ''} />
                    <Box>
                        <Typography className="topic" variant={'overline'}>{t('section.gift.voucher')}</Typography>
                        <Typography className="previewText" variant={'h6'}>{design.titleText}</Typography>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <Box className="preview">
                        <img className="preview" src={cover ?? ''} alt={design.titleText ?? ''} />
                    </Box>
                    <Box className="content">
                        <Box>
                            <Typography
                                variant={'subtitle1'}
                                className="description"
                            >{design.descriptionText}</Typography>
                            <CodeCopyField code={voucher.activationKey} />
                        </Box>
                        <Typography variant={'subtitle2'} className="terms">{design.termsAndConditionsText}</Typography>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </Box>
        : null)
})

import {FC} from 'react'
import GeneralDashboardRoute from './GeneralDashboardRoute'
import {useGeneralDashboardStyles} from './general-dashboard.styles'
import {ContainerGeneralFooter} from './shared/footer/ContainerGeneralFooter'
import {ContainerHeader} from './shared/header/ContainerHeader'

export const ContainerGeneralDashboard: FC = () => {
    const dashboardClasses = useGeneralDashboardStyles()

    return (
        <div className={dashboardClasses.dashboardWrap}>
            <div className="header">
                <ContainerHeader/>
            </div>

            <div className="mainContent">
                <GeneralDashboardRoute/>
            </div>

            <div className="footer">
                <ContainerGeneralFooter/>
            </div>
        </div>
    )
}
import {FC, memo} from 'react'
import {IContainerProfileNavigationMenuProps} from './ContainerProfileNavigationMenu'
import {IProfileNavigationConfig} from './hooks/profile-navigation.hook'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'

export interface IProfileNavigationMenuProps extends IContainerProfileNavigationMenuProps {
    navigationClasses: any,
    menuItems: IProfileNavigationConfig[],
    goToPage: (endpoint: string) => void,
    getIsMenuItemActive: (endpoint: string) => boolean
}

export const ProfileNavigationMenu: FC<IProfileNavigationMenuProps> = memo(props => {
    const {
        navigationClasses,
        menuItems,
        goToPage,
        getIsMenuItemActive,
    } = props

    return (
        <ul className={navigationClasses.navigationMenu}>
            {
                menuItems.map(item => {
                    return (
                        <li
                            key={item.link + Math.random()}
                            onClick={() => goToPage(item.link)}
                            className={clsx({active: getIsMenuItemActive(item.link)})}
                        >
                            <Typography className={'content'}>
                                <span className={'icon'}>
                                    {item.icon}
                                </span>

                                <span className={'title'}>
                                    {item.title}
                                </span>
                            </Typography>
                        </li>
                    )
                })
            }
        </ul>
    )
})
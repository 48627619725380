import {HttpMethods, IRequestModel} from '../../fetch/fetch.types'
import {PUBLIC_ENDPOINT, DOWNLOAD_ENDPOINT} from '../../core.api.constants'

export const IMAGES_ENDPOINT = '/images'

/**
 * @description Image => /images/public/download/1
 * @return ImageFile
 */
export const DOWNLOAD_IMAGE_GET_API: IRequestModel = {
    url: `${IMAGES_ENDPOINT}${PUBLIC_ENDPOINT}${DOWNLOAD_ENDPOINT}/{id}`,
    method: HttpMethods.GET,
    responseType: 'blob',
}
import {HttpMethods, IRequestModel} from '../../../../../dst-react-core/fetch/fetch.types'
import {AUTH_PATH} from '../../../../web-app.routes'
import {CONTENT_TYPE_KEY, JSON_CONTENT_TYPE} from '../../../../../dst-react-core/core.constants'

export const CHANGE_PASSWORD_API: IRequestModel = {
    url: `${AUTH_PATH}/change-password`,
    method: HttpMethods.PATCH,
    headers: {
        [CONTENT_TYPE_KEY]: JSON_CONTENT_TYPE
    }
}

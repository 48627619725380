import {FC, memo} from 'react'
import Typography from '@material-ui/core/Typography'
import {IUserExtraDataItem} from '../ContainerProfileAccountOverview'

interface IAccountOverviewUserExtraDataProps {
    userExtraData: IUserExtraDataItem[],
}

export const AccountOverviewUserExtraData: FC<IAccountOverviewUserExtraDataProps> = memo(props => {
    const {
        userExtraData
    } = props

    return (
        <ul className="extraInfoList">
            {
                userExtraData.map(user => {
                    return (
                        <li key={user.value}>
                            <Typography className={'label'}>
                                {user.label}
                            </Typography>

                            <Typography className={'value'}>
                                {user.value}
                            </Typography>
                        </li>
                    )
                })
            }
        </ul>
    )
})
import {FC, memo} from 'react'
import {IContainerGeneralHeaderSideMenuFilterProps} from './ContainerGeneralHeaderSideMenuFilter'
import {ContainerSideMenuFilterSelect} from './filter-select/ContainerSideMenuFilterSelect'
import Typography from '@material-ui/core/Typography'
import {ECampaignStatus, EGiftType} from '../../../../../../../dst-react-core/campaign/campaign.types'
import {IOption} from '../../../../../../../dst-react-core/core.types'

export interface IGeneralHeaderSideMenuFilterProps extends IContainerGeneralHeaderSideMenuFilterProps {
    sideMenuClasses: any,
    creationDateLabel: string,
    filterPerLabel: string,
    categoryLabel: string,
    statusLabel: string,
    creationDateOptions: IOption[],
    statusOptions: IOption[],
    giftTypeOptions: IOption[]
}

export const GeneralHeaderSideMenuFilter: FC<IGeneralHeaderSideMenuFilterProps> = memo(props => {
    const {
        sideMenuClasses,
        creationDateLabel,
        filterPerLabel,
        creationDateOptions,
        categoryLabel,
        statusLabel,
        setFilterByNewestCallback,
        setFilterByGiftTypeCallback,
        isFilterByNewest,
        filterByGiftType,
        statusOptions,
        giftTypeOptions,
        filterStatus,
        setFilterStatusCallback
    } = props

    return (
        <div className={sideMenuClasses.filterWrap}>
            <Typography className={'title'}>
                {filterPerLabel}
            </Typography>

            <ContainerSideMenuFilterSelect
                selectedValue={isFilterByNewest}
                onChange={
                    value => {
                        setFilterByNewestCallback((value === undefined) ? undefined : (value === 'true'))
                    }
                }
                title={creationDateLabel}
                options={creationDateOptions}
                resetValue={undefined}
            />


            <ContainerSideMenuFilterSelect
                selectedValue={filterByGiftType}
                onChange={
                    value => setFilterByGiftTypeCallback(value as ('' | EGiftType))
                }
                title={categoryLabel}
                options={giftTypeOptions}
                resetValue={''}
            />

            <ContainerSideMenuFilterSelect
                selectedValue={filterStatus}
                onChange={
                    value => setFilterStatusCallback(value as ('' | ECampaignStatus))
                }
                title={statusLabel}
                options={statusOptions}
                resetValue={''}
            />

        </div>
    )
})
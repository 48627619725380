import makeStyles from '@material-ui/core/styles/makeStyles'
import {font} from '../../dst-react-core/utils/styles.utils'

export const useDialogStyles = makeStyles(theme => ({
    dialogWrap: {
        position: 'relative',
    },
    dialogActions: {
        justifyContent: 'space-between',
        padding: theme.spacing(3),
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
    },
    dialogTitle: {
        textAlign: 'center',
        margin: theme.spacing(5.25, 0),
        fontSize: 34,
        lineHeight: '36px',
        color: theme.palette.grey[500],
        fontFamily: font('DM Sans'),
    },
    label: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
        color: 'black',
        fontWeight: 'bold',
        fontSize: 14,
        lineHeight: '24px',
        letterSpacing: 0.1,
    },
    icon: {
        width: 60,
        height: 60,
        backgroundColor: '#F0F0FF',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        color: theme.palette.secondary.main,
    },
    closeIcon: {
        position: 'absolute',
        right: 25,
        top: 25,
        color: '#7F7FEB',
        cursor: 'pointer',
        display: 'block',
    },
}))

import makeStyles from '@material-ui/core/styles/makeStyles'
import {important} from 'dst-react-core/utils/styles.utils'
import {CSSDependsOnBrowserFlexGapSupporting} from 'dst-react-core/utils/css/supports-flex-gap.util'

export const useCampaignSectionStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        minHeight: '100%',
        alignItems: 'center',
        padding: theme.spacing(7, 3, 6, 3),
        backgroundSize: important('cover'),
        backgroundRepeat: important('no-repeat'),
        backgroundAttachment: important('fixed'),
        backgroundPosition: important('center'),
        [theme.breakpoints.down('sm')]: {
            backgroundAttachment: important('initial'),
        },
        '&:first-child': {
            alignItems: 'flex-start',
            [theme.breakpoints.up('sm')]: {
                alignItems: 'center'
            },
            '& > div:first-child': {
                margin: 0,
                '& .title': {
                    marginBottom: 97,
                    lineHeight: '56px',
                    letterSpacing: -1.5
                },
                '& .subtitle': {
                    fontWeight: 'bold',
                    lineHeight: '32px',
                    letterSpacing: 0.18,
                    marginBottom: theme.spacing(1)
                },
                '& .paragraph': {
                    marginBottom: theme.spacing(3)
                }
            },
        },
        '& .heading': {
            margin: theme.spacing(2, 0, 12),
        },
        '& .MuiButton-root:disabled': {
            filter: 'brightness(70%)',
        },
        '& .questionCard, & .voucherCard': {
            backgroundColor: 'white',
            '& .MuiAccordionSummary-content': {
                margin: theme.spacing(4, 0),
            },
            '&.voucherCard': {
                color: theme.palette.grey[800],
                [theme.breakpoints.down('sm')]: {
                    '& .MuiAccordionSummary-expandIcon': {
                        display: 'none',
                    },
                },
                '& .MuiAccordionSummary-root': {
                    '& .MuiAccordionSummary-content': {
                        alignItems: 'center',
                        gap: theme.spacing(3),
                        '& > .MuiAvatar-root': {
                            width: 64,
                            height: 64,
                        },
                    },
                },
                '& .MuiAccordionDetails-root': {
                    padding: theme.spacing(4, 5, 5, 15),
                    display: 'grid',
                    gap: theme.spacing(7),
                    gridTemplateColumns: 'auto 40%',
                    [theme.breakpoints.down('sm')]: {
                        gridTemplateColumns: '100%',
                        padding: theme.spacing(3),
                    },
                    '& .content': {
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        '& .terms': {
                            fontSize: 14,
                            lineHeight: '24px',
                            letterSpacing: 0.1,
                        },
                    },
                },
                '& .description': {
                    marginBottom: theme.spacing(5),
                },
                '& .MuiOutlinedInput-root': {
                    color: theme.palette.text.hint,
                    '& .MuiOutlinedInput-notchedOutline': {
                        ':hover:not(.Mui-focused) > &': {
                            borderColor: theme.palette.grey[100],
                        },
                        '.Mui-focused > &': {
                            borderColor: theme.palette.grey[100],
                            borderWidth: 1,
                        },
                    },
                },
            },
            '&.questionCard ': {
                '& .MuiAccordionSummary-content': {
                    flexDirection: 'column',
                },
            },
            '& .topic': {
                fontSize: 10,
                lineHeight: '10px',
            },
            '& .previewText': {
                fontSize: 20,
                lineHeight: '24px',
            },
        },
    },
    content: {
        width: 'clamp(200px, 100%, 1300px)',
        margin: theme.spacing(2.5),
    },
    buttonRow: {
        display: 'flex',
        gap: theme.spacing(1),
        flexWrap: 'wrap',
    },
    headerSection: {
        '& > .side': {
            width: '50%',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
            '& > .title': {
                marginBottom: theme.spacing(14),
                fontSize: 70,
                fontWeight: 550,
                [theme.breakpoints.down('sm')]: {
                    fontSize: 50,
                },
            },
            '& > .subtitle': {
                marginBottom: theme.spacing(2),
            },
            '& > .paragraph': {
                marginBottom: theme.spacing(5),
            },
        },
    },
    giftSection: {
        '& .info': {
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(2),
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
            },
            '& .MuiAvatar-root': {
                width: 88,
                height: 88,
            },
        },
        [theme.breakpoints.down('sm')]: {
            '& .title, & .subtitle': {
                textAlign: 'center',
                marginBottom: theme.spacing(2),
            },
        },
        '& .review': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: theme.spacing(3),
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                justifyContent: 'flex-start',
                textAlign: 'center',
            },
            '& .reviewTitle': {
                maxWidth: 192,
                order: 1,
            },
            '& .rating': {
                maxWidth: 192,
                order: 3,
                '& .stars': {
                    display: 'flex',
                    alignItems: 'center',
                    '& .counter': {
                        fontSize: 24,
                        marginRight: theme.spacing(1),
                        fontWeight: 700,
                    },
                },
            },
            '& .reviewText': {
                maxWidth: 630,
                order: 3,
                [theme.breakpoints.down('sm')]: {
                    order: 2,
                },
            },
            '& .button': {
                order: 4,
            },
        },
        '& .gift': {
            margin: theme.spacing(5, 0),
            '& .preview': {
                width: '100%',
                '& img': {
                    width: '100%',
                    borderRadius: 8,
                },
            },
            '&.multiplyGift': {
                display: 'flex',
                gap: theme.spacing(3),
                [theme.breakpoints.down('sm')]: {
                    flexDirection: 'column',
                },
                '& .MuiAccordion-root': {
                    backgroundColor: 'white',
                    borderRadius: 8,
                    '& .title': {
                        minHeight: 'initial',
                        margin: 0,
                        color: 'black',
                    },
                    '& .subtitle': {
                        color: 'black',
                    },
                    '& .MuiAccordionSummary-root': {
                        padding: theme.spacing(0, 3),
                        '& .MuiAccordionSummary-content div': {
                            display: 'flex',
                            gap: theme.spacing(1),
                            alignItems: 'center',
                        },
                    },
                    '& .MuiAccordionDetails-root': {
                        flexDirection: 'column',
                        padding: theme.spacing(2, 3, 3),
                        '& .MuiTypography-caption': {
                            marginTop: theme.spacing(2.75),
                        },
                        '& .subtitle': {
                            textAlign: 'left',
                        },
                    },
                },
                '& .content': {
                    width: '100%',
                    height: 'fit-content',
                },
                '& .list': {
                    maxWidth: 416,
                    padding: theme.spacing(2),
                    borderRadius: '8px 0 0 8px',
                    width: '100%',
                    overflow: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: theme.spacing(3),
                    '&::-webkit-scrollbar-thumb': {
                        borderWidth: 0,
                        borderRadius: 0,
                    },
                    boxShadow: '0px 0px 24px rgba(52, 54, 78, 0.15)',
                    '& .MuiPaper-elevation24': {
                        boxShadow: 'none',
                    },
                    [theme.breakpoints.down('sm')]: {
                        maxWidth: '100%',
                    },
                },
                '& .info': {
                    display: 'flex',
                    margin: theme.spacing(5.75, 0),
                    gap: theme.spacing(1.5),
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'space-around',
                },
            },
            '&.fileGift': {
                display: 'flex',
                gap: theme.spacing(9),
                flexDirection: 'column',
                alignItems: 'center',
                '& .file': {
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    gap: theme.spacing(3),
                    '& .button': {
                        maxWidth: 195,
                    },
                    [theme.breakpoints.down('sm')]: {
                        maxWidth: 'initial',
                        textAlign: 'center',
                        alignItems: 'center',
                    },
                },
            },
        },
    },
    crossSellingSection: {
        '& .grid': {
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(2),
            margin: theme.spacing(6, 0),
            // use "_row" to avoid contact with bootstrap "row"
            '& ._row': {
                display: 'flex',
                gap: theme.spacing(2),
                [theme.breakpoints.down('sm')]: {
                    flexWrap: 'wrap',
                },
                '& .cell': {
                    width: '100%',
                    height: 328,
                    borderRadius: 24,
                    overflow: 'hidden',
                    position: 'relative',
                    '& img': {
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        transition: theme.transitions.create(['transform', 'filter'], {
                            duration: '200ms',
                            easing: 'ease',
                        }),
                    },
                    '&:hover img': {
                        cursor: 'pointer',
                        transform: 'scale(1.1)',
                        filter: 'brightness(80%)',
                    },
                },
            },
        },
        '& .socialBar': {
            display: 'flex',
            gap: theme.spacing(2),
            justifyContent: 'center',
            flexWrap: 'wrap',
            '& .link': {
                width: 80,
                height: 80,
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: '2px solid',
                [theme.breakpoints.down('sm')]: {
                    width: 70,
                    height: 70,
                },
                '& .MuiSvgIcon-root': {
                    fontSize: 24,
                },
            },
        },
        [theme.breakpoints.down('sm')]: {
            '& .title, & .subtitle': {
                textAlign: 'center',
                marginBottom: theme.spacing(2),
            },
        },
    },
    faqSection: {
        '& .questions': {
            display: 'flex',
            flexDirection: 'column',
            ...CSSDependsOnBrowserFlexGapSupporting(
                {
                    gap: theme.spacing(4),
                },
                {
                    '& > *': {
                        marginTop: theme.spacing(4),
                        '&:first-child': {
                            marginTop: 0,
                        },
                    },
                },
            ),
        },
    },
}))
import React, {FC, memo} from 'react'
import Button from '@material-ui/core/Button'
import Drawer from '@material-ui/core/Drawer'
import {ContainerGeneralHeaderSideMenu} from './ContainerGeneralHeaderSideMenu'
import MenuIcon from '@material-ui/icons/Menu'
import {IContainerGeneralHeaderSearchBarProps} from '../searchbar/ContainerGeneralHeaderSearchBar'
import {ECampaignStatus, EGiftType} from '../../../../../../dst-react-core/campaign/campaign.types'

interface IGeneralHeaderSideMenuButtonProps extends IContainerGeneralHeaderSearchBarProps {
    toggleSideMenu: (isOpen: boolean) => void,
    isSideMenuOpen: boolean,
    className?: string,
    setFilterByNewestCallback: (isByNewest: boolean | undefined) => void,
    setFilterByGiftTypeCallback: (giftType: EGiftType | '') => void,
    isFilterByNewest: boolean | undefined,
    filterByGiftType: EGiftType | '',
    filterStatus: ECampaignStatus | '',
    setFilterStatusCallback: (status: ECampaignStatus | '') => void,
    areFiltersAbleToBeOpened: boolean,
}

export const GeneralHeaderSideMenuButton: FC<IGeneralHeaderSideMenuButtonProps> = memo(props => {
    const {
        toggleSideMenu,
        isSideMenuOpen,
        className,
        searchValue,
        applyFilter,
        setSearchValueCallback,
        isSideMenu,
        setFilterByNewestCallback,
        setFilterByGiftTypeCallback,
        isFilterByNewest,
        filterByGiftType,
        filterStatus,
        setFilterStatusCallback,
        areFiltersAbleToBeOpened,
    } = props

    const open = () => toggleSideMenu(true)
    const close = () => toggleSideMenu(false)

    return (
        <>
            <Button
                onClick={open}
                className={className}
            >
                <MenuIcon/>
            </Button>
            <Drawer
                open={isSideMenuOpen}
                onClose={close}
            >
                <ContainerGeneralHeaderSideMenu
                    searchValue={searchValue}
                    applyFilter={applyFilter}
                    setSearchValueCallback={setSearchValueCallback}
                    handleClose={close}
                    isSideMenu={isSideMenu}
                    setFilterByNewestCallback={setFilterByNewestCallback}
                    setFilterByGiftTypeCallback={setFilterByGiftTypeCallback}
                    isFilterByNewest={isFilterByNewest}
                    filterByGiftType={filterByGiftType}
                    filterStatus={filterStatus}
                    setFilterStatusCallback={setFilterStatusCallback}
                    areFiltersAbleToBeOpened={areFiltersAbleToBeOpened}
                />
            </Drawer>
        </>
    )
})
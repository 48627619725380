import React, {FC, useCallback, useEffect, useMemo, useState} from 'react'
import {ProfileNavigationMenu} from './ProfileNavigationMenu'
import {useProfileNavigationStyles} from './navigation.styles'
import {IProfileNavigationConfig, useProfileNavigationConfig} from './hooks/profile-navigation.hook'
import {useHistory, useLocation} from 'react-router'
import {useScreen} from '../../../../../dst-react-core/core.hooks'
import {ProfileNavigationMobileMenu} from './mobile/ProfileNavigationMobileMenu'

export interface IContainerProfileNavigationMenuProps {

}

export const ContainerProfileNavigationMenu: FC<IContainerProfileNavigationMenuProps> = props => {
    const navigationClasses = useProfileNavigationStyles()

    const history = useHistory()
    const pathname = useLocation().pathname

    const {isMobile} = useScreen()

    const menuItems = useProfileNavigationConfig()

    const initialSelectedMenuItem = useMemo(
        () => menuItems.find(item => pathname.includes(item.link)),
        [menuItems, pathname],
    )

    const [anchorMobileMenuEl, setAnchorMobileMenuEl] = useState<null | HTMLElement>(null)
    const [selectedMobileItem, setSelectedMobileItem] = useState<IProfileNavigationConfig>(initialSelectedMenuItem as IProfileNavigationConfig)

    const handleMobileMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorMobileMenuEl(event.currentTarget)
    }

    const handleMobileMenuClose = () => {
        setAnchorMobileMenuEl(null)
    }

    const setSelectedMobileItemCallback = (menuItem: IProfileNavigationConfig) => {
        setSelectedMobileItem(menuItem)
    }

    const goToPage = useCallback(
        (endpoint: string) => {
            history.push(endpoint)
        },
        [history],
    )

    const getIsMenuItemActive = useCallback(
        (endpoint: string): boolean => {
            return pathname.includes(endpoint)
        },
        [pathname],
    )

    useEffect(
        () => {
            if(isMobile) {
                setSelectedMobileItem(initialSelectedMenuItem as IProfileNavigationConfig)
            }
        },
        [initialSelectedMenuItem, isMobile]
    )

    return (
        isMobile ?
            (
                <ProfileNavigationMobileMenu
                    {...props}
                    navigationClasses={navigationClasses}
                    menuItems={menuItems}
                    goToPage={goToPage}
                    getIsMenuItemActive={getIsMenuItemActive}
                    anchorMobileMenuEl={anchorMobileMenuEl}
                    handleMobileMenuClick={handleMobileMenuClick}
                    handleMobileMenuClose={handleMobileMenuClose}
                    selectedMobileItem={selectedMobileItem}
                    setSelectedMobileItemCallback={setSelectedMobileItemCallback}
                />
            ) :
            (
                <ProfileNavigationMenu
                    {...props}
                    navigationClasses={navigationClasses}
                    menuItems={menuItems}
                    goToPage={goToPage}
                    getIsMenuItemActive={getIsMenuItemActive}
                />
            )
    )
}
import {FC, useMemo} from 'react'
import {ProfileForm} from './ProfileForm'
import {useSelector} from 'react-redux'
import {personSelector} from '../../../../../../components/auth/redux/auth.selectors'
import {joinDate, parseDate} from 'dst-react-core/utils/date-utils/parse-date.util'
import {
    getYupRequiredDayNumber, getYupRequiredEmail, getYupRequiredMonth,
    getYupRequiredString,
    getYupRequiredYear,
} from 'shared/formik/validation.yup'
import * as Yup from 'yup'
import {useTranslation} from 'react-i18next'
import {IGenderConfigItem, IProfileFormValues, ProfileFormFieldType} from './profile-form.types'
import {useDefaultFormik} from 'dst-react-core/formik/default-formik.hook'
import {EPersonGender, IPerson} from 'dst-react-core/person/person.types'
import {useSchemaValidation} from 'dst-react-core/formik/schema-validation.hook'
import {useUpdateUser} from '../../../hooks/update-user.hook'
import {checkDifferences, cutObjectFields} from 'dst-react-core/utils/js/object-check-differences'
import {IPrincipal} from 'dst-react-core/principal/principal.types'
import {useProfileSubPageFormStyles} from '../../../profile-sub-page-form.styles'

export interface IContainerProfileFormProps {

}

export const ContainerProfileForm: FC<IContainerProfileFormProps> = props => {
    const profileSubPagesFormClasses = useProfileSubPageFormStyles()
    const person = useSelector(personSelector)
    const {t} = useTranslation()

    const nameLabel = t('string.name')
    const surnameLabel = t('string.last_name')
    const emailLabel = t('string.email')
    const birthDateLabel = t('string.birth_date')
    const genderLabel = t('string.sex')
    const saveDataLabel = t('general_dashboard.save_data')

    const {updatePerson, updatePrincipal} = useUpdateUser()

    const parsedBirthDate = useMemo(
        () => parseDate(person?.birthDate || ''),
        [person],
    )

    const initialValues: IProfileFormValues = useMemo(
        () => ({
            name: person?.name || '',
            surname: person?.surname || '',
            email: person?.principal?.email || '',
            gender: person?.gender || '',
            birthDate: {
                dayNumber: parsedBirthDate.dayNumber || '',
                month: parsedBirthDate.month.index || '',
                year: parsedBirthDate.year || '',
            },
        }),
        [person, parsedBirthDate],
    )

    const Schema = useSchemaValidation<ProfileFormFieldType>(
        {
            name: getYupRequiredString(t),
            surname: getYupRequiredString(t),
            gender: getYupRequiredString(t),
            email: getYupRequiredEmail(t),
            birthDate: Yup.object().shape({
                dayNumber: getYupRequiredDayNumber(t),
                month: getYupRequiredMonth(t),
                year: getYupRequiredYear(t),
            }),
        },
    )


    const getBirthDate = (y: string, d: string, m: string) => {
        return joinDate({
            year: parseInt(y),
            dayNumber: parseInt(d),
            month: {
                index: parseInt(m),
            },
        }, true)
    }

    const formik = useDefaultFormik(
        initialValues,
        values => {
            const editedPerson = {
                name: values.name,
                surname: values.surname,
                birthDate: getBirthDate(
                    values.birthDate.year as string,
                    values.birthDate.dayNumber as string,
                    values.birthDate.month as string,
                ),
                gender: values.gender,
            } as IPerson

            const differance = checkDifferences(
                {...initialValues, birthDate: person?.birthDate},
                editedPerson,
            )

            if (differance.areObjectsDifferent) {
                const personRequiredFields = cutObjectFields(editedPerson, differance.differentProps, true) as IPerson
                updatePerson(person?.id as number, personRequiredFields)
            }

            if (initialValues.email !== values.email) {
                updatePrincipal(person?.id as number, {email: values.email} as IPrincipal)
            }
        },
        Schema,
    )

    const genderConfigItems: IGenderConfigItem[] = useMemo(
        () => [
            {
                value: EPersonGender.MALE,
                label: t('gender.male'),
            },
            {
                value: EPersonGender.FEMALE,
                label: t('gender.female'),
            },
            {
                value: EPersonGender.OTHER,
                label: t('gender.other'),
            },
        ],
        [t],
    )

    return (
        <ProfileForm
            {...props}
            profileSubPagesFormClasses={profileSubPagesFormClasses}
            formik={formik}
            nameLabel={nameLabel}
            surnameLabel={surnameLabel}
            emailLabel={emailLabel}
            birthDateLabel={birthDateLabel}
            genderLabel={genderLabel}
            genderConfigItems={genderConfigItems}
            saveDataLabel={saveDataLabel}
        />
    )
}